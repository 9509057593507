import React from "react";
import { Error, Info, Warning } from "@mui/icons-material";
import PropTypes from "prop-types";

export const levels = {
  low: "low",
  middle: "middle",
  high: "high",
};

const LevelIcon = ({ value }) => {
  switch (value) {
    case levels.low:
      return (
        <Info
          fontSize="small"
          className="recs_item__description_item_icon"
          color="success"
        />
      );
    case levels.middle:
      return (
        <Error
          fontSize="small"
          className="recs_item__description_item_icon"
          color="warning"
        />
      );
    case levels.high:
      return (
        <Warning
          fontSize="small"
          className="recs_item__description_item_icon"
          color="error"
        />
      );
    default:
      return <div className="recs_item__description_item_icon">{value}</div>;
  }
};

LevelIcon.propTypes = {
  value: PropTypes.string.isRequired,
};

export default LevelIcon;
