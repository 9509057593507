import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs, tabsClasses } from "@mui/material";
import CTabPanel from "./CTabPanel";
import { PropTypes } from "prop-types";
import classnames from "classnames";
import { styled } from "@mui/material/styles";
import Preloader from "./Preloader";
import Query from "../helpers/query";
import { useNavigate } from "react-router-dom";

const tabPositions = {
  start: "start",
  center: "center",
  end: "end",
};

const StyledTabs = styled(Tabs)(() => ({
  [`&.${tabsClasses.root}`]: {
    width: "fit-content",
  },
  [`.${tabsClasses.indicator}`]: {
    height: 3,
  },
}));

const TabBlock = ({
  className,
  queryParam,
  tabs,
  tabPosition,
  panels,
  handleChangeTab,
}) => {
  const tabBlockClass = classnames({
    tab_block: true,
    [className]: className,
  });

  const query = new Query();
  const navigate = useNavigate();
  let queryTab = queryParam && query.get(queryParam);
  if (tabs && queryTab && !tabs.find((it) => it.value === queryTab)) {
    queryTab = null;
  }

  useEffect(() => {
    if (queryParam && queryTab !== query.get(queryParam)) {
      query.update({ [queryParam]: queryTab });
      navigate({ search: query.asString() });
    }
  }, [queryParam, queryTab]); // eslint-disable-line react-hooks/exhaustive-deps

  let initTab = queryTab || (tabs && tabs[0].value);
  const [tab, setTab] = useState();

  // todo need refactoring
  if (tab !== initTab) {
    setTab(initTab);
    handleChangeTab && handleChangeTab(initTab);
  }

  const changeTab = (event, newTab) => {
    setTab(newTab);
    if (queryParam && tab !== newTab) {
      query.update({ [queryParam]: newTab });
      navigate({ search: query.asString() });
    }

    handleChangeTab && handleChangeTab(newTab);
  };

  return (
    <div className={tabBlockClass}>
      <Box
        className="tab_block__tabs"
        display="flex"
        justifyContent={tabPosition}
      >
        <StyledTabs value={tab} onChange={changeTab}>
          {tabs &&
            tabs.map((it, i) => (
              <Tab
                key={i}
                {...it}
                className="tab_block__tab"
                style={{ justifySelf: "center" }}
              />
            ))}
        </StyledTabs>
      </Box>
      {panels &&
        panels.map((it, i) => (
          <CTabPanel
            key={i}
            selectedTab={tab}
            {...it}
            className="tab_block__panel"
          >
            {it.loading ? <Preloader /> : it.content}
          </CTabPanel>
        ))}
    </div>
  );
};

TabBlock.propTypes = {
  className: PropTypes.string,
  queryParam: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  tabPosition: PropTypes.oneOf(Object.values(tabPositions)),
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      content: PropTypes.node,
      style: PropTypes.object,
      loading: PropTypes.bool,
    })
  ).isRequired,
  handleChangeTab: PropTypes.func,
};

TabBlock.defaultProps = {
  queryParam: null,
  tabPosition: tabPositions.center,
};

export default TabBlock;
