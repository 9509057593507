import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isDev, sleep } from "../../utils";
import adwizyApi from "../../api/adwizyApi";
import CButton from "../common/CButton";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Close as CloseIcon,
} from "@mui/icons-material/";
import CTable, { types, types as tableTypes } from "../common/CTable";
import ConnectionManagementStatus from "./ConnectionManagementStatus";
import PropTypes from "prop-types";
import { useInformer } from "../../context/InformerProvider";
import NetworkIcon from "../network/NetworkIcon";
import { makeStyles } from "@mui/styles";
import CDialog from "../common/CDialog";
import NetworkConnection from "../network/NetworkConnection";
import NetworkAccountList from "../network/NetworkAccountList";
import { useEvent } from "../../context/EventProvider";
import {
  getConnectionsAccountsEventHandlers,
  getConnectionsEventHandlers,
} from "../../helpers/eventHandlers";
import { getTitleOfNetwork } from "../../enum/CampaignNetworks";

const useStyles = makeStyles((theme) => ({
  networkIcon: {
    marginRight: "0.56rem",
    "& img": {
      width: "1.39rem",
    },
  },
  closeBtn: {
    color: theme.palette.inactive.main,
    "&:hover": {
      color: theme.palette.error.main,
      backgroundColor: "transparent",
    },
  },
}));

const ConnectionManagement = ({ orgId }) => {
  const event = useEvent();
  const informer = useInformer();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [loadingDeleteToken, setLoadingDeleteToken] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [network, setNetwork] = useState(null);
  const [isOpenNetworkConnect, setIsOpenNetworkConnect] = useState(false);
  const [isOpenNetworkEdit, setIsOpenNetworkEdit] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  //todo need refactoring
  const [removedTokenId, setRemoveTokenId] = useState(false);

  const fetchTokens = async () => {
    setLoading(true);
    if (isDev) {
      await sleep(200);
      setTokens(require("../../mocks/org__connections.json").data);
      setLoading(false);
    } else {
      await adwizyApi
        .get(`/org/${orgId}/connections`)
        .then((res) => {
          if (res.data.success) {
            setTokens(res.data.data);
          }
        })
        .catch(informer.showErrorNotice)
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    const eventHandlers = {
      ...getConnectionsEventHandlers(setTokens),
      ...getConnectionsAccountsEventHandlers(setTokens),
    };
    event.subscribe(eventHandlers).catch(informer.showErrorNotice);
    return () => event.unsubscribe(eventHandlers);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orgId) {
      fetchTokens();
    }
  }, [orgId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (network && tokens) {
      const token = tokens.find((it) => it.tokenId === network.tokenId);
      setNetwork((prev) =>
        !loading && token
          ? { ...prev, isAutoAddNewAccounts: token.isAutoAddNewAccounts }
          : null
      );
    }
  }, [tokens]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSuccessAddNetwork = (data) => {
    setIsOpenNetworkConnect(false);
    fetchTokens().then(() => handleEdit(data));
  };

  const handleErrorAddNetwork = (err) => {
    setIsOpenNetworkConnect(false);
    informer.showErrorNotice(err);
  };

  const handleEdit = (data) => {
    let { tokenId, network, name, isAutoAddNewAccounts, loading } = data;
    setNetwork(
      loading ? null : { orgId, tokenId, network, name, isAutoAddNewAccounts }
    );
    setIsOpenNetworkEdit(true);
  };

  const handleDelete = async (tokenId) => {
    setLoadingDeleteToken(true);
    if (isDev) {
      await sleep();
    } else {
      await adwizyApi
        .delete(`/org/${orgId}/connection/${tokenId}/delete`)
        .then(() => fetchTokens())
        .catch(informer.showErrorNotice);
    }
    setLoadingDeleteToken(false);
  };

  const onChangeAutoConnect = (value) => {
    let token;
    if ((token = tokens.find((it) => it.tokenId === network.tokenId))) {
      token.isAutoAddNewAccounts = value;
      setNetwork((prev) => ({ ...prev, isAutoAddNewAccounts: value }));
    }
  };

  return (
    <>
      <CTable
        loading={loading}
        rows={tokens}
        settings={{
          columns: {
            network: {
              title: () => "Network",
              format: tableTypes.custom,
              component: (row) => (
                <Box display="flex" alignItems="center">
                  <NetworkIcon
                    network={row.network}
                    className={classes.networkIcon}
                    withLabel
                  />
                </Box>
              ),
            },
            name: {
              title: () => "Name",
            },
            status: {
              title: () => "Status",
              format: tableTypes.custom,
              component: (row) => (
                <ConnectionManagementStatus
                  status={row.status}
                  timestamp={row.tsDateUpdated}
                />
              ),
            },
            accounts: {
              title: () => "Accounts ID",
              format: tableTypes.custom,
              component: (row) =>
                `${row.accounts.enabled}/${row.accounts.total} connected`,
            },
            actions: {
              title: () => "",
              format: types.custom,
              component: (row) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton onClick={() => handleEdit(row)}>
                    <EditIcon sx={{ fontSize: "1.25rem" }} />
                  </IconButton>
                  <CDialog
                    btn={
                      <IconButton className={classes.closeBtn}>
                        <CloseIcon sx={{ fontSize: "1.66rem" }} />
                      </IconButton>
                    }
                    title="Are you sure?"
                    content={
                      <>
                        Are you sure you want to unlink{" "}
                        <Box component="span" sx={{ color: "#000" }}>
                          {getTitleOfNetwork(row.network)}
                        </Box>
                        ?
                      </>
                    }
                    applyBtnCb={() => handleDelete(row.tokenId)}
                    applyBtnText="Yes"
                    cancelBtnText="No"
                    maxWidth="xs"
                    loading={loadingDeleteToken}
                  />
                </Box>
              ),
              sx: {
                width: "2.22rem",
              },
            },
          },
        }}
        topArea1={
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "2.77rem",
              }}
            >
              <h2 className="subtitle color-primary m-0">
                Connection management
              </h2>
              <CButton
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setIsOpenNetworkConnect(true)}
                sx={{ marginLeft: ".83rem" }}
                endIcon={<AddIcon />}
                disabled={loading}
              >
                Add new connection
              </CButton>
            </Box>
            <Box
              className="table-title"
              sx={{
                marginBottom: "1.66rem",
              }}
            >
              Networks
            </Box>
          </>
        }
      />
      <NetworkConnection
        open={isOpenNetworkConnect}
        onClose={() => setIsOpenNetworkConnect(false)}
        data={{ orgId }}
        onSuccess={handleSuccessAddNetwork}
        onError={handleErrorAddNetwork}
      />
      <NetworkAccountList
        open={isOpenNetworkEdit}
        onClose={async () => {
          await fetchTokens();
          setIsOpenNetworkEdit(false);
          setNetwork(null);
        }}
        data={network}
        removeNetwork={(data) => {
          setRemoveTokenId(data);
          setIsOpenConfirm(true);
        }}
        onChangeAutoConnect={onChangeAutoConnect}
      />

      <CDialog
        open={isOpenConfirm}
        title="Are you sure?"
        content={`
          Are you sure you want to remove this connection?
          It will disconnect all it's accounts
        `}
        onClose={() => setIsOpenConfirm(false)}
        applyBtnCb={async () => {
          await handleDelete(removedTokenId);
          setIsOpenNetworkEdit(false);
          setRemoveTokenId(null);
          fetchTokens();
        }}
        applyBtnText="Yes"
        cancelBtnText="No"
        maxWidth="xs"
        loading={loadingDeleteToken}
      />
    </>
  );
};

ConnectionManagement.propTypes = {
  orgId: PropTypes.number,
};

export default ConnectionManagement;
