import React, { useState, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonUnstyled,
  CardActions,
  FormControl,
  Select,
  TextField,
  Divider,
  buttonUnstyledClasses,
  buttonClasses,
  accordionClasses,
  accordionSummaryClasses,
  accordionDetailsClasses,
  cardActionsClasses,
  selectClasses,
  outlinedInputClasses,
} from "@mui/material";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  ArrowDropDown as ArrowDropDownIcon,
  FilterAlt as FilterAltIcon,
  Sort as SortIcon,
} from "@mui/icons-material/";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import DropdownActions from "./DropdownActions";
import CheckboxList from "./CheckboxList";
import { conditionTypes } from "./CTable";
import theme from "../../theme";
import CMenuItem from "./CMenuItem";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  formControl: {
    fontWeight: 600,
    fontSize: "0.9rem",
    width: "100%",
    marginBottom: 0,

    "& label": {
      fontWeight: 600,
      fontSize: "0.9rem",
    },
  },
  button: {
    display: "flex",
    color: theme.palette.hover.main,
    padding: ".6rem .8rem",
    justifyContent: "flex-start",
  },
  title: {
    padding: ".5rem 1.6rem",
    display: "flex",
    justifyContent: "flex-start",
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.defaultLight.main,
    },
  },
  buttonIconActive: {
    color: `${theme.palette.primary.main} !important`,
  },
  buttonIconOpen: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#fff !important",
  },
}));

const SortButton = styled(ButtonUnstyled)(() => ({
  [`&.${buttonUnstyledClasses.root}`]: {
    width: "100%",
    textAlign: "left",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: 2,
    color: "#000",
    padding: ".55rem 1.72rem .5rem",
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "&:hover, &.active": {
      backgroundColor: "defaultLight.main",
    },
    "& svg": {
      marginLeft: "1em",
    },
  },
}));

const TopButton = styled(Button)(() => ({
  [`&.${buttonClasses.root}`]: {
    fontSize: "0.8rem",
    lineHeight: "1.4rem",
    textDecorationLine: "underline",
    textTransform: "none",
    minWidth: "unset",
    padding: 0,

    "&:not(:first-of-type)": {
      marginLeft: ".72rem",
    },
  },
}));

const StyledAccordion = styled(Accordion)(() => ({
  [`&.${accordionClasses.root}`]: {
    boxShadow: "none",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  [`&.${accordionSummaryClasses.root}`]: {
    marginTop: ".61rem",
    marginBottom: ".2rem",
    padding: 0,
    justifyContent: "flex-start",
    minHeight: "unset",
  },
  [`.${accordionSummaryClasses.content}`]: {
    margin: 0,
    fontSize: "1rem",
    lineHeight: 2,
    fontWeight: 500,
    flexGrow: 0,
  },
  [`.${accordionSummaryClasses.content}.${accordionSummaryClasses.expanded}`]: {
    margin: 0,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  [`&.${accordionDetailsClasses.root}`]: {
    padding: "0 0 1.38rem 0",
  },
}));

const TopCardActions = styled(CardActions)(() => ({
  [`&.${cardActionsClasses.root}`]: {
    padding: ".2rem 0 .3rem 0",
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  [`.${selectClasses.select}`]: {
    borderRadius: 3,
    borderWidth: 1,
    padding: ".7rem",
    fontSize: ".9rem",
    lineHeight: "initial",
    minHeight: "unset !important",
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.borders.main,
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`.${outlinedInputClasses.input}`]: {
    borderRadius: 3,
    borderWidth: 1,
    borderColor: theme.palette.borders.main,
    padding: ".7rem",
    fontSize: ".9rem",
  },
}));

const FilterButton = ({ title, sx, onClick, open, isFiltered, order }) => {
  const isActive = isFiltered || order !== null;
  let icon = (
    <KeyboardArrowDownIcon
      sx={{ transform: open ? "rotate(180deg)" : "rotate(0)" }}
      color="hover"
    />
  );

  if (isFiltered && order) {
    icon = (
      <KeyboardArrowDownIcon
        sx={{ transform: open ? "rotate(180deg)" : "rotate(0)" }}
        color="primary"
      />
    );
  } else if (isFiltered) {
    icon = <FilterAltIcon color="primary" />;
  } else if (order) {
    icon = (
      <SortIcon
        sx={{ transform: order === "asc" ? "rotate(180deg)" : "rotate(0)" }}
        color="primary"
      />
    );
  }

  return (
    <Button
      sx={{
        textTransform: "initial",
        fontWeight: 700,
        fontSize: "1rem",
        padding: ".83rem .77rem",
        marginLeft: "-.83rem",
        ...sx,
      }}
      onClick={onClick}
      endIcon={icon}
      color={isActive ? "primary" : "hover"}
    >
      {title}
    </Button>
  );
};

FilterButton.propTypes = {
  title: PropTypes.node,
  sx: PropTypes.object,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  isFiltered: PropTypes.bool,
  order: PropTypes.string,
};

const FilterCell = ({
  title,
  orderBy,
  order,
  field,
  includeSort,
  includeTextConditions,
  includeNumberConditions,
  includeArrayConditions,
  customConditions,
  customConditionsCallback,
  handleFilter,
  handleSort,
  sx,
  filterValue,
}) => {
  const numberConditions = {
    [conditionTypes.greaterThan]: "Greater than",
    [conditionTypes.greaterThanOrEqual]: "Greater than or equal to",
    [conditionTypes.lessThan]: "Less than",
    [conditionTypes.lessThanOrEqual]: "Less than or equal to",
    [conditionTypes.equal]: "Is equal to",
    [conditionTypes.notEqual]: "Is not equal to",
    [conditionTypes.between]: "Is between",
    [conditionTypes.notBetween]: "Is not between",
  };

  const textConditions = {
    [conditionTypes.contains]: "Text contains",
    [conditionTypes.notContains]: "Text does not contain",
    [conditionTypes.starts]: "Text starts with",
    [conditionTypes.ends]: "Text ends with",
    [conditionTypes.exactly]: "Text is exactly",
  };

  const arrayConditions = {
    [conditionTypes.empty]: "Is empty",
    [conditionTypes.notEmpty]: "Not empty",
  };

  const classes = useStyles();
  const [selectedConditionType, setSelectedConditionType] = useState("");
  const [value, setValue] = useState("");
  const [valueFrom, setValueFrom] = useState("");
  const [valueTo, setValueTo] = useState("");
  const [customValues, setCustomValues] = useState(() => {
    if (filterValue?.type === conditionTypes.includes && filterValue?.value) {
      return filterValue?.value;
    }
    return [];
  });
  const popup = useRef();

  const getConditions = () => {
    const conditions = [];

    if (includeTextConditions) conditions.push(textConditions);

    if (includeNumberConditions) conditions.push(numberConditions);

    if (includeArrayConditions) conditions.push(arrayConditions);

    return conditions;
  };

  const handleChangeCondition = (evt) => {
    const value = evt.target.value;

    setSelectedConditionType(value);
    if (Object.keys(arrayConditions).includes(value)) {
      setValue(value);
    }
  };

  const resetState = () => {
    setSelectedConditionType("");
    setValue("");
    setValueFrom("");
    setValueTo("");
    setCustomValues([]);

    return handleFilter(field, {});
  };

  const handleReset = () => {
    popup.current.handleClose();

    resetState();
  };

  const isDefaultSelectedConditionType = () => {
    return (
      Object.keys(numberConditions).includes(selectedConditionType) ||
      Object.keys(textConditions).includes(selectedConditionType) ||
      Object.keys(arrayConditions).includes(selectedConditionType)
    );
  };

  const handleOk = async () => {
    if (!value && !valueFrom && !valueTo && customValues.length === 0) {
      return resetState();
    }

    if (!isDefaultSelectedConditionType()) {
      if (customConditionsCallback) {
        return handleFilter(field, {
          type: conditionTypes.callback,
          value: customValues,
          callback: customConditionsCallback,
        });
      } else {
        return handleFilter(field, {
          type: conditionTypes.includes,
          value: customValues,
        });
      }
    }

    return handleFilter(field, {
      type: selectedConditionType,
      value: value,
      valueFrom: valueFrom,
      valueTo: valueTo,
    });
  };

  const handleSelectAll = async () => {
    setCustomValues([...Object.keys(customConditions)]);
  };

  const sort = (field, order) => {
    popup.current.handleClose();

    return handleSort(field, order);
  };

  const customValuesHandle = (val) => {
    if (!customValues.includes(val)) {
      setCustomValues((prevState) => [...prevState, val]);
    } else {
      const index = customValues.indexOf(val);
      if (index !== -1) {
        customValues.splice(index, 1);
      }
      setCustomValues([...customValues]);
    }
  };

  const isFilterCell = () => {
    return (
      getConditions().length > 0 ||
      Object.values(customConditions ?? []).length > 0 ||
      includeSort === true
    );
  };

  const isFiltered =
    filterValue !== undefined && Object.keys(filterValue).length !== 0;

  return (
    <>
      {!isFilterCell() ? (
        <Box
          className={classes.filterCell}
          sx={{
            padding: ".83rem 0",
            ...sx,
          }}
        >
          {title}
        </Box>
      ) : (
        <DropdownActions
          ref={popup}
          btn={
            <FilterButton
              title={title}
              sx={sx}
              isFiltered={isFiltered}
              order={orderBy === field ? order : null}
            />
          }
          handleOk={handleOk}
        >
          {includeSort && (
            <Box sx={{ marginBottom: ".33rem" }}>
              <SortButton
                onClick={() => sort(field, "asc")}
                className={orderBy === field && order === "asc" ? "active" : ""}
              >
                Sort in ascending over
                <SortIcon
                  color="inactive"
                  sx={{
                    transform: "rotate(180deg)",
                  }}
                />
              </SortButton>
              <SortButton
                onClick={() => sort(field, "desc")}
                className={
                  orderBy === field && order === "desc" ? "active" : ""
                }
              >
                Sort in descending over
                <SortIcon color="inactive" />
              </SortButton>
            </Box>
          )}
          {(customConditions || getConditions().length > 0) && (
            <Box sx={{ padding: "0 1.61rem" }}>
              {includeSort && <Divider className={"divider"} />}
              <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary
                  expandIcon={<ArrowDropDownIcon color="inactive" />}
                >
                  Filter by value
                </StyledAccordionSummary>

                <StyledAccordionDetails>
                  <TopCardActions>
                    {customConditions && (
                      <TopButton
                        variant="text"
                        color="primary"
                        onClick={handleSelectAll}
                      >
                        Select all
                      </TopButton>
                    )}
                    <TopButton
                      variant="text"
                      color="primary"
                      onClick={handleReset}
                    >
                      Reset
                    </TopButton>
                  </TopCardActions>

                  {getConditions().length > 0 && (
                    <FormControl
                      className={classes.formControl}
                      sx={{ marginBottom: ".38rem" }}
                    >
                      <StyledSelect
                        value={selectedConditionType}
                        onChange={handleChangeCondition}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "dropdown-paper" },
                          variant: "menu",
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: theme.palette.inactive.main,
                          },
                        }}
                      >
                        <CMenuItem value="">
                          <em>None</em>
                        </CMenuItem>
                        {getConditions().map((conditions) => {
                          return Object.keys(conditions).map((key) => (
                            <CMenuItem key={key} value={key}>
                              {conditions[key]}
                            </CMenuItem>
                          ));
                        })}
                      </StyledSelect>
                      {selectedConditionType &&
                        !Object.keys(arrayConditions).includes(
                          selectedConditionType
                        ) && (
                          <>
                            {["<>", "!<>"].includes(selectedConditionType) ? (
                              <>
                                <StyledTextField
                                  type="number"
                                  placeholder="Value from"
                                  value={valueFrom}
                                  variant="outlined"
                                  onChange={(evt) =>
                                    setValueFrom(evt.target.value)
                                  }
                                  sx={{ margin: ".88rem 0 .44rem" }}
                                />
                                and
                                <StyledTextField
                                  type="number"
                                  placeholder="Value to"
                                  value={valueTo}
                                  variant="outlined"
                                  onChange={(evt) =>
                                    setValueTo(evt.target.value)
                                  }
                                  sx={{ margin: ".44rem 0" }}
                                />
                              </>
                            ) : (
                              <StyledTextField
                                placeholder="Value"
                                value={value}
                                variant="outlined"
                                onChange={(evt) => setValue(evt.target.value)}
                                sx={{ marginTop: ".44rem" }}
                              />
                            )}
                          </>
                        )}
                    </FormControl>
                  )}

                  {customConditions && (
                    <CheckboxList
                      list={customConditions}
                      checkedCallback={(val) => customValues.includes(val)}
                      handleChange={customValuesHandle}
                      includeSearch
                    />
                  )}
                </StyledAccordionDetails>
              </StyledAccordion>
            </Box>
          )}
        </DropdownActions>
      )}
    </>
  );
};

FilterCell.propTypes = {
  title: PropTypes.node,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  field: PropTypes.string,
  includeSort: PropTypes.bool,
  includeTextConditions: PropTypes.bool,
  includeNumberConditions: PropTypes.bool,
  includeArrayConditions: PropTypes.bool,
  customConditions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  customConditionsCallback: PropTypes.func,
  handleFilter: PropTypes.func,
  handleSort: PropTypes.func,
  sx: PropTypes.object,
  filterValue: PropTypes.object,
};

export default FilterCell;
