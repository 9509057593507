import React, { createRef, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { withStyles } from "@mui/styles";
import { Box, Grid, IconButton, iconButtonClasses } from "@mui/material";
import { Home, PanTool, ZoomIn } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  chartColors,
  formatValue,
  moneyFormatter,
  nFormatter,
} from "../../utils";
import CSkeleton from "../common/CSkeleton";

const styles = () => ({
  chart: {
    "& .apexcharts-toolbar": {
      display: "none",
    },
    "& .apexcharts-legend": {
      paddingTop: "3.06rem",
    },
    "& .apexcharts-tooltip": {
      boxShadow: "none",
    },
  },
  tooltip: {
    backgroundColor: "#fff",
    borderWidth: 3,
    borderStyle: "solid",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    fontSize: "0.9rem",
    fontWeight: 600,
    width: "4.61rem",
    height: "1.94rem",
    marginBottom: "0.25em",
  },
});

const StyledIconButton = styled(IconButton)(() => ({
  [`&.${iconButtonClasses.root}`]: {
    borderRadius: 4,
    padding: 0,
    width: "1.67rem",
    height: "1.67rem",
    marginRight: 6,
    "&:last-of-type": {
      marginRight: 18,
    },
  },
}));

export const types = {
  line: "Line",
  area: "Area",
};

export const yaxisContent = {
  labels: {
    style: {
      fontSize: "1rem",
      fontWeight: 100,
      colors: ["#072234"],
    },
    formatter: (value) => nFormatter(value).toUpperCase(),
  },
};

const CReactApexLineChart = ({
  classes,
  options,
  series,
  tools,
  title,
  height,
  type,
  loading,
  currency,
}) => {
  const [localSeries, setLocalSeries] = useState([]);
  const chartRef = createRef();
  const [key, setKey] = useState(0);
  const [generatedOptions, setGeneratedOptions] = useState(null);
  const [activeButton, setActiveButton] = useState("zoom");

  const zoomButtonHandle = () => {
    setActiveButton("zoom");
    chartRef.current.chart.el.querySelector(".apexcharts-zoom-icon").click();
  };

  const panButtonHandle = () => {
    setActiveButton("pan");
    chartRef.current.chart.el.querySelector(".apexcharts-pan-icon").click();
  };

  const resetButtonHandle = () => {
    chartRef.current.chart.el.querySelector(".apexcharts-reset-icon").click();
  };

  const isNoData = () => {
    return localSeries.length === 0;
  };

  useEffect(() => {
    const res = [];
    if (series) {
      for (const index in series) {
        const data = series[index].data;

        const a = data.filter((datum) => {
          if (datum && typeof datum === "object") {
            return datum?.y !== null;
          } else {
            return datum !== null;
          }
        });
        if (a.length > 0) {
          res.push(series[index]);
        }
      }
    }
    setLocalSeries(res);
  }, [series]);

  useEffect(() => {
    const defaultStrokeWidth = 5;
    const {
      colors = chartColors,
      chart,
      stroke,
      legend,
      xaxis = {},
      yaxis = {},
      yaxisTitle,
      xaxisTitle,
      tooltip,
      markers,
      ...other
    } = options || {};

    const markersColors = (colors, stroke) =>
      stroke?.dashArray?.length === colors.length
        ? stroke.dashArray.map((it, i) => {
            let w = stroke.width
              ? Array.isArray(stroke.width)
                ? stroke.width[i] //|| stroke.width[i / stroke.width.length]
                : stroke.width
              : defaultStrokeWidth;
            let y = w / 2;
            let c = colors[i].replace("#", "%23");
            let d = it + " " + it;
            return it > 0
              ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46' height='${w}' %3E%3Cline x1='0' x2='46' y1='${y}' y2='${y}' style='stroke: ${c}; stroke-width: ${w}px; stroke-dasharray: ${d}; stroke-linecap: round;'/%3E%3C/svg%3E")`
              : colors[i];
          })
        : colors;

    setGeneratedOptions({
      chart: {
        type: type,
        toolbar: {
          tools: {
            download: false,
            zoomin: false,
            zoomout: false,
          },
        },
        animations: {
          enabled: false,
        },
        ...chart,
      },
      stroke: {
        curve: "smooth",
        lineCap: "round",
        ...stroke,
      },
      legend: {
        showForSingleSeries: true,
        position: "bottom",
        offsetY: 0,
        markers: {
          width: 45,
          height: (stroke && stroke.width) || defaultStrokeWidth,
          offsetX: -10,
          fillColors: markersColors(colors, stroke),
        },
        itemMargin: {
          horizontal: 30,
        },
        ...legend,
      },
      colors,
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        ...xaxis,
        tooltip: {
          enabled: false,
          ...xaxis.tooltip,
        },
        labels: {
          style: {
            // fontSize: '1rem',
            fontWeight: 100,
            colors: ["#072234"],
          },
          ...xaxis.labels,
        },
        title: {
          ...xaxisTitle,
        },
      },
      yaxis:
        Object.keys(yaxis).length > 0
          ? yaxis
          : {
              labels: {
                style: {
                  fontSize: "1rem",
                  fontWeight: 100,
                  colors: ["#072234"],
                },
                formatter: (value) =>
                  currency
                    ? moneyFormatter({ num: value, currency: currency })
                    : formatValue(value).toUpperCase(),
                ...yaxis.labels,
              },
              title: {
                ...yaxisTitle,
              },
            },
      tooltip: {
        y: {
          formatter: (value) =>
            currency
              ? moneyFormatter({
                  num: value,
                  currency: currency,
                  clipped: false,
                })
              : formatValue(value).toUpperCase(),
        },
        ...tooltip,
      },
      markers: {
        strokeColors: colors,
        strokeWidth: 3,
        hover: {
          size: 7,
        },
        size: 1,
        ...markers,
      },
      ...other,
    });
    setKey((key) => key + 1);
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className={"chart-container"}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
        sx={{ marginBottom: 4 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: "0.5em",
            flexGrow: 1,
          }}
        >
          {title && <div className={"charts-title"}>{title}</div>}

          {isNoData() && (
            <Box color="inactive.main" marginLeft="auto">
              There is no data for graphic
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
          <StyledIconButton
            color={activeButton === "zoom" ? "primary" : "inactive"}
            onClick={zoomButtonHandle}
          >
            <ZoomIn />
          </StyledIconButton>
          <StyledIconButton
            color={activeButton === "pan" ? "primary" : "inactive"}
            onClick={panButtonHandle}
          >
            <PanTool sx={{ width: "0.8em" }} />
          </StyledIconButton>
          <StyledIconButton color="inactive" onClick={resetButtonHandle}>
            <Home />
          </StyledIconButton>
          {tools &&
            tools.map((it, i) => <React.Fragment key={i}>{it}</React.Fragment>)}
        </Box>
      </Grid>
      {loading || !generatedOptions ? (
        <CSkeleton width={"100%"} height={height} />
      ) : (
        <ReactApexChart
          key={key}
          ref={chartRef}
          options={generatedOptions}
          series={localSeries}
          height={height}
          className={classes.chart}
          type={type}
        />
      )}
    </Box>
  );
};

CReactApexLineChart.propTypes = {
  classes: PropTypes.object,
  options: PropTypes.shape({
    colors: PropTypes.array,
    chart: PropTypes.object,
    stroke: PropTypes.object,
    legend: PropTypes.object,
    xaxis: PropTypes.object,
    yaxis: PropTypes.object,
    xaxisTitle: PropTypes.object,
    yaxisTitle: PropTypes.object,
    tooltip: PropTypes.object,
    markers: PropTypes.object,
  }),
  series: PropTypes.array,
  tools: PropTypes.arrayOf(PropTypes.node),
  title: PropTypes.node,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(Object.keys(types)),
  loading: PropTypes.bool,
  currency: PropTypes.string,
};

CReactApexLineChart.defaultProps = {
  height: 500,
  type: Object.keys(types)[0],
  loading: false,
};

export default withStyles(styles)(CReactApexLineChart);
