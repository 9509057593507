import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CTable, { types as tableTypes } from "../common/CTable";
import { useUser } from "../../context/UserProvider";
import AppInfo from "./AppInfo";
import AppMMPStatus from "./AppMMPStatus";
import AppMMPTarget from "./AppMMPTarget";
import { AccessEntity, AccessLevelsEnum, isAccess } from "../../enum/ACL";
import { AppstoreTypesEnum } from "../../enum/AppstoreTypes";
import { useParams } from "react-router-dom";

const AppsSettings = () => {
  const user = useUser();
  const params = useParams();
  const orgId = Number(params.orgId);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user?.apps !== null) {
      setLoading(false);
    }
  }, [user?.apps]);

  return (
    <Box
      sx={{
        "& th": {
          pt: "1.1rem !important",
          pb: "1.1rem !important",
        },
        "& th:first-of-type": {
          pl: ".88rem",
        },
      }}
    >
      <CTable
        loading={loading || user.ACL === undefined}
        rows={user?.apps ?? []}
        settings={{
          columns: {
            appName: {
              title: () => <div className="f-w-700">App name</div>,
              format: tableTypes.custom,
              component: (row) => (
                <AppInfo
                  appName={row?.appName}
                  appstore={row?.appstore}
                  orgId={orgId}
                  appId={row?.appId}
                  externalAppId={row?.externalAppId}
                  platform={row?.platform}
                  appIcon={row?.appIcon}
                />
              ),
              sx: {
                minWidth: "23rem",
                width: "23rem",
              },
            },
            MMPStatus: {
              title: () => <div className="f-w-700">MMP Status</div>,
              format: tableTypes.custom,
              component: (row) => (
                <AppMMPStatus
                  appstore={row?.appstore}
                  MMPConnectionStatus={row?.mmpConnectionStatus}
                  MMPServiceName={row?.mmpName}
                />
              ),
              sx: {
                minWidth: "17.5rem",
                width: "17.5rem",
                textAlign: "center",
              },
            },
            actions: {
              title: () => null,
              format: tableTypes.custom,
              component: (row) => {
                if (
                  row.appstore === AppstoreTypesEnum.Omni ||
                  !isAccess(
                    AccessLevelsEnum.manage,
                    user.ACL?.[AccessEntity.MMP]?.[row?.appId]
                  )
                ) {
                  return null;
                }

                return (
                  <AppMMPTarget
                    complexAppId={row?.appId}
                    orgId={orgId}
                    MMPConnectionStatus={row?.mmpConnectionStatus}
                    MMPServiceName={row?.mmpName}
                    appName={row?.appName}
                    target={row?.targetData?.target}
                    targetValue={row?.targetData?.targetValue}
                    targetCurrency={row?.targetData?.currency}
                    preferredCurrencies={row?.currencies ?? []}
                  />
                );
              },
            },
          },
          default: {
            order: "asc",
            orderBy: "appName",
          },
        }}
      />
    </Box>
  );
};

export default AppsSettings;
