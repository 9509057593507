import {
  CreditCard as CreditCardIcon,
  AccountBalance as AccountBalanceIcon,
} from "@mui/icons-material";

export const PaymentMethodsEnum = {
  BankCard: "bank-card",
  BankTransfer: "bank-transfer",
  Skip: "skip",
};

export const PaymentMethods = {
  [PaymentMethodsEnum.BankCard]: {
    title: "Bank card",
    icon: CreditCardIcon,
  },
  [PaymentMethodsEnum.BankTransfer]: {
    title: "Bank transfer",
    icon: AccountBalanceIcon,
  },
};
