import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormHelperText,
  outlinedInputClasses,
  Select,
  TextField,
} from "@mui/material";
import CButton from "../common/CButton";
import EmptyDialog from "../EmptyDialog";
import PropTypes from "prop-types";
import theme from "../../theme";
import adwizyApi from "../../api/adwizyApi";
import { useInformer } from "../../context/InformerProvider";
import CSkeleton from "../common/CSkeleton";
import { MMPTargetsEnum, MMPTargetsMapping } from "../../enum/MMPTargets";
import AppId from "../../helpers/appId";
import MMPTargetConversionList, {
  defaultConvWindowsValue,
} from "./MMPTargetConversionList";
import { getCurrencies, getCurrencySymbol } from "../../utils";
import CMenuItem from "../common/CMenuItem";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material/";
import { useUser } from "../../context/UserProvider";
import { getMetricsScoresAndRecsHandler } from "../../helpers/eventHandlers";
import { events, useEvent } from "../../context/EventProvider";

const MMPTargetConversionSetup = ({
  complexAppId,
  orgId,
  appName,
  btn,
  open,
  currencyValues,
  targetCurrency,
  target,
  targetValue,
  onSetTargetData,
}) => {
  const user = useUser();
  const informer = useInformer();
  const event = useEvent();
  const dialog = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [newTarget, setNewTarget] = useState("");
  const [newTargetValue, setNewTargetValue] = useState(0);
  const [newCurrency, setNewCurrency] = useState(null);
  const [errorTarget, setErrorTarget] = useState("");
  const [errorTargetValue, setErrorTargetValue] = useState("");
  const [errorRows, setErrorRows] = useState("");
  const [errorHasConversionsValue, setErrorHasConversionsValue] =
    useState(false);
  const [disableOkBtn, setDisableOkBtn] = useState(false);

  const targetValueRoasErrorText = "Value must be at least 1 and at most 1000";

  const localOnClose = () => {
    setIsOpen(false);
  };
  const openHandle = async () => {
    setErrorTargetValue("");
    setIsOpen(true);
  };

  const handleChangeTargetValue = (e) => {
    let input = e.target.value;

    if (input.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
      setNewTargetValue(input);
      if (newTarget === MMPTargetsEnum.roas && (input > 1000 || input < 1)) {
        setErrorTargetValue(targetValueRoasErrorText);
      } else {
        setErrorTargetValue("");
      }
    }
  };

  const handleChangeTarget = (e) => {
    const value = e.target.value;
    setNewTarget(value);
    setErrorTarget("");
    if (
      value === MMPTargetsEnum.roas &&
      (newTargetValue > 1000 || newTargetValue < 1)
    ) {
      setErrorTargetValue(targetValueRoasErrorText);
    } else {
      setErrorTargetValue("");
    }
  };

  const fetchTargetConversions = async () => {
    setLoading(true);
    setDisableOkBtn(true);
    await adwizyApi
      .get("/mmp/target-conversions", {
        orgId: orgId,
        appstoreAppId: AppId(complexAppId).appstoreAppId,
        appId: AppId(complexAppId).appId,
      })
      .then((res) => {
        if (res.data.success) {
          setRows(
            res.data.data.map((row) => {
              if (row.conversionWindow === null) {
                row.conversionWindow = defaultConvWindowsValue;
              }

              return row;
            })
          );
        } else {
          informer.showErrorNotice(res.data.message);
        }
      })
      .catch(informer.showErrorNotice)
      .finally(() => {
        setLoading(false);
        setDisableOkBtn(false);
      });
  };

  const setTargetData = async () => {
    setDisableOkBtn(true);
    let error = false;
    if (!newTarget) {
      setErrorTarget("This field is required");
      error = true;
    }
    if (!newTargetValue) {
      setErrorTargetValue("This field is required");
      error = true;
    }
    if (
      newTarget === MMPTargetsEnum.roas &&
      (newTargetValue > 1000 || newTargetValue < 1)
    ) {
      setErrorTargetValue(targetValueRoasErrorText);
      error = true;
    }
    if (newTarget !== MMPTargetsEnum.roas && newTargetValue < 0.01) {
      setErrorTargetValue("Value must be at least 0.01");
      error = true;
    }

    const countIsChecked = Object.values(rows).filter(
      (row) => row.isChecked
    ).length;
    if (countIsChecked === 0) {
      setErrorRows("You must select at least one conversion");
      error = true;
    }

    if (!error) {
      await adwizyApi
        .post("/mmp/target", {
          orgId: orgId,
          appstoreAppId: AppId(complexAppId).appstoreAppId,
          appId: AppId(complexAppId).appId,
          target: newTarget,
          targetValue: newTargetValue,
          currency: newTarget === MMPTargetsEnum.roas ? null : newCurrency,
          targetConversionData: rows,
        })
        .then((res) => {
          if (res.data.success) {
            informer.showSuccessNotice(res.data.message);
          } else {
            informer.showErrorNotice(res.data.message);
          }
        })
        .catch(informer.showErrorNotice)
        .finally(() => {
          setIsOpen(false);
          if (typeof onSetTargetData === "function") {
            onSetTargetData();
          }
        });

      await user.userAppsListChanged().finally(() => setLoading(false));
    }
    setDisableOkBtn(false);
  };

  useEffect(() => {
    if (open != null) {
      setIsOpen(open);
    }
  }, [open]);

  useEffect(() => {
    if (isOpen) {
      if (targetCurrency) {
        setNewCurrency(targetCurrency);
      } else {
        if (currencyValues[0]) {
          setNewCurrency(currencyValues[0]);
        }
      }
      if (target) {
        setNewTarget(target);
      }
      if (targetValue) {
        setNewTargetValue(targetValue);
      }

      fetchTargetConversions();
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setErrorRows("");
  }, [rows]);

  useEffect(() => {
    if (errorHasConversionsValue) {
      setDisableOkBtn(true);
    } else {
      if (!errorRows && !errorTarget && !errorTargetValue) {
        setDisableOkBtn(false);
      }
    }
  }, [errorHasConversionsValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      disableOkBtn &&
      newTarget !== MMPTargetsEnum.budgetROAS &&
      newTarget !== MMPTargetsEnum.roas
    ) {
      setDisableOkBtn(false);
    }
  }, [newTarget]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handler = getMetricsScoresAndRecsHandler(
      () => {
        if (dialog.current.offsetParent !== null) {
          setDisableOkBtn(true);
          informer.showWarningNotice(
            "Target settings has been updated. Please reopen this dialog."
          );
        }
      },
      ({ ids }) => ids?.appId === complexAppId
    );
    const eventHandlers = {
      [events.MMPTargetUpdated]: handler,
      [events.MMPTargetConversionsUpdated]: handler,
    };
    event.subscribe(eventHandlers).catch(informer.showErrorNotice);
    return () => event.unsubscribe(eventHandlers);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ArrowDownIcon = (props) => (
    <KeyboardArrowDownIcon
      {...props}
      sx={{
        color: `${theme.palette.inactive.main} !important`,
        fontSize: "1.25em",
      }}
    />
  );

  const selectInputStyles = {
    padding: ".5rem .66rem",
    fontSize: ".83rem",
    lineHeight: "1.5rem",
  };

  return (
    <EmptyDialog
      btn={btn}
      onClickBtn={openHandle}
      open={isOpen}
      onClose={localOnClose}
      maxWidth="md"
      sx={{
        paper: {
          padding: "3rem 2.27rem",
        },
      }}
    >
      <Box
        ref={dialog}
        className="connection-dialog__content"
        sx={{ width: "31rem" }}
      >
        <Box className="connection-dialog__title" sx={{ mb: "1.55rem" }}>
          Target {appName}
        </Box>
        <div className="connection-dialog__content">
          <Box
            sx={{
              fontSize: ".83rem",
              mb: ".5rem",
            }}
          >
            Set your optimization goal and target on app level. This setting
            will only impact reporting in Adwizy and recommendations in our
            platform.
          </Box>

          <Box
            sx={{
              display: "grid",
              gridGap: ".83rem",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            {loading ? (
              <>
                <CSkeleton className="m-b-1" />
                <CSkeleton className="m-b-1" />
              </>
            ) : (
              <>
                <div>
                  <div className="text-field__label">Optimization</div>
                  <Select
                    value={newTarget}
                    onChange={handleChangeTarget}
                    IconComponent={ArrowDownIcon}
                    className="w-100"
                    sx={{
                      [`& .${outlinedInputClasses.input}`]: selectInputStyles,
                    }}
                    MenuProps={{
                      classes: { paper: "dropdown-paper dropdown-paper-mini" },
                    }}
                  >
                    {Object.keys(MMPTargetsMapping).map((key, index) => (
                      <CMenuItem key={index} value={key}>
                        <Box sx={{ color: "hover.main" }}>
                          {MMPTargetsMapping[key]}
                        </Box>
                      </CMenuItem>
                    ))}
                  </Select>

                  <FormHelperText error={true}>{errorTarget}</FormHelperText>
                </div>

                <div>
                  <div className="text-field__label">Target</div>

                  <div className="d-flex">
                    {newTarget !== MMPTargetsEnum.roas && (
                      <Select
                        value={newCurrency}
                        onChange={(e) => setNewCurrency(e.target.value)}
                        IconComponent={ArrowDownIcon}
                        sx={{
                          [`& .${outlinedInputClasses.input}`]:
                            selectInputStyles,
                          [`& .${outlinedInputClasses.notchedOutline}`]: {
                            borderRight: "none",
                            borderRadius: "3px 0 0 3px",
                          },
                          flexShrink: 0,
                        }}
                        MenuProps={{
                          classes: {
                            paper: "dropdown-paper dropdown-paper-mini",
                          },
                        }}
                      >
                        {Object.keys(getCurrencies()).map((currency, index) => (
                          <CMenuItem key={index} value={currency}>
                            <Box sx={{ color: "hover.main" }}>
                              {currency}{" "}
                              {getCurrencySymbol(currency) !== currency &&
                                `(${getCurrencySymbol(currency)})`}
                            </Box>
                          </CMenuItem>
                        ))}
                      </Select>
                    )}
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="none"
                      inputProps={{
                        sx: {
                          padding: ".5rem .78rem",
                          fontSize: ".83rem",
                          height: "1.5rem",
                        },
                      }}
                      sx={{
                        [`& .${outlinedInputClasses.notchedOutline}`]:
                          newTarget === MMPTargetsEnum.roas
                            ? {}
                            : {
                                borderRadius: "0 3px 3px 0",
                              },
                      }}
                      InputProps={{
                        style: { fontSize: ".83rem" },
                        startAdornment:
                          newTarget === MMPTargetsEnum.roas && "%",
                      }}
                      value={newTargetValue ?? ""}
                      onChange={handleChangeTargetValue}
                    />
                  </div>
                  <FormHelperText error={true}>
                    {errorTargetValue}
                  </FormHelperText>
                </div>
              </>
            )}
          </Box>

          <MMPTargetConversionList
            complexAppId={complexAppId}
            rows={rows}
            considerHasConversionsValue={
              newTarget === MMPTargetsEnum.budgetROAS ||
              newTarget === MMPTargetsEnum.roas
            }
            modifyRowsHandler={setRows}
            setErrorHandler={setErrorHasConversionsValue}
            errorMsg={errorRows}
            loading={loading}
          />
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gridGap: ".88rem",
            marginTop: "4.66rem",
          }}
        >
          <CButton
            color="defaultLight"
            variant="contained"
            onClick={localOnClose}
            size="large"
            sx={{
              border: "1px solid",
              borderColor: "borders.main",
            }}
          >
            Cancel
          </CButton>
          <CButton
            color="primary"
            variant="contained"
            onClick={setTargetData}
            size="large"
            disabled={disableOkBtn}
          >
            OK
          </CButton>
        </Box>
      </Box>
    </EmptyDialog>
  );
};

MMPTargetConversionSetup.propTypes = {
  complexAppId: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  orgId: PropTypes.number.isRequired,
  btn: PropTypes.node.isRequired,
  open: PropTypes.bool,
  currencyValues: PropTypes.array.isRequired,
  onSetTargetData: PropTypes.func,
  targetCurrency: PropTypes.string,
  target: PropTypes.oneOf(Object.values(MMPTargetsEnum)),
  targetValue: PropTypes.number,
};

export default MMPTargetConversionSetup;
