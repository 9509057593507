import React from "react";
import {
  Select,
  selectClasses,
  outlinedInputClasses,
  inputBaseClasses,
  paperClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledSelect = styled(Select)(({ theme, iconOpenColor }) => ({
  [`.${paperClasses.root}`]: {
    backgroundColor: "#000",
  },
  [`.${selectClasses.icon}`]: {
    right: ".66rem",
    top: "calc(50% - .4em)",
    color: theme.palette.inactive.main,
    fontSize: "1.33rem",
  },
  [`.${selectClasses.iconOpen}`]: {
    color: `${iconOpenColor}`,
  },
  [`.${outlinedInputClasses.input}`]: {
    fontSize: "1rem",
    lineHeight: 2,
    color: theme.palette.primary.main,
    padding: ".88rem .66rem !important",

    [`&.${outlinedInputClasses.disabled}`]: {
      [`& ~ .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: "#EEEEEE",
      },
    },
  },
  [`.${selectClasses.filled}`]: {
    [`.${inputBaseClasses.root}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`.${selectClasses.disabled}`]: {
    color: theme.palette.borders.main,
  },
}));

const CSelect = (props) => {
  return (
    <StyledSelect
      MenuProps={{
        classes: { paper: "dropdown-paper" },
        variant: "menu",
      }}
      inputProps={{
        sx: {
          paddingRight: "1.4em !important",
        },
      }}
      {...props}
    />
  );
};

export default CSelect;
