const states = {
  true: "true",
  indeterminate: "indeterminate",
  false: "false",
};

const setInStorage = (key, value) => {
  localStorage.setItem(key, value);
};

const getFromStorage = (key) => {
  return localStorage.getItem(key);
};

const hasInStorage = (key) => {
  return getFromStorage(key) !== null;
};

const removeFromStorage = (key) => {
  hasInStorage(key) && localStorage.removeItem(key);
};

const getBooleanFromStorage = (key) => {
  return hasInStorage(key) &&
    Object.values(states).includes(getFromStorage(key))
    ? getFromStorage(key) === states.true
    : null;
};

const getThreeStatesFromStorage = (key) => {
  return hasInStorage(key) &&
    Object.values(states).includes(getFromStorage(key))
    ? getFromStorage(key)
    : null;
};

export {
  states,
  setInStorage,
  getFromStorage,
  hasInStorage,
  removeFromStorage,
  getBooleanFromStorage,
  getThreeStatesFromStorage,
};
