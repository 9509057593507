import React from "react";
import AuthTemplate from "../../templates/AuthTemplate";
import { useAuth } from "../../context/AuthProvider";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

const NotFoundPage = () => {
  const auth = useAuth();

  return (
    <AuthTemplate>
      <div className="window">
        <div className="title">Page not found</div>
        <Box textAlign="center">Oops.. This page does not exists</Box>
        <div className="alternative_offer">
          {auth.isAuthorized ? (
            <Link to="/">Go Home</Link>
          ) : (
            <>
              You can <Link to="/auth/sign-in">Sign in</Link> or{" "}
              <Link to="/auth/sign-up">Sign up</Link>
            </>
          )}
        </div>
      </div>
    </AuthTemplate>
  );
};

export default NotFoundPage;
