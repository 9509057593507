import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { PropTypes } from "prop-types";
import { dateFromUnix, dateTimeRenderFormat } from "../../utils";

const useStyles = makeStyles((theme) => ({
  status: {
    fontSize: "1rem",
    fontWeight: 500,
    color: theme.palette.inactive.main,
  },
  statusSuccessful: {
    color: theme.palette.success.main,
  },
  timestamp: {
    fontSize: "0.8rem",
    fontWeight: 400,
    marginTop: 2,
  },
}));

const statusEnum = {
  linked: "linked",
  expired: "expired",
  revoked: "revoked",
  invalid: "invalid",
};

const statusTitle = {
  linked: "Linked",
  expired: "Expired",
  revoked: "Revoked",
  invalid: "Invalid",
};

const ConnectionManagementStatus = ({ status, timestamp }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box
        className={classNames({
          [classes.status]: true,
          [classes.statusSuccessful]: status === statusEnum.linked,
        })}
      >
        {statusTitle[status]}
      </Box>
      {timestamp && (
        <Box className={classes.timestamp}>
          {dateFromUnix(timestamp, dateTimeRenderFormat)}
        </Box>
      )}
    </Box>
  );
};

ConnectionManagementStatus.propTypes = {
  status: PropTypes.oneOf(Object.keys(statusEnum)).isRequired,
  timestamp: PropTypes.number,
};

export default ConnectionManagementStatus;
