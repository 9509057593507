/**
 * @param {function(queue: array): Promise<any>} request
 * @param {number} timeout
 * @returns {{push: function(item: any): void, getPromise: function(): Promise<any>|null}}
 */
const RequestQueue = (request, timeout = 1000) => {
  let promise = null;
  let queue = [];

  return {
    push: (item) => {
      queue.push(item);
      if (!promise) {
        promise = new Promise((res, rej) =>
          setTimeout(() => {
            request(queue).then(res).catch(rej);
            promise = null;
            queue = [];
          }, timeout)
        );
      }
    },
    getPromise: () => promise,
  };
};

export default RequestQueue;
