import React from "react";
import CIcon from "../CIcon";
import { PropTypes } from "prop-types";
import { Box } from "@mui/material";
import { MMPServices } from "../../enum/MMPServices";

const MMPIcon = ({ service, withLabel, customLabel, className, style }) => {
  const link = MMPServices[service].icon;
  const alt = MMPServices[service].title;

  // eslint-disable-next-line no-mixed-operators
  let label = customLabel || (withLabel && alt);
  return link && alt ? (
    <Box display="flex" alignItems="center" className={className} style={style}>
      <CIcon link={link} alt={alt} />
      {label && (
        <Box marginLeft={2} width="max-content">
          {label}
        </Box>
      )}
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" alignItems="center">
      {customLabel ?? alt}
    </Box>
  );
};

MMPIcon.propTypes = {
  service: PropTypes.oneOf(Object.keys(MMPServices)).isRequired,
  withLabel: PropTypes.bool,
  customLabel: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

MMPIcon.defaultProps = {
  withLabel: false,
};

export default MMPIcon;
