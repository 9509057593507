import React from "react";
import CIcon from "../CIcon";
import GoogleAds from "../../icons/google.svg";
import Facebook from "../../icons/facebook.svg";
// import TikTokAds from "../../icons/tiktok.svg";
import { PropTypes } from "prop-types";
import { Box } from "@mui/material";
import { CampaignNetworksEnum } from "../../enum/CampaignNetworks";

const NetworkIcon = ({ network, withLabel, customLabel, className, style }) => {
  let link;
  let alt;

  switch (network) {
    case CampaignNetworksEnum.googleAds:
      link = GoogleAds;
      alt = "Google Ads";
      break;
    case CampaignNetworksEnum.facebookAds:
      link = Facebook;
      alt = "Facebook Ads";
      break;
    case CampaignNetworksEnum.tiktokAds:
      link = null; // TikTokAds
      alt = "TikTok Ads";
      break;
    default:
      break;
  }

  // eslint-disable-next-line no-mixed-operators
  let label = customLabel || (withLabel && alt);
  return (
    <Box className={className} display="flex" alignItems="center" style={style}>
      {link && alt ? (
        <>
          <CIcon link={link} alt={alt} />
          {label && (
            <Box marginLeft={2} width="max-content">
              {label}
            </Box>
          )}
        </>
      ) : (
        <Box width="max-content">{customLabel ?? alt}</Box>
      )}
    </Box>
  );
};

NetworkIcon.propTypes = {
  network: PropTypes.string.isRequired,
  withLabel: PropTypes.bool,
  customLabel: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

NetworkIcon.defaultProps = {
  withLabel: false,
};

export default NetworkIcon;
