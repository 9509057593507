import React, { useEffect, useState } from "react";
import LegalTemplate from "../templates/LegalTemplate";
import Preloader from "../components/Preloader";
import { isDev, sleep } from "../utils";
import adwizyApi from "../api/adwizyApi";

const EulaPage = () => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (isDev) {
      await sleep(300);
      setContent(require("../mocks/document__eula.json").data.html);
      setLoading(false);
    } else {
      adwizyApi.get("/document/eula").then((res) => {
        if (res.data.success) {
          setContent(res.data.data.html);
          setLoading(false);
        }
      });
    }
  }, []);

  return (
    <LegalTemplate>
      {loading ? (
        <Preloader />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      )}
    </LegalTemplate>
  );
};

export default EulaPage;
