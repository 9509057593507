export const CampaignNetworksEnum = {
  googleAds: "google-ads",
  facebookAds: "facebook-ads",
  tiktokAds: "tiktok-ads",
};

export const CampaignNetworks = {
  [CampaignNetworksEnum.googleAds]: {
    title: "Google Ads",
  },
  [CampaignNetworksEnum.facebookAds]: {
    title: "Facebook Ads",
  },
  [CampaignNetworksEnum.tiktokAds]: {
    title: "TikTok Ads",
    comingSoon: true,
  },
};

export const CampaignLevelNetworks = {
  [CampaignNetworksEnum.googleAds]: CampaignNetworksEnum.googleAds,
};

export const getTitleOfNetwork = (network) => CampaignNetworks[network]?.title;
