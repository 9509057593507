import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import InfoBar from "../../../components/common/InfoBar";
import {
  countryMappingFilter,
  isDev,
  networkMappingFilter,
  nFormatter,
  dateSendFormat,
  getPeriods,
  arrayEquals,
  htmlDecode,
} from "../../../utils";
import {
  metrics,
  reverseGrowthMetrics,
  getMetricList,
} from "../../../enum/Metrics";
import adwizyApi from "../../../api/adwizyApi";
import { useUser } from "../../../context/UserProvider";
import { useInformer } from "../../../context/InformerProvider";
import CTable, {
  conditionTypes,
  types,
  types as tableTypes,
} from "../../../components/common/CTable";
import StatusIcon from "../../../components/table/StatusIcon";
import ReportChartsBlock from "../../../components/charts/ReportChartsBlock";
import Percent, { variants } from "../../../components/common/Percent";
import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  IconButton,
  Popper,
} from "@mui/material";
import { types as infoPanelTypes } from "../../../components/common/InfoPanel";
import MainTemplate from "../../../templates/MainTemplate";
import NetworkIcon from "../../../components/network/NetworkIcon";
import CTooltip from "../../../components/common/CTooltip";
import AppId from "../../../helpers/appId";
import { getMetricsScoresAndRecsHandler } from "../../../helpers/eventHandlers";
import { useEvent, events } from "../../../context/EventProvider";
import AppIcon from "../../../components/common/AppIcon";
import { StatusesEnum, StatusesMapping } from "../../../enum/Statuses";
import {
  AppstoreTypesEnum,
  getAppstoreMappingValues,
} from "../../../enum/AppstoreTypes";
import { GoalsMapping, getGoalMappingValues } from "../../../enum/Goals";
import {
  DimensionsEnum,
  getDimensionsMappingValues,
} from "../../../enum/Dimensions";
import CButton from "../../../components/common/CButton";
import MMPConnection from "../../../components/MMP/MMPConnection";
import MMPTargetConversionSetup from "../../../components/MMP/MMPTargetConversionSetup";
import {
  hideMMPAlerts,
  isHideMMPAlerts,
  MMPConnectionStatusesEnum,
} from "../../../enum/MMPConnectionStatuses";
import CSkeleton from "../../../components/common/CSkeleton";
import { getTargetString } from "../../../enum/MMPTargets";
import { ErrorOutlineOutlined as ErrorOutlineOutlinedIcon } from "@mui/icons-material/";
import { getTitleOfNetwork } from "../../../enum/CampaignNetworks";
import { AccessEntity, AccessLevelsEnum, isAccess } from "../../../enum/ACL";
import AppMMPStatusDropdownMenu from "../../../components/MMP/AppMMPStatusDropdownMenu";
import theme from "../../../theme";
import { AutoAwesome, Close } from "@mui/icons-material";
import { ReportTypes } from "../../../enum/ReportTypes";
import PropTypes from "prop-types";

const ReportingAppPageContent = ({ orgId, appId, selectedApp }) => {
  const user = useUser();
  const event = useEvent();
  const informer = useInformer();
  const btnTargetRef = useRef(null);

  const [app, setApp] = useState(null);
  const [loadingFetchFilters, setLoadingFetchFilters] = useState(true);
  const [loadingScore, setLoadingScore] = useState(true);
  const [loadingTop, setLoadingTop] = useState(true);
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [loadingTarget, setLoadingTarget] = useState(true);

  const [score, setScore] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [appstores, setAppstores] = useState();
  const [selectedAppstores, setSelectedAppstores] = useState();
  const [countries, setCountries] = useState();
  const [selectedCountries, setSelectedCountries] = useState();
  const [networks, setNetworks] = useState();
  const [selectedNetworks, setSelectedNetworks] = useState();
  const [accounts, setAccounts] = useState();
  const [selectedAccounts, setSelectedAccounts] = useState();
  const [campaignGoals, setCampaignGoals] = useState();
  const [selectedCampaignGoals, setSelectedCampaignGoals] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [filterId, setFilterId] = useState(null);
  const [filterKey, setFilterKey] = useState(0);
  const [successfulFetchFilters, setSuccessfulFetchFilters] = useState(null);

  const [campaignGoalsOfResultCampaigns, setCampaignGoalsOfResultCampaigns] =
    useState(null);
  const [networksOfResultCampaigns, setNetworksOfResultCampaigns] =
    useState(null);
  const [
    campaignStatusesOfResultCampaigns,
    setCampaignStatusesOfResultCampaigns,
  ] = useState(null);
  const [defaultFilter, setDefaultFilter] = useState([]);
  const [targetData, setTargetData] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [MMPAccessLevel, setMMPAccessLevel] = useState(null);

  const fetchFilters = async () => {
    setLoadingFetchFilters(true);
    setLoadingScore(true);
    setLoadingTop(true);
    setLoadingCampaigns(true);
    if (!isDev) {
      setSuccessfulFetchFilters(false);
      await adwizyApi
        .get("/report/app/filter", {
          orgId: orgId,
          appId: selectedApp.appId,
          appstoreAppId: selectedApp.appstoreAppId,
          appstore: selectedAppstores,
          accountId: selectedAccounts,
          network: selectedNetworks,
          countryCode3: selectedCountries,
          campaignGoal: selectedCampaignGoals,
          dateFrom: dateFrom,
          dateTo: dateTo,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            const dataFilter = data.data;
            setAppstores(getAppstoreMappingValues(dataFilter.appstores));
            setCampaignGoals(getGoalMappingValues(dataFilter.campaignGoals));
            setCountries(countryMappingFilter(dataFilter.countryCodes3));
            setNetworks(networkMappingFilter(dataFilter.networks));
            setAccounts(
              Object.keys(dataFilter.accounts).length ? dataFilter.accounts : {}
            );
            setFilterId((prevState) => {
              if (prevState === dataFilter.filterId) {
                setLoadingScore(false);
                setLoadingTop(false);
                setLoadingCampaigns(false);
              }
              return dataFilter.filterId;
            });
            setSuccessfulFetchFilters(true);
          }
        })
        .catch(informer.showErrorNotice);
    }
    setLoadingFetchFilters(false);
  };

  const handleErrorFilter = (error) => {
    if (error?.response?.status === 417 && successfulFetchFilters) {
      setFilterId(null);
      fetchFilters();
    } else {
      informer.showErrorNotice(error);
    }
  };

  const fetchScore = async () => {
    setLoadingScore(true);
    if (!isDev) {
      await adwizyApi
        .get("/report/app/score-card", {
          orgId: orgId,
          filterId: filterId,
          forceConversion: user.forceConversion,
        })
        .then((res) => {
          if (res.data.success === true) {
            const score = res.data.data;
            const periods = getPeriods({ dateFrom, dateTo });

            setScore([
              {
                title: "Total cost",
                currentValue: score.cost?.currentValue,
                previousValue: score.cost?.previousValue,
                currency: score.cost?.currency,
                periods,
              },
              {
                title: "CPA",
                currentValue: score.cpa?.currentValue,
                previousValue: score.cpa?.previousValue,
                currency: score.cpa?.currency,
                periods,
                reverse: reverseGrowthMetrics.includes(metrics.cpa),
              },
              {
                title: "IPM",
                currentValue: score.ipm?.currentValue,
                previousValue: score.ipm?.previousValue,
                periods,
                reverse: reverseGrowthMetrics.includes(metrics.ipm),
              },
              {
                title: "Score",
                currentValue: score.optimizationScore?.currentValue,
                type: infoPanelTypes.score,
                periods,
              },
            ]);
          } else {
            setScore(null);
          }
        })
        .catch((error) => handleErrorFilter(error));
    }
    setLoadingScore(false);
  };

  const fetchCampaigns = async () => {
    setLoadingCampaigns(true);
    if (!isDev) {
      await adwizyApi
        .get("/report/app/campaigns", {
          orgId: orgId,
          filterId: filterId,
          forceConversion: user.forceConversion,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            const campaigns = data.data;
            const campaignGoals = {};
            const networks = {};
            const campaignStatuses = {};
            const defaultFilterValue = {};
            for (const campaign of campaigns) {
              let campaignGoal = campaign.campaignGoal;
              let network = campaign.network;
              let campaignStatus = campaign.campaignStatus;

              if (campaignGoal) {
                campaignGoals[campaignGoal] = GoalsMapping[campaignGoal];
              }
              if (network) {
                networks[network] = getTitleOfNetwork(network);
              }
              if (campaignStatus) {
                campaignStatuses[campaignStatus] =
                  StatusesMapping[campaignStatus];
              }

              if (
                campaignStatus === StatusesEnum.Enabled ||
                campaignStatus === StatusesEnum.Paused
              ) {
                defaultFilterValue[campaignStatus] = campaignStatus;
              }
            }
            setCampaigns(campaigns);
            setCampaignGoalsOfResultCampaigns(campaignGoals);
            setNetworksOfResultCampaigns(networks);
            setCampaignStatusesOfResultCampaigns(campaignStatuses);

            if (
              Object.prototype.hasOwnProperty.call(
                defaultFilterValue,
                StatusesEnum.Enabled
              )
            ) {
              setDefaultFilter([StatusesEnum.Enabled]);
            } else if (
              Object.prototype.hasOwnProperty.call(
                defaultFilterValue,
                StatusesEnum.Paused
              ) &&
              !Object.prototype.hasOwnProperty.call(
                defaultFilterValue,
                StatusesEnum.Enabled
              )
            ) {
              setDefaultFilter([StatusesEnum.Paused]);
            } else {
              setDefaultFilter([]);
            }
          } else {
            setCampaigns([]);
            setCampaignGoalsOfResultCampaigns(null);
            setNetworksOfResultCampaigns(null);
            setCampaignStatusesOfResultCampaigns(null);
            setDefaultFilter([]);
          }
        })
        .catch((error) => handleErrorFilter(error));
    }
    setLoadingCampaigns(false);
  };

  const handleSetFilter = (prevState, val) => {
    if (!prevState) {
      return val;
    }
    return !arrayEquals(prevState, val) ? val : prevState;
  };

  const hideAlert = () => {
    hideMMPAlerts(appId);
    setShowAlert(false);
  };

  useEffect(() => {
    const currentApp = user.apps.find((app) => app.appId === appId);
    setApp(currentApp);
    user.setAppIdOfSelectedApp(currentApp?.appId);
  }, [user.apps, appId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      user.isAllowReport(ReportTypes.base) &&
      (selectedApp?.appId || selectedApp?.appstoreAppId) &&
      selectedCampaignGoals &&
      selectedAppstores &&
      selectedCountries &&
      selectedNetworks &&
      selectedAccounts &&
      dateFrom &&
      dateTo
    ) {
      fetchFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterKey,
    selectedApp,
    selectedCampaignGoals,
    selectedAppstores,
    selectedCountries,
    selectedNetworks,
    selectedAccounts,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    if (app && selectedApp) {
      setTargetData(app?.targetData);
      setLoadingTarget(false);
    }
  }, [app, selectedApp]);

  useEffect(() => {
    if (filterId) {
      fetchScore();
      fetchCampaigns();
    }
  }, [filterId, user.forceConversion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handler = getMetricsScoresAndRecsHandler(
      () => setFilterKey((key) => key + 1),
      ({ payload }) => payload?.appIds?.includes(appId)
    );
    const eventHandlers = {
      [events.orgAppMetricsUpdated]: handler,
      [events.orgAppScoresUpdated]: handler,
      [events.MMPConnectionAdded]: handler,
      [events.MMPConnectionRemoved]: handler,
      [events.MMPTargetUpdated]: handler,
    };
    event.subscribe(eventHandlers).catch(informer.showErrorNotice);
    return () => event.unsubscribe(eventHandlers);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setShowAlert(
      !isHideMMPAlerts(appId) &&
        app?.isAppMap !== true &&
        app?.mmpConnectionStatus === null
    );
  }, [isHideMMPAlerts(appId), app]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMMPAccessLevel(user.ACL?.[AccessEntity.MMP]?.[appId]);
  }, [user.ACL]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (btnTargetRef.current) {
      setAnchorEl(btnTargetRef.current);
    }
  }, [btnTargetRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainTemplate
      isAllowed={user.isAllowReport(ReportTypes.base)}
      title={
        <>
          {app?.appId && (
            <div className="d-flex align-items-center justify-content-between">
              <Box
                className="d-flex align-items-center"
                sx={{ gridGap: "1rem" }}
              >
                <AppIcon
                  complexAppId={app?.appId}
                  appstore={app?.appstore}
                  isAppMap={app?.isAppMap}
                  MMPConnectionStatus={app?.mmpConnectionStatus}
                  MMPServiceName={app?.mmpName}
                  colored
                  sx={{
                    width: "1.72rem",
                    height: "1.72rem",
                  }}
                />
                {app ? htmlDecode(app?.appName) : ""}
              </Box>
              {isAccess(AccessLevelsEnum.manage, MMPAccessLevel) && (
                <>
                  {(app?.mmpConnectionStatus ===
                    MMPConnectionStatusesEnum.Pending ||
                    app?.mmpConnectionStatus ===
                      MMPConnectionStatusesEnum.Disconnected) && (
                    <AppMMPStatusDropdownMenu
                      appstoreAppId={selectedApp?.appstoreAppId}
                      orgId={orgId}
                      appName={app?.mmpName}
                      MMPConnectionStatus={app?.mmpConnectionStatus}
                      MMPServiceName={app?.mmpName}
                      afterDeleteConnectionCb={fetchFilters}
                    />
                  )}
                  {(app?.mmpConnectionStatus ===
                    MMPConnectionStatusesEnum.AwaitingOfSetup ||
                    app?.mmpConnectionStatus ===
                      MMPConnectionStatusesEnum.Connected) && (
                    <Box ref={btnTargetRef}>
                      {loadingTarget ? (
                        <CSkeleton width="12rem" />
                      ) : (
                        <MMPTargetConversionSetup
                          complexAppId={app.appId}
                          orgId={orgId}
                          appName={htmlDecode(app.appName)}
                          btn={
                            app?.mmpConnectionStatus ===
                            MMPConnectionStatusesEnum.Connected ? (
                              <CButton
                                variant="contained"
                                color="success"
                                sx={{
                                  borderRadius: "45px",
                                  padding: ".67rem 1.77rem !important",
                                  gridGap: ".2em",
                                }}
                              >
                                <b>Target:</b>
                                {getTargetString(
                                  targetData?.target,
                                  targetData?.targetValue,
                                  targetData?.currency
                                )}
                              </CButton>
                            ) : (
                              <CButton variant="contained" color="primary">
                                Set target
                              </CButton>
                            )
                          }
                          onSetTargetData={() => {
                            setFilterKey((key) => key + 1);
                          }}
                          currencyValues={app?.currencies}
                          targetCurrency={targetData?.currency}
                          target={targetData?.target}
                          targetValue={targetData?.targetValue}
                        />
                      )}
                    </Box>
                  )}
                </>
              )}
            </div>
          )}
        </>
      }
      filters={[
        {
          title: "Store",
          values: appstores,
          handleSelected: (val) =>
            setSelectedAppstores((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedAppstores,
          multiple: true,
          toFilterValues: true,
          getParam: "appstores",
          disabled: loadingFetchFilters || loadingScore || loadingCampaigns,
          loading: loadingFetchFilters,
        },
        {
          title: "Network",
          values: networks,
          handleSelected: (val) =>
            setSelectedNetworks((prevState) => handleSetFilter(prevState, val)),
          selectedValue: selectedNetworks,
          multiple: true,
          toFilterValues: true,
          getParam: "networks",
          disabled: loadingFetchFilters || loadingScore || loadingCampaigns,
          loading: loadingFetchFilters,
        },
        {
          title: "Account",
          values: accounts,
          handleSelected: (val) =>
            setSelectedAccounts((prevState) => handleSetFilter(prevState, val)),
          selectedValue: selectedAccounts,
          multiple: true,
          toFilterValues: true,
          getParam: "accounts",
          disabled: loadingFetchFilters || loadingScore || loadingCampaigns,
          loading: loadingFetchFilters,
        },
        {
          title: "Campaign goal",
          values: campaignGoals,
          handleSelected: (val) =>
            setSelectedCampaignGoals((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedCampaignGoals,
          multiple: true,
          toFilterValues: true,
          getParam: "campaignGoals",
          disabled: loadingFetchFilters || loadingScore || loadingCampaigns,
          loading: loadingFetchFilters,
        },
        {
          title: "Country",
          values: countries,
          handleSelected: (val) =>
            setSelectedCountries((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedCountries,
          multiple: true,
          toFilterValues: true,
          getParam: "countries",
          disabled: loadingFetchFilters || loadingScore || loadingCampaigns,
          loading: loadingFetchFilters,
        },
      ]}
      datepicker={{
        onChange: ({ start, end }) => {
          setDateFrom(start.format(dateSendFormat));
          setDateTo(end.format(dateSendFormat));
        },
        disabled: loadingFetchFilters || loadingScore || loadingCampaigns,
        withContext: true,
        withQuery: true,
      }}
    >
      {app?.appId &&
        app.appstore !== AppstoreTypesEnum.Omni &&
        isAccess(AccessLevelsEnum.manage, MMPAccessLevel) && (
          <Collapse in={showAlert}>
            <Alert
              variant="outlined"
              severity="warning"
              icon={false}
              sx={{
                p: "1.66rem 2rem",
                mb: "1.66rem",
                bgcolor: "white.main",
                borderWidth: "2px",
              }}
            >
              <Box display="flex" flexDirection="column">
                <AlertTitle
                  className="f-w-700 d-flex align-items-center"
                  color="hover.main"
                  fontSize="1.66rem"
                  sx={{ gridGap: ".44rem" }}
                >
                  MMP
                  <ErrorOutlineOutlinedIcon color="warning" />
                </AlertTitle>
                <Box
                  className="f-w-500 m-b-1"
                  sx={{
                    fontSize: ".83rem",
                  }}
                  color="hover.main"
                >
                  The app doesn&#39;t have a connected MMP (mobile measurement
                  partner). Connect your MMP to Adwizy to benefit from advanced
                  reporting and recommendations.
                </Box>

                <Box
                  className="d-flex align-items-center"
                  sx={{ gridGap: "1rem" }}
                >
                  <MMPConnection
                    appstoreAppId={AppId(app.appId).appstoreAppId}
                    orgId={orgId}
                    btn={
                      <CButton variant="contained" color="primary">
                        Connect
                      </CButton>
                    }
                    cbAfterAddedConnection={() => {
                      fetchFilters();
                      setShowAlert(false);
                    }}
                  />

                  <CButton
                    variant="text"
                    color="inactive"
                    className="text-decoration-underline"
                    onClick={hideAlert}
                  >
                    Hide for 7 days
                  </CButton>
                </Box>
              </Box>
            </Alert>
          </Collapse>
        )}

      <InfoBar
        className="content_block"
        panels={score}
        loading={loadingScore || !score}
        loadingCount={4}
      />

      <ReportChartsBlock
        urlRequest="/report/app/top"
        orgId={orgId}
        filterId={filterId}
        forceConversion={user.forceConversion}
        dimensions={getDimensionsMappingValues([
          DimensionsEnum.account,
          DimensionsEnum.network,
          DimensionsEnum.campaignName,
        ])}
        metrics={getMetricList(
          metrics.targetConversions,
          metrics.cost,
          metrics.cpi,
          metrics.cpa,
          metrics.ipm
        )}
        handleError={handleErrorFilter}
        loading={loadingTop}
        handleChangeLoading={setLoadingTop}
      />

      <CTable
        loading={loadingCampaigns}
        rows={campaigns}
        topArea2={
          <Box className="table-title" sx={{ marginBottom: "0.56rem" }}>
            All campaigns ({campaigns.length})
          </Box>
        }
        settings={{
          columns: {
            campaignName: {
              title: () => "Campaign name",
              format: tableTypes.custom,
              component: (campaign) => (
                <Link
                  to={`/org/${orgId}/reporting/app/${app.appId}/campaign/${campaign.campaignId}`}
                  className={"link"}
                  title={campaign.campaignName}
                >
                  {campaign.campaignName}
                </Link>
              ),
              includeTextConditions: true,
              includeSort: true,
              sx: {
                width: "18rem",
                minWidth: "18rem",
              },
              trim: true,
            },
            campaignStatus: {
              title: () => "Status",
              format: tableTypes.custom,
              component: (campaign) => (
                <Box display="flex" justifyContent="center" paddingRight="2rem">
                  <StatusIcon status={campaign.campaignStatus} />
                </Box>
              ),
              includeSort: true,
              customConditions: campaignStatusesOfResultCampaigns,
              sx: {
                textAlign: "center",
              },
            },
            network: {
              title: () => "Network",
              format: tableTypes.custom,
              component: (campaign) => (
                <Box display="flex" justifyContent="center" paddingRight="2rem">
                  <CTooltip
                    customLabel={
                      <NetworkIcon
                        network={campaign.network}
                        className="table-icon"
                      />
                    }
                    content={getTitleOfNetwork(campaign.network)}
                  />
                </Box>
              ),
              customConditions: networksOfResultCampaigns,
              includeTextConditions: true,
              includeSort: true,
              sx: {
                textAlign: "center",
              },
            },
            campaignGoal: {
              title: () => "Campaign goal",
              format: tableTypes.custom,
              component: (campaign) => GoalsMapping[campaign.campaignGoal],
              customConditions: campaignGoalsOfResultCampaigns,
              includeTextConditions: true,
              includeSort: true,
            },
            cost: {
              title: () => "Cost",
              includeNumberConditions: true,
              includeSort: true,
              format: types.money,
            },
            budgetUtilization: {
              title: () => "Budget utilization",
              format: types.custom,
              component: (campaign) =>
                nFormatter(campaign.budgetUtilization.value),
              includeNumberConditions: true,
              includeSort: true,
            },
            campaignAccuracy: {
              title: () => "Campaign accuracy",
              format: types.custom,
              component: (campaign) =>
                nFormatter(campaign.campaignAccuracy.value),
              includeNumberConditions: true,
              includeSort: true,
            },
            optimizationScore: {
              title: () => "Score",
              format: types.custom,
              component: (campaign) => (
                <Percent
                  variant={variants.text}
                  value={campaign.optimizationScore.value}
                />
              ),
              includeNumberConditions: true,
              includeSort: true,
            },
          },
          default: {
            order: "desc",
            orderBy: "cost",
          },
        }}
        defaultFilters={
          defaultFilter.length
            ? {
                campaignStatus: {
                  type: conditionTypes.includes,
                  value: defaultFilter,
                },
              }
            : {}
        }
      />

      {isAccess(AccessLevelsEnum.manage, MMPAccessLevel) &&
        !loadingTarget &&
        app?.mmpConnectionStatus ===
          MMPConnectionStatusesEnum.AwaitingOfSetup && (
          <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="bottom-end"
            style={{ zIndex: 2 }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                gridGap: ".7rem",
                alignItems: "start",
                bgcolor: theme.palette.white.main,
                borderRadius: "4px",
                border: `2px ${theme.palette.primary.main} solid`,
                padding: ".77rem .9rem",
                mt: ".88rem",
              }}
            >
              <AutoAwesome color="primary" />
              <div className="d-flex flex-direction-column align-items-start">
                <Box className="f-w-700" color="hover.main">
                  New feature launched
                </Box>
                <Box
                  sx={{
                    fontSize: ".66rem",
                    fontWeight: 400,
                    m: ".55rem 0",
                  }}
                  color="hover.main"
                >
                  Set target to track cross-network performance of this app
                  against your business goal.
                </Box>
              </div>
              <IconButton onClick={() => setAnchorEl(null)} size="small">
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Popper>
        )}
    </MainTemplate>
  );
};

ReportingAppPageContent.propTypes = {
  orgId: PropTypes.number,
  appId: PropTypes.object,
  selectedApp: PropTypes.func,
};

const ReportingAppPage = () => {
  const user = useUser();
  const params = useParams();
  const navigate = useNavigate();
  const orgId = Number(params.orgId);
  const paramAppId = params.appId;
  const [selectedApp, setSelectedApp] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const appId = AppId(paramAppId);
    if (user.apps.find((it) => appId.isEqual(it.appId))) {
      if (!selectedApp || !appId.isEqual(selectedApp)) {
        setSelectedApp(appId);
      }
    } else {
      setSelectedApp(null);
      navigate(`/org/${orgId}/reporting`);
    }
  }, [paramAppId, user.apps]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ReportingAppPageContent
      key={paramAppId}
      orgId={orgId}
      appId={paramAppId}
      selectedApp={selectedApp}
    />
  );
};

export default ReportingAppPage;
