/**
 * @param {Object|String} app
 * @returns {AppId}
 */
function AppId(app) {
  if (!(this instanceof AppId)) {
    return new AppId(app);
  }

  if (typeof app === "string") {
    app = parseAppIdString(app);
  }

  this.appId = app.appId;
  this.appstoreAppId = app.appstoreAppId;
}

/**
 * @param {Object|String} app
 * @returns {Boolean}
 */
AppId.prototype.isEqual = function (app) {
  if (typeof app === "string") {
    app = parseAppIdString(app);
  }

  return (
    (this.appId != null && this.appId === app.appId) ||
    (this.appstoreAppId != null && this.appstoreAppId === app.appstoreAppId)
  );
};

/**
 * @param {String} appId
 * @returns {{appId: ?Number, appstoreAppId: ?Number}}
 */
const parseAppIdString = (appId) => {
  const app = {
    appId: null,
    appstoreAppId: null,
  };

  if (appId.startsWith("appId:")) {
    app.appId = parseInt(appId.replace("appId:", ""));
  }

  if (appId.startsWith("appstoreAppId:")) {
    app.appstoreAppId = parseInt(appId.replace("appstoreAppId:", ""));
  }

  return app;
};

AppId.prototype.toString = function () {
  if (this.appId) {
    return `appId:${this.appId}`;
  }

  if (this.appstoreAppId) {
    return `appstoreAppId:${this.appstoreAppId}`;
  }
};

export default AppId;
