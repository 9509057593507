import React, { useEffect, useState } from "react";
import InfoBar from "../../../components/common/InfoBar";
import {
  arrayEquals,
  countryMappingFilter,
  dateSendFormat,
  getPeriods,
  isDev,
  networkMappingFilter,
  htmlDecode,
} from "../../../utils";
import {
  metrics,
  reverseGrowthMetrics,
  getMetricList,
} from "../../../enum/Metrics";
import adwizyApi from "../../../api/adwizyApi";
import { useUser } from "../../../context/UserProvider";
import { Link, useParams } from "react-router-dom";
import CTable, {
  types,
  types as tableTypes,
} from "../../../components/common/CTable";
import { useInformer } from "../../../context/InformerProvider";
import ReportChartsBlock from "../../../components/charts/ReportChartsBlock";
import Percent, { variants } from "../../../components/common/Percent";
import { Box } from "@mui/material";
import { types as infoPanelTypes } from "../../../components/common/InfoPanel";
import MainTemplate from "../../../templates/MainTemplate";
import { useEvent, events } from "../../../context/EventProvider";
import { getMetricsScoresAndRecsHandler } from "../../../helpers/eventHandlers";
import { getAppstoreMappingValues } from "../../../enum/AppstoreTypes";
import {
  DimensionsEnum,
  getDimensionsMappingValues,
} from "../../../enum/Dimensions";
import { ReportTypes } from "../../../enum/ReportTypes";

const ReportingPage = () => {
  const user = useUser();
  const event = useEvent();
  const informer = useInformer();
  const params = useParams();
  const orgId = Number(params.orgId);

  const [loadingFetchFilters, setLoadingFetchFilters] = useState(true);
  const [loadingScore, setLoadingScore] = useState(true);
  const [loadingTop, setLoadingTop] = useState(true);
  const [loadingApps, setLoadingApps] = useState(true);
  const [score, setScore] = useState(null);
  const [apps, setApps] = useState([]);
  const [appstores, setAppstores] = useState();
  const [selectedAppstores, setSelectedAppstores] = useState();
  const [appNames, setAppNames] = useState();
  const [selectedAppNames, setSelectedAppNames] = useState([]);
  const [selectedAppIds, setSelectedAppIds] = useState([]);
  const [selectedAppstoreAppIds, setSelectedAppstoreAppIds] = useState([]);
  const [networks, setNetworks] = useState();
  const [selectedNetworks, setSelectedNetworks] = useState();
  const [accounts, setAccounts] = useState();
  const [selectedAccounts, setSelectedAccounts] = useState();
  const [countries, setCountries] = useState();
  const [selectedCountries, setSelectedCountries] = useState();

  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [filterId, setFilterId] = useState(null);
  const [filterKey, setFilterKey] = useState(0);
  const [successfulFetchFilters, setSuccessfulFetchFilters] = useState(null);

  const fetchFilters = async () => {
    setLoadingFetchFilters(true);
    setLoadingScore(true);
    setLoadingTop(true);
    setLoadingApps(true);
    if (!isDev) {
      setSuccessfulFetchFilters(false);
      await adwizyApi
        .get("/report/ultimate/filter", {
          orgId: orgId,
          appId: selectedAppIds,
          appstoreAppIds: selectedAppstoreAppIds,
          appstore: selectedAppstores,
          accountId: selectedAccounts,
          network: selectedNetworks,
          countryCode3: selectedCountries,
          dateFrom: dateFrom,
          dateTo: dateTo,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            const dataFilter = data.data;
            setAppstores(getAppstoreMappingValues(dataFilter.appstores));
            setCountries(countryMappingFilter(dataFilter.countryCodes3));
            setAppNames(
              Object.keys(dataFilter.apps).length ? dataFilter.apps : {}
            );
            setNetworks(networkMappingFilter(dataFilter.networks));
            setAccounts(
              Object.keys(dataFilter.accounts).length ? dataFilter.accounts : {}
            );
            setFilterId((prevState) => {
              if (prevState === dataFilter.filterId) {
                setLoadingScore(false);
                setLoadingTop(false);
                setLoadingApps(false);
              }
              return dataFilter.filterId;
            });
            setSuccessfulFetchFilters(true);
          }
        })
        .catch(informer.showErrorNotice);
    }
    setLoadingFetchFilters(false);
  };

  const handleErrorFilter = (error) => {
    if (error?.response?.status === 417 && successfulFetchFilters) {
      setFilterId(null);
      fetchFilters();
    } else {
      informer.showErrorNotice(error);
    }
  };

  const fetchScore = async () => {
    setLoadingScore(true);
    if (!isDev) {
      await adwizyApi
        .get("/report/ultimate/score-card", {
          orgId: orgId,
          filterId: filterId,
          forceConversion: user.forceConversion,
        })
        .then((res) => {
          if (res.data.success) {
            const score = res.data.data;
            const periods = getPeriods({ dateFrom, dateTo });

            setScore([
              {
                title: "Total cost",
                currentValue: score.cost?.currentValue,
                previousValue: score.cost?.previousValue,
                currency: score.cost?.currency,
                periods,
              },
              {
                title: "CPA",
                currentValue: score.cpa?.currentValue,
                previousValue: score.cpa?.previousValue,
                currency: score.cpa?.currency,
                periods,
                reverse: reverseGrowthMetrics.includes(metrics.cpa),
              },
              {
                title: "Installs",
                currentValue: score.installs?.currentValue,
                previousValue: score.installs?.previousValue,
                type: infoPanelTypes.number,
                periods,
              },
            ]);
          } else {
            setScore(null);
          }
        })
        .catch((error) => handleErrorFilter(error));
    }
    setLoadingScore(false);
  };

  const fetchApps = async () => {
    setLoadingApps(true);
    if (!isDev) {
      await adwizyApi
        .get("/report/ultimate/apps", {
          orgId: orgId,
          filterId: filterId,
          forceConversion: user.forceConversion,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            setApps(data.data);
          } else {
            setApps([]);
          }
        })
        .catch((error) => handleErrorFilter(error));
    }
    setLoadingApps(false);
  };

  const handleSetFilter = (prevState, val) => {
    if (!prevState) {
      return val;
    }
    return !arrayEquals(prevState, val) ? val : prevState;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const appIds = [];
    const appstoreAppIds = [];
    for (const selectedAppName of selectedAppNames) {
      if (selectedAppName.includes("appId:")) {
        appIds.push(selectedAppName.replace("appId:", ""));
      }
      if (selectedAppName.includes("appstoreAppId:")) {
        appstoreAppIds.push(selectedAppName.replace("appstoreAppId:", ""));
      }
    }
    setSelectedAppIds(appIds);
    setSelectedAppstoreAppIds(appstoreAppIds);
  }, [selectedAppNames]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      user.isAllowReport(ReportTypes.base) &&
      selectedAppIds &&
      selectedAppstoreAppIds &&
      selectedAppstores &&
      selectedCountries &&
      selectedNetworks &&
      selectedAccounts &&
      dateFrom &&
      dateTo
    ) {
      fetchFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterKey,
    user.apps?.length,
    selectedAppIds,
    selectedAppstoreAppIds,
    selectedAppstores,
    selectedCountries,
    selectedNetworks,
    selectedAccounts,
    dateFrom,
    dateTo,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (filterId) {
      fetchScore();
      fetchApps();
    }
  }, [filterId, user.forceConversion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handler = getMetricsScoresAndRecsHandler(() =>
      setFilterKey((key) => key + 1)
    );
    const eventHandlers = {
      [events.orgAppMetricsUpdated]: handler,
      [events.orgAppScoresUpdated]: handler,
    };
    event.subscribe(eventHandlers).catch(informer.showErrorNotice);
    return () => event.unsubscribe(eventHandlers);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainTemplate
      isAllowed={user.isAllowReport(ReportTypes.base)}
      title="Reporting"
      filters={[
        {
          title: "Store",
          values: appstores,
          handleSelected: (val) =>
            setSelectedAppstores((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedAppstores,
          multiple: true,
          toFilterValues: true,
          getParam: "appstores",
          disabled: loadingFetchFilters || loadingScore || loadingApps,
          loading: loadingFetchFilters,
        },
        {
          title: "App name",
          values: appNames,
          handleSelected: (val) =>
            setSelectedAppNames((prevState) => handleSetFilter(prevState, val)),
          selectedValue: selectedAppNames,
          multiple: true,
          toFilterValues: true,
          getParam: "appNames",
          disabled: loadingFetchFilters || loadingScore || loadingApps,
          loading: loadingFetchFilters,
        },
        {
          title: "Network",
          values: networks,
          handleSelected: (val) =>
            setSelectedNetworks((prevState) => handleSetFilter(prevState, val)),
          selectedValue: selectedNetworks,
          multiple: true,
          toFilterValues: true,
          getParam: "networks",
          disabled: loadingFetchFilters || loadingScore || loadingApps,
          loading: loadingFetchFilters,
        },
        {
          title: "Account",
          values: accounts,
          handleSelected: (val) =>
            setSelectedAccounts((prevState) => handleSetFilter(prevState, val)),
          selectedValue: selectedAccounts,
          multiple: true,
          toFilterValues: true,
          getParam: "accounts",
          disabled: loadingFetchFilters || loadingScore || loadingApps,
          loading: loadingFetchFilters,
        },
        {
          title: "Country",
          values: countries,
          handleSelected: (val) =>
            setSelectedCountries((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedCountries,
          multiple: true,
          toFilterValues: true,
          getParam: "countries",
          disabled: loadingFetchFilters || loadingScore || loadingApps,
          loading: loadingFetchFilters,
        },
      ]}
      datepicker={{
        onChange: ({ start, end }) => {
          setDateFrom(start.format(dateSendFormat));
          setDateTo(end.format(dateSendFormat));
        },
        disabled: loadingFetchFilters || loadingScore || loadingApps,
        withContext: true,
        withQuery: true,
      }}
    >
      <InfoBar
        className="content_block"
        panels={score}
        loading={loadingScore || !score}
        loadingCount={3}
      />

      <ReportChartsBlock
        urlRequest="/report/ultimate/top"
        orgId={orgId}
        filterId={filterId}
        forceConversion={user.forceConversion}
        dimensions={getDimensionsMappingValues([
          DimensionsEnum.appstore,
          DimensionsEnum.appstoreApp,
          DimensionsEnum.account,
          DimensionsEnum.network,
          DimensionsEnum.campaignName,
        ])}
        metrics={getMetricList(
          metrics.targetConversions,
          metrics.cost,
          metrics.cpi,
          metrics.cpa,
          metrics.ipm
        )}
        handleError={handleErrorFilter}
        loading={loadingTop}
        handleChangeLoading={setLoadingTop}
      />

      <CTable
        loading={loadingApps}
        rows={apps}
        topArea2={
          <Box className="table-title" sx={{ marginBottom: "10px" }}>
            All applications
          </Box>
        }
        settings={{
          columns: {
            appName: {
              title: () => "App name",
              format: tableTypes.custom,
              component: (app) => (
                <Link
                  to={`/org/${orgId}/reporting/app/${app.appId}`}
                  className={"link"}
                >
                  {htmlDecode(app.appName)}
                </Link>
              ),
              includeTextConditions: true,
              includeSort: true,
              trim: true,
            },
            cost: {
              title: () => "Cost",
              includeNumberConditions: true,
              includeSort: true,
              format: types.money,
            },
            cpi: {
              title: () => "CPI",
              includeNumberConditions: true,
              includeSort: true,
              format: types.money,
            },
            optimizationScore: {
              title: () => "Score",
              format: types.custom,
              component: (campaign) => (
                <Percent
                  variant={variants.text}
                  value={campaign.optimizationScore.value}
                />
              ),
              includeNumberConditions: true,
              includeSort: true,
            },
            roas: {
              title: () => "ROAS",
              includeNumberConditions: true,
              includeSort: true,
              format: types.percent,
            },
          },
          default: {
            order: "desc",
            orderBy: "cost",
          },
        }}
      />
    </MainTemplate>
  );
};

export default ReportingPage;
