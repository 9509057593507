import React, { useEffect, useState } from "react";
import {
  LCWithoutFirst,
  moneyFormatter,
  updateObjectStateBySetter,
} from "../../utils";
import { useInformer } from "../../context/InformerProvider";
import { useInView } from "react-intersection-observer";
import {
  DecisionLabels,
  isHidden,
  isNewRec,
  RecommendationDecisionEnum,
} from "../../enum/RecommendationDecision";
import classnames from "classnames";
import { Box } from "@mui/material";
import CButton from "../common/CButton";
import ProgressButton from "../ProgressButton";
import DropdownFilter from "../common/DropdownFilter";
import TextAccordion from "../TextAccordion";
import PropTypes from "prop-types";
import LevelIcon, { levels } from "./LevelIcon";
import { AccessEntity, AccessLevelsEnum, isAccess } from "../../enum/ACL";
import { useUser } from "../../context/UserProvider";
import { useParams } from "react-router-dom";

const daysToIgnoreVariants = {
  1: "1 day",
  7: "7 days",
  14: "14 days",
  28: "28 days",
};

const prepareText = (string, values, currency) => {
  for (let key in values) {
    if (key === "text") {
      continue;
    }

    if (key.startsWith("(money)")) {
      string = string.replaceAll(
        `%${key}%`,
        moneyFormatter({ num: values[key], currency, clipped: false })
      );
    } else {
      string = string.replaceAll(`%${key}%`, values[key]);
    }
  }

  return string;
};

const prepareAsset = (asset, key) => {
  let text = asset.type.replaceAll("_", " ");
  text = LCWithoutFirst(text) + " (" + asset.id + ")";
  let linkLabel;

  switch (asset.type) {
    case "MARKETING_IMAGE":
      text += asset.display_url ? ": " : "";
      linkLabel = asset.display_url ? "Link to the image" : "";
      break;
    case "YOUTUBE_VIDEO":
      text += asset.display_url ? ": " : "";
      linkLabel = asset.display_url ? "Link to the video" : "";
      break;
    case "HTML5":
    case "HEADLINE":
    case "DESCRIPTION":
    default:
      text += asset.display_text ? ": " + asset.display_text : "";
      break;
  }

  return (
    <li key={key} className="recs_item__description_asset">
      {text}
      {linkLabel && (
        <a href={asset.display_url} target="blank">
          {linkLabel}
        </a>
      )}
    </li>
  );
};

const RecommendationItem = ({
  recId,
  level,
  params,
  decisionData,
  currency,
  showHiddenRecs,
  viewRequestQueue,
  handleChange,
  onChanged,
  fetchRecs,
}) => {
  const user = useUser();
  const informer = useInformer();
  const { appId } = useParams();
  const { ref, inView } = useInView();
  const [isViewing, setIsViewing] = useState(false);
  const [accessLevel, setAccessLevel] = useState(AccessLevelsEnum.view);
  const [processing, setProcessing] = useState(false);
  const [acceptButtonSettings, setAcceptButtonSettings] = useState({});
  const [declineButtonSettings, setDeclineButtonSettings] = useState({});
  const [resetButtonSettings, setResetButtonSettings] = useState({});
  const [ignoreDropdownSettings, setIgnoreDropdownSettings] = useState({});

  useEffect(() => {
    setAccessLevel(user.ACL?.[AccessEntity.App]?.[appId]);
  }, [appId, user.ACL]);

  useEffect(() => {
    if (isNewRec(decisionData.decision)) {
      updateObjectStateBySetter(setAcceptButtonSettings, { show: true });
      updateObjectStateBySetter(setDeclineButtonSettings, { show: true });
      updateObjectStateBySetter(setResetButtonSettings, { show: false });
      updateObjectStateBySetter(setIgnoreDropdownSettings, { show: false });
    } else {
      updateObjectStateBySetter(setAcceptButtonSettings, { show: false });
      updateObjectStateBySetter(setDeclineButtonSettings, { show: false });
      updateObjectStateBySetter(setResetButtonSettings, { show: true });
    }
  }, [decisionData]);

  const localHandleChange = ({ decision, daysToIgnore, data }) => {
    setProcessing(true);
    return handleChange(decision, daysToIgnore)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data?.successful[recId]) {
            onChanged(data === undefined ? { decision } : data);
          } else {
            if (
              res.data.data?.recommendationNotFound[recId] ||
              res.data.data?.alreadyImplemented[recId]
            ) {
              fetchRecs();
            }

            throw "Something went wrong";
          }
        }
      })
      .catch((err) => {
        informer.showErrorNotice(err);
        throw err;
      })
      .finally(() => setProcessing(false));
  };

  const acceptRec = () => {
    updateObjectStateBySetter(setAcceptButtonSettings, { processing: true });
    localHandleChange({
      decision: RecommendationDecisionEnum.accepted,
    }).finally(() =>
      updateObjectStateBySetter(setAcceptButtonSettings, { processing: false })
    );
  };

  const declineRec = () => {
    updateObjectStateBySetter(setDeclineButtonSettings, { processing: true });
    localHandleChange({ decision: RecommendationDecisionEnum.skipped })
      .then(() => waitIgnoreAnswer(30))
      .finally(() =>
        updateObjectStateBySetter(setDeclineButtonSettings, {
          processing: false,
        })
      );
  };

  const ignoreRec = (daysToIgnore) => {
    updateObjectStateBySetter(setIgnoreDropdownSettings, { processing: true });
    localHandleChange({
      decision: RecommendationDecisionEnum.ignored,
      daysToIgnore,
    })
      .then(() => waitIgnoreAnswer(5, daysToIgnore))
      .finally(() =>
        updateObjectStateBySetter(setIgnoreDropdownSettings, {
          processing: false,
        })
      );
  };

  const resetRec = () => {
    updateObjectStateBySetter(setResetButtonSettings, { processing: true });
    localHandleChange({ decision: RecommendationDecisionEnum.viewed }).finally(
      () =>
        updateObjectStateBySetter(setResetButtonSettings, { processing: false })
    );
  };

  const waitIgnoreAnswer = (range, daysToIgnore) => {
    let time = 1;
    let interval;
    const handler = () => {
      if (time > range) {
        clearInterval(interval);
        if (showHiddenRecs) {
          updateObjectStateBySetter(setResetButtonSettings, { progress: null });
          updateObjectStateBySetter(setIgnoreDropdownSettings, { show: false });
        } else {
          onChanged(null);
        }

        return;
      }

      updateObjectStateBySetter(setResetButtonSettings, {
        progress: (100 / range) * time++,
      });
    };

    const stopHandler = () => {
      clearInterval(interval);
      updateObjectStateBySetter(setResetButtonSettings, { progress: null });
    };

    updateObjectStateBySetter(setResetButtonSettings, { cb: stopHandler });
    updateObjectStateBySetter(setIgnoreDropdownSettings, {
      show: true,
      value: daysToIgnore,
      openCb: stopHandler,
      cancelCb: () => waitIgnoreAnswer(5),
    });

    handler();
    interval = setInterval(handler, 1000);
  };

  useEffect(() => {
    if (inView && !decisionData.decision && !isViewing) {
      viewRequestQueue.push(recId);
      setIsViewing(true);
      viewRequestQueue
        .getPromise()
        ?.then((res) => {
          if (res.data.success && res.data.data?.successful[recId]) {
            onChanged({ decision: RecommendationDecisionEnum.viewed });
          }
        })
        .finally(() => setIsViewing(false));
    }
  }, [inView]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        ref={ref}
        className={classnames({
          recs_item__description_item: true,
          "recs_item__description_new-item": !decisionData.decision,
        })}
      >
        <Box
          display="flex"
          className={classnames({
            "recs_item__description_hidden-item":
              isHidden(decisionData.decision) && !ignoreDropdownSettings?.show,
          })}
        >
          <LevelIcon value={level} />
          {prepareText(params.text, params, currency)}
        </Box>
        <Box display="flex" alignItems="center" marginLeft="1.11rem">
          {!isNewRec(decisionData.decision) && (
            <Box marginRight="1rem">
              {DecisionLabels[decisionData.decision]}
            </Box>
          )}
          {isAccess(AccessLevelsEnum.manage, accessLevel) && (
            <>
              {acceptButtonSettings?.show && (
                <CButton
                  color="success"
                  variant="contained"
                  onClick={() => {
                    acceptRec();
                    acceptButtonSettings.cb && acceptButtonSettings.cb();
                  }}
                  disabled={processing}
                  processing={acceptButtonSettings.processing}
                  sx={{ marginRight: "0.6rem" }}
                >
                  Accept
                </CButton>
              )}
              {declineButtonSettings?.show && (
                <CButton
                  color="error"
                  variant="contained"
                  onClick={() => {
                    declineRec();
                    declineButtonSettings.cb && declineButtonSettings.cb();
                  }}
                  disabled={processing}
                  processing={declineButtonSettings.processing}
                >
                  Decline
                </CButton>
              )}
              {resetButtonSettings?.show && (
                <ProgressButton
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    resetRec();
                    resetButtonSettings.cb && resetButtonSettings.cb();
                  }}
                  disabled={processing}
                  processing={resetButtonSettings.processing}
                  progress={resetButtonSettings.progress}
                  progressColor="primaryLight"
                  progressInterval={1000}
                >
                  Reset
                </ProgressButton>
              )}
              {ignoreDropdownSettings?.show && (
                <DropdownFilter
                  title="Ignore for"
                  values={daysToIgnoreVariants}
                  onOpen={ignoreDropdownSettings.openCb}
                  onCancel={ignoreDropdownSettings.cancelCb}
                  handleSelected={(daysToIgnore) => {
                    ignoreRec(daysToIgnore);
                    ignoreDropdownSettings.cb && ignoreDropdownSettings.cb();
                  }}
                  selectedValue={ignoreDropdownSettings.value}
                  disabled={processing}
                  processing={ignoreDropdownSettings.processing}
                  sx={{
                    height: "2.33rem",
                    minWidth: "8.06rem",
                    marginLeft: "0.6rem",
                  }}
                />
              )}
            </>
          )}
        </Box>
      </div>
      {params.asset_ids && (
        <TextAccordion
          className={classnames({
            recs_item__description_assets_block: true,
            "recs_item__description_new-item": !decisionData.decision,
            "recs_item__description_hidden-item":
              isHidden(decisionData.decision) && !ignoreDropdownSettings?.show,
          })}
          title="Asset list"
          titleColor="primary"
        >
          <ul className="recs_item__description_assets">
            {params.asset_ids.map(prepareAsset)}
          </ul>
        </TextAccordion>
      )}
    </>
  );
};

RecommendationItem.propTypes = {
  recId: PropTypes.string,
  level: PropTypes.oneOf(Object.values(levels)).isRequired,
  params: PropTypes.object,
  decisionData: PropTypes.shape({
    decision: PropTypes.oneOf(Object.values(RecommendationDecisionEnum)),
  }),
  currency: PropTypes.string.isRequired,
  showHiddenRecs: PropTypes.bool,
  viewRequestQueue: PropTypes.shape({
    push: PropTypes.func,
    getPromise: PropTypes.func,
  }),
  handleChange: PropTypes.func.isRequired,
  onChanged: PropTypes.func.isRequired,
  fetchRecs: PropTypes.func.isRequired,
};

export default RecommendationItem;
