import React from "react";
import { Button, buttonClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import Preloader from "../Preloader";
import PropTypes from "prop-types";

const StyledButton = styled(Button)(() => ({
  [`&.${buttonClasses.root}`]: {
    textTransform: "initial",
    fontSize: ".9rem",
    lineHeight: 1,
    fontWeight: 600,
    padding: ".67rem 1.22rem",
    boxShadow: "none",
    minWidth: "8.06rem",
    height: "2.33rem",
    [`&.${buttonClasses.sizeSmall}`]: {
      height: "2.17rem",
      minWidth: "6.78rem",
    },
    [`&.${buttonClasses.sizeLarge}`]: {
      fontSize: "1rem",
      padding: ".77rem 1.97rem",
      height: "2.83rem",
    },
    "&.size-huge": {
      lineHeight: "1.27rem",
      padding: ".73rem 2.28rem",
      height: "auto",
      minWidth: "11.6rem",
    },
  },
}));

const CButton = ({ children, size, processing, ...other }) => (
  <StyledButton size={size} {...other}>
    {processing ? (
      <Preloader size={size === "large" ? "2.2rem" : "1.8rem"} />
    ) : (
      children
    )}
  </StyledButton>
);

CButton.propTypes = {
  ...Button.propTypes,
  processing: PropTypes.bool,
};

export default CButton;
