import React, { useEffect, useState } from "react";
import { Box /*, Button*/, Grid /*, TextField*/ } from "@mui/material";
// import CIcon from "../CIcon"
// import NotificationIcon from "../../icons/notification.svg"
// import UserIcon from "../../icons/user.svg"
import { PropTypes } from "prop-types";
import OrgSelect from "./OrgSelect";
import { useUser } from "../../context/UserProvider";

const Header = (/*{ search, setSearch }*/) => {
  const user = useUser();
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    setOrgs(user.orgs);
  }, [user.orgs]);

  return (
    <>
      {orgs.length > 1 && (
        <Grid container id="header" className="block_outer__right">
          <Grid item xs={6}>
            {/*<TextField*/}
            {/*    id="search_input"*/}
            {/*    className="w-100"*/}
            {/*    type="search"*/}
            {/*    label="Search"*/}
            {/*    value={search}*/}
            {/*    color="primary"*/}
            {/*    variant="outlined"*/}
            {/*    onBlur={(event) => setSearch(event.target.value)}*/}
            {/*/>*/}
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="end" className="buttons">
              <OrgSelect />
              {/*<Button className="button icon_button" color="warningClicked" variant="contained">*/}
              {/*    <CIcon link={NotificationIcon} alt="Notification"/>*/}
              {/*</Button>*/}
              {/*<Button className="button icon_button" color="primaryClicked" variant="contained">*/}
              {/*    <CIcon link={UserIcon} alt="User"/>*/}
              {/*</Button>*/}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

Header.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
};

export default Header;
