import React, { useCallback, useEffect, useState } from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";
import Percent, { variants } from "../common/Percent";
import { ArrowRightAlt } from "@mui/icons-material";
import { getStateFromSetter, isDev, sleep } from "../../utils";
import PropTypes from "prop-types";
import PercentBlock from "../common/PercentBlock";
import adwizyApi from "../../api/adwizyApi";
import { RecommendationDecisionEnum } from "../../enum/RecommendationDecision";
import { StrategiesEnum } from "../../enum/Strategies";
import RequestQueue from "../../helpers/requestQueue";
import RecommendationItem from "./RecommendationItem";

const orders = {
  performance: "Performance",
  creatives: "Creatives",
  hygiene: "Hygiene",
};

const Recommendations = ({
  orgId,
  campaignId,
  campaignAdGroupId,
  campaignAdGroupStrategy,
  showHiddenRecs,
  onToggleShowHiddenRecs,
  fetchFunc,
  data,
}) => {
  const [recommendations, setRecommendations] = useState([]);
  const [showHidden, setShowHidden] = useState(showHiddenRecs || false);
  const [viewRequestQueue, setViewRequestQueue] = useState(null);

  useEffect(() => {
    if (data?.recommendations) {
      setRecommendations(data?.recommendations);
    }
  }, [data?.recommendations]);

  useEffect(() => setShowHidden(showHiddenRecs), [showHiddenRecs]);

  useEffect(() => {
    const request = isDev
      ? () => sleep(1000).then(() => ({ data: { success: true } }))
      : (recIds) =>
          adwizyApi.post(`/recommendations/decisions`, {
            orgId,
            campaignId,
            campaignAdGroupId,
            campaignAdGroupStrategy,
            recIds,
            decision: RecommendationDecisionEnum.viewed,
          });

    setViewRequestQueue(RequestQueue(request));
  }, [orgId, campaignId, campaignAdGroupId, campaignAdGroupStrategy]);

  const changeRecDecision = useCallback(
    (rec, decision, daysToIgnore) =>
      isDev
        ? sleep(1000).then(() => ({ data: { success: true } }))
        : adwizyApi.post(`/recommendations/decisions`, {
            orgId,
            campaignId,
            campaignAdGroupId,
            campaignAdGroupStrategy,
            recIds: [rec.recId],
            decision,
            daysToIgnore,
          }),
    [orgId, campaignId, campaignAdGroupId, campaignAdGroupStrategy]
  );

  const updateRecommendations = (order, id, decisionData) =>
    setRecommendations((state) => {
      let idx = state[order].findIndex(({ recId }) => recId === id);
      if (idx !== -1) {
        if (decisionData) {
          state[order][idx]["decisionData"] = decisionData;
        } else {
          state[order].splice(idx, 1);
        }

        return { ...state };
      }

      return state;
    });

  return (
    <Box display="flex" flexDirection="column" className="recs">
      <FormControlLabel
        control={
          <Switch
            checked={showHidden}
            onChange={() =>
              onToggleShowHiddenRecs(!getStateFromSetter(setShowHidden))
            }
          />
        }
        label="Show hidden recs"
        sx={{ marginLeft: "auto" }}
      />
      <PercentBlock
        className="recs_conclusion"
        variant={variants.circleClock}
        items={[
          {
            value: data?.scores?.hygiene,
            description: orders.hygiene,
            divider: "+",
          },
          {
            value: data?.scores?.performance,
            description: orders.performance,
            divider: "+",
          },
          {
            value: data?.scores?.creatives,
            description: orders.creatives,
            divider: (
              <ArrowRightAlt sx={{ fontSize: "2.22rem" }} color="inactive" />
            ),
          },
          {
            value: data?.scores?.quality,
            description: "Quality",
          },
        ]}
      />
      <div className="recs_items">
        {Object.entries(orders).map(([key, label], i) => (
          <Box key={i} display="flex" className="recs_item">
            <Percent
              variant={variants.circleClock}
              value={data?.scores && data?.scores[key]}
            />
            <div className="recs_item__description">
              <h3 className="recs_item__description_title subtitle">{label}</h3>
              {recommendations && recommendations[key]?.length ? (
                recommendations[key].map((rec) => (
                  <RecommendationItem
                    key={rec.recId}
                    currency={data?.currency}
                    showHiddenRecs={showHidden}
                    viewRequestQueue={viewRequestQueue}
                    handleChange={(decision, daysToIgnore) =>
                      changeRecDecision(rec, decision, daysToIgnore)
                    }
                    onChanged={(decisionData) =>
                      updateRecommendations(key, rec.recId, decisionData)
                    }
                    fetchRecs={fetchFunc}
                    {...rec}
                  />
                ))
              ) : (
                <div className="recs_item__description_no_data">
                  {`There is no ${label.toLowerCase()} recommendations for now.`}
                </div>
              )}
            </div>
          </Box>
        ))}
      </div>
    </Box>
  );
};

Recommendations.propTypes = {
  orgId: PropTypes.number,
  campaignId: PropTypes.number,
  campaignAdGroupId: PropTypes.number,
  campaignAdGroupStrategy: PropTypes.oneOf(Object.keys(StrategiesEnum)),
  showHiddenRecs: PropTypes.bool,
  onToggleShowHiddenRecs: PropTypes.func.isRequired,
  fetchFunc: PropTypes.func.isRequired,
  data: PropTypes.shape({
    currency: PropTypes.string,
    scores: PropTypes.shape({
      hygiene: PropTypes.number,
      performance: PropTypes.number,
      creatives: PropTypes.number,
      quality: PropTypes.number,
    }),
    recommendations: PropTypes.shape({
      performance: PropTypes.arrayOf(PropTypes.object),
      creatives: PropTypes.arrayOf(PropTypes.object),
      hygiene: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export default Recommendations;
