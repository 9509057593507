import React, { useEffect } from "react";
import { useUser } from "../context/UserProvider";
import {
  Routes,
  Route,
  Navigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import PreloaderPage from "../pages/PreloaderPage";
import {
  getFromStorage,
  hasInStorage,
  removeFromStorage,
  setInStorage,
} from "../localStorageUtils";
import ReportRoutes from "./ReportRoutes";
import CreativeRoutes from "./CreativeRoutes";
import OrganizationPage from "../pages/main/OrganizationPage";
import ConnectAccountPage from "../pages/main/ConnectAccountPage";
import NotFoundPage from "../pages/main/errors/NotFoundPage";
import UserPage from "../pages/main/UserPage";
import AppsPage from "../pages/main/AppsPage";
import NotificationsPage from "../pages/main/NotificationsPage";
import PlanSelectionPage from "../pages/PlanSelectionPage";
import PaymentMethodsPage from "../pages/PaymentMethodsPage";
import SubscriptionStatusPage from "../pages/SubscriptionStatusPage";
import { AccessEntity, AccessLevelsEnum, isAccess } from "../enum/ACL";
import { BillingStatusesEnum } from "../enum/BillingStatuses";
import ForbiddenPage from "../pages/main/errors/ForbiddenPage";
import PropTypes from "prop-types";

const CheckAccess = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  const user = useUser();

  if (!auth.isAuthorized) {
    return <Navigate to="/auth/sign-in" state={{ from: location }} />;
  }

  return user.orgs ? (
    user.orgs.length ? (
      children
    ) : (
      <Navigate to="/auth/create-org" />
    )
  ) : (
    <PreloaderPage />
  );
};

CheckAccess.propTypes = {
  children: PropTypes.node,
};

const getDefaultOrg = (orgs) => {
  let selectedOrg = getFromStorage("selectedOrg");

  if (selectedOrg) {
    const org = orgs.find((it) => Number(it.orgId) === Number(selectedOrg));
    if (org) {
      return org;
    }
    removeFromStorage("selectedOrg");
  }

  return orgs[0];
};

const FindOrg = ({ children }) => {
  const user = useUser();
  let { orgId } = useParams();
  const org = user.orgs.find((it) => Number(it.orgId) === Number(orgId));

  useEffect(() => {
    if (org) {
      org !== user.selectedOrg && user.setSelectedOrg(org);
      !hasInStorage("selectedOrg") && setInStorage("selectedOrg", orgId);
    } else {
      const defOrg = getDefaultOrg(user.orgs);
      defOrg !== user.selectedOrg && user.setSelectedOrg(defOrg);
    }
  }, [org]); // eslint-disable-line react-hooks/exhaustive-deps

  return org ? children : <NotFoundPage />;
};

FindOrg.propTypes = {
  children: PropTypes.node,
};

const CheckApps = ({ children }) => {
  const user = useUser();
  let { orgId } = useParams();

  return user.apps ? (
    user.apps.length ? (
      children
    ) : (
      <Navigate to={`/org/${orgId}/organisation`} />
    )
  ) : (
    <PreloaderPage />
  );
};

CheckApps.propTypes = {
  children: PropTypes.node,
};

const DefaultOrg = ({ children }) => {
  const user = useUser();
  const org = getDefaultOrg(user.orgs);

  useEffect(() => {
    user.setSelectedOrg(org);
  }, [org]); // eslint-disable-line react-hooks/exhaustive-deps

  return children;
};

DefaultOrg.propTypes = {
  children: PropTypes.node,
};

const DefaultRoute = () => {
  const user = useUser();
  const orgId = user.selectedOrg?.orgId;

  return orgId ? (
    <Navigate
      to={
        user.selectedOrg.billingStatus === BillingStatusesEnum.active
          ? `/org/${orgId}/reporting`
          : `/org/${orgId}/organisation`
      }
    />
  ) : (
    <PreloaderPage />
  );
};

const ManageOnly = ({ children }) => {
  const user = useUser();

  return user.ACL ? (
    isAccess(AccessLevelsEnum.manage, user?.ACL[AccessEntity.Org]) ? (
      children
    ) : (
      <ForbiddenPage />
    )
  ) : (
    <PreloaderPage />
  );
};

ManageOnly.propTypes = {
  children: PropTypes.node,
};

const RequiredActiveSubscription = ({ children }) => {
  const user = useUser();

  return user?.selectedOrg ? (
    user.isSubscriptionActiveForSelectedOrg() ? (
      children
    ) : (
      <ForbiddenPage />
    )
  ) : (
    <PreloaderPage />
  );
};

RequiredActiveSubscription.propTypes = {
  children: PropTypes.node,
};

const MainRoutes = () => (
  <CheckAccess>
    <Routes>
      <Route
        path="/org/:orgId/*"
        element={
          <FindOrg>
            <Routes>
              <Route
                path="/reporting/*"
                element={
                  <CheckApps>
                    <ReportRoutes />
                  </CheckApps>
                }
              />
              <Route
                path="/creatives/*"
                element={
                  <CheckApps>
                    <CreativeRoutes />
                  </CheckApps>
                }
              />
              <Route
                path="/apps"
                element={
                  <RequiredActiveSubscription>
                    <AppsPage />
                  </RequiredActiveSubscription>
                }
              />
              <Route path="/organisation" element={<OrganizationPage />} />
              <Route
                path="/plan-selection"
                element={
                  <ManageOnly>
                    <PlanSelectionPage />
                  </ManageOnly>
                }
              />
              <Route
                path="/payment-method-selection/:tariffCode"
                element={
                  <ManageOnly>
                    <PaymentMethodsPage />
                  </ManageOnly>
                }
              />
              <Route
                path="/subscription-status"
                element={
                  <ManageOnly>
                    <SubscriptionStatusPage />
                  </ManageOnly>
                }
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </FindOrg>
        }
      />
      <Route
        path="*"
        element={
          <DefaultOrg>
            <Routes>
              <Route path="/" element={<DefaultRoute />} />
              <Route path="/user" element={<UserPage />} />
              <Route path="/notifications" element={<NotificationsPage />} />
              <Route
                path="/connect-account/:network"
                element={<ConnectAccountPage />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </DefaultOrg>
        }
      />
    </Routes>
  </CheckAccess>
);

export default MainRoutes;
