import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  Close as CloseIcon,
  Square as SquareIcon,
} from "@mui/icons-material/";
import CTooltip from "../common/CTooltip";
import { StatusesEnum, StatusesMapping } from "../../enum/Statuses";

const useStyles = makeStyles(() => ({
  status: {
    width: "1.11rem",
    height: "1.11rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const StatusIcon = ({ status }) => {
  const classes = useStyles();
  let icon = null;

  switch (status) {
    case StatusesEnum.Enabled:
      icon = <PlayArrowIcon color="success" />;
      break;
    case StatusesEnum.Paused:
      icon = <PauseIcon color="warning" />;
      break;
    case StatusesEnum.Removed:
      icon = <CloseIcon color="error" />;
      break;
    case StatusesEnum.Completed:
      icon = <SquareIcon color="inactive" />;
      break;
    default:
      break;
  }

  return (
    <Box className={classes.status}>
      <CTooltip customLabel={icon} content={StatusesMapping[status]} />
    </Box>
  );
};

StatusIcon.propTypes = {
  status: PropTypes.oneOf(Object.values(StatusesEnum)).isRequired,
};

export default StatusIcon;
