import React, { useEffect, useState } from "react";
import MainTemplate from "../../templates/MainTemplate";
import { Box } from "@mui/material";
import { useUser } from "../../context/UserProvider";
import CButton from "../../components/common/CButton";
import ChangePassword from "../../components/UserPage/ChangePassword";
import { isDev, sleep } from "../../utils";
import adwizyApi from "../../api/adwizyApi";
import { useInformer } from "../../context/InformerProvider";
import { SettingsEnum } from "../../enum/Settings";
import AccordionBlock from "../../components/AccordionBlock";
import KeyValueTable from "../../components/KeyValueTable";
import CCheckbox from "../../components/common/CCheckbox";
import DeleteAccount from "../../components/UserPage/DeleteAccount";

const UserPage = () => {
  const user = useUser();
  const informer = useInformer();
  const [settingsData, setSettingsData] = useState(null);

  useEffect(() => {
    setSettingsData(null);
    const setSettings = (data) => {
      const settings = {};
      Object.keys(SettingsEnum).forEach((key) => {
        settings[key] = { value: data[key], loading: false };
      });

      setSettingsData(settings);
    };

    if (isDev) {
      sleep(700).then(() =>
        setSettings({ ...require("../../mocks/user__settings.json").data })
      );
    } else {
      adwizyApi
        .get("/user/settings")
        .then((res) => {
          if (res.data.success) {
            setSettings(res.data.data);
          }
        })
        .catch(informer.showErrorNotice);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeSettingsHandle = (settingName, settingValue) => {
    if (isDev) {
      setSettingsData((data) => {
        data[settingName].value = settingValue;
        return { ...data };
      });
    } else {
      setSettingsData((data) => {
        data[settingName].loading = true;
        return { ...data };
      });

      adwizyApi
        .post("/user/settings", { settingName, settingValue })
        .then((res) => {
          if (res.data.success) {
            setSettingsData((data) => {
              data[settingName].value = settingValue;
              return { ...data };
            });
          }
        })
        .catch(informer.showErrorNotice)
        .finally(() =>
          setSettingsData((data) => {
            data[settingName].loading = false;
            return { ...data };
          })
        );
    }
  };

  return (
    <MainTemplate title="User">
      <div className="block-content">
        <h2 className="subtitle color-primary m-0">User profile</h2>
        <Box sx={{ marginTop: "2.83rem" }}>
          <KeyValueTable
            data={{
              Email: user?.data?.email,
              Password: (
                <ChangePassword
                  btn={
                    <CButton color="primary" variant="outlined">
                      Change password
                    </CButton>
                  }
                />
              ),
              "Send email notifications": settingsData ? (
                <CCheckbox
                  checked={
                    !settingsData[SettingsEnum.dontSendEmailNotifications].value
                  }
                  onClick={(evt) =>
                    changeSettingsHandle(
                      SettingsEnum.dontSendEmailNotifications,
                      !evt.target.checked
                    )
                  }
                  disabled={
                    settingsData[SettingsEnum.dontSendEmailNotifications]
                      .loading
                  }
                />
              ) : (
                <CCheckbox loading={true} />
              ),
            }}
            sx={{ maxWidth: "27.8rem" }}
          />
        </Box>
      </div>
      <AccordionBlock title="Delete user permanently">
        <Box marginBottom="1.11rem">
          Proceed if you want to delete the user permanently. This action cannot
          be undone.
        </Box>
        <DeleteAccount
          btn={
            <CButton color="error" variant="outlined" size="large">
              Delete account
            </CButton>
          }
        />
      </AccordionBlock>
    </MainTemplate>
  );
};

export default UserPage;
