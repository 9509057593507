import React from "react";
import "../styles/LegalTemplate.sass";
import { Paper } from "@mui/material";
import CScrollbars from "../components/CScrollbars";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Header = () => {
  return (
    <div className="header">
      <Link to="/" className="logo">
        <img src={"/static/logo_white.svg"} alt="Logo" />
      </Link>
    </div>
  );
};

const Content = ({ children }) => {
  return (
    <div className="content-container">
      <Paper className="content" elevation={2}>
        {children}
      </Paper>
    </div>
  );
};

Content.propTypes = {
  children: PropTypes.node,
};

const LegalTemplate = ({ children }) => {
  return (
    <CScrollbars
      style={{ height: "100vh" }}
      content={{ id: "legal_template" }}
      vertical={{
        track: { className: "scrollbar" },
        thumb: { className: "scrollbar_thumb" },
      }}
    >
      <Header />
      <Content>{children}</Content>
    </CScrollbars>
  );
};

LegalTemplate.propTypes = {
  children: PropTypes.node,
};

export default LegalTemplate;
