export const AppstoreTypesEnum = {
  Apple: "apple",
  Google: "google",
  Huawei: "huawei",
  Omni: "omni",
};

export const AppstoreTypesMapping = {
  [AppstoreTypesEnum.Apple]: "Apple App Store",
  [AppstoreTypesEnum.Google]: "Google Play",
  [AppstoreTypesEnum.Huawei]: "HUAWEI AppGallery",
  [AppstoreTypesEnum.Omni]: "Facebook Omni App",
};

export const getAppstoreMappingValues = (appstores) => {
  const res = {};
  for (const appstore in appstores) {
    res[appstore] = AppstoreTypesMapping[appstore];
  }

  return res;
};
