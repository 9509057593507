import React, { useState } from "react";
import CInput from "./CInput";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PropTypes } from "prop-types";

const PasswordInput = ({ label, ...other }) => {
  const [showPass, setShowPass] = useState(false);
  const [focused, setFocused] = useState(false);

  return (
    <CInput
      type={showPass ? "text" : "password"}
      label={label || "Password"}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPass((prev) => !prev)} edge="end">
              {showPass ? (
                <VisibilityOff color={focused ? "black" : "inactive"} />
              ) : (
                <Visibility color={focused ? "black" : "inactive"} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
};

PasswordInput.propTypes = {
  ...CInput.propTypes,
  label: PropTypes.string,
};

PasswordInput.defaultProps = CInput.defaultProps;

export default PasswordInput;
