import React, { useEffect, useState } from "react";
import { isDev, sleep } from "../../../utils";
import MainTemplate from "../../../templates/MainTemplate";
import CTable, { types } from "../../../components/common/CTable";
import { IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material/";
import CDialog from "../../../components/common/CDialog";
import EditableTextField from "../../../components/common/EditableTextField";
import { useUser } from "../../../context/UserProvider";
import adwizyApi from "../../../api/adwizyApi";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import DropdownApp from "../../../components/common/DropdownApp";
import { useInformer } from "../../../context/InformerProvider";
import { events, useEvent } from "../../../context/EventProvider";
import AppId from "../../../helpers/appId";
import { getMetricsScoresAndRecsHandlerForCreatives } from "../../../helpers/eventHandlers";
import { ReportTypes } from "../../../enum/ReportTypes";

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    color: theme.palette.inactive.main,
    "&:hover": {
      color: theme.palette.error.main,
      backgroundColor: "transparent",
    },
  },
}));

const ConceptSettingsPage = () => {
  const user = useUser();
  const event = useEvent();
  const informer = useInformer();
  const classes = useStyles();

  const [loadingFetchConcepts, setLoadingFetchConcepts] = useState(true);
  const [loadingDeleteConcept, setLoadingDeleteConcept] = useState(false);
  const [concepts, setConcepts] = useState([]);
  const [selectedApp, setSelectedApp] = useState({
    appstoreAppId: null,
    appId: null,
  });
  const [metricsKey, setMetricsKey] = useState(0);
  const params = useParams();
  const orgId = Number(params.orgId);

  const fetchConcepts = async () => {
    await setLoadingFetchConcepts(true);

    if (isDev) {
      await sleep(2000);
      setConcepts(require("../../../mocks/concepts.json").data);
    } else {
      await adwizyApi
        .get("/concept/list", {
          orgId: orgId,
          appId: selectedApp.appId,
          appstoreAppId: selectedApp.appstoreAppId,
          forceConversion: user.forceConversion,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            setConcepts(data.data);
          } else {
            setConcepts([]);
          }
        });
    }

    await setLoadingFetchConcepts(false);
  };

  useEffect(() => {
    if (
      user.isAllowReport(ReportTypes.creativeAnalysis) &&
      (selectedApp.appId || selectedApp.appstoreAppId)
    ) {
      fetchConcepts().catch(informer.showErrorNotice);
    }
  }, [metricsKey, selectedApp, user.forceConversion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handler = getMetricsScoresAndRecsHandlerForCreatives(
      setMetricsKey,
      (cb) =>
        setSelectedApp((prev) => {
          cb(AppId(prev).toString());
          return prev;
        })
    );
    event
      .subscribe(events.orgAppMetricsUpdated, handler)
      .catch(informer.showErrorNotice);
    return () => event.unsubscribe(events.orgAppMetricsUpdated, handler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeConceptName = async (conceptId, value) => {
    if (isDev) {
      await sleep(500);
      if (value === "error") {
        return {
          status: "error",
          msg: "This is an invalid value",
        };
      } else {
        return {
          status: "success",
          msg: "",
        };
      }
    } else {
      return adwizyApi
        .put(`/concept/${conceptId}`, {
          orgId: orgId,
          conceptName: value,
        })
        .then((res) => res.data);
    }
  };

  const handleDeleteConcept = async (conceptId) => {
    await setLoadingDeleteConcept(true);
    if (isDev) {
      await sleep();
    } else {
      await adwizyApi
        .delete(`/concept/${conceptId}`, {
          orgId: orgId,
        })
        .then(() => {
          fetchConcepts().catch(informer.showErrorNotice);
        });
    }

    await setLoadingDeleteConcept(false);
    await setConcepts((prevState) => [
      ...prevState.filter((row) => row.id !== conceptId),
    ]);
  };

  return (
    <MainTemplate
      isAllowed={user.isAllowReport(ReportTypes.creativeAnalysis)}
      title="Concept settings"
      subtitle={
        <DropdownApp
          title="App name"
          handleSelected={(val) =>
            setSelectedApp((prevState) =>
              JSON.stringify(prevState) !== JSON.stringify(val)
                ? val
                : prevState
            )
          }
          disabled={loadingFetchConcepts}
        />
      }
    >
      <CTable
        loading={loadingFetchConcepts}
        rows={concepts}
        settings={{
          columns: {
            conceptId: {
              title: () => "Concept",
              format: types.custom,
              component: (concept) => (
                <EditableTextField
                  key={concept.conceptId}
                  onConfirm={(val) =>
                    handleChangeConceptName(concept.conceptId, val)
                  }
                  defaultValue={concept.name}
                />
              ),
              includeTextConditions: true,
              includeSort: true,
              sx: {
                textAlign: "center",
              },
              filterCellProps: {
                sx: {
                  justifyContent: "center",
                },
              },
            },
            countOfActiveAssets: {
              title: () => "Active creatives #",
              format: types.number,
              includeNumberConditions: true,
              includeSort: true,
              sx: {
                textAlign: "center",
              },
              filterCellProps: {
                sx: {
                  justifyContent: "center",
                },
              },
            },
            allAssetsInAllApps: {
              title: () => "All creatives #",
              format: types.number,
              includeNumberConditions: true,
              includeSort: true,
              sx: {
                textAlign: "center",
              },
              filterCellProps: {
                sx: {
                  justifyContent: "center",
                },
              },
            },
            allAssets: {
              title: () => "All creatives in all apps #",
              format: types.number,
              includeNumberConditions: true,
              includeSort: true,
              sx: {
                textAlign: "center",
              },
              filterCellProps: {
                sx: {
                  justifyContent: "center",
                },
              },
            },
            cost: {
              title: () => "Total spend last 7 days",
              format: types.money,
              includeNumberConditions: true,
              includeSort: true,
              sx: {
                textAlign: "center",
              },
              filterCellProps: {
                sx: {
                  justifyContent: "center",
                },
              },
            },
            actions: {
              title: () => "",
              format: types.custom,
              component: (concept) => (
                <CDialog
                  btn={
                    <IconButton className={classes.closeBtn}>
                      <CloseIcon sx={{ fontSize: "1.3rem" }} />
                    </IconButton>
                  }
                  title="Attention"
                  content={`
                    Are you sure you want to delete created concept? 
                    All creatives with selected concept will lose this concept name. 
                    This action cannot be undone.
                  `}
                  applyBtnCb={() => handleDeleteConcept(concept.conceptId)}
                  applyBtnText="Clear"
                  maxWidth="xs"
                  loading={loadingDeleteConcept}
                />
              ),
              sx: {
                width: "2.22rem",
              },
            },
          },
          default: {
            order: "desc",
            orderBy: "cost",
          },
        }}
      />
    </MainTemplate>
  );
};

export default ConceptSettingsPage;
