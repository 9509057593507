import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: "Mulish",
    fontSize: 15,
  },
  palette: {
    primary: {
      main: "#399BDC",
      light: "#E3EFF7",
    },
    primaryLight: {
      main: "#E5F5FF",
    },
    primaryActive: {
      main: "#1171B0",
    },
    primaryActiveLight: {
      main: "#CFECFF",
    },
    primaryClicked: {
      main: "#E5F5FF",
      dark: "#CFECFF",
    },
    success: {
      main: "#3D9F1A",
    },
    successLight: {
      main: "#E5FDDC",
    },
    successClicked: {
      main: "#E5FDDC",
    },
    warning: {
      main: "#E6AE20",
    },
    warningLight: {
      main: "#FFF7E4",
    },
    warningClicked: {
      main: "#FFF7E4",
      dark: "#FDEABD",
    },
    error: {
      main: "#E71E1E",
    },
    errorLight: {
      main: "#FFE5E5",
    },
    errorClicked: {
      main: "#FFE5E5",
    },
    borders: {
      main: "#DBDBDB",
    },
    hover: {
      main: "#072234",
    },
    inactive: {
      main: "#9CA7AE",
      light: "rgba(7, 34, 52, 0.2)",
    },
    tables: {
      main: "#F8FCFE",
    },
    defaultLight: {
      main: "#F6F6F6",
    },
    white: {
      main: "#fff",
    },
    black: {
      main: "#000",
    },
  },
});

export default theme;
