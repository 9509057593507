import React, { useEffect, useState } from "react";
import { Chip, Grid, chipClasses, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  CancelSharp as CancelSharpIcon,
  AddCircleRounded as AddCircleRoundedIcon,
} from "@mui/icons-material/";
import ConceptActions from "./ConceptActions";
import Preloader from "../Preloader";
import PropTypes from "prop-types";

const StyledChip = styled(Chip)(({ theme }) => ({
  [`&.${chipClasses.root}`]: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: "0.8rem",
    fontWeight: 500,
    [`& .${chipClasses.deleteIcon}`]: {
      color: theme.palette.primary.main,
    },
  },
}));

const AssetConcepts = ({
  conceptIds,
  concepts,
  onDeleteConcept,
  onAddNewConcept,
  onChangeConcepts,
}) => {
  const [loading, setLoading] = useState(false);
  const [conceptIdsDeletedNow, setConceptIdsDeletedNow] = useState([]);
  const [conceptIdsChecked, setConceptIdsChecked] = useState([]);
  const [conceptAssetForActions, setConceptAssetForActions] = useState({});
  const [assetConcepts, setAssetConcepts] = useState([]);

  useEffect(() => {
    const newAssetConcepts = [];
    const newConceptAssetForActions = {};
    for (const concept of Object.values(concepts)) {
      newAssetConcepts[concept.conceptId] = concept.conceptName;
      if (!conceptIds.includes(concept.conceptId)) {
        newConceptAssetForActions[concept.conceptId] = concept.conceptName;
      }
    }
    setAssetConcepts(newAssetConcepts);
    setConceptAssetForActions(newConceptAssetForActions);
  }, [conceptIds, concepts]);

  const addConceptIdsDeletedNow = (conceptId) => {
    setConceptIdsDeletedNow((prevState) => [...prevState, conceptId]);
  };

  const removeConceptIdsDeletedNow = (conceptId) => {
    setConceptIdsDeletedNow((prevState) => [
      ...prevState.filter((item) => item !== conceptId),
    ]);
  };

  const handleDelete = async (conceptId) => {
    await addConceptIdsDeletedNow(conceptId);
    await onDeleteConcept(conceptId);
    await removeConceptIdsDeletedNow(conceptId);
  };

  const handleOk = async () => {
    setLoading(true);
    await onChangeConcepts(conceptIdsChecked);
    setLoading(false);
    setConceptIdsChecked([]);
  };

  const handleCancel = () => {
    setConceptIdsChecked([]);
  };

  const changeConceptIdsChecked = (conceptId) => {
    const cIds = conceptIdsChecked;
    const index = cIds.indexOf(conceptId);

    if (index !== -1) {
      cIds.splice(index, 1);
    } else {
      cIds.push(conceptId);
    }

    setConceptIdsChecked([...cIds]);
  };

  return (
    <Grid container spacing={1} alignItems="center">
      {conceptIds.map((conceptId, i) => (
        <Grid item key={i}>
          <StyledChip
            key={i}
            label={assetConcepts[conceptId]}
            onDelete={() => handleDelete(conceptId)}
            deleteIcon={
              conceptIdsDeletedNow.includes(conceptId) ? (
                <Preloader size={20} />
              ) : (
                <CancelSharpIcon />
              )
            }
          />
        </Grid>
      ))}
      <Grid item>
        <ConceptActions
          btn={
            <IconButton sx={{ padding: 0 }} color="primary">
              <AddCircleRoundedIcon sx={{ fontSize: "1.3rem" }} />
            </IconButton>
          }
          title="Select concepts"
          loading={loading}
          handleOk={handleOk}
          handleCancel={handleCancel}
          concepts={conceptAssetForActions}
          conceptIdsChecked={conceptIdsChecked}
          changeConceptIdsChecked={changeConceptIdsChecked}
          onAddNewConcept={onAddNewConcept}
          allowCreateNewConcept
        />
      </Grid>
    </Grid>
  );
};

AssetConcepts.propTypes = {
  conceptIds: PropTypes.array,
  concepts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onDeleteConcept: PropTypes.func,
  onAddNewConcept: PropTypes.func,
  onChangeConcepts: PropTypes.func,
};

export default AssetConcepts;
