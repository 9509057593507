import React, { useState } from "react";
import { TextField, InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CDialog from "../common/CDialog";
import { useInformer } from "../../context/InformerProvider";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: theme.palette.inactive.main,
    fontSize: "0.9rem",
    fontWeight: 500,
    lineHeight: "26px",
    marginBottom: 8,
  },
}));

const DialogNewConcept = ({ btn, onAddNewConcept }) => {
  const classes = useStyles();
  const informer = useInformer();
  const [loading, setLoading] = useState(false);
  const [newConcept, setNewConcept] = useState("");
  const [error, setError] = useState("");

  const handleApply = async (newConcept) => {
    let response = true;
    setLoading(true);
    await onAddNewConcept(newConcept)
      .then((res) => {
        if (res.status === "error") {
          setError(res.msg);
          response = false;
        }
      })
      .catch((err) => {
        if (err?.response?.data?.errors.length > 0) {
          let errorText = "";
          for (const error in err.response.data.errors) {
            errorText += err.response.data.errors[error] + "\n\r";
          }
          setError(errorText);
        } else if (err?.response?.data?.message) {
          setError(err?.response?.data?.message);
        } else {
          informer.showErrorNotice(err);
        }
        response = false;
      })
      .finally(() => setLoading(false));

    return response;
  };

  const handleChange = (evt) => {
    setError("");
    setNewConcept(evt.target.value);
  };

  return (
    <CDialog
      btn={btn}
      title="New concept"
      content={
        <>
          <InputLabel className={classes.inputLabel}>
            Please enter a new concept name:
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            margin="none"
            inputProps={{
              sx: { padding: "0.58rem 0.78rem" },
            }}
            onChange={handleChange}
            error={error.length > 0}
            helperText={error}
          />
        </>
      }
      applyBtnCb={() => handleApply(newConcept)}
      maxWidth="sm"
      fullWidth={true}
      loading={loading}
    />
  );
};

DialogNewConcept.propTypes = {
  btn: PropTypes.node,
  onAddNewConcept: PropTypes.func,
};

export default DialogNewConcept;
