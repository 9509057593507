import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  buttonClasses,
  Checkbox,
  checkboxClasses,
  FormControl,
  InputLabel,
  inputLabelClasses,
  ListItemText,
  outlinedInputClasses,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material/";
import PropTypes from "prop-types";
import { StyledSelect } from "./CSelect";
import CMenuItem from "./CMenuItem";
import theme from "../../theme";
import classNames from "classnames";
import Query from "../../helpers/query";
import { useNavigate } from "react-router-dom";
import CSkeleton from "./CSkeleton";
import Preloader from "../Preloader";

export const variants = {
  mini: "mini",
};

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root > fieldset, &.MuiInputBase-root:hover > fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.MuiInputBase-root > .MuiSvgIcon-root:not(.Mui-disabled)": {
      fill: theme.palette.primary.main,
    },
  },
  selectIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "0.67rem",
  },
  selectIconActive: {
    "&::after, &::before": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inactiveMenuItem: {
    color: `${theme.palette.inactive.main} !important`,
  },
  miniDropdown: {
    "&.MuiInputBase-root > fieldset, &.MuiInputBase-root:hover > fieldset": {
      borderColor: theme.palette.borders.main,
    },
    [`& .${outlinedInputClasses.input}`]: {
      color: theme.palette.hover.main,
      padding: ".58rem 0.66rem",
    },
  },
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  [`&.${inputLabelClasses.root}`]: {
    fontSize: "1rem",
    lineHeight: 2,
    fontWeight: 500,
    color: theme.palette.inactive.main,

    "&.Mui-error": {
      color: theme.palette.error.main,
    },
  },
  [`&.${inputLabelClasses.shrink}`]: {
    color: theme.palette.primary.main,
    fontSize: "0.9rem",
  },
  [`&.Mui-disabled`]: {
    color: theme.palette.inactive.main,
  },
  [`&.${inputLabelClasses.root}:not(.${inputLabelClasses.shrink})`]: {
    top: "50%",
    left: 0,
    transform: "translate(0.66rem, -50%) scale(1)",
    paddingRight: "1.1rem",
  },
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  [`&.${checkboxClasses.root}`]: {
    padding: 0,
    marginRight: 13,
  },
  [`&:not(.${checkboxClasses.checked})`]: {
    "& svg": {
      fill: theme.palette.borders.main,
    },
  },
}));

const TopButton = styled(Button)(() => ({
  [`&.${buttonClasses.root}`]: {
    fontSize: "0.8rem",
    lineHeight: "1.44rem",
    letterSpacing: "0.008rem",
    textDecorationLine: "underline",
    textTransform: "none",
    minWidth: "unset",
    padding: 0,
    whiteSpace: "nowrap",

    "&:not(:first-of-type)": {
      marginLeft: ".72rem",
    },
  },
}));

const DropdownFilter = ({
  title,
  values,
  selectedValue,
  handleSelected,
  multiple,
  maxSelected,
  variant,
  hasError,
  disabled,
  loading,
  processing,
  toFilterValues,
  getParam,
  skeletonHeight,
  onOpen,
  onClose,
  onCancel,
  sx,
}) => {
  const classes = useStyles();
  const query = new Query();
  const navigate = useNavigate();

  const getDefaultSelected = () => {
    if (getParam && query.has(getParam)) {
      if (multiple) {
        return query.getAll(getParam);
      }
      return query.get(getParam);
    }

    if (multiple) {
      return selectedValue || [];
    }

    return selectedValue || "";
  };
  const [selected, setSelected] = useState(getDefaultSelected());
  const [allValues, setAllValues] = useState({});
  const [filteredValues, setFilteredValues] = useState({});
  const [valuesHaveBeenUpdated, setValuesHaveBeenUpdated] = useState(false);
  const [lastGetValue, setLastGetValue] = useState();

  const renderValue = () => {
    if (multiple) {
      return selected.length
        ? selected.length > 1
          ? `${values[selected[0]]} + ${selected.length - 1} more`
          : values[selected]
        : "";
    } else {
      return values[selected];
    }
  };

  const handleChangeMultiple = (value) => {
    if (maxSelected && value.length > maxSelected) {
      return;
    }

    setSelected(value);
  };

  const updateGetParams = (value) => {
    if (multiple) {
      query.updateMultiple({ [getParam]: value.length > 0 ? value : null });
    } else {
      query.update({ [getParam]: value.length > 0 ? value : null });
    }
    if (JSON.stringify(value) === JSON.stringify(lastGetValue)) {
      navigate({ search: query.asString() }, { replace: true });
    } else {
      navigate({ search: query.asString() });
    }

    setLastGetValue(value);
  };

  const handleChange = async (evt) => {
    let value = evt.target.value;
    if (multiple) {
      handleChangeMultiple(value);
    } else {
      setSelected(value);
      await handleSelected(value);

      if (getParam) {
        updateGetParams(value);
      }
    }
  };

  const handleClose = (evt) => {
    if (multiple) {
      if (getParam) {
        updateGetParams(selected);
      } else {
        handleSelected(selected);
      }
    }

    if (onClose) {
      onClose(evt);
    }

    if (onCancel && evt.target.classList.contains("MuiBackdrop-root")) {
      onCancel(evt);
    }
  };

  useEffect(() => {
    if (selectedValue && selected !== selectedValue) {
      setSelected(selectedValue);
    }
  }, [selectedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!toFilterValues) {
      setAllValues(values);
      return;
    }

    if (Object.keys(values).length === 0) {
      setAllValues(values);
      setFilteredValues({});
      setValuesHaveBeenUpdated(false);
      return;
    }

    if (Object.keys(values).length > 0 && !valuesHaveBeenUpdated) {
      setAllValues(values);
      setValuesHaveBeenUpdated(true);
    } else if (valuesHaveBeenUpdated) {
      setFilteredValues(values);
    }
  }, [values, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!values && getParam) {
      handleSelected(selected);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (multiple && getParam) {
      handleSelected(query.getAll(getParam));
    }
  }, [query.getAll(getParam)]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!multiple && getParam) {
      handleSelected(query.get(getParam));
    }
  }, [query.get(getParam)]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormControl
      fullWidth
      variant="outlined"
      size="small"
      error={hasError}
      disabled={disabled}
      title={title}
      sx={sx}
    >
      {loading ? (
        <CSkeleton height={skeletonHeight} />
      ) : (
        <>
          <StyledInputLabel>{title}</StyledInputLabel>
          <StyledSelect
            multiple={multiple}
            label={title}
            value={selected}
            onChange={handleChange}
            onOpen={onOpen}
            onClose={handleClose}
            renderValue={renderValue}
            className={classNames({
              [classes.select]: Array.isArray(selectedValue)
                ? selectedValue.length > 0
                : selectedValue,
              [classes.miniDropdown]: variant === variants.mini,
              [classes.select]: Array.isArray(selected)
                ? selected.length > 0
                : selected,
            })}
            iconopencolor={theme.palette.primary.main}
            IconComponent={(props) =>
              processing ? (
                <Preloader size="1.5rem" sx={{ marginRight: "0.5rem" }} />
              ) : (
                <KeyboardArrowDownIcon {...props} />
              )
            }
            MenuProps={{
              classes: {
                paper:
                  "dropdown-paper dropdown-paper-primary disable-offset-top",
              },
              variant: "menu",
            }}
            disabled={disabled || Object.keys(allValues).length === 0}
            sx={{ height: "100%" }}
          >
            {multiple && (
              <Box
                component="li"
                sx={{
                  position: "sticky",
                  zIndex: 120,
                  top: 0,
                  padding: ".72rem .88rem .5rem",
                  backgroundColor: theme.palette.white.main,
                }}
                className={"d-flex align-items-center"}
              >
                {maxSelected && Object.keys(allValues).length > maxSelected && (
                  <Box
                    sx={{
                      fontSize: ".8rem",
                      fontWeight: 500,
                      lineHeight: "2rem",
                      color: theme.palette.inactive.main,
                      whiteSpace: "nowrap",
                      marginRight: ".72rem",
                    }}
                  >
                    Selected ({selected.length}/{maxSelected})
                  </Box>
                )}
                {!maxSelected && (
                  <TopButton
                    variant="text"
                    color="primary"
                    onClick={() => setSelected(Object.keys(values))}
                    disabled={selected.length === Object.keys(values).length}
                  >
                    Select all
                  </TopButton>
                )}
                <TopButton
                  variant="text"
                  color="primary"
                  onClick={() => setSelected([])}
                  disabled={selected.length === 0}
                >
                  Reset
                </TopButton>
              </Box>
            )}
            {Object.keys(allValues).map((key) => (
              <CMenuItem
                className={classNames({
                  [classes.inactiveMenuItem]:
                    filteredValues &&
                    Object.keys(filteredValues).length !== 0 &&
                    !Object.keys(filteredValues).includes(key),
                })}
                disabled={
                  filteredValues &&
                  Object.keys(filteredValues).length !== 0 &&
                  !Object.keys(filteredValues).includes(key)
                }
                value={key}
                key={key}
              >
                {multiple ? (
                  <>
                    <StyledCheckbox checked={selected.indexOf(key) > -1} />
                    <ListItemText primary={allValues[key]} />
                  </>
                ) : (
                  allValues[key]
                )}
              </CMenuItem>
            ))}
          </StyledSelect>
        </>
      )}
    </FormControl>
  );
};

DropdownFilter.propTypes = {
  title: PropTypes.string,
  values: PropTypes.object,
  className: PropTypes.string,
  selectedValue: PropTypes.any,
  handleSelected: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  maxSelected: PropTypes.number,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  processing: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(variants)),
  filterValues: PropTypes.bool,
  toFilterValues: PropTypes.bool,
  getParam: PropTypes.string,
  skeletonHeight: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  sx: PropTypes.object,
};

DropdownFilter.defaultProps = {
  styles: {},
  multiple: false,
  filterValues: false,
  skeletonHeight: "3.78rem",
};

export default DropdownFilter;
