import React, { useEffect, useState } from "react";
import AuthTemplate from "../templates/AuthTemplate";
import adwizyApi from "../api/adwizyApi";
import { useInformer } from "../context/InformerProvider";
import { Link, useNavigate, useParams } from "react-router-dom";
import CButton from "../components/common/CButton";
import { getTitleOfNetwork } from "../enum/CampaignNetworks";
import { MMPServices } from "../enum/MMPServices";
import { moneyFormatter } from "../utils";
import CSkeleton from "../components/common/CSkeleton";
import { ReportTypesMapping } from "../enum/ReportTypes";
import { useUser } from "../context/UserProvider";
import { PaymentMethodsEnum } from "../enum/PaymentMethodsEnum";
import PropTypes from "prop-types";

const TariffItem = ({
  tariff,
  orgId,
  billingStatus,
  disabled,
  disabledBtnCb,
}) => {
  const navigate = useNavigate();
  const user = useUser();
  const informer = useInformer();
  const [benefits, setBenefits] = useState([]);

  const handleClick = async () => {
    if (billingStatus !== null) {
      navigate(`/org/${orgId}/payment-method-selection/${tariff.tariffCode}`);
    } else {
      const planData = {
        tariffCode: tariff.tariffCode,
        billingSubscriptionId: null,
        paymentMethod: PaymentMethodsEnum.Skip,
      };

      disabledBtnCb(true);
      await adwizyApi
        .post(`/billing/${orgId}/plan`, planData)
        .then(async (res) => {
          if (res.data.success) {
            await user.fetchOrgs();
            await user.fetchACL();
            user.setSubscriptionStatusData(planData);
            navigate(`/org/${orgId}/subscription-status`);
          } else {
            informer.showErrorNotice(res.data.message);
          }
        })
        .finally(() => {
          disabledBtnCb(false);
        });
    }
  };

  useEffect(() => {
    const options = tariff.options;
    const res = [];

    if (options?.networks) {
      const availableNetworks = [];
      for (const network of options.networks) {
        availableNetworks.push(getTitleOfNetwork(network));
      }
      let lastAvailableNetwork;
      if (availableNetworks.length > 1) {
        lastAvailableNetwork = availableNetworks.pop();
      }

      let availableNetworksStr = availableNetworks.join(", ");
      if (lastAvailableNetwork) {
        availableNetworksStr += " & " + lastAvailableNetwork;
      }
      res.push(availableNetworksStr);
    }

    if (options?.appsCount) {
      let msgAppsCount = options?.appsCount;
      if (options?.appsCount > 1) {
        msgAppsCount += " apps";
      } else {
        msgAppsCount += " app";
      }

      if (msgAppsCount.length === 1) {
        msgAppsCount += " (iOS + Android)";
      }

      res.push(msgAppsCount);
    }

    if (options?.reports) {
      const availableReports = [];
      for (const report of options?.reports || []) {
        availableReports.push(ReportTypesMapping[report]);
      }
      let lastAvailableReport;
      if (availableReports.length > 1) {
        lastAvailableReport = availableReports.pop();
      }

      let availableReportsStr = availableReports.join(", ");
      if (lastAvailableReport) {
        availableReportsStr += " & " + lastAvailableReport;
      }
      res.push(availableReportsStr + " reports");
    }

    if (options?.MMPs) {
      const availableMMPs = [];
      for (const MMP of options?.MMPs || []) {
        const title = MMPServices[MMP]?.title;
        if (title) {
          availableMMPs.push(title);
        }
      }

      if (availableMMPs.length > 0) {
        res.push(
          "Advanced x-network reports with MMP integration (" +
            availableMMPs.join(", ") +
            ")"
        );
      }
    }

    setBenefits(res);
  }, [tariff.options]);

  return (
    <div className="tariff-item">
      <div className="tariff-item__title">{tariff.tariffName}</div>

      <div className="tariff-item__cost">
        {moneyFormatter({
          num: tariff.price.amount,
          currency: tariff.price.currency,
        })}
        <span className="tariff-item__cost-period">
          / {tariff.daysCount} days
        </span>
      </div>

      <CButton
        className="button f-w-700"
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={disabled}
      >
        {tariff.isTrial
          ? `Try for free for ${tariff.trialPeriodDaysCount} days`
          : "Select"}
      </CButton>

      {benefits.length > 0 && (
        <ul className="tariff-item__benefits">
          {Object.values(benefits).map((benefit, i) => (
            <li key={i} className="tariff-item__benefit-item">
              {benefit}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

TariffItem.propTypes = {
  tariff: PropTypes.object,
  orgId: PropTypes.number,
  billingStatus: PropTypes.string,
  disabled: PropTypes.bool,
  disabledBtnCb: PropTypes.func,
};

const PlanSelectionPage = () => {
  const user = useUser();
  const params = useParams();
  const orgId = Number(params.orgId);
  const informer = useInformer();
  const [loading, setLoading] = useState(true);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [availableTariffs, setAvailableTariffs] = useState([]);
  const [billingStatus, setBillingStatus] = useState(null);

  const fetchTariffs = async () => {
    setLoading(true);
    await adwizyApi.get(`/billing/${orgId}/tariffs`).then((res) => {
      if (res.data.success) {
        setAvailableTariffs(res.data.data);
        setLoading(false);
      } else {
        informer.showErrorNotice(res.data.message);
      }
    });
  };

  useEffect(() => {
    setBillingStatus(
      user.orgs.find((org) => org.orgId === orgId).billingStatus
    );
    fetchTariffs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthTemplate>
      <div className="window">
        <div className="title">Select your plan</div>
        <div className="tariff-wrapper">
          {loading && (
            <>
              <CSkeleton width="16.66rem" height="25rem" />
              <CSkeleton width="16.66rem" height="25rem" />
            </>
          )}
          {Object.keys(availableTariffs).map((tariffCode, i) => (
            <TariffItem
              key={i}
              tariff={availableTariffs[tariffCode]}
              orgId={orgId}
              billingStatus={billingStatus}
              disabled={disabledBtn}
              disabledBtnCb={setDisabledBtn}
            />
          ))}
        </div>
        <div className="tariff-actions">
          <CButton
            component={Link}
            to={`/org/${orgId}/organisation`}
            variant="contained"
            color="primary"
          >
            Select later
          </CButton>
        </div>
      </div>
    </AuthTemplate>
  );
};

export default PlanSelectionPage;
