import React from "react";
import CButton from "./common/CButton";
import PropTypes from "prop-types";
import { colors } from "../utils";
import { Box } from "@mui/material";

const ProgressButton = ({
  children,
  progress,
  progressColor,
  progressInterval,
  sx,
  ...other
}) => (
  <CButton
    sx={{
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: progress ? `${progress}%` : 0,
        transition:
          progress != null ? `width ${progressInterval}ms linear` : "unset",
        backgroundColor: colors[progressColor],
        borderRadius: "4px",
      },
      ...sx,
    }}
    {...other}
  >
    <Box position="absolute">{children}</Box>
  </CButton>
);

ProgressButton.propTypes = {
  ...CButton.propTypes,
  progress: PropTypes.number,
  progressColor: PropTypes.string.isRequired,
  progressInterval: PropTypes.number.isRequired,
};

export default ProgressButton;
