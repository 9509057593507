import React from "react";
import classnames from "classnames";
import {
  TextField,
  formHelperTextClasses,
  outlinedInputClasses,
  inputLabelClasses,
} from "@mui/material";
import { PropTypes } from "prop-types";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`.${inputLabelClasses.root}`]: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 2,
    color: theme.palette.inactive.main,

    "&.Mui-error": {
      color: theme.palette.error.main,
    },
  },
  [`.${formHelperTextClasses.root}`]: {
    margin: "0.56rem 0",
    fontSize: "0.78rem",
    fontWeight: 500,
  },
  [`.${outlinedInputClasses.input}`]: {
    height: "unset",
    lineHeight: 2,
  },
}));

const CInput = ({
  className,
  type,
  value,
  hasError,
  helperText,
  submit,
  ...other
}) => {
  const inputClass = classnames({
    input: true,
    with_helper: helperText,
    [className]: className,
  });

  return (
    <StyledTextField
      className={inputClass}
      type={type || "text"}
      value={value}
      error={hasError}
      helperText={helperText}
      onKeyUp={(e) => e.key === "Enter" && submit && submit()}
      fullWidth={true}
      {...other}
    />
  );
};

CInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  helperText: PropTypes.node,
  submit: PropTypes.func,
};

CInput.defaultProps = {
  hasError: false,
};

export default CInput;
