import React from "react";
import { SvgIcon } from "@mui/material";

const AppStoreIcon = (props) => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <path d="M16.0714 0H1.92857C0.864 0 0 0.864 0 1.92857V16.0714C0 17.136 0.864 18 1.92857 18H16.0714C17.136 18 18 17.136 18 16.0714V1.92857C18 0.864 17.136 0 16.0714 0ZM5.103 14.1628C4.99644 14.3471 4.82103 14.4815 4.61535 14.5365C4.40968 14.5915 4.19059 14.5625 4.00629 14.4559C3.82198 14.3494 3.68755 14.174 3.63258 13.9683C3.5776 13.7626 3.60658 13.5435 3.71314 13.3592L4.28786 12.3666C4.93457 12.1699 5.46493 12.3223 5.87893 12.8244L5.103 14.1628ZM10.6836 11.9976H3.375C2.93271 11.9976 2.57143 11.6357 2.57143 11.1941C2.57143 10.7524 2.93336 10.3905 3.375 10.3905H5.42443L8.05179 5.84229L7.22829 4.42029C7.1223 4.23585 7.09364 4.01695 7.14857 3.81144C7.2035 3.60594 7.33755 3.43053 7.52143 3.32357C7.70587 3.21759 7.92476 3.18893 8.13027 3.24386C8.33578 3.29879 8.51118 3.43284 8.61814 3.61671L8.97557 4.23514L9.333 3.61671C9.38576 3.52545 9.45599 3.44548 9.53966 3.38136C9.62333 3.31724 9.71881 3.27023 9.82065 3.24301C9.92248 3.21579 10.0287 3.20889 10.1332 3.22271C10.2377 3.23654 10.3385 3.27081 10.4297 3.32357C10.521 3.37633 10.6009 3.44656 10.6651 3.53023C10.7292 3.6139 10.7762 3.70938 10.8034 3.81122C10.8306 3.91306 10.8375 4.01926 10.8237 4.12376C10.8099 4.22827 10.7756 4.32903 10.7229 4.42029L7.27586 10.3911H9.77079C10.5821 10.3911 11.0366 11.3432 10.683 11.9983L10.6836 11.9976ZM14.625 11.9976H13.4595L14.247 13.3599C14.4681 13.7456 14.3351 14.2361 13.9539 14.4566C13.5681 14.6777 13.0776 14.5446 12.8571 14.1634C11.5354 11.8774 10.5467 10.1578 9.88393 9.01671C9.21279 7.85121 9.69107 6.68636 10.1694 6.29293C10.6959 7.20514 11.4834 8.57121 12.5357 10.3911H14.625C14.8381 10.3911 15.0425 10.4758 15.1932 10.6265C15.3439 10.7772 15.4286 10.9816 15.4286 11.1947C15.4286 11.4078 15.3439 11.6122 15.1932 11.7629C15.0425 11.9136 14.8381 11.9983 14.625 11.9983V11.9976Z" />
  </SvgIcon>
);
AppStoreIcon.displayName = "AppStoreIcon";
AppStoreIcon.muiName = "SvgIcon";

export default AppStoreIcon;
