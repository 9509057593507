import React from "react";
import { SvgIcon } from "@mui/material";

const FolderIcon = (props) => (
  <SvgIcon viewBox="0 0 18 14" {...props}>
    <path d="M15.75 1.75H8.75L7 0H1.75C0.7875 0 0.00874999 0.7875 0.00874999 1.75L0 12.25C0 13.2125 0.7875 14 1.75 14H15.75C16.7125 14 17.5 13.2125 17.5 12.25V3.5C17.5 2.5375 16.7125 1.75 15.75 1.75Z" />
  </SvgIcon>
);
FolderIcon.displayName = "FolderIcon";
FolderIcon.muiName = "SvgIcon";

export default FolderIcon;
