import React from "react";
import PropTypes from "prop-types";
import PlatformIcon from "../common/PlatformIcon";
import { htmlDecode } from "../../utils";
import { Link } from "react-router-dom";
import TableColumnTitle from "../table/TableColumnTitle";
import { AutoAwesomeMotion } from "@mui/icons-material";
import {
  AppstoreTypesEnum,
  AppstoreTypesMapping,
} from "../../enum/AppstoreTypes";

const AppInfo = ({
  appName,
  appstore,
  orgId,
  appId,
  externalAppId,
  platform,
  appIcon,
}) => {
  const htmlDecodeAppName = htmlDecode(appName);

  return (
    <Link
      to={`/org/${orgId}/reporting/app/${appId}`}
      className="text-decoration-none"
    >
      <div className="app-info__container">
        <div className="app-info__platform">
          {appstore === AppstoreTypesEnum.Omni ? (
            <AutoAwesomeMotion
              role="img"
              titleAccess={AppstoreTypesMapping[appstore]}
              sx={{ color: "#8c949c" }}
            />
          ) : (
            platform && (
              <PlatformIcon platform={platform} sx={{ color: "#8c949c" }} />
            )
          )}
        </div>

        {appIcon ? (
          <img src={appIcon} className="app-info__icon" alt="App icon" />
        ) : (
          <div className="app-info__icon" />
        )}
        <TableColumnTitle title={htmlDecodeAppName} subtitle={externalAppId} />
      </div>
    </Link>
  );
};

AppInfo.propTypes = {
  appName: PropTypes.string.isRequired,
  appstore: PropTypes.oneOf(Object.values(AppstoreTypesEnum)).isRequired,
  orgId: PropTypes.number.isRequired,
  appId: PropTypes.string.isRequired,
  externalAppId: PropTypes.string,
  platform: PropTypes.string,
  appIcon: PropTypes.string,
};

export default AppInfo;
