import React from "react";
import { Box } from "@mui/material";
import CTooltip from "../components/common/CTooltip";
import { informTypes } from "../utils";
import PropTypes from "prop-types";

export const campaignAdGroupInfoTitles = {
  settings: "Settings",
  bidding: "Bidding",
  creatives: "Creatives",
};

export const CampaignAdGroupInfoTooltip = ({ data }) => {
  const content = data
    ? Object.keys(data).reduce((result, current) => {
        const errors = Array.isArray(data[current])
          ? data[current].filter((it) => it.error)
          : [];
        return errors.length
          ? [
              ...result,
              {
                title: campaignAdGroupInfoTitles[current],
                count: errors.length,
              },
            ]
          : result;
      }, [])
    : [];

  return content.length ? (
    <Box marginLeft={2}>
      <CTooltip
        type={informTypes.error}
        content={content.map((it, i) => (
          <div key={i}>
            {'Section "' + it.title + '" has ' + it.count + " errors"}
          </div>
        ))}
      />
    </Box>
  ) : (
    ""
  );
};

CampaignAdGroupInfoTooltip.propTypes = {
  data: PropTypes.node,
};

export const chartsIsEmpty = (metrics, charts) => {
  let hasData = 0;

  if (metrics) {
    Object.values(metrics).forEach(
      (metric) => metric && (hasData += metric.value ? 1 : 0)
    );
  }

  if (charts?.series) {
    Object.values(charts.series).forEach(
      (chart) =>
        chart &&
        Object.values(chart).forEach((line) => {
          if (line && typeof line === "object") {
            hasData += line.data ? 1 : 0;
          }
        })
    );
  }

  return !hasData;
};

export const prepareInfo = (info) =>
  info.map(({ title, content }) => ({
    title,
    content: content.filter((it) => it),
  }));

export const getInfoOptionalContent = (data, condition) =>
  condition ? data : null;

export const getInfoMultipleValuesContent = (
  label,
  data,
  details,
  params = {}
) => ({
  label,
  value: data && data.length ? (data.length === 1 ? data[0] : "") : null,
  info:
    data && data.length > 1
      ? params.tootipTemplate && params.tootipTemplate === "ol"
        ? {
            label: `${data[0]} + ${data.length - 1} more`,
            title: (
              <ol>
                {data.map((it, i) => (
                  <li key={i}>{it}</li>
                ))}
              </ol>
            ),
            details,
          }
        : {
            label: `${data[0]} + ${data.length - 1} more`,
            title: data.join(", "),
            details,
          }
      : null,
});

export const getInfoCreativeContent = (label, data) => {
  let disapproved = data ? data.total - data.approved : null;

  return {
    label,
    value: data && `${data.approved}/${data.total}`,
    error: disapproved
      ? {
          title: `Disapproved: ${disapproved}`,
          link: data.link && `#${data.link}`,
        }
      : null,
  };
};
