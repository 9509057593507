import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { colors, formatVal, sortObjectsByParam } from "../../utils";
import { Box } from "@mui/material";
import CTooltip, { placements } from "../common/CTooltip";

const ArrowMetric = ({
  className,
  value,
  title,
  start,
  end,
  settings,
  hasLegend,
  beforeLegend,
}) => {
  const sortedSettings = sortObjectsByParam(settings, "to");
  end = end || sortedSettings[sortedSettings.length - 1].to;
  value = value == null ? null : value;
  const selectedSetting =
    value == null
      ? null
      : sortedSettings.find((it) => value <= it.to) ||
        sortedSettings[settings.length - 1];
  const color = selectedSetting ? selectedSetting.color : colors.default;

  const arrowMetricClass = classnames({
    arrow_metric: true,
    [className]: className,
  });

  const preparedValue = (val = value) =>
    val > end ? end : val < start ? start : val;

  let legend = "";
  if (hasLegend) {
    const val = (val) => val.toFixed(2);

    legend = [];
    for (let i = sortedSettings.length - 1; i >= 0; i--) {
      legend.push(
        <div key={i} className="legend_row">
          <span
            className="legend_row_item legend_color"
            style={{ backgroundColor: sortedSettings[i].color }}
          />
          <span className="legend_row_item legend_data">
            {i === sortedSettings.length - 1
              ? ">=" + val(sortedSettings[i].to)
              : i === 0
              ? "<=" + val(sortedSettings[i].to)
              : val(sortedSettings[i - 1].to + 0.01) +
                "-" +
                val(sortedSettings[i].to)}
          </span>
          {sortedSettings[i].comment && (
            <span className="legend_row_item legend_description">
              {sortedSettings[i].comment}
            </span>
          )}
        </div>
      );
    }
  }

  return (
    <Box
      className={arrowMetricClass}
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {hasLegend && (
        <CTooltip
          labelClassName="arrow_metric__legend_icon"
          placement={placements.bottomStart}
          content={
            <div className="arrow_metric__legend">
              {beforeLegend}
              {legend}
            </div>
          }
        />
      )}
      {title && <div className="arrow_metric__title">{title}</div>}
      <div className="arrow_metric__scale">
        {sortedSettings &&
          sortObjectsByParam(settings, "to", -1).map((it, i) => (
            <div
              key={i}
              className="arrow_metric__scale_item"
              style={{
                borderBottomColor: it.color,
                borderLeftColor: it.color,
                transform:
                  "rotate(" +
                  (-45 +
                    (((i === 0 ? end : preparedValue(it.to)) - start) /
                      (end - start)) *
                      180) +
                  "deg)",
              }}
            />
          ))}
      </div>
      <div
        className="arrow_metric__arrow"
        style={{
          display: value == null ? "none" : "block",
          transform:
            "rotate(" +
            (-90 + ((preparedValue() - start) / (end - start)) * 180) +
            "deg)",
          color,
        }}
      />
      <div
        className={classnames({
          arrow_metric__value: true,
          no_data: value == null,
        })}
        style={{ color }}
      >
        {value == null ? "No data" : formatVal(value)}
      </div>
    </Box>
  );
};

ArrowMetric.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  title: PropTypes.string,
  start: PropTypes.number,
  end: PropTypes.number,
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      comment: PropTypes.string,
    })
  ).isRequired,
  hasLegend: PropTypes.bool,
  beforeLegend: PropTypes.node,
};

ArrowMetric.defaultProps = {
  start: 0,
  hasLegend: false,
};

export default ArrowMetric;
