import React, { useCallback, useEffect, useState } from "react";
import MainTemplate from "../../../templates/MainTemplate";
import { useNavigate, useParams } from "react-router-dom";
import InfoBar from "../../../components/common/InfoBar";
import TabBlock from "../../../components/TabBlock";
import Recommendations from "../../../components/Recommendation/Recommendations";
import CampaignAdGroupMetrics from "../../../components/CampaignAdGroupReport/CampaignAdGroupMetrics";
import CampaignAdGroupCharts from "../../../components/CampaignAdGroupReport/CampaignAdGroupCharts";
import CampaignAdGroupInfoTable, {
  contentTypes as campaignInfoContentTypes,
} from "../../../components/CampaignAdGroupReport/CampaignAdGroupInfoTable";
import {
  dateSendFormat,
  dateFromUnix,
  getCountry,
  getLanguage,
  getPeriods,
  isDev,
  sleep,
  htmlDecode,
  colors,
} from "../../../utils";
import {
  metrics as enumMetrics,
  reverseGrowthMetrics,
} from "../../../enum/Metrics";
import adwizyApi from "../../../api/adwizyApi";
import { useUser } from "../../../context/UserProvider";
import { Box } from "@mui/material";
import AppId from "../../../helpers/appId";
import { useInformer } from "../../../context/InformerProvider";
import { types as infoPanelTypes } from "../../../components/common/InfoPanel";
import deepEqual from "deep-equal";
import AppIcon from "../../../components/common/AppIcon";
import ValuePicker from "../../../components/common/ValuePicker";
import { StatusesEnum, StatusesMapping } from "../../../enum/Statuses";
import { GoalsMapping } from "../../../enum/Goals";
import { StrategiesEnum } from "../../../enum/Strategies";
import {
  getInfoMultipleValuesContent,
  getInfoOptionalContent,
  CampaignAdGroupInfoTooltip,
  campaignAdGroupInfoTitles,
  chartsIsEmpty,
  prepareInfo,
} from "../../../helpers/campaignAdGroup";
import NetworkMiniIcon from "../../../components/common/NetworkMiniIcon";
import { ReportTypes } from "../../../enum/ReportTypes";
import { events, useEvent } from "../../../context/EventProvider";
import { getMetricsScoresAndRecsHandler } from "../../../helpers/eventHandlers";
import { MMPConnectionStatusesEnum } from "../../../enum/MMPConnectionStatuses";
import CTooltip from "../../../components/common/CTooltip";

const ReportingCampaignPage = () => {
  const user = useUser();
  const event = useEvent();
  const informer = useInformer();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [adGroupStrategy, setAdGroupStrategy] = useState(null);
  const [adGroupStrategyLoading, setAdGroupStrategyLoading] = useState(false);

  const [app, setApp] = useState(null);
  const [adGroup, setAdGroup] = useState(null);

  const [score, setScore] = useState(null);

  const [recs, setRecs] = useState(null);
  const [showHiddenRecs, setShowHiddenRecs] = useState(false);
  const [metrics, setMetrics] = useState(null);
  const [charts, setCharts] = useState(null);
  const [info, setInfo] = useState(null);

  const [metricsKey, setMetricsKey] = useState(0);
  const [scoresKey, setScoresKey] = useState(0);
  const [recsKey, setRecsKey] = useState(0);

  const params = useParams();
  let orgId = Number(params.orgId);
  let appId = params.appId;
  let campaignId = Number(params.campaignId);
  let adGroupId = Number(params.adGroupId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const id = AppId(appId);
    let fApp = user.apps.find((it) => id.isEqual(it.appId));
    if (fApp) {
      fApp = { ...fApp, ...id };
      !deepEqual(fApp, app) && setApp(fApp);
    } else {
      setApp(null);
      navigate(`/org/${orgId}/reporting`);
    }
  }, [appId, user.apps]); // eslint-disable-line react-hooks/exhaustive-deps

  const adGroupStrategyChangeHandler = async (val) => {
    setAdGroupStrategyLoading(true);

    await adwizyApi
      .put(`/campaign-ad-group/${adGroupId}`, {
        orgId,
        campaignAdGroupStrategy: val,
      })
      .then((res) => {
        if (res.data.success) {
          setAdGroupStrategy(val);
        }
      })
      .catch(informer.showErrorNotice);

    setAdGroupStrategyLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setLoading(true);
    setAdGroup(null);
    setAdGroupStrategy(null);
    setInfo(null);

    if (app) {
      const setAdGroupData = (adGroup) => {
        setAdGroup(adGroup);
        setAdGroupStrategy(adGroup.adGroupStrategy);
        setInfo(
          prepareInfo([
            {
              title: campaignAdGroupInfoTitles.settings,
              content: [
                {
                  label: "Ad group status",
                  value: adGroup.adGroupStatus
                    ? StatusesMapping[adGroup.adGroupStatus]
                    : null,
                },
                getInfoOptionalContent(
                  {
                    label: "Start date",
                    value: dateFromUnix(adGroup.tsStartDate),
                  },
                  !!adGroup.tsStartDate
                ),
                getInfoOptionalContent(
                  {
                    label: "End date",
                    value: dateFromUnix(adGroup.tsEndDate),
                  },
                  !!adGroup.tsEndDate
                ),
                getInfoOptionalContent(
                  {
                    label: "Target App",
                    value: adGroup.targetApp,
                  },
                  !!adGroup.targetApp
                ),
                getInfoMultipleValuesContent(
                  "Targeting location",
                  adGroup.targetLocations?.included?.map(
                    (code) => getCountry(code)?.name
                  )
                  // "Any info about location"
                ),
                getInfoMultipleValuesContent(
                  "Targeting location (excluded)",
                  adGroup.targetLocations?.excluded?.map(
                    (code) => getCountry(code)?.name
                  )
                  // "Excluded locations"
                ),
                getInfoMultipleValuesContent(
                  "Language",
                  adGroup.languages?.map((code) => getLanguage(code)?.name)
                ),
              ],
            },
            {
              title: campaignAdGroupInfoTitles.bidding,
              content: [
                getInfoOptionalContent(
                  {
                    label: "Ad group goal",
                    value: adGroup.adGroupGoal
                      ? GoalsMapping[adGroup.adGroupGoal]
                      : null,
                  },
                  !!adGroup.adGroupGoal
                ),
                getInfoMultipleValuesContent(
                  "Target conversion",
                  adGroup.targetConversions,
                  "",
                  { tootipTemplate: "ol" }
                ),
                getInfoOptionalContent(
                  {
                    label: "Attribution window",
                    value: adGroup.attributionWindow,
                  },
                  adGroup.attributionWindow
                ),
                getInfoOptionalContent(
                  {
                    label: adGroup.budget?.label,
                    value: adGroup.budget?.value,
                    type: campaignInfoContentTypes.money,
                    currency: adGroup.currency,
                  },
                  !!adGroup.budget
                ),
                getInfoOptionalContent(
                  {
                    label: adGroup.bid?.label,
                    value: adGroup.bid?.value,
                    type: adGroup.bid?.type,
                    currency: adGroup.currency,
                  },
                  !!adGroup.bid
                ),
              ],
            },
            {
              title: campaignAdGroupInfoTitles.creatives,
              content: [
                {
                  label: "Text",
                  value: adGroup.creatives.text,
                },
                {
                  label: "Image",
                  value: adGroup.creatives.image,
                },
                {
                  label: "Video",
                  value: adGroup.creatives.video,
                },
                {
                  label: "HTML5",
                  value: adGroup.creatives.html5,
                },
              ],
            },
          ])
        );

        setLoading(false);
      };

      if (isDev) {
        await sleep(300);
        setAdGroupData(require("../../../mocks/report__adGroupInfo.json").data);
      } else {
        adwizyApi
          .get("/report/campaign-ad-group/info", {
            orgId,
            campaignId,
            campaignAdGroupId: adGroupId,
          })
          .then((res) => {
            if (res.data.success) {
              setAdGroupData(res.data.data);
            }
          })
          .catch(informer.showErrorNotice);
      }
    }
  }, [orgId, app, campaignId, adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchRecs = useCallback(async () => {
    setRecs(null);
    if (app && adGroupStrategy) {
      if (isDev) {
        await sleep(400);
        setRecs({
          ...require("../../../mocks/report__campaignAdGroupRecs.json").data,
        });
      } else {
        adwizyApi
          .get("/recommendations/list", {
            orgId,
            campaignId,
            campaignAdGroupId: adGroupId,
            campaignAdGroupStrategy: adGroupStrategy,
            showHidden: showHiddenRecs,
          })
          .then((res) => {
            if (res.data.success) {
              setRecs(res.data.data);
            }
          })
          .catch(informer.showErrorNotice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, app, adGroup, adGroupStrategy, showHiddenRecs]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchRecs, [scoresKey, recsKey, fetchRecs]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setMetrics(null);

    if (app && adGroup) {
      if (isDev) {
        await sleep(300);
        setMetrics(require("../../../mocks/report__adGroupMetrics.json").data);
      } else {
        adwizyApi
          .get("/report/campaign-ad-group/metrics", {
            orgId,
            appId: app.appId,
            appstoreAppId: app.appstoreAppId,
            campaignId,
            campaignAdGroupId: adGroupId,
            dateFrom,
            dateTo,
            forceConversion: user.forceConversion,
          })
          .then((res) => {
            if (res.data.success) {
              setMetrics(res.data.data);
            }
          })
          .catch(informer.showErrorNotice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsKey, orgId, app, adGroup, dateFrom, dateTo, user.forceConversion]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setScore(null);
    if (app && adGroup && adGroupStrategy) {
      const periods = getPeriods({ dateFrom, dateTo });
      const setScoreData = (score) =>
        setScore([
          {
            title: "Total cost",
            currentValue: score.cost?.currentValue,
            previousValue: score.cost?.previousValue,
            currency: score.cost?.currency,
            periods,
            reverse: reverseGrowthMetrics.includes(enumMetrics.cost),
          },
          {
            title: "CPA",
            currentValue: score.cpa?.currentValue,
            previousValue: score.cpa?.previousValue,
            currency: score.cpa?.currency,
            periods,
            reverse: reverseGrowthMetrics.includes(enumMetrics.cpa),
          },
          {
            title: "Conversions",
            currentValue: score.conversions?.currentValue,
            previousValue: score.conversions?.previousValue,
            type: infoPanelTypes.number,
            periods,
            reverse: reverseGrowthMetrics.includes(enumMetrics.conversions),
          },
          {
            title: "Score",
            currentValue: score.score?.currentValue,
            type: infoPanelTypes.score,
            periods,
            reverse: reverseGrowthMetrics.includes(enumMetrics.score),
          },
        ]);

      if (isDev) {
        await sleep(300);
        setScoreData(
          require("../../../mocks/report__adGroupScoreCard.json").data
        );
      } else {
        adwizyApi
          .get("/report/campaign-ad-group/score-card", {
            orgId,
            appId: app.appId,
            appstoreAppId: app.appstoreAppId,
            campaignId,
            campaignAdGroupId: adGroupId,
            campaignAdGroupStrategy: adGroupStrategy,
            dateFrom,
            dateTo,
            forceConversion: user.forceConversion,
          })
          .then((res) => {
            if (res.data.success) {
              setScoreData(res.data.data);
            }
          })
          .catch(informer.showErrorNotice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    metricsKey,
    scoresKey,
    orgId,
    app,
    adGroup,
    adGroupStrategy,
    dateFrom,
    dateTo,
    user.forceConversion,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setCharts(null);
    if (app && adGroup && adGroupStrategy) {
      if (isDev) {
        await sleep(500);
        setCharts(require("../../../mocks/report__adGroupCharts.json").data);
      } else {
        const payload = {
          orgId,
          appId: app.appId,
          appstoreAppId: app.appstoreAppId,
          campaignId,
          campaignAdGroupId: adGroupId,
          campaignAdGroupStrategy: adGroupStrategy,
          dateFrom,
          dateTo,
          forceConversion: user.forceConversion,
        };

        adwizyApi
          .get("/report/campaign-ad-group/charts", payload)
          .then((res) => {
            if (res.data.success) {
              setCharts(res.data.data);
            }
          })
          .catch(informer.showErrorNotice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    metricsKey,
    recsKey,
    orgId,
    app,
    adGroup,
    adGroupStrategy,
    dateFrom,
    dateTo,
    user.forceConversion,
  ]);

  useEffect(() => {
    const condition = ({ payload }) =>
      payload?.campaignAdGroupIds?.includes(adGroupId);
    const eventHandlers = {
      [events.orgAppMetricsUpdated]: getMetricsScoresAndRecsHandler(
        () => setMetricsKey((key) => key + 1),
        condition
      ),
      [events.orgAppScoresUpdated]: getMetricsScoresAndRecsHandler(
        () => setScoresKey((key) => key + 1),
        condition
      ),
      [events.orgAppRecsUpdated]: getMetricsScoresAndRecsHandler(
        () => setRecsKey((key) => key + 1),
        condition
      ),
    };

    event.subscribe(eventHandlers).catch(informer.showErrorNotice);
    return () => event.unsubscribe(eventHandlers);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainTemplate
      isAllowed={user.isAllowReport(ReportTypes.base)}
      breadcrumbs={
        app && [
          {
            path: `/org/${orgId}/reporting/app/${appId}`,
            content: (
              <Box className="d-flex align-items-center">
                <AppIcon
                  complexAppId={appId}
                  appstore={app?.appstore}
                  isAppMap={app?.isAppMap}
                  MMPConnectionStatus={app?.mmpConnectionStatus}
                  MMPServiceName={app?.mmpName}
                />
                <Box marginLeft="15px">{htmlDecode(app?.appName)}</Box>
              </Box>
            ),
          },
          {
            path: `/org/${orgId}/reporting/app/${appId}/campaign/${campaignId}`,
            content: (
              <Box className="d-flex align-items-center">
                <NetworkMiniIcon network={adGroup?.network} />
                <Box marginLeft="15px">{adGroup?.campaignName}</Box>
              </Box>
            ),
          },
        ]
      }
      subtitle={
        adGroup && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            fullWidth
          >
            <Box>
              <span>{adGroup.adGroupName}</span>
              <span className="subtitle_info">id {adGroup.externalId}</span>
            </Box>
            {app.mmpConnectionStatus === MMPConnectionStatusesEnum.Connected ? (
              <CTooltip
                customLabel={
                  <div className="value-picker">
                    <div className="value-picker__content without-icon">
                      {StrategiesEnum[adGroupStrategy]}
                    </div>
                  </div>
                }
                content={
                  "Ad group strategy is selected automatically based on the app target and current ad group performance"
                }
              />
            ) : (
              <ValuePicker
                values={StrategiesEnum}
                handleSelected={adGroupStrategyChangeHandler}
                selectedValue={adGroupStrategy}
                loading={loading || adGroupStrategyLoading}
                disabled={adGroupStrategyLoading}
              />
            )}
          </Box>
        )
      }
      datepicker={{
        onChange: ({ start, end }) => {
          setDateFrom(start.format(dateSendFormat));
          setDateTo(end.format(dateSendFormat));
        },
        disabled: loading,
        withContext: true,
        withQuery: true,
      }}
      loading={loading}
    >
      <InfoBar
        className="content_block"
        panels={score}
        loading={loading || !score}
        loadingCount={4}
      />
      <TabBlock
        className="content_block"
        queryParam="tab"
        tabs={[
          { label: "Recommendations", value: "recommendations" },
          { label: "Charts", value: "charts" },
          {
            label: (
              <Box display="flex" alignItems="center">
                Ad group Information
                <CampaignAdGroupInfoTooltip data={info} />
              </Box>
            ),
            value: "adGroupInformation",
          },
        ]}
        panels={[
          {
            value: "recommendations",
            content:
              adGroup?.adGroupStatus === StatusesEnum.Enabled ? (
                <Recommendations
                  orgId={orgId}
                  campaignId={campaignId}
                  campaignAdGroupId={adGroupId}
                  campaignAdGroupStrategy={adGroupStrategy}
                  showHiddenRecs={showHiddenRecs}
                  onToggleShowHiddenRecs={setShowHiddenRecs}
                  fetchFunc={fetchRecs}
                  data={{ ...recs }}
                />
              ) : (
                <Box paddingY="5rem" textAlign="center" color={colors.default}>
                  The ad group is not active. Adwizy only shows recommendations
                  for active ad groups.
                </Box>
              ),
            loading: loading || !recs,
          },
          {
            value: "charts",
            content: chartsIsEmpty(metrics, charts) ? (
              <Box paddingY="5rem" textAlign="center" color={colors.default}>
                There is no data for this period.
              </Box>
            ) : (
              <Box display="flex" flexDirection="column">
                <CampaignAdGroupMetrics
                  title="Ad group accuracy"
                  data={metrics}
                />
                <Box zIndex={1}>
                  <CampaignAdGroupCharts data={charts} />
                </Box>
              </Box>
            ),
            loading: loading || !metrics || !charts,
          },
          {
            value: "adGroupInformation",
            content: <CampaignAdGroupInfoTable data={info} />,
            style: { padding: loading || !info ? undefined : 0 },
            loading: loading || !info,
          },
        ]}
      />
    </MainTemplate>
  );
};

export default ReportingCampaignPage;
