export const RecommendationDecisionEnum = {
  viewed: "viewed",
  accepted: "accepted",
  skipped: "skipped",
  ignored: "ignored",
  implemented: "implemented",
};

export const DecisionLabels = {
  [RecommendationDecisionEnum.accepted]: "Accepted",
  [RecommendationDecisionEnum.skipped]: "Declined",
  [RecommendationDecisionEnum.ignored]: "Declined",
};

export const isNewRec = (decision) =>
  !decision || decision === RecommendationDecisionEnum.viewed;

export const isHidden = (decision) =>
  [
    RecommendationDecisionEnum.skipped,
    RecommendationDecisionEnum.ignored,
    RecommendationDecisionEnum.implemented,
  ].includes(decision);
