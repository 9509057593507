import React from "react";
import MainTemplate from "../../templates/MainTemplate";
import AppsSettings from "../../components/AppsPage/AppsSettings";

const AppsPage = () => {
  return (
    <MainTemplate title="Apps">
      <div className="content_block">
        <AppsSettings />
      </div>
    </MainTemplate>
  );
};

export default AppsPage;
