import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MMPConnectionStatusesEnum } from "../../enum/MMPConnectionStatuses";
import CButton from "../common/CButton";
import { useUser } from "../../context/UserProvider";
import { IconButton } from "@mui/material";
import { MMPTargetsEnum } from "../../enum/MMPTargets";
import MMPTargetConversionSetup from "../MMP/MMPTargetConversionSetup";
import CSkeleton from "../common/CSkeleton";
import MMPConnection from "../MMP/MMPConnection";
import AppId from "../../helpers/appId";
import { MMPServices } from "../../enum/MMPServices";
import AppMMPStatusDropdownMenu from "../MMP/AppMMPStatusDropdownMenu";
import { MoreVert } from "@mui/icons-material";

const AppMMPTarget = ({
  complexAppId,
  orgId,
  MMPConnectionStatus,
  MMPServiceName,
  appName,
  target,
  targetValue,
  targetCurrency,
  preferredCurrencies,
}) => {
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const [, setAppId] = useState(null);
  const [appstoreAppId, setAppstoreAppId] = useState(null);

  useEffect(() => {
    const res = AppId(complexAppId);
    setAppId(res.appId);
    setAppstoreAppId(res.appstoreAppId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? (
    <CSkeleton width="12rem" />
  ) : (
    <div className="app-mmp-target">
      {appstoreAppId && MMPConnectionStatus === null && (
        <MMPConnection
          appstoreAppId={appstoreAppId}
          orgId={orgId}
          btn={
            <CButton variant="contained" color="primary">
              Connect
            </CButton>
          }
          cbAfterAddedConnection={user.userAppsListChanged}
        />
      )}

      {(MMPConnectionStatus === MMPConnectionStatusesEnum.AwaitingOfSetup ||
        MMPConnectionStatus === MMPConnectionStatusesEnum.Connected) && (
        <MMPTargetConversionSetup
          complexAppId={complexAppId}
          orgId={orgId}
          appName={appName}
          btn={
            <CButton variant="contained" color="primary">
              Set target
            </CButton>
          }
          onSetTargetData={async () => {
            setLoading(true);
            await user.userAppsListChanged();
            setLoading(false);
          }}
          currencyValues={preferredCurrencies}
          targetCurrency={targetCurrency}
          target={target}
          targetValue={targetValue}
        />
      )}
      {(MMPConnectionStatus === MMPConnectionStatusesEnum.Pending ||
        MMPConnectionStatus === MMPConnectionStatusesEnum.Disconnected) && (
        <div />
      )}

      {appstoreAppId && (
        <AppMMPStatusDropdownMenu
          appstoreAppId={appstoreAppId}
          orgId={orgId}
          appName={appName}
          MMPConnectionStatus={MMPConnectionStatus}
          MMPServiceName={MMPServiceName}
          btn={
            <IconButton>
              <MoreVert />
            </IconButton>
          }
          autoWidthPaper={false}
        />
      )}
    </div>
  );
};

AppMMPTarget.propTypes = {
  complexAppId: PropTypes.string.isRequired,
  orgId: PropTypes.number.isRequired,
  MMPConnectionStatus: PropTypes.oneOf(
    Object.values(MMPConnectionStatusesEnum)
  ),
  MMPServiceName: PropTypes.oneOf(Object.keys(MMPServices)),
  appName: PropTypes.string.isRequired,
  target: PropTypes.oneOf(Object.values(MMPTargetsEnum)),
  targetValue: PropTypes.number,
  targetCurrency: PropTypes.string,
  preferredCurrencies: PropTypes.array,
};

export default AppMMPTarget;
