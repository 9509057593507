import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useUser } from "../../context/UserProvider";

const NotificationButton = () => {
  const user = useUser();
  const [countUnreadNotifications, setCountUnreadNotifications] = useState(0);

  useEffect(() => {
    if (user.notifications) {
      const res = user.notifications.filter((n) => !n.is_read).length;
      setCountUnreadNotifications(res > 9 ? "9+" : res);
    }
  }, [user.notifications]);

  return (
    <Link to="/notifications">
      <Badge
        badgeContent={countUnreadNotifications}
        color="error"
        overlap="circular"
        className="notification-btn"
      >
        <Button
          className="icon_button position-relative"
          color="warningClicked"
          variant="contained"
        >
          <NotificationsIcon color="warning" />
        </Button>
      </Badge>
    </Link>
  );
};
export default NotificationButton;
