import { Box, FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import DropdownFilter, {
  variants as dropdownVariants,
} from "../common/DropdownFilter";
import {
  dateFromUnix,
  getCountry,
  getCurrencies,
  isDev,
  sleep,
} from "../../utils";
import adwizyApi from "../../api/adwizyApi";
import { defaultValidate } from "../../templates/AuthTemplate";
import PropTypes from "prop-types";
import { useInformer } from "../../context/InformerProvider";
import CTooltip from "../common/CTooltip";
import { Warning as WarningIcon } from "@mui/icons-material";
import CTable, { types as tableTypes } from "../common/CTable";
import moment from "moment";
import { Link } from "react-router-dom";
import CSkeleton from "../common/CSkeleton";

const DateExpired = ({ tsExpiredIn }) => {
  const [days, setDays] = useState(null);

  const getExpireSubscriptionMsg = () => {
    return days === 1
      ? "Today is the last day of the prepaid period."
      : `${days} days left before the end of the prepaid period.`;
  };

  useEffect(() => {
    if (tsExpiredIn) {
      const duration = moment.duration(moment.unix(tsExpiredIn).diff(moment()));
      setDays(Math.ceil(duration.asDays()));
    }
  }, [tsExpiredIn]);

  return (
    <div className="d-flex align-items-center">
      <Box mr=".2em">{dateFromUnix(tsExpiredIn)}</Box>
      {days <= 15 && (
        <CTooltip
          customLabel={<WarningIcon color="error" />}
          content={getExpireSubscriptionMsg()}
        />
      )}
    </div>
  );
};

DateExpired.propTypes = {
  tsExpiredIn: PropTypes.number,
};

const SubscriptionActions = ({
  orgId,
  billingSubscriptionId,
  tariffCode,
  tsExpiredIn,
  isLast,
  isUpgradeable,
  isBetaTested,
}) => {
  const [weeks, setWeeks] = useState(null);

  useEffect(() => {
    if (tsExpiredIn) {
      const duration = moment.duration(moment.unix(tsExpiredIn).diff(moment()));
      setWeeks(duration.asWeeks());
    }
  }, [tsExpiredIn]);

  return (
    <Box
      className="d-flex align-items-center flex-wrap"
      sx={{
        gridGap: "1rem",
      }}
    >
      {!isBetaTested && isLast && weeks <= 4 && (
        <Link
          to={`/org/${orgId}/payment-method-selection/${tariffCode}?billingSubscriptionId=${billingSubscriptionId}`}
          className="f-w-600"
        >
          Extend
        </Link>
      )}
      {isUpgradeable && (
        <Link to={`/org/${orgId}/plan-selection`} className="f-w-600">
          Upgrade
        </Link>
      )}
    </Box>
  );
};

SubscriptionActions.propTypes = {
  orgId: PropTypes.number,
  billingSubscriptionId: PropTypes.number,
  tariffCode: PropTypes.string,
  tsExpiredIn: PropTypes.number,
  isLast: PropTypes.bool,
  isUpgradeable: PropTypes.bool,
  isBetaTested: PropTypes.bool,
};

const OrganizationInformation = ({ orgId, subscriptions }) => {
  const informer = useInformer();
  const [loading, setLoading] = useState(true);
  const [currencies, setCurrencies] = useState({});
  const [currency, setCurrency] = useState(null);
  const [country, setCountry] = useState(null);
  const [currencyError, setCurrencyError] = useState("");

  const validateCurrency = (currency) =>
    defaultValidate("Currency", currency, setCurrencyError);

  const fetchOrgData = async () => {
    if (!isDev) {
      await adwizyApi
        .get(`/org/${orgId}`)
        .then((res) => {
          if (!res.data.success) {
            informer.showErrorNotice(res.data.data.message);
          } else {
            setCurrency(res.data.data.preferredCurrency);
            setCountry(res.data.data.countryCode3);
          }
        })
        .catch(informer.showErrorNotice);
    } else {
      await sleep(300);
      setCurrency(require("../../mocks/org.json").data.preferredCurrency);
      setCountry(require("../../mocks/org.json").data.countryCode3);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (orgId) {
      fetchOrgData();
    }
  }, [orgId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrencies(getCurrencies());
  }, []);

  const changeInformation = async (newCurrencyValue) => {
    if (validateCurrency(newCurrencyValue)) {
      setLoading(true);
      if (!isDev) {
        await adwizyApi
          .put(`/org/${orgId}`, {
            preferredCurrency: newCurrencyValue,
          })
          .then((res) => {
            if (!res.data.success) {
              setCurrencyError(res.data.data.message);
            } else {
              setCurrency(newCurrencyValue);
            }
          })
          .catch(informer.showErrorNotice);
      }
      setLoading(false);
    }
  };

  return (
    <CTable
      rows={subscriptions}
      settings={{
        columns: {
          name: {
            title: () => "Name",
            format: tableTypes.custom,
            component: (row) => (
              <>
                {row.name}
                {row.isTrial && " (trial period)"}
              </>
            ),
          },
          tsDateStart: {
            title: () => "Start date",
            format: tableTypes.custom,
            component: (row) => dateFromUnix(row.tsDateStart),
            sx: {
              minWidth: "10rem",
              width: "10rem",
            },
          },
          tsExpiredIn: {
            title: () => "Expiry date",
            format: tableTypes.custom,
            component: (row) => <DateExpired tsExpiredIn={row.tsExpiredIn} />,
            sx: {
              minWidth: "10rem",
              width: "10rem",
            },
          },
          actions: {
            title: () => "",
            format: tableTypes.custom,
            component: (row) => (
              <SubscriptionActions
                orgId={orgId}
                billingSubscriptionId={row.billingSubscriptionId}
                tariffCode={row.tariffCode}
                tsExpiredIn={row.tsExpiredIn}
                isLast={row.isLast}
                isUpgradeable={row.isUpgradeable}
                isBetaTested={row.isBetaTested}
              />
            ),
          },
        },
      }}
      topArea1={
        <>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <Box marginRight={4}>
              <h2 className="subtitle color-primary m-0">
                Organization information
              </h2>
            </Box>

            {loading ? (
              <CSkeleton width="30%" height="3.72rem" />
            ) : (
              <div className="d-flex align-items-baseline">
                <div className="d-flex align-items-center f-w-500">
                  Country: {getCountry(country)?.name}
                </div>

                <Box className="d-flex align-items-center" ml="1.61rem">
                  <Box
                    className="f-w-500"
                    whiteSpace="nowrap"
                    marginRight=".89rem"
                  >
                    Preferred currency:
                  </Box>

                  <Box minWidth="11.11rem">
                    <DropdownFilter
                      variant={dropdownVariants.mini}
                      values={currencies}
                      handleSelected={changeInformation}
                      selectedValue={currency}
                    />
                    {currencyError && (
                      <FormHelperText error={true}>
                        {currencyError}
                      </FormHelperText>
                    )}
                  </Box>
                </Box>
              </div>
            )}
          </div>
          <Box
            className="table-title"
            sx={{
              m: "2.77rem 0 1.66rem",
            }}
          >
            Subscriptions
          </Box>
        </>
      }
      emptyDataText="There is no active subscriptions"
    />
  );
};

OrganizationInformation.propTypes = {
  orgId: PropTypes.number,
  subscriptions: PropTypes.array,
};

export default OrganizationInformation;
