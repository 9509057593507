import React, { useEffect, useState } from "react";
import CReactApexLineChart from "../charts/CReactApexLineChart";
import PropTypes from "prop-types";
import { formatVal, formatValue, moneyFormatter } from "../../utils";

const CampaignAdGroupCharts = ({ data, loading }) => {
  const getOptions = ({ chart, xaxis, yaxis, legend, stroke, ...other }) => ({
    chart: {
      group: "campaign_charts",
      ...chart,
    },
    xaxis: {
      type: "datetime",
      ...xaxis,
    },
    yaxis: {
      labels: {
        minWidth: "2.78rem",
        maxWidth: "2.78rem",
      },
      forceNiceScale: true,
      ...yaxis,
    },
    legend: {
      showForNullSeries: false,
      ...legend,
    },
    stroke: {
      ...stroke,
    },
    colors: [],
    labels: data?.labels || [],
    ...other,
  });

  const getDefaultChartsSettings = ({ targetRealBid } = {}) => ({
    budgetCost: {
      title: (
        <div className="campaign_chart__title">
          Cost and budget daily dynamics
        </div>
      ),
      options: getOptions({
        chart: {
          id: "budgetUtilization",
        },
        stroke: {
          dashArray: [],
        },
      }),
      series: [],
    },
    targetRealBid: {
      title: (
        <div className="campaign_chart__title">
          {`${targetRealBid?.realBid?.name}${
            targetRealBid?.targetBid
              ? ` and ${targetRealBid.targetBid.name}`
              : ""
          } daily dynamics`}
        </div>
      ),
      options: getOptions({
        chart: {
          id: "campaignAimAccuracy",
        },
      }),
      series: [],
    },
    conversions: {
      title: <div className="campaign_chart__title">Conversions</div>,
      options: getOptions({
        chart: {
          id: "conversions",
        },
        stroke: {
          dashArray: [],
        },
      }),
      series: [],
    },
  });

  const getDefaultLinesSettings = () => ({
    budgetCost: {
      budget: {
        color: "#E6AE20",
        dash: 0,
      },
      cost: {
        color: "#399BDC",
        dash: 0,
      },
      recommendedBudget: {
        color: "#9CA7AE",
        dash: 15,
      },
    },
    targetRealBid: {
      targetBid: {
        color: "#3D9F1A",
      },
      realBid: {
        color: "#E71E1E",
      },
    },
    conversions: {
      conversions: {
        color: "#B642DF",
        dash: 0,
      },
      conversionsForecast: {
        color: "#B642DF",
        dash: 10,
      },
    },
  });

  const [chartsSettings, setChartsSettings] = useState([]);

  useEffect(() => {
    if (data) {
      const chartsData = data;
      const charts = getDefaultChartsSettings(data.series);
      const lines = getDefaultLinesSettings();
      const currency = data.currency;
      const chartsSettings = [];

      Object.entries(chartsData.series).forEach(([chartName, chartData]) => {
        if (chartData) {
          const chart = {};
          Object.assign(chart, charts[chartName]);
          let type = chartData.type;
          let unit = type === "percent" ? "%" : "";
          chart.options.yaxis.labels.formatter =
            type === "money"
              ? (value) => moneyFormatter({ num: value, currency })
              : (value) => (formatValue(value) + unit).toUpperCase();

          chart.options.tooltip = {
            y: {
              formatter: (value) =>
                type === "money"
                  ? moneyFormatter({ num: value, currency, clipped: false })
                  : (formatValue(value) + unit).toUpperCase(),
            },
          };

          Object.entries(chartData).forEach(([lineName, lineData]) => {
            if (lineData && typeof lineData === "object") {
              if (lines[chartName] && lines[chartName][lineName]) {
                chart.options.colors.push(lines[chartName][lineName].color);
                chart.options.stroke.dashArray?.push(
                  lines[chartName][lineName].dash
                );
              }

              chart.series.push({
                name: lineData.name,
                data: Array.isArray(lineData.data)
                  ? lineData.data.map((it) => formatVal(it, 10))
                  : Array(chartsData.labels.length).fill(
                      formatVal(lineData.data, 10)
                    ),
              });
            }
          });

          chartsSettings.push(chart);
        }
      });

      setChartsSettings(chartsSettings);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return chartsSettings.map((it, i) => (
    <div key={i} className="campaign_chart">
      <CReactApexLineChart {...it} height={400} loading={loading} />
    </div>
  ));
};

CampaignAdGroupCharts.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    series: PropTypes.objectOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            data: PropTypes.oneOfType([
              PropTypes.array,
              PropTypes.number,
              PropTypes.string,
            ]),
          }),
        ])
      )
    ).isRequired,
  }),
  loading: PropTypes.bool,
};

export default CampaignAdGroupCharts;
