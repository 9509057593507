import React from "react";
import { SvgIcon } from "@mui/material";

const AppStoreColorIcon = (props) => (
  <SvgIcon viewBox="0 0 31 31" {...props}>
    <path
      d="M6.789 0H24.211C27.9605 0 31 3.03945 31 6.789V24.211C31 27.9605 27.9605 31 24.211 31H6.789C3.03945 31 0 27.9605 0 24.211V6.789C0 3.03945 3.03945 0 6.789 0Z"
      fill="url(#paint0_linear_4503_60248)"
    />
    <path
      d="M9.93797 22.5005L9.94088 22.5015L8.88094 24.3373C8.49405 25.0074 7.63719 25.237 6.96705 24.8501C6.29704 24.4632 6.06733 23.6064 6.45422 22.9362L7.23504 21.5839L7.30999 21.454C7.4438 21.2617 7.77402 20.9297 8.43459 20.9922C8.43459 20.9922 9.98907 21.1609 10.1016 21.9688C10.1016 21.9688 10.1168 22.2346 9.93797 22.5005ZM24.9709 17.8118H21.6658C21.4407 17.7966 21.3425 17.7162 21.3037 17.6695L21.3013 17.6653L17.7633 11.5372L17.7587 11.5402L17.5465 11.236C17.1987 10.7042 16.6466 12.0643 16.6466 12.0643C15.9872 13.5799 16.7402 15.303 17.0026 15.8238L21.9168 24.3355C22.3036 25.0055 23.1604 25.2352 23.8307 24.8482C24.5007 24.4613 24.7304 23.6044 24.3434 22.9343L23.1147 20.8059C23.0908 20.7544 23.0494 20.6144 23.3014 20.6138H24.9709C25.7447 20.6138 26.372 19.9865 26.372 19.2127C26.372 18.4389 25.7447 17.8117 24.9709 17.8117V17.8118ZM18.5512 19.7163C18.5512 19.7163 18.7277 20.6138 18.0451 20.6138H5.82684C5.05305 20.6138 4.42578 19.9865 4.42578 19.2127C4.42578 18.4389 5.05305 17.8117 5.82684 17.8117H8.96801C9.47515 17.7824 9.59527 17.4896 9.59527 17.4896L9.59818 17.491L13.6984 10.3891L13.6972 10.3889C13.7719 10.2517 13.7097 10.122 13.6989 10.1015L12.3447 7.75604C11.9578 7.08602 12.1874 6.22904 12.8575 5.84227C13.5277 5.45538 14.3845 5.68485 14.7714 6.35498L15.3994 7.44277L16.0263 6.35692C16.4132 5.68691 17.2701 5.45719 17.9402 5.84421C18.6103 6.2311 18.8399 7.08784 18.453 7.75797L12.7473 17.6404C12.7224 17.7006 12.7148 17.7951 12.9004 17.8117H16.3109L16.3116 17.845C16.3116 17.845 18.2828 17.8756 18.5512 19.7163Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4503_60248"
        x1="15.5"
        y1="0"
        x2="15.5"
        y2="31"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#17C9FB" />
        <stop offset="1" stopColor="#1A74E8" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
AppStoreColorIcon.displayName = "FolderIcon";
AppStoreColorIcon.muiName = "SvgIcon";

export default AppStoreColorIcon;
