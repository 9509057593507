import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";

const GoBack = () => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(-1)}
      sx={{
        fontSize: "1rem",
        textTransform: "unset",
      }}
    >
      <ArrowBackIos fontSize="small" /> Back
    </Button>
  );
};

export default GoBack;
