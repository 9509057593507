import React, { useState, useRef, useEffect } from "react";
import Menu from "@mui/material/Menu";
import { cloneElement } from "react";
import { MenuItem } from "@mui/material";
import PropTypes from "prop-types";

const DropdownMenu = ({ btn, items, autoWidthPaper }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [width, setWidth] = useState(0);
  const menuRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const menuHTML = menuRef.current
      ? menuRef.current.getBoundingClientRect()
      : { width: 0 };
    setWidth(menuHTML.width);
  }, [menuRef]);

  return (
    <div>
      {btn &&
        cloneElement(btn, { onClick: handleClick, open: open, ref: menuRef })}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PopoverClasses={{
          paper: "dropdown-paper",
        }}
        className="dropdown-menu__list"
        PaperProps={{
          sx: {
            ...(autoWidthPaper && {
              width: width,
            }),
          },
        }}
      >
        {items.map((it, i) => (
          <MenuItem key={i} className="p-0">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="dropdown-menu__item" onClick={handleClose}>
              {it}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

DropdownMenu.propTypes = {
  btn: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  autoWidthPaper: PropTypes.bool,
};

DropdownMenu.defaultProps = {
  autoWidthPaper: true,
};

export default DropdownMenu;
