import React from "react";
import { MenuItem, menuItemClasses, typographyClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`&.${menuItemClasses.root}`]: {
    fontSize: "1rem",
    fontWeight: 500,
    color: theme.palette.primary.main,
    padding: "0.36rem 0.83rem",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: theme.palette.primaryLight.main,
    },
  },
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: theme.palette.primaryLight.main,
  },
  [`.${typographyClasses.root}`]: {
    lineHeight: 2,
  },
}));

const CMenuItem = (props) => <StyledMenuItem {...props} />;

export default CMenuItem;
