import React from "react";
import { SvgIcon } from "@mui/material";

const GooglePlayIcon = (props) => (
  <SvgIcon viewBox="0 0 19 19" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.99938 0.999869H16.9994C16.9994 0.999869 17.9472 0.947459 18.4995 1.49974C19.0518 2.05203 18.9994 2.99987 18.9994 2.99987V16.9999C18.9994 16.9999 19.0518 17.9475 18.4995 18.4998C17.9472 19.052 16.9994 18.9999 16.9994 18.9999H2.99938C2.99938 18.9999 2.05154 19.0523 1.49926 18.5C0.94697 17.9477 0.99938 16.9999 0.99938 16.9999V2.99987C0.99938 2.99987 0.946984 2.05204 1.49927 1.49976C2.05155 0.947473 2.99938 0.999869 2.99938 0.999869ZM6.85791 4.30481L12.0318 9.49257L13.4406 8.08376L6.85791 4.30481ZM4.99938 4.8273C4.99938 4.44997 5.20305 4.15928 5.50799 3.99987L11.5236 9.99856L5.50799 15.9999C5.20342 15.8405 4.99938 15.5498 4.99938 15.1724V4.8273ZM14.0947 8.48923L15.4754 9.28853C15.9255 9.62385 15.9255 10.3789 15.5036 10.7138L14.0947 11.5131L12.5547 10.0012L14.0947 8.48923ZM13.441 11.919L6.85829 15.6979L12.0322 10.5102L13.441 11.919Z"
    />
  </SvgIcon>
);
GooglePlayIcon.displayName = "GooglePlayIcon";
GooglePlayIcon.muiName = "SvgIcon";

export default GooglePlayIcon;
