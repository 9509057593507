import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import classnames from "classnames";
import {
  Assessment,
  Assignment,
  ErrorOutlineOutlined,
  ExpandMore,
  Logout,
  Person,
  PrivacyTip,
  TextSnippet,
  AutoAwesomeMosaic,
  Wysiwyg,
} from "@mui/icons-material";
import { PropTypes } from "prop-types";
import {
  setInStorage,
  states,
  getThreeStatesFromStorage,
  removeFromStorage,
} from "../../localStorageUtils";
import CIcon from "../CIcon";
import MenuIcon from "../../icons/menu.svg";
import CScrollbars from "../CScrollbars";
import Preloader from "../Preloader";
import { useUser } from "../../context/UserProvider";
import { htmlDecode } from "../../utils";
import ForceConversionSwitcher from "./ForceConversionSwitcher";
import {
  getTooltipMessageByStatus,
  isHideMMPAlerts,
  MMPConnectionStatusesEnum,
} from "../../enum/MMPConnectionStatuses";
import AppMiniIcon from "../common/AppMiniIcon";
import CTooltip from "../common/CTooltip";
import NotificationButton from "../Notification/NotificationButton";
import { AppstoreTypesEnum } from "../../enum/AppstoreTypes";
import { ReportTypes } from "../../enum/ReportTypes";

const SidebarAccordion = ({
  id,
  icon,
  label,
  link,
  subLinks,
  fullSidebar,
  toggleSidebar,
  loading,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  let isThere = link && link === location.pathname;
  let isActive =
    isThere || !!subLinks.find((it) => location.pathname.startsWith(it.path));
  let initExpanded = getThreeStatesFromStorage(id);
  const [expanded, setExpanded] = useState(
    initExpanded !== null ? initExpanded : isActive
  );
  const accordionSummaryClass = classnames({
    sidebar_menu__item: true,
    active: isActive,
    expanded: expanded,
  });

  const toggle = (event, state) => {
    event && event.stopPropagation();
    setExpanded(state != null ? state : !expanded);
  };

  const smartToggle = () => {
    if (!fullSidebar) {
      toggleSidebar();
      return;
    }

    if (link && !isThere) {
      expanded !== true && setInStorage(id, states.indeterminate);
      navigate(link);
      return;
    }

    toggle();
  };

  useEffect(() => {
    if (expanded === states.indeterminate) {
      toggle(null, true);
      removeFromStorage(id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Accordion
      disableGutters
      title={label}
      expanded={expanded === true}
      onChange={smartToggle}
      sx={{
        boxShadow: "none",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          fullSidebar && (
            <IconButton
              color={isActive ? "primary" : "inactive"}
              onClick={toggle}
            >
              <ExpandMore />
            </IconButton>
          )
        }
        className={accordionSummaryClass}
      >
        <div className="sidebar_menu__item_icon">{icon}</div>
        <div className="sidebar_menu__item_label">{label}</div>
      </AccordionSummary>
      {fullSidebar && (
        <AccordionDetails sx={{ padding: 0 }}>
          {loading ? (
            <Preloader />
          ) : subLinks.length ? (
            subLinks.map((sublink, i) => (
              <NavLink
                key={i}
                to={sublink.path}
                className={classnames({
                  sidebar_menu__item_sublink: true,
                  sidebar_menu__item_sublink_mmp:
                    sublink?.appId &&
                    (sublink?.MMPConnectionStatus ===
                      MMPConnectionStatusesEnum.AwaitingOfSetup ||
                      sublink?.MMPConnectionStatus === null),
                })}
                onClick={(e) => {
                  if (
                    e.target.classList.contains("sidebar_menu__item_btn") ||
                    e.target.closest(".sidebar_menu__item_sublink") === null
                  ) {
                    e.preventDefault();
                  }
                }}
              >
                {sublink?.appstore || sublink?.isAppMap ? (
                  <AppMiniIcon
                    appstore={sublink.appstore}
                    isAppMap={sublink.isAppMap}
                  />
                ) : (
                  <span />
                )}

                <div className="sidebar_menu__item_label" title={sublink.label}>
                  {sublink.label}
                </div>

                {sublink?.appId &&
                  sublink.appstore !== AppstoreTypesEnum.Omni &&
                  sublink.MMPConnectionStatus !==
                    MMPConnectionStatusesEnum.Connected &&
                  !isHideMMPAlerts(sublink.appId) && (
                    <CTooltip
                      customLabel={
                        <ErrorOutlineOutlined
                          sx={{
                            width: "1.335rem",
                            height: "1.335rem",
                          }}
                          color="warning"
                        />
                      }
                      content={getTooltipMessageByStatus(
                        sublink.MMPConnectionStatus,
                        sublink.MMPServiceName
                      )}
                    />
                  )}
              </NavLink>
            ))
          ) : (
            <div className="sidebar_menu__item_sublink">You have no apps</div>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

SidebarAccordion.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  subLinks: PropTypes.array.isRequired,
  fullSidebar: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

SidebarAccordion.defaultProps = {
  loading: false,
};

const Sidebar = ({ fullSidebar, toggleSidebar }) => {
  const user = useUser();
  const orgId = user.selectedOrg?.orgId;
  const sidebarClass = classnames({
    full_page: !fullSidebar,
  });
  let sidebarMenuClassDefault = "sidebar_menu";
  const [sidebarMenuClass, setSidebarMenuClass] = useState(
    sidebarMenuClassDefault
  );
  const [isThereAreAllowReports, setIsThereAreAllowReports] = useState(false);

  useEffect(() => {
    if (user.selectedOrg) {
      setIsThereAreAllowReports(
        user.isAllowReport(ReportTypes.base) ||
          user.isAllowReport(ReportTypes.creativeAnalysis)
      );
    }
  }, [user.selectedOrg]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="sidebar" className={sidebarClass}>
      <div className="sidebar_header block_outer__left">
        <NavLink to="/" className="logo">
          <img src={"/static/logo.svg"} alt="Logo" />
        </NavLink>
        <div className="sidebar_menu__btn-wrapper">
          <NotificationButton />
          <Button
            className="icon_button"
            color="primaryClicked"
            variant="contained"
            onClick={toggleSidebar}
          >
            <CIcon link={MenuIcon} alt="Menu" />
          </Button>
        </div>
      </div>
      {isThereAreAllowReports && (
        <div className="block_force_conversion_switcher">
          <ForceConversionSwitcher />
        </div>
      )}
      <CScrollbars
        className={sidebarMenuClass}
        content={{ style: { overflowX: "hidden", marginBottom: 0 } }}
        vertical={{
          track: { className: "scrollbar sidebar_menu__scrollbar" },
          thumb: { className: "scrollbar_thumb sidebar_menu__scrollbar_thumb" },
        }}
        horizontal={{ track: { className: "d-none" } }}
        onChangeStatus={(scrollbar) =>
          setSidebarMenuClass(
            scrollbar
              ? `${sidebarMenuClassDefault} bordered`
              : sidebarMenuClassDefault
          )
        }
      >
        {isThereAreAllowReports && user.apps && !!user.apps.length && (
          <>
            <div className="sidebar_menu__title block_outer__left">GENERAL</div>
            <div>
              {user.isAllowReport(ReportTypes.base) && (
                <SidebarAccordion
                  id="sidebarReporting"
                  icon={<TextSnippet className="sidebar_menu__icon" />}
                  label="Reporting"
                  link={`/org/${orgId}/reporting`}
                  subLinks={user.apps.map((app) => ({
                    label: htmlDecode(app.appName),
                    path: `/org/${orgId}/reporting/app/${app.appId}`,
                    appId: app.appId,
                    orgId: orgId,
                    appstore: app.appstore,
                    isAppMap: app.isAppMap,
                    MMPConnectionStatus: app.mmpConnectionStatus,
                    MMPServiceName: app.mmpName,
                  }))}
                  fullSidebar={fullSidebar}
                  toggleSidebar={toggleSidebar}
                />
              )}
              {user.isAllowReport(ReportTypes.creativeAnalysis) && (
                <SidebarAccordion
                  id="sidebarCreatives"
                  icon={<Assessment className="sidebar_menu__icon" />}
                  label="Creatives"
                  subLinks={[
                    {
                      label: "Concept mapping",
                      path: `/org/${orgId}/creatives/concept-mapping`,
                    },
                    {
                      label: "Creative analysis",
                      path: `/org/${orgId}/creatives/creative-analysis`,
                    },
                    {
                      label: "Concept settings",
                      path: `/org/${orgId}/creatives/concept-settings`,
                    },
                    {
                      label: "Asset insights",
                      path: `/org/${orgId}/creatives/asset-insights`,
                    },
                  ]}
                  fullSidebar={fullSidebar}
                  toggleSidebar={toggleSidebar}
                />
              )}
            </div>
          </>
        )}
        <div className="sidebar_menu__title block_outer__left">SETTINGS</div>
        <div>
          <NavLink to="/user" className="sidebar_menu__item" title="User">
            <div className="sidebar_menu__item_icon">
              <Person className="sidebar_menu__icon" />
            </div>
            <div className="sidebar_menu__item_label">User</div>
          </NavLink>
          {user.isSubscriptionActiveForSelectedOrg() && (
            <NavLink
              to={`/org/${orgId}/apps`}
              className="sidebar_menu__item"
              title="Apps"
            >
              <div className="sidebar_menu__item_icon">
                <AutoAwesomeMosaic className="sidebar_menu__icon" />
              </div>
              <div className="sidebar_menu__item_label">Apps</div>
            </NavLink>
          )}
          <NavLink
            to={`/org/${orgId}/organisation`}
            className="sidebar_menu__item"
            title="Organisation"
          >
            <div className="sidebar_menu__item_icon">
              <Wysiwyg className="sidebar_menu__icon" />
            </div>
            <div className="sidebar_menu__item_label">Organisation</div>
          </NavLink>
        </div>
        <div className="sidebar_menu__title block_outer__left">ABOUT</div>
        <div>
          <NavLink
            to="/privacy-policy"
            className="sidebar_menu__item"
            title="Privacy policy"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className="sidebar_menu__item_icon">
              <PrivacyTip className="sidebar_menu__icon" />
            </div>
            <div className="sidebar_menu__item_label">Privacy policy</div>
          </NavLink>
          <NavLink
            to="/eula"
            className="sidebar_menu__item"
            title="Eula"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className="sidebar_menu__item_icon">
              <Assignment className="sidebar_menu__icon" />
            </div>
            <div className="sidebar_menu__item_label">Eula</div>
          </NavLink>
        </div>
        <Box marginTop={fullSidebar ? 5 : 0}>
          <NavLink
            to="/auth/sign-out"
            className="sidebar_menu__item"
            title="Sign Out"
          >
            <div className="sidebar_menu__item_icon">
              <Logout color="error" className="sidebar_menu__icon" />
            </div>
            <Box className="sidebar_menu__item_label" color="error.main">
              Sign out
            </Box>
          </NavLink>
        </Box>
      </CScrollbars>
    </div>
  );
};

Sidebar.propTypes = {
  fullSidebar: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default Sidebar;
