import React from "react";
import { PropTypes } from "prop-types";
import classnames from "classnames";

const CIcon = ({ className, style, icon, link, alt, title }) => {
  const classes = classnames({
    icon: true,
    [className]: className,
  });

  return icon
    ? icon
    : link && (
        <img
          src={link}
          alt={alt}
          title={title}
          className={classes}
          style={style}
        />
      );
};

CIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.element,
  link: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

export default CIcon;
