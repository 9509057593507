/**
 * @param {String} source
 */
function Query(source = window.location.search) {
  if (!(this instanceof Query)) {
    return new Query(source);
  }

  this.query = new URLSearchParams(source);
}

/**
 * @returns {URLSearchParams}
 */
Query.prototype.asDefault = function () {
  return this.query;
};

/**
 * @returns {Object}
 */
Query.prototype.asObject = function () {
  let query = {};
  for (let [key, value] of this.query.entries()) {
    query = {
      ...query,
      [key]: value,
    };
  }

  return query;
};

/**
 * @returns {String}
 */
Query.prototype.asString = function () {
  return this.query.toString();
};

/**
 * @param {String} key
 * @returns {Boolean}
 */
Query.prototype.has = function (key) {
  return this.query.has(key);
};

/**
 * @param {String} key
 * @returns {String|Null}
 */
Query.prototype.get = function (key) {
  return this.query.get(key);
};

/**
 *
 * @param key
 * @returns {string[]}
 */
Query.prototype.getAll = function (key) {
  return this.query.getAll(key);
};

/**
 * @param {Object} query { param: value, .. }
 */
Query.prototype.update = function (query) {
  Object.keys(query).forEach((param) => {
    query[param]
      ? this.query.has(param)
        ? this.query.set(param, query[param])
        : this.query.append(param, query[param])
      : this.query.has(param) && this.query.delete(param);
  });
};

/**
 @param {Object} query { param: value, .. }
 */
Query.prototype.updateMultiple = function (query) {
  Object.keys(query).forEach((param) => {
    this.query.delete(param);
    if (query[param]) {
      query[param].forEach((val) => {
        this.query.append(param, val);
      });
    }
  });
};

export default Query;
