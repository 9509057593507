import React, { useEffect, useState } from "react";
import "../styles/MainTemplate.sass";
import classnames from "classnames";
import StickyHeader from "../components/MainTemplate/StickyHeader";
import PropTypes from "prop-types";
import { setInStorage, getBooleanFromStorage } from "../localStorageUtils";
import Sidebar from "../components/MainTemplate/Sidebar";
import Header from "../components/MainTemplate/Header";
import CScrollbars from "../components/CScrollbars";
import { useSearchParams } from "react-router-dom";
import NotAllowedDialog from "../components/MainTemplate/NotAllowedDialog";

const Content = ({ children, ...other }) => {
  return (
    <div id="content">
      <StickyHeader {...other} />
      <div className="block_outer__right">{children}</div>
    </div>
  );
};

Content.propTypes = {
  ...StickyHeader.propTypes,
  children: PropTypes.node.isRequired,
};

const MainTemplate = (props) => {
  const initSidebarState = getBooleanFromStorage("fullSidebar");
  const [fullSidebar, setFullSidebar] = useState(
    initSidebarState !== null ? initSidebarState : true
  );
  const [showNotAllowedDialog, setShowNotAllowedDialog] = useState(false);
  const pageClass = classnames({
    full_page: !fullSidebar,
  });

  const horizontalScrollbarClass = classnames({
    scrollbar_horizontal: true,
    full_page: !fullSidebar,
  });

  const toggleSidebar = () => {
    setFullSidebar(!fullSidebar);
    setInStorage("fullSidebar", !fullSidebar);
  };

  const [query, setQuery] = useSearchParams();
  let search = query.get("search") || "";

  const setSearch = (value) => {
    setQuery({ search: value || "" });
    search = value;
  };

  useEffect(() => {
    if (props?.isAllowed === false) {
      setShowNotAllowedDialog(true);
    }
  }, [props?.isAllowed]);

  return (
    <CScrollbars
      style={{ height: "100vh" }}
      content={{ id: "main_template", className: pageClass }}
      vertical={{
        track: { className: "scrollbar" },
        thumb: { className: "scrollbar_thumb" },
      }}
      horizontal={{
        track: { className: horizontalScrollbarClass },
        thumb: { className: "scrollbar_thumb" },
      }}
    >
      <Sidebar fullSidebar={fullSidebar} toggleSidebar={toggleSidebar} />
      <Header search={search} setSearch={setSearch} />
      <div id="content-wrapper" className="position-relative h-100">
        <Content {...props} />
        <NotAllowedDialog open={showNotAllowedDialog} />
      </div>
    </CScrollbars>
  );
};

MainTemplate.propTypes = Content.propTypes;

export default MainTemplate;
