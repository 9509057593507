import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Query from "../../helpers/query";
import {
  getFromStorage,
  removeFromStorage,
  setInStorage,
} from "../../localStorageUtils";
import adwizyApi from "../../api/adwizyApi";
import PreloaderPage from "../PreloaderPage";
import NotFoundPage from "./errors/NotFoundPage";

const ConnectAccountPage = () => {
  let { network } = useParams();
  const query = new Query();
  let orgId = query.get("orgId");

  useEffect(() => {
    if (window.opener) {
      const data = {
        network,
        state: query.get("state"),
        code: query.get("code"),
      };

      if (orgId) {
        setInStorage("connectOrgId", orgId);
      } else {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        orgId = getFromStorage("connectOrgId");
        removeFromStorage("connectOrgId");
      }

      adwizyApi
        .post(`/org/${orgId}/connection/add`, data)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.redirectUri) {
              window.location.replace(res.data.data.redirectUri);
            } else {
              window.opener.successHandler(res.data.data);
              window.close();
            }
          }
        })
        .catch((err) => {
          window.opener.errorHandler(err);
          window.close();
        });
    }
  }, []);

  return window.opener ? <PreloaderPage /> : <NotFoundPage />;
};

export default ConnectAccountPage;
