import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  TextField,
  Box,
  InputAdornment,
  formControlLabelClasses,
  formGroupClasses,
} from "@mui/material";
import {
  Check as CheckIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material/";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import SearchIcon from "../icons/SearchIcon";
import theme from "../../theme";

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  [`&.${formControlLabelClasses.root}`]: {
    fontSize: "0.9rem",
    lineHeight: "1.5rem",
    paddingLeft: "1rem",
    marginLeft: "-1rem",
    gridGap: ".55rem",
    "& > span": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

const StyledFormGroup = styled(FormGroup)(() => ({
  [`&.${formGroupClasses.root}`]: {
    marginTop: "1.05rem",
    maxHeight: "9.72rem",
    flexWrap: "nowrap",
    overflow: "auto",
    gridGap: ".38rem",
    paddingBottom: ".38rem",
  },
}));

const CheckboxList = ({
  list,
  handleChange,
  checkedCallback,
  includeSearch,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredList, setFilteredList] = useState({ ...list });

  const handleSearch = (evt) => setSearchQuery(evt.target.value);

  useEffect(() => {
    if (searchQuery) {
      const newList = {};
      for (const item in list) {
        if (list[item].toLowerCase().includes(searchQuery.toLowerCase())) {
          newList[item] = list[item];
        }
      }
      setFilteredList(newList);
    } else {
      setFilteredList(list);
    }
  }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  const getLabel = (value) => {
    const originalValue = filteredList[value];
    const normalizedOriginalValue = originalValue.toLowerCase();
    const normalizedSearchQuery = searchQuery.toLowerCase();
    const originalValueChars = originalValue.split("");
    const normalizedValueChars = normalizedOriginalValue.split("");

    const start = normalizedOriginalValue.indexOf(normalizedSearchQuery);
    const end =
      normalizedOriginalValue.indexOf(normalizedSearchQuery) +
      normalizedSearchQuery.length -
      1;

    let res = "";
    for (let i = 0; i < normalizedValueChars.length; i++) {
      if (normalizedSearchQuery.length === 1 && i === start) {
        res += `<b>${originalValueChars[i]}</b>`;
      } else if (i === start && end !== -1) {
        res += "<b>" + originalValueChars[i];
      } else if (i === end) {
        res += originalValueChars[i] + "</b>";
      } else {
        res += originalValueChars[i];
      }
    }

    return <span dangerouslySetInnerHTML={{ __html: res }} />;
  };

  return (
    <>
      {includeSearch && (
        <Box marginTop=".66rem">
          <TextField
            fullWidth
            variant="outlined"
            margin="none"
            inputProps={{
              sx: { padding: ".52rem .77rem" },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: theme.palette.borders.main },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    sx={{ width: "0.78rem", height: "0.83rem" }}
                    color="inactive"
                  />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Box>
      )}
      <StyledFormGroup>
        {Object.keys(filteredList)
          .sort()
          .reverse()
          .map((value, index) => (
            <StyledFormControlLabel
              key={index}
              label={getLabel(value)}
              title={filteredList[value]}
              control={
                <Checkbox
                  className="p-0 checkbox"
                  value={value}
                  checked={checkedCallback(value)}
                  icon={<CheckBoxOutlineBlankIcon sx={{ opacity: 0 }} />}
                  checkedIcon={<CheckIcon color="inactive" />}
                  onChange={(evt) => handleChange(evt.target.value)}
                />
              }
            />
          ))}
      </StyledFormGroup>
    </>
  );
};

CheckboxList.propTypes = {
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  handleChange: PropTypes.func.isRequired,
  checkedCallback: PropTypes.func.isRequired,
  includeSearch: PropTypes.bool,
};

export default CheckboxList;
