import React, { useEffect, useState } from "react";
import {
  formatValue,
  moneyFormatter,
  nFormatter,
  percentFormatter,
} from "../../utils";
import {
  Box,
  buttonBaseClasses,
  inputBaseClasses,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableFooter,
  TableHead,
  TablePagination,
  tablePaginationClasses,
  TableRow,
  tableRowClasses,
  IconButton,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FilterCell from "./FilterCell";
import CSkeleton from "./CSkeleton";
import CTooltip, { placements } from "./CTooltip";
import CScrollbars from "../CScrollbars";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import PropTypes from "prop-types";

export const types = {
  custom: "custom",
  money: "money",
  number: "number",
  float: "float",
  percent: "percent",
  order: "order",
  default: "default",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.hover.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: 500,
  },
  [`&.${tableCellClasses.head}`]: {
    padding: ".28rem 0.56rem",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "1.11rem 0.56rem",
  },
  [`&.${tableCellClasses.body}, &.${tableCellClasses.head}`]: {
    color: theme.palette.hover.main,
    fontSize: "1rem",
    border: "none",
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
    maxWidth: "7.78rem",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    [`&:hover:not(.${tableRowClasses.footer})`]: {
      backgroundColor: theme.palette.tables.main,
    },
    [`&.active:not(.${tableRowClasses.footer})`]: {
      backgroundColor: theme.palette.primary.light,
    },
    ["& .hover-show-content"]: {
      opacity: 0,
    },
    [`&:hover:not(.${tableRowClasses.footer}) .hover-show-content`]: {
      opacity: 1,
    },
  },
  [`&.${tableRowClasses.footer}`]: {
    backgroundColor: "transparent",
    [`& > td`]: {
      border: "none",
    },
  },
}));

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  [`.${tablePaginationClasses.toolbar}`]: {
    padding: "0.44rem 0 0.44rem 1.11rem",
  },
  [`.${tablePaginationClasses.selectLabel}`]: {
    fontSize: "0.9rem",
    fontWeight: 500,
    color: theme.palette.inactive.main,
    margin: 0,
  },
  [`.${inputBaseClasses.root}`]: {
    margin: "0 1.44rem 0 0",
  },
  [`.${tablePaginationClasses.select}`]: {
    color: theme.palette.hover.main,
    fontSize: "0.9rem",
    fontWeight: 500,
    padding: "0 1.33rem 0 0",
  },
  [`.${tablePaginationClasses.displayedRows}`]: {
    fontSize: "0.9rem",
    fontWeight: 500,
    color: theme.palette.hover.main,
    margin: 0,
  },
  [`.${tablePaginationClasses.actions}`]: {
    marginLeft: "1.44rem",
    [`.${buttonBaseClasses.root}`]: {
      padding: "0.67rem",
    },
  },
}));

export const conditionTypes = {
  greaterThan: ">",
  greaterThanOrEqual: ">=",
  lessThan: "<",
  lessThanOrEqual: "<=",
  equal: "==",
  notEqual: "!=",
  between: "<>",
  notBetween: "!<>",
  contains: "contains",
  notContains: "notContains",
  starts: "starts",
  ends: "ends",
  exactly: "exactly",
  empty: "empty",
  notEmpty: "notEmpty",
  includes: "includes",
  callback: "callback",
};

const defaultRowsPerPageOptions = [10, 25, 50, 100];

const CTableRow = ({ className, children, collapseContent, openCb }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);

    if (typeof openCb === "function") {
      openCb();
    }
  };

  return (
    <React.Fragment>
      <StyledTableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        className={className}
      >
        {children}
        {collapseContent && (
          <StyledTableCell>
            <IconButton
              title="Expand row"
              aria-label="expand row"
              size="small"
              onClick={handleClick}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </StyledTableCell>
        )}
      </StyledTableRow>
      {collapseContent && (
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={children.length + 1}
          >
            <Collapse in={open} timeout={0} unmountOnExit>
              {collapseContent}
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      )}
    </React.Fragment>
  );
};

CTableRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  collapseContent: PropTypes.node,
  openCb: PropTypes.func,
};

const CTable = ({
  rows,
  settings,
  className,
  style,
  topArea1,
  topArea2,
  loading,
  additional,
  defaultFilters,
  defaultRowsPerPage,
  pagination,
  emptyDataText,
}) => {
  const [order, setOrder] = useState(settings?.default?.order);
  const [orderBy, setOrderBy] = useState(settings?.default?.orderBy);
  const [filters, setFilters] = useState(defaultFilters);
  const [page, setPage] = useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(
    defaultRowsPerPageOptions
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    pagination ? defaultRowsPerPage : 0
  );

  useEffect(() => {
    if (pagination) {
      const rowsPerPageOptions = defaultRowsPerPageOptions.slice();
      if (!rowsPerPageOptions.includes(defaultRowsPerPage)) {
        rowsPerPageOptions.push(defaultRowsPerPage);
        rowsPerPageOptions.sort((a, b) => a - b);
      }

      setRowsPerPageOptions(rowsPerPageOptions);
    }

    handleChangeRowsPerPage(pagination ? defaultRowsPerPage : 0);
  }, [defaultRowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (page * rowsPerPage - getRows().length === 0) {
      setPage(page === 0 ? 0 : page - 1);
    }
  }, [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  useEffect(() => {
    setPage(0);
  }, [filters]);

  const getColumns = () => settings.columns;

  const getCellSx = (col, row = null) => {
    let sx = getColumns()[col].sx;
    if (typeof sx === "function" && row) {
      return sx(row);
    }

    return sx;
  };

  const getRows = () => {
    return Object.values(rows)
      .filter((row) => {
        const columnFilters = [];

        for (const column in filters) {
          if (Object.keys(filters[column]).length === 0) {
            continue;
          }
          const filterParams = filters[column];
          const val =
            typeof row[column] === "object" ? row[column]?.value : row[column];
          const callback = filterParams.callback;

          switch (filterParams["type"]) {
            case conditionTypes.greaterThan:
              columnFilters.push(val > parseFloat(filterParams["value"]));
              break;
            case conditionTypes.greaterThanOrEqual:
              columnFilters.push(parseFloat(filterParams["value"]));
              break;
            case conditionTypes.lessThan:
              columnFilters.push(val < parseFloat(filterParams["value"]));
              break;
            case conditionTypes.lessThanOrEqual:
              columnFilters.push(val <= parseFloat(filterParams["value"]));
              break;
            case conditionTypes.equal:
              columnFilters.push(val === parseFloat(filterParams["value"]));
              break;
            case conditionTypes.notEqual:
              columnFilters.push(val !== parseFloat(filterParams["value"]));
              break;
            case conditionTypes.between:
              columnFilters.push(
                val >= parseFloat(filterParams["valueFrom"]) &&
                  val <= parseFloat(filterParams["valueTo"])
              );
              break;
            case conditionTypes.notBetween:
              columnFilters.push(
                val <= parseFloat(filterParams["valueFrom"]) ||
                  val >= parseFloat(filterParams["valueTo"])
              );
              break;
            case conditionTypes.contains:
              columnFilters.push(
                val.toLowerCase().includes(filterParams["value"].toLowerCase())
              );
              break;
            case conditionTypes.notContains:
              columnFilters.push(
                !val.toLowerCase().includes(filterParams["value"].toLowerCase())
              );
              break;
            case conditionTypes.starts:
              columnFilters.push(
                val
                  .toLowerCase()
                  .startsWith(filterParams["value"].toLowerCase())
              );
              break;
            case conditionTypes.ends:
              columnFilters.push(
                val.toLowerCase().endsWith(filterParams["value"].toLowerCase())
              );
              break;
            case conditionTypes.exactly:
              columnFilters.push(
                val.toLowerCase() === filterParams["value"].toLowerCase()
              );
              break;
            case conditionTypes.includes:
              columnFilters.push(filterParams["value"].includes(`${val}`));
              break;
            case conditionTypes.empty:
              columnFilters.push(val.length === 0);
              break;
            case conditionTypes.notEmpty:
              columnFilters.push(val.length !== 0);
              break;
            case conditionTypes.callback:
              columnFilters.push(callback(row, filterParams["value"]));
              break;
            default:
              columnFilters.push(String(val) === String(filterParams["value"]));
          }
        }

        return columnFilters.reduce((prev, curr) => curr && prev, true);
      })
      .sort((a, b) => {
        let orderByA = a[orderBy];
        let orderByB = b[orderBy];

        if (typeof orderByA === "object" && typeof orderByB === "object") {
          orderByA = orderByA?.value;
          orderByB = orderByB?.value;
        }

        if (orderByA < orderByB) {
          return order === "desc" ? 1 : -1;
        }
        if (orderByA > orderByB) {
          return order === "desc" ? -1 : 1;
        }
        return 0;
      });
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (val) => {
    setRowsPerPage(val);
    setPage(0);
  };

  const handleFilter = async (field, filter) => {
    let newColumnFilters = Object.assign({}, filters);
    newColumnFilters[field] = filter;

    await setFilters(newColumnFilters);
  };

  const handleSort = async (property, order) => {
    await setOrderBy(property);
    await setOrder(order);
  };

  const renderSwitch = (settings, row, val, orderIndex) => {
    switch (settings.format) {
      case types.custom:
        return settings.component(row, additional);
      case types.money:
        return val.currency ? (
          <CTooltip
            placement={placements.topStart}
            customLabel={
              <Box component="span">
                {moneyFormatter({ num: val.value, currency: val.currency })}
              </Box>
            }
            content={moneyFormatter({
              num: val.value,
              currency: val.currency,
              clipped: false,
            })}
          />
        ) : (
          val.value
        );
      case types.percent:
        return (
          <CTooltip
            placement={placements.topStart}
            customLabel={
              <Box component="span">{percentFormatter(val.value)}</Box>
            }
            content={percentFormatter(val.value)}
          />
        );
      case types.order:
        return <strong>{orderIndex}</strong>;
      case types.number:
        return (
          <CTooltip
            placement={placements.topStart}
            customLabel={
              <Box component="span">{nFormatter(val.value, false)}</Box>
            }
            content={formatValue(Math.round(val.value * 100) / 100)}
          />
        );
      case types.float:
        return (
          <CTooltip
            placement={placements.topStart}
            customLabel={
              <Box component="span">
                {formatValue(Math.round(val.value * 100) / 100)}
              </Box>
            }
            content={nFormatter(val.value)}
          />
        );
      case types.default:
      default:
        return val;
    }
  };

  const isActiveRow = (row) => {
    if (settings?.activeRules === undefined) {
      return false;
    }

    return settings?.activeRules.cb(
      row[settings?.activeRules.field],
      additional
    );
  };

  const Pagination = (
    <StyledTablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      count={getRows().length}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: { "aria-label": "rows per page" },
        native: true,
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={(evt) =>
        handleChangeRowsPerPage(parseInt(evt.target.value, 10))
      }
    />
  );

  return (
    <Box
      className={`block-content ${className ? className : ""}`}
      style={style}
    >
      {topArea1}
      {loading ? (
        <Table>
          <TableHead>
            <TableRow sx={{ marginBottom: "0.5em" }}>
              <StyledTableCell colSpan={3}>
                <CSkeleton />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ marginBottom: "0.5em" }}>
              <StyledTableCell>
                <CSkeleton />
              </StyledTableCell>
              <StyledTableCell>
                <CSkeleton />
              </StyledTableCell>
              <StyledTableCell>
                <CSkeleton />
              </StyledTableCell>
            </TableRow>
            <TableRow sx={{ marginBottom: "0.5em" }}>
              <StyledTableCell>
                <CSkeleton />
              </StyledTableCell>
              <StyledTableCell>
                <CSkeleton />
              </StyledTableCell>
              <StyledTableCell>
                <CSkeleton />
              </StyledTableCell>
            </TableRow>
            <TableRow sx={{ marginBottom: "0.5em" }}>
              <StyledTableCell>
                <CSkeleton />
              </StyledTableCell>
              <StyledTableCell>
                <CSkeleton />
              </StyledTableCell>
              <StyledTableCell>
                <CSkeleton />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <>
          {topArea2}
          <CScrollbars
            content={{ style: { position: "relative" } }}
            vertical={{
              track: { className: "scrollbar" },
              thumb: { className: "scrollbar_thumb" },
            }}
          >
            <Table sx={{ minWidth: "36.11rem" }}>
              <TableHead>
                {pagination && getRows().length > defaultRowsPerPage && (
                  <StyledTableRow>{Pagination}</StyledTableRow>
                )}
                {!getRows().length ? (
                  ""
                ) : (
                  <TableRow>
                    {Object.keys(getColumns()).map((column, i) => (
                      <StyledTableCell
                        key={i}
                        sx={{
                          ...getCellSx(column),
                          ...(getColumns()[column].trim && {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }),
                        }}
                      >
                        <FilterCell
                          field={column}
                          filterValue={filters[column]}
                          isActive={
                            filters[column] !== undefined &&
                            Object.keys(filters[column]).length !== 0
                          }
                          title={getColumns()[column].title(
                            additional,
                            getRows()
                          )}
                          order={order}
                          orderBy={orderBy}
                          handleSort={handleSort}
                          handleFilter={handleFilter}
                          includeNumberConditions={
                            getColumns()[column].includeNumberConditions
                          }
                          includeTextConditions={
                            getColumns()[column].includeTextConditions
                          }
                          includeArrayConditions={
                            getColumns()[column].includeArrayConditions
                          }
                          includeSort={getColumns()[column].includeSort}
                          customConditions={
                            getColumns()[column].customConditions
                          }
                          customConditionsCallback={
                            getColumns()[column].customConditionsCallback
                          }
                          {...getColumns()[column].filterCellProps}
                        />
                      </StyledTableCell>
                    ))}
                    {settings?.collapseContent !== undefined && (
                      <StyledTableCell padding="checkbox" />
                    )}
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {getRows().length ? (
                  (rowsPerPage > 0
                    ? getRows().slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : getRows()
                  ).map((row, rowIndex) => (
                    <CTableRow
                      key={
                        settings?.rowIndexField
                          ? row[settings?.rowIndexField]
                          : rowIndex
                      }
                      className={isActiveRow(row) ? "active" : ""}
                      collapseContent={
                        settings?.collapseContent
                          ? settings?.collapseContent(row)
                          : null
                      }
                      openCb={() => settings?.collapseOpenCallback(row)}
                    >
                      {Object.keys(getColumns()).map((column, columnIndex) => (
                        <StyledTableCell
                          key={columnIndex}
                          sx={{
                            ...getCellSx(column, row),
                            ...(getColumns()[column].trim && {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }),
                            ...(getColumns()[column]?.cb && {
                              cursor: "pointer",
                            }),
                          }}
                          onClick={() => {
                            if (
                              typeof getColumns()[column]?.cb === "function"
                            ) {
                              getColumns()[column]?.cb(row);
                            }
                          }}
                        >
                          {renderSwitch(
                            getColumns()[column],
                            row,
                            row[column],
                            page * rowsPerPage + (rowIndex + 1)
                          )}
                        </StyledTableCell>
                      ))}
                    </CTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <React.Fragment>
                      <StyledTableCell
                        colSpan={
                          Object.keys(getColumns()).length +
                          (settings?.collapseContent !== undefined ? 1 : 0)
                        }
                        align="center"
                      >
                        {emptyDataText || "There is no data"}
                      </StyledTableCell>
                      {settings?.collapseContent !== undefined && (
                        <StyledTableCell padding="checkbox" />
                      )}
                    </React.Fragment>
                  </StyledTableRow>
                )}
              </TableBody>
              {pagination && getRows().length > defaultRowsPerPage && (
                <TableFooter>
                  <StyledTableRow>{Pagination}</StyledTableRow>
                </TableFooter>
              )}
            </Table>
          </CScrollbars>
        </>
      )}
    </Box>
  );
};

CTable.propTypes = {
  rows: PropTypes.object,
  settings: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  topArea1: PropTypes.node,
  topArea2: PropTypes.node,
  loading: PropTypes.bool,
  additional: PropTypes.object,
  defaultFilters: PropTypes.object,
  defaultRowsPerPage: PropTypes.number,
  pagination: PropTypes.bool,
  emptyDataText: PropTypes.string,
};

CTable.defaultProps = {
  defaultFilters: {},
  defaultRowsPerPage: 10,
  pagination: true,
};

export default CTable;
