import React from "react";
import { FormControl, Box, Typography } from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material/";
import PropTypes from "prop-types";
import CMenuItem from "./CMenuItem";
import CSelect from "./CSelect";
import { styled } from "@mui/system";
import theme from "../../theme";

const SubtitleBlock = ({ subtitle, fontSize }) => {
  const StyledTypography = styled(Typography)(({ theme }) => ({
    "&": {
      marginRight: "0.33em",
      color: theme.palette.hover.main,
      fontWeight: "bold",
    },
  }));

  return (
    <StyledTypography
      sx={{
        fontSize: `${fontSize} !important`,
        fontWeight: "bold",
        marginRight: "0.33em",
      }}
    >
      {subtitle}
    </StyledTypography>
  );
};

SubtitleBlock.propTypes = {
  subtitle: PropTypes.node,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const DropdownBigText = ({
  title,
  rightSubtitle,
  leftSubtitle,
  values,
  selectedValue,
  handleSelected,
  multiple,
  renderValue,
  color,
  fontSize,
  disabled,
}) => {
  return (
    <FormControl variant="standard" className={"dropdown-big-text"}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {leftSubtitle && (
          <SubtitleBlock subtitle={leftSubtitle} fontSize={fontSize} />
        )}
        <CSelect
          multiple={multiple}
          label={title}
          value={selectedValue ?? ""}
          onChange={handleSelected}
          renderValue={renderValue}
          disableUnderline
          displayEmpty
          disabled={disabled}
          IconComponent={(props) => (
            <KeyboardArrowDownIcon
              sx={{
                color: `${color} !important`,
                fontSize: `${fontSize} !important`,
                right: "0 !important",
              }}
              {...props}
            />
          )}
          sx={{
            color: color ? `${color} !important` : theme.palette.inactive.main,
            fontSize: `${fontSize} !important`,
            fontWeight: "bold",
            fontStyle: "normal",
          }}
          inputProps={{
            sx: {
              padding: "0 1em 0 0 !important",
            },
          }}
        >
          {Object.keys(values).map((key) => (
            <CMenuItem value={key} key={key}>
              {values[key]}
            </CMenuItem>
          ))}
        </CSelect>
        {rightSubtitle && (
          <SubtitleBlock subtitle={rightSubtitle} fontSize={fontSize} />
        )}
      </Box>
    </FormControl>
  );
};

DropdownBigText.propTypes = {
  title: PropTypes.string,
  rightSubtitle: PropTypes.string,
  leftSubtitle: PropTypes.string,
  values: PropTypes.object,
  selectedValue: PropTypes.any,
  handleSelected: PropTypes.func,
  multiple: PropTypes.bool,
  renderValue: PropTypes.func,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  disabled: PropTypes.bool,
};

DropdownBigText.defaultProps = {
  multiple: false,
  fontSize: "1.66rem",
  disabled: false,
};

export default DropdownBigText;
