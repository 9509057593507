import React, { createContext, useContext, useEffect, useState } from "react";
import { useInformer } from "./InformerProvider";
import adwizyApi from "../api/adwizyApi";
import { debug, getStateFromSetter, isDev, sleep } from "../utils";
import { getFromStorage } from "../localStorageUtils";
import { useAuth } from "./AuthProvider";
import EventProvider, { events, useEvent } from "./EventProvider";
import { MMPConnectionStatusesEnum } from "../enum/MMPConnectionStatuses";
import { BillingStatusesEnum } from "../enum/BillingStatuses";
import { AccountDeletedWarning } from "../components/UserPage/DeleteAccount";

export const UserContext = createContext();
export const useUser = () => useContext(UserContext);

const UserProviderHandler = (props) => {
  const auth = useAuth();
  const event = useEvent();
  const informer = useInformer();
  const [data, setData] = useState(null);
  const [userDeleted, setUserDeleted] = useState(false);
  const [orgs, setOrgs] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [apps, setApps] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [ACL, setACL] = useState(null);
  const [forceConversion, setForceConversion] = useState(0);
  const [appIdOfSelectedApp, setAppIdOfSelectedApp] = useState(null);
  const [datePickerValue, setDatePickerValue] = useState(null);
  const [allowedReports, setAllowedReports] = useState([]);
  const [subscriptionStatusData, setSubscriptionStatusData] = useState(null);

  /**
   * @param {String} debugName
   * @param {Function} setFunc
   * @param {Function} devFunc
   * @param {Function} apiReqFunc
   * @returns {Promise}
   */
  const fetch = (debugName, setFunc, devFunc, apiReqFunc) => {
    debug && console.log(debugName);
    setFunc(null);

    if (isDev) {
      return devFunc();
    } else {
      return apiReqFunc()
        .then((res) => {
          if (res.data.success) {
            setFunc(res.data.data);
          }
        })
        .catch((err) => {
          informer.showErrorNotice(err);
        });
    }
  };

  /**
   * @returns {Promise}
   */
  const fetchUser = () =>
    fetch(
      "fetchUser",
      setData,
      async () => {
        await sleep(150);
        setData(require("../mocks/user__profile.json").data);
      },
      () =>
        adwizyApi.get("/user/profile").catch((err) => {
          if (err?.response?.status === 404) {
            auth.signOutLocal();
          }

          if (err?.response?.data?.errors?.account === "suspended") {
            userDeleteRequested();
          }

          throw err;
        })
    );

  /**
   * @returns {Promise}
   */
  const fetchOrgs = () =>
    fetch(
      "fetchOrgs",
      setOrgs,
      async () => {
        await sleep(150);
        setOrgs(require("../mocks/user__orgs.json").data);
      },
      () => adwizyApi.get("/user/orgs")
    );

  /**
   * @param {Object} org
   */
  const addOrg = (org) =>
    setOrgs((prev) => {
      prev && prev.push(org);
      return prev;
    });

  /**
   * @returns {Promise}
   */
  const fetchApps = () =>
    fetch(
      "fetchApps",
      setApps,
      async () => {
        await sleep(200);
        setApps(
          require("../mocks/user__apps.json").data.filter(
            (it) => it.orgId === selectedOrg.orgId
          )
        );
      },
      () => adwizyApi.get("/user/apps", { orgId: selectedOrg.orgId })
    );

  /**
   * @returns {Promise}
   */
  const fetchNotifications = () =>
    fetch(
      "fetchNotifications",
      setNotifications,
      async () => {
        await sleep(300);
        setNotifications(require("../mocks/user__notifications.json").data);
      },
      () =>
        adwizyApi.get("/user/notifications", {
          isArchived: 0,
        })
    );

  const fetchACL = () =>
    fetch(
      "fetchACL",
      setACL,
      async () => {
        await sleep(300);
        setACL(require("../mocks/user__acl.json").data);
      },
      () =>
        adwizyApi.get("/user/acl", {
          orgId:
            selectedOrg?.orgId ?? getStateFromSetter(setSelectedOrg)?.orgId,
        })
    );

  const userOrgsListChanged = () =>
    adwizyApi.get("/user/orgs").then((res) => {
      if (res.data.success) {
        setOrgs(res.data.data);
      }
    });

  const userAppsListChanged = () =>
    adwizyApi
      .get("/user/apps", { orgId: getStateFromSetter(setSelectedOrg)?.orgId })
      .then((res) => {
        if (res.data.success) {
          setApps(res.data.data);
        }
      });

  const userDeleteRequested = () => setUserDeleted(true);

  const orgAppAdded = (events) =>
    setApps((apps) => {
      let changes = 0;
      events.forEach(({ payload }) => {
        let idx = apps.findIndex((it) => it.appId === payload.appId);
        if (idx === -1) {
          changes++;
          apps.push(payload);
        }
      });

      return changes ? apps.slice() : apps;
    });

  const orgAppRemoved = (events) =>
    setApps((apps) => {
      let changes = 0;
      events.forEach(({ ids }) => {
        let idx = apps.findIndex((it) => it.appId === ids.appId);
        if (idx !== -1) {
          changes++;
          apps.splice(idx, 1);
        }
      });

      return changes ? apps.slice() : apps;
    });

  const isSubscriptionActiveForSelectedOrg = () => {
    return selectedOrg?.billingStatus === BillingStatusesEnum.active;
  };

  const isAllowReport = (reportType) => {
    return (
      isSubscriptionActiveForSelectedOrg() &&
      allowedReports.includes(reportType)
    );
  };

  useEffect(() => {
    if (auth.isAuthorized) {
      const listener = Promise.all([fetchUser(), fetchOrgs()]).then(() =>
        event.listen(setSelectedOrg, getStateFromSetter(setData)?.timestamp)
      );

      const eventHandlers = {
        [events.userOrgsListChanged]: userOrgsListChanged,
        // [events.userAppsListChanged]: userAppsListChanged,
        [events.userDeleteRequested]: userDeleteRequested,
        [events.orgAppAdded]: orgAppAdded,
        [events.orgAppRemoved]: orgAppRemoved,
        [events.MMPConnectionAdded]: userAppsListChanged,
        [events.MMPConnectionRemoved]: userAppsListChanged,
        [events.NotificationsUpdated]: fetchNotifications,
        [events.MMPConnectionUpdated]: (events) =>
          userAppsListChanged().then(() => {
            events.forEach(({ payload }) => {
              if (
                payload?.appstoreAppName &&
                payload?.status === MMPConnectionStatusesEnum.AwaitingOfSetup
              ) {
                informer.showSuccessNotice(
                  `«${payload?.appstoreAppName}» connection status updated`
                );
              }
            });
          }),
        [events.orgBillingUpdated]: () => {
          fetchOrgs();
          fetchACL();
        },
      };

      event
        .subscribe(eventHandlers)
        .catch((err) => Object.values(err).forEach(informer.showErrorNotice));
      return async () => {
        event.unsubscribe(eventHandlers);
        (await listener) && (await listener).stopListen();
      };
    }
  }, [auth.isAuthorized]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data && selectedOrg?.orgId) {
      Promise.all([fetchApps(), fetchNotifications(), fetchACL()]);

      setForceConversion(
        getFromStorage(`forceConversion_${selectedOrg.orgId}`) ?? 0
      );
    }
  }, [data, selectedOrg?.orgId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orgs) {
      const res = [];

      for (const org of orgs) {
        if (org.subscriptions) {
          for (const subscription of org.subscriptions) {
            if (subscription.reports) {
              for (const report of subscription.reports) {
                if (!res.includes(report)) {
                  res.push(report);
                }
              }
            }
          }
        }
      }

      setAllowedReports(res);
    }
  }, [orgs]);

  return (
    <>
      <UserContext.Provider
        value={{
          data,
          orgs,
          addOrg,
          selectedOrg,
          setSelectedOrg,
          apps,
          notifications,
          ACL,
          forceConversion,
          setForceConversion,
          appIdOfSelectedApp,
          setAppIdOfSelectedApp,
          datePickerValue,
          setDatePickerValue,
          userAppsListChanged,
          userDeleteRequested,
          fetchNotifications,
          fetchOrgs,
          fetchACL,
          isSubscriptionActiveForSelectedOrg,
          isAllowReport,
          subscriptionStatusData,
          setSubscriptionStatusData,
        }}
        {...props}
      />
      <AccountDeletedWarning open={userDeleted} />
    </>
  );
};

const UserProvider = (props) => (
  <EventProvider>
    <UserProviderHandler {...props} />
  </EventProvider>
);

export default UserProvider;
