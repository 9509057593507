export const StatusesEnum = {
  Enabled: "enabled",
  Paused: "paused",
  Removed: "removed",
  Completed: "completed",
};

export const StatusesMapping = {
  [StatusesEnum.Enabled]: "Active",
  [StatusesEnum.Paused]: "Paused",
  [StatusesEnum.Removed]: "Removed",
  [StatusesEnum.Completed]: "Completed",
};
