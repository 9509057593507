import { withStyles } from "@mui/styles";
import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";

const passwordHelperStyles = (theme) => ({
  validation: {
    display: "flex",
    flexWrap: "wrap",
  },
  item: {
    display: "flex",
    alignItems: "center",
    marginRight: "0.7rem",
  },
  marker: {
    height: "0.5rem",
    width: "0.5rem",
    marginRight: "0.39rem",
    borderRadius: "50%",
  },
  ok: {
    color: theme.palette.primary.main,
    "& $marker": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  error: {
    color: theme.palette.error.main,
    "& $marker": {
      backgroundColor: theme.palette.error.main,
    },
  },
});

const PasswordHelper = ({
  classes,
  length,
  register,
  digits,
  symbols,
  forbidden,
}) => {
  const className = (status) =>
    classnames({
      [classes.item]: true,
      [classes.ok]: status,
      [classes.error]: !status,
    });

  return (
    <div className={classes.validation}>
      <div className={className(length)}>
        <div className={classes.marker} />
        use from 8 to 32 characters
      </div>
      <div className={className(register)}>
        <div className={classes.marker} />
        use lowercase and uppercase letters
      </div>
      <div className={className(digits)}>
        <div className={classes.marker} />
        use digits
      </div>
      <div className={className(symbols)}>
        <div className={classes.marker} />
        use symbols
      </div>
      {forbidden && (
        <div className={className(false)}>
          <div className={classes.marker} />
          don&#39;t use{" "}
          {forbidden.length > 10
            ? `${forbidden.slice(0, 10).join(" ")}...`
            : forbidden.join(" ")}
        </div>
      )}
    </div>
  );
};

PasswordHelper.propTypes = {
  classes: PropTypes.object,
  length: PropTypes.number,
  register: PropTypes.bool,
  digits: PropTypes.bool,
  symbols: PropTypes.bool,
  forbidden: PropTypes.array,
};

export default withStyles(passwordHelperStyles)(PasswordHelper);
