import React from "react";
import { withStyles } from "@mui/styles";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const styles = (theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.9rem 0",
    margin: "0",
    borderBottom: `1px solid ${theme.palette.borders.main}`,
  },
  key: {
    color: theme.palette.inactive.main,
    marginRight: "1rem",
    fontSize: "1rem",
    border: "none",
  },
  value: {
    fontSize: "1rem",
    fontWeight: 500,
    border: "none",
    textAlign: "end",
  },
});

const KeyValueTable = ({ classes, data, sx }) => {
  return Object.entries(data).map(([key, value], i) => (
    <Box key={i} className={classes.row} sx={sx}>
      <div className={classes.key}>{key}</div>
      <div className={classes.value}>{value || "-"}</div>
    </Box>
  ));
};

KeyValueTable.propTypes = {
  data: PropTypes.object.isRequired,
  sx: PropTypes.object,
};

export default withStyles(styles)(KeyValueTable);
