import React, { useState } from "react";
import CDialog from "../common/CDialog";
import { isDev, sleep } from "../../utils";
import adwizyApi from "../../api/adwizyApi";
import { useInformer } from "../../context/InformerProvider";
import PropTypes from "prop-types";
import { useAuth } from "../../context/AuthProvider";
import { useUser } from "../../context/UserProvider";

export const AccountDeletedWarning = ({ open }) => {
  const auth = useAuth();
  return (
    <CDialog
      open={open}
      onClose={auth.signOutLocal}
      content={
        <>
          You won’t be able to sign in with your e-mail after. If you have any
          further questions please reach out support team:&nbsp;
          <a
            href="mailto:support@adwizy.com"
            className={"link"}
            style={{ fontSize: "unset" }}
          >
            support@adwizy.com
          </a>
        </>
      }
      applyBtnText="Ok"
      cancelBtn={false}
    />
  );
};

AccountDeletedWarning.propTypes = {
  open: PropTypes.bool,
};

const DeleteAccount = ({ btn }) => {
  const user = useUser();
  const informer = useInformer();
  const [loadingDeleteAccount, setLoadingDeleteAccount] = useState(false);

  const deleteAccountHandle = async () => {
    setLoadingDeleteAccount(true);
    if (isDev) {
      await sleep(1000);
      user.userDeleteRequested();
    } else {
      await adwizyApi
        .delete("/user/profile")
        .then((res) => {
          if (res.data.success) {
            user.userDeleteRequested();
          }
        })
        .catch(informer.showErrorNotice);
    }

    setLoadingDeleteAccount(false);
  };

  return (
    <>
      <CDialog
        btn={btn}
        title="Are you sure?"
        content={
          "Are you sure you want to delete your account? This action cannot be undone."
        }
        applyBtnCb={() => deleteAccountHandle().then(() => false)}
        cancelBtnText="No, do nothing"
        cancelBtnStyle={{
          color: "primary",
          variant: "contained",
        }}
        applyBtnText="Yes, delete my account"
        applyBtnStyle={{
          color: "error",
          variant: "outlined",
        }}
        loading={loadingDeleteAccount}
      />
    </>
  );
};

DeleteAccount.propTypes = {
  btn: PropTypes.node.isRequired,
};

export default DeleteAccount;
