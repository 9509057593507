import React from "react";
import { styled } from "@mui/styles";
import { Checkbox } from "@mui/material";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import CSkeleton, { types } from "./CSkeleton";

const CCheckbox = ({
  className,
  checked,
  color,
  borderColor,
  disabled,
  loading,
  ...other
}) => {
  const checkboxClass = classnames({
    checked: checked,
    disabled: disabled,
    [className]: className,
  });

  const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    "& svg": {
      fill: borderColor || theme.palette.borders.main,
    },
    "&.checked svg": {
      fill: color || theme.palette.primary.main,
    },
    "&.disabled svg": {
      fill: theme.palette.inactive.main,
    },
  }));

  return loading ? (
    <StyledCheckbox
      icon={
        <CSkeleton
          type={types.rectangle}
          height="1.22rem"
          width="1.22rem"
          sx={{ margin: "3px" }}
        />
      }
      disabled
    />
  ) : (
    <StyledCheckbox
      className={checkboxClass}
      checked={checked}
      disabled={disabled}
      {...other}
    />
  );
};

CCheckbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default CCheckbox;
