import React, { useEffect } from "react";
import { useAuth } from "../../context/AuthProvider";
import { Navigate } from "react-router-dom";
import Query from "../../helpers/query";
import { getFromStorage, removeFromStorage } from "../../localStorageUtils";

const SignOutPage = () => {
  const auth = useAuth();
  const query = new Query();
  let from = null;

  if (query.get("force") === "true") {
    auth.signOutLocal();
    from = JSON.parse(getFromStorage("locationStateFrom"));
  } else {
    auth.signOut();
  }

  useEffect(() => () => removeFromStorage("locationStateFrom"), []);

  return <Navigate to="/auth/sign-in" state={{ from }} />;
};

export default SignOutPage;
