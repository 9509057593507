import React from "react";
import ReactMarkdown from "react-markdown";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import { htmlDecode } from "../../utils";

const NotificationContent = ({ title, teaser, content, open }) => (
  <Accordion
    expanded={open}
    sx={{
      background: "transparent",
      border: "none",
      boxShadow: "none",
    }}
  >
    <AccordionSummary
      sx={{
        p: 0,
        "& > .MuiAccordionSummary-content": {
          width: "100%",
        },
      }}
    >
      <div className="d-flex flex-direction-column w-100">
        <Box
          className="table-column-title__title"
          title={htmlDecode(title)}
          fontSize="1.2rem"
        >
          {htmlDecode(title)}
        </Box>
        {!open && (
          <Box
            className="table-column-title__subtitle"
            title={htmlDecode(teaser)}
            fontSize="1rem"
          >
            {htmlDecode(teaser)}
          </Box>
        )}
      </div>
    </AccordionSummary>
    <AccordionDetails
      sx={{
        p: 0,
      }}
    >
      <ReactMarkdown>{content}</ReactMarkdown>
    </AccordionDetails>
  </Accordion>
);

NotificationContent.propTypes = {
  title: PropTypes.string.isRequired,
  teaser: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

export default NotificationContent;
