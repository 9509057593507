import React from "react";
import PropTypes from "prop-types";
import {
  getTooltipMessageByStatus,
  MMPConnectionStatusesEnum,
  MMPConnectionStatusesMapping,
} from "../../enum/MMPConnectionStatuses";
import { MMPServices } from "../../enum/MMPServices";
import CIcon from "../CIcon";
import { Warning, ErrorOutline } from "@mui/icons-material";
import CTooltip from "../common/CTooltip";
import { AppstoreTypesEnum } from "../../enum/AppstoreTypes";
import { htmlDecode } from "../../utils";

const AppMMPStatus = ({ appstore, MMPConnectionStatus, MMPServiceName }) => {
  const statusText = MMPConnectionStatusesMapping[MMPConnectionStatus];
  const MMPService = MMPServices[MMPServiceName];

  return (
    <div className="app-mmp-status">
      {MMPService === undefined ? (
        <div />
      ) : (
        <CIcon
          link={MMPService?.icon}
          alt={MMPService?.title}
          title={MMPService?.title}
          style={{
            width: "1.8rem",
          }}
        />
      )}
      <span>
        {appstore === AppstoreTypesEnum.Omni
          ? htmlDecode("&mdash;")
          : statusText
          ? statusText
          : "Not connected"}
      </span>
      {(MMPConnectionStatus === MMPConnectionStatusesEnum.Pending ||
        MMPConnectionStatus === MMPConnectionStatusesEnum.AwaitingOfSetup) && (
        <CTooltip
          customLabel={<ErrorOutline color="warning" />}
          content={getTooltipMessageByStatus(
            MMPConnectionStatus,
            MMPServiceName
          )}
        />
      )}
      {MMPConnectionStatus === MMPConnectionStatusesEnum.Disconnected && (
        <CTooltip
          customLabel={<Warning color="error" />}
          content={getTooltipMessageByStatus(
            MMPConnectionStatus,
            MMPServiceName
          )}
        />
      )}
    </div>
  );
};

AppMMPStatus.propTypes = {
  appstore: PropTypes.oneOf(Object.values(AppstoreTypesEnum)),
  MMPConnectionStatus: PropTypes.oneOf(
    Object.values(MMPConnectionStatusesEnum)
  ),
  MMPServiceName: PropTypes.oneOf(Object.keys(MMPServices)),
};

export default AppMMPStatus;
