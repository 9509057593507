import { getFromStorage, setInStorage } from "../localStorageUtils";
import { MMPServices } from "./MMPServices";

export const MMPConnectionStatusesEnum = {
  Pending: "pending",
  AwaitingOfSetup: "awaitingOfSetup",
  Connected: "connected",
  Disconnected: "disconnected",
};

export const MMPConnectionStatusesMapping = {
  [MMPConnectionStatusesEnum.Pending]: "Pending connection",
  [MMPConnectionStatusesEnum.AwaitingOfSetup]: "Awaiting target setup",
  [MMPConnectionStatusesEnum.Connected]: "Connected",
  [MMPConnectionStatusesEnum.Disconnected]: "Connection problems",
};

export const getTooltipMessageByStatus = (status, MMPServiceName) => {
  switch (status) {
    case MMPConnectionStatusesEnum.Pending:
      return "Connection to MMP is pending conversions.";
    case MMPConnectionStatusesEnum.AwaitingOfSetup:
      return "Finish setup by choosing target conversions for this app.";
    case MMPConnectionStatusesEnum.Disconnected:
      return `Adwizy haven't receive events from ${MMPServices[MMPServiceName]?.title} for at least 7 days.`;
    case null:
      return "The app doesn't have a connected MMP. Connect your MMP to Adwizy to benefit from advanced reporting and recommendations.";
  }
};

const localStorageKey = "hideMMPAlertFor";

export const hideMMPAlerts = (appId) => {
  setInStorage(localStorageKey + appId, Date.now() + 7 * 24 * 60 * 60 * 1000);
};

export const isHideMMPAlerts = (appId) => {
  return getFromStorage(localStorageKey + appId)
    ? Date.now() < getFromStorage(localStorageKey + appId)
    : false;
};
