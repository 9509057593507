import React, { createContext, useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { SnackbarProvider, useSnackbar } from "notistack";
import {
  Box,
  Slide,
  Grow,
  Fade,
  Zoom,
  Collapse,
  IconButton,
} from "@mui/material";
import { getOutlinedIcon, getStateFromSetter, informTypes } from "../utils";
import { PropTypes } from "prop-types";
import { Close, Refresh } from "@mui/icons-material";

export const InformerContext = createContext();
export const useInformer = () => useContext(InformerContext);

export const transitions = {
  slide: Slide,
  grow: Grow,
  fade: Fade,
  zoom: Zoom,
  collapse: Collapse,
};

export const positions = {
  topCenter: "topCenter",
  topRight: "topRight",
  bottomRight: "bottomRight",
  bottomCenter: "bottomCenter",
  bottomLeft: "bottomLeft",
  topLeft: "topLeft",
};

const anchorOrigins = {
  topCenter: { vertical: "top", horizontal: "center" },
  topRight: { vertical: "top", horizontal: "right" },
  bottomRight: { vertical: "bottom", horizontal: "right" },
  bottomCenter: { vertical: "bottom", horizontal: "center" },
  bottomLeft: { vertical: "bottom", horizontal: "left" },
  topLeft: { vertical: "top", horizontal: "left" },
};

const useStyles = makeStyles((theme) => ({
  notice: {
    display: "flex",
    padding: "0.83rem 1.11rem",
    minWidth: "19.44rem",
    border: "2px solid",
    borderRadius: 5,
    backgroundColor: theme.palette.white.main,
    fontSize: "1rem",
    fontWeight: 500,

    [`&.${informTypes.default}`]: {
      borderWidth: 1,
      borderColor: theme.palette.borders.main,
    },

    [`&.${informTypes.info}, &.${informTypes.feature}`]: {
      borderColor: theme.palette.primary.main,
    },

    [`&.${informTypes.success}`]: {
      borderColor: theme.palette.success.main,
    },

    [`&.${informTypes.warning}`]: {
      borderColor: theme.palette.warning.main,
    },

    [`&.${informTypes.error}`]: {
      borderColor: theme.palette.error.main,
    },
  },
}));

const defaultMaxNotice = 10;
const defaultTransition = transitions.slide;
const defaultPosition = positions.bottomRight;
const defaultHideDuration = 10 * 1000;

const InformerProviderHandler = ({
  setMaxNotice,
  setTransition,
  settings,
  ...other
}) => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [, setPosition] = useState(defaultPosition);
  const [, setHideDuration] = useState(defaultHideDuration);
  const noticesKeysStorage = {};

  const setSettings = ({
    maxNotice,
    transition,
    position,
    hideDuration,
  } = {}) => {
    setMaxNotice(Number.isInteger(maxNotice) ? maxNotice : defaultMaxNotice);

    setTransition(
      Object.values(transitions).includes(transition)
        ? transition
        : defaultTransition
    );

    setPosition(
      Object.values(positions).includes(position) ? position : defaultPosition
    );

    setHideDuration(
      Number.isInteger(hideDuration) || hideDuration === null
        ? hideDuration
        : defaultHideDuration
    );
  };

  useEffect(() => setSettings(settings), [settings]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * @param {String|Number} key
   */
  const closeNoticeByKey = (key) => {
    closeSnackbar(key);
  };

  /**
   * @param {Function|Any} action
   * @param {Boolean} reloadButton
   * @param {Boolean} closeButton
   * @returns {?ReactNode}
   */
  const getAction = (action, reloadButton = false, closeButton = true) =>
    typeof action === "function" || closeButton
      ? (key) => (
          <Box marginLeft={1}>
            {typeof action === "function" && action(key)}
            {reloadButton && (
              <IconButton onClick={() => window.location.reload()} size="small">
                <Refresh fontSize="small" />
              </IconButton>
            )}
            {closeButton && (
              <IconButton onClick={() => closeNoticeByKey(key)} size="small">
                <Close fontSize="small" />
              </IconButton>
            )}
          </Box>
        )
      : undefined;

  /**
   * @param {String} message
   * @param {Object} options
   * @returns {String|Number}
   */
  const showNotice = (message, options = {}) => {
    let TransitionComponent = options.transition;
    let anchorOrigin =
      anchorOrigins[options.position || getStateFromSetter(setPosition)];
    let autoHideDuration = Object.prototype.hasOwnProperty.call(
      options,
      "hideDuration"
    )
      ? options.hideDuration
      : getStateFromSetter(setHideDuration);
    let variant = options.type || informTypes.default;

    const action = getAction(
      options.action,
      options.reloadButton != null
        ? options.reloadButton
        : settings?.reloadButton,
      options.closeButton != null ? options.closeButton : settings?.closeButton
    );

    let icon = getOutlinedIcon(variant);
    const content = (key, message) =>
      typeof options.content === "function" ? (
        <Box key={key} display="flex" justifyContent="space-between">
          {options.content(message)}
          {action && action(key)}
        </Box>
      ) : (
        <Box
          key={key}
          className={`${classes.notice} ${variant}`}
          display="flex"
          justifyContent="space-between"
        >
          <Box display="flex">
            {icon && <Box marginRight={2}>{icon}</Box>}
            <div>{message}</div>
          </Box>
          {action && action(key)}
        </Box>
      );

    let { replaceKey } = options;
    if (replaceKey) {
      closeNoticeByKey(noticesKeysStorage[replaceKey]);
    }

    delete options.transition;
    delete options.position;
    delete options.hideDuration;
    delete options.action;
    delete options.reloadButton;
    delete options.closeButton;
    delete options.content;
    delete options.replaceKey;

    let key = enqueueSnackbar(message, {
      TransitionComponent,
      anchorOrigin,
      autoHideDuration,
      variant,
      action,
      content,
      ...options,
    });

    if (replaceKey) {
      noticesKeysStorage[replaceKey] = key;
    }

    return key;
  };

  /**
   * @param {String} message
   * @param {Object} options
   * @returns {String|Number}
   */
  const showInfoNotice = (message, options = {}) => {
    return showNotice(message, { ...options, type: informTypes.info });
  };

  /**
   * @param {String} message
   * @param {Object} options
   * @returns {String|Number}
   */
  const showSuccessNotice = (message, options = {}) => {
    return showNotice(message, { ...options, type: informTypes.success });
  };

  /**
   * @param {String} message
   * @param {Object} options
   * @returns {String|Number}
   */
  const showWarningNotice = (message, options = {}) => {
    return showNotice(message, { ...options, type: informTypes.warning });
  };

  /**
   *
   * @param {String} message
   * @param {Object} options
   * @returns {String|Number}
   */
  const showFeatureNotice = (message, options = {}) => {
    return showNotice(message, { ...options, type: informTypes.feature });
  };

  /**
   * @param {Object|String} err
   * @param {Object} options
   * @returns {String|Number}
   */
  const showErrorNotice = (err, options = {}) => {
    const errors = err.response?.data?.errors
      ? Object.keys(err.response.data.errors).reduce(
          (result, it) => ({
            ...result,
            [it]: Array.isArray(err.response.data.errors[it])
              ? `
                  <ul style="margin-top: 0">
                      ${err.response.data.errors[it]
                        .map((it) => `<li>${it}</li>`)
                        .join("")}
                  </ul>
              `
              : err.response.data.errors[it],
          }),
          {}
        )
      : null;

    const message = err.response?.data ? (
      errors ? (
        <div
          dangerouslySetInnerHTML={{
            __html: err.response.data.message
              ? Object.keys(errors).reduce(
                  (result, it) =>
                    result.replace(new RegExp(`%${it}%[.,;]? ?`), errors[it]),
                  err.response.data.message
                )
              : Object.keys(errors).map(
                  (it) => `
                      <div>
                          ${it}:
                          ${errors[it]}
                      </div>
                  `
                ),
          }}
        />
      ) : (
        err.response.data.message
      )
    ) : null;

    return showNotice(message || err.message || err, {
      ...options,
      type: informTypes.error,
    });
  };

  return (
    <InformerContext.Provider
      value={{
        setSettings,
        closeNoticeByKey,
        showNotice,
        showInfoNotice,
        showSuccessNotice,
        showWarningNotice,
        showErrorNotice,
        showFeatureNotice,
      }}
      {...other}
    />
  );
};

InformerProviderHandler.propTypes = {
  setMaxNotice: PropTypes.func,
  setTransition: PropTypes.func,
  settings: PropTypes.object,
};

const InformerProvider = (props) => {
  const [maxNotice, setMaxNotice] = useState(defaultMaxNotice);
  const [transition, setTransition] = useState(defaultTransition);

  return (
    <SnackbarProvider maxSnack={maxNotice} TransitionComponent={transition}>
      <InformerProviderHandler
        {...props}
        setMaxNotice={setMaxNotice}
        setTransition={setTransition}
      />
    </SnackbarProvider>
  );
};

InformerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.shape({
    maxNotice: PropTypes.number,
    transition: PropTypes.oneOf(Object.values(transitions)),
    position: PropTypes.oneOf(Object.values(positions)),
    hideDuration: PropTypes.number,
    reloadButton: PropTypes.bool,
    closeButton: PropTypes.bool,
    action: PropTypes.func,
  }),
};

export default InformerProvider;
