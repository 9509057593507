import React from "react";
import PropTypes from "prop-types";
import AppStoreColorIcon from "../icons/AppStoreColorIcon";
import GooglePlayIcon from "../icons/GooglePlayIcon";
import FolderIcon from "../icons/FolderIcon";
import { AutoAwesomeMotion, ErrorOutlineOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import CTooltip from "./CTooltip";
import { AppstoreTypesEnum } from "../../enum/AppstoreTypes";
import {
  getTooltipMessageByStatus,
  isHideMMPAlerts,
  MMPConnectionStatusesEnum,
} from "../../enum/MMPConnectionStatuses";
import AppStoreIcon from "../icons/AppStoreIcon";

const AppIcon = ({
  complexAppId,
  appstore,
  isAppMap,
  MMPConnectionStatus,
  MMPServiceName,
  colored,
  sx,
}) => {
  if (isAppMap) {
    return (
      <FolderIcon
        sx={{
          ...{
            width: "1rem",
            height: ".77rem",
          },
          ...sx,
        }}
      />
    );
  }

  let icon;
  if (appstore === AppstoreTypesEnum.Apple) {
    sx = {
      ...{
        width: "1rem",
        height: "1rem",
      },
      ...sx,
    };

    icon = colored ? <AppStoreColorIcon sx={sx} /> : <AppStoreIcon sx={sx} />;
  }

  if (appstore === AppstoreTypesEnum.Google) {
    icon = (
      <GooglePlayIcon
        sx={{
          ...{
            width: "1rem",
            height: "1rem",
          },
          ...sx,
        }}
      />
    );
  }

  if (appstore === AppstoreTypesEnum.Huawei) {
    //todo
  }

  if (appstore === AppstoreTypesEnum.Omni) {
    icon = (
      <AutoAwesomeMotion
        sx={{
          ...{
            width: "1rem",
            height: "1rem",
          },
          ...sx,
        }}
      />
    );
  }

  return (
    <Box position="relative" display="flex">
      {appstore !== AppstoreTypesEnum.Omni &&
        !isHideMMPAlerts(complexAppId) &&
        MMPConnectionStatus !== undefined &&
        MMPConnectionStatus !== MMPConnectionStatusesEnum.Connected && (
          <CTooltip
            customLabel={
              <ErrorOutlineOutlined
                sx={{
                  position: "absolute",
                  width: "1.115rem",
                  height: "1.115rem",
                  top: 0,
                  right: 0,
                  backgroundColor: "#fff",
                  border: "0.5px solid #fff",
                  borderRadius: "50%",
                  transform: "translate(60%, -40%)",
                }}
                color="warning"
              />
            }
            content={getTooltipMessageByStatus(
              MMPConnectionStatus,
              MMPServiceName
            )}
          />
        )}
      {icon}
    </Box>
  );
};

AppIcon.propTypes = {
  complexAppId: PropTypes.string.isRequired,
  appstore: PropTypes.oneOf(Object.values(AppstoreTypesEnum)),
  isAppMap: PropTypes.bool,
  MMPConnectionStatus: PropTypes.string,
  MMPServiceName: PropTypes.string,
  colored: PropTypes.bool,
  sx: PropTypes.object,
};

export default AppIcon;
