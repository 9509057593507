import React from "react";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { InfoOutlined } from "@mui/icons-material";
import classnames from "classnames";
import { getFilledIcon, getOutlinedIcon, informTypes } from "../../utils";

export const placements = {
  topStart: "top-start",
  top: "top",
  topEnd: "top-end",
  rightStart: "right-start",
  right: "right",
  rightEnd: "right-end",
  bottomStart: "bottom-start",
  bottom: "bottom",
  bottomEnd: "bottom-end",
  leftStart: "left-start",
  left: "left",
  leftEnd: "left-end",
};

const CTooltip = ({
  tooltipClassName,
  labelClassName,
  type,
  placement,
  label,
  customLabel,
  content,
  contentIcon,
  onOpen,
}) => {
  const tooltipClass = classnames({
    tooltip: true,
    [tooltipClassName]: tooltipClassName,
    tooltip_info: type === informTypes.info,
    tooltip_success: type === informTypes.success,
    tooltip_warning: type === informTypes.warning,
    tooltip_error: type === informTypes.error,
  });

  const labelClass = classnames({
    tooltip_label: true,
    [labelClassName]: labelClassName,
    tooltip_label_info: type === informTypes.info,
    tooltip_label_success: type === informTypes.success,
    tooltip_label_warning: type === informTypes.warning,
    tooltip_label_error: type === informTypes.error,
  });

  return (
    <Tooltip
      componentsProps={{ tooltip: { className: tooltipClass } }}
      placement={placement}
      title={
        <div className="tooltip_content">
          {(type !== informTypes.default || contentIcon) && contentIcon === true
            ? getOutlinedIcon(type, { className: "tooltip_content__icon" })
            : contentIcon}
          <div>{content}</div>
        </div>
      }
      onOpen={onOpen}
    >
      {customLabel || (
        <div className={labelClass}>
          {label || getFilledIcon(type) || <InfoOutlined color="borders" />}
        </div>
      )}
    </Tooltip>
  );
};

CTooltip.propTypes = {
  tooltipClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.oneOf(Object.values(informTypes)),
  placement: PropTypes.oneOf(Object.values(placements)),
  label: PropTypes.node,
  customLabel: PropTypes.node,
  content: PropTypes.node.isRequired,
  contentIcon: PropTypes.node,
  onOpen: PropTypes.func,
};

CTooltip.defaultProps = {
  type: informTypes.default,
  placement: placements.bottom,
  contentIcon: false,
};

export default CTooltip;
