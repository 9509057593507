import React, { useEffect, useState } from "react";
import EmptyDialog from "../EmptyDialog";
import { Box, Button, IconButton } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import CButton from "../common/CButton";
import { Close } from "@mui/icons-material";
import NetworkIcon from "./NetworkIcon";
import PropTypes from "prop-types";
import { CampaignNetworks } from "../../enum/CampaignNetworks";
import { useLocation } from "react-router-dom";

const NetworkConnection = ({
  btn,
  open,
  onClose,
  data,
  onSuccess,
  onError,
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [testMode, setTestMode] = useState(false);
  const [preInfo, setPreInfo] = useState(false);

  useEffect(() => {
    open != null && setIsOpen(open);
  }, [open]);

  useEffect(() => {
    location.hash === "#testNetworks" && setTestMode(true);
  }, [location.hash]);

  useEffect(() => {
    testMode && setIsOpen(testMode);
  }, [testMode]);

  const localOnClose = () => {
    setIsOpen(false);
    cancelAddConnection(false);
    typeof onClose === "function" && onClose();
  };

  const addConnection = async (network) => {
    if (!showPreInfo(network)) {
      addConnectionNextStep(network);
    }
  };

  const showPreInfo = function (network) {
    if (network === "google-ads") {
      setPreInfo({
        title: "Google Ads API connection",
        network: network,
        text: (
          <>
            <p>
              Hereby, Apptimizer OU (Adwizy&#39;s owning company) confirms that
              any usage and transfer of information received from Google APIs to
              other services and applications complies with the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                }
              >
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements.
            </p>
            <p>
              By clicking Proceed, you confirm that you are acknowledged with
              it.
            </p>
          </>
        ),
      });

      return true;
    }
    return false;
  };

  const cancelAddConnection = async () => {
    setPreInfo(false);
  };

  const addConnectionNextStep = async (network) => {
    popupWindow(`/connect-account/${network}?orgId=${data?.orgId}`);
  };

  const popupWindow = (url, height = 700, width = 700) => {
    let h = window.screen.availHeight;
    let w = window.screen.availWidth;
    height = height > h ? h : height;
    width = width > w ? w : width;
    let top = (h - height) / 2;
    let left = (w - width) / 2;
    window.open(
      url,
      "Add account",
      `height=${height}, width=${width}, top=${top}, left=${left}`
    );
    window.successHandler = (res) => {
      typeof onSuccess === "function" && onSuccess(res);
      cancelAddConnection(false);
    };
    window.errorHandler = (err) => {
      typeof onError === "function" && onError(err);
      cancelAddConnection(false);
    };
  };

  return (
    <EmptyDialog
      btn={btn}
      onClickBtn={() => setIsOpen(true)}
      open={isOpen}
      onClose={localOnClose}
      maxWidth="md"
      sx={{
        paper: {
          padding: "4.44rem 2.22rem",
        },
      }}
    >
      <Box position="absolute" top="20px" right="20px">
        <IconButton onClick={localOnClose}>
          <Close />
        </IconButton>
      </Box>
      <div className="connection-dialog__title text-center">
        Network connection
      </div>
      <div className="connection-dialog__subtitle text-center">
        Connect accounts to Adwizy
      </div>
      <div className="connection-dialog__content">
        {preInfo !== false ? (
          <div className="connection-dialog__pre-info">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <NetworkIcon
                network={preInfo.network}
                style={{ marginRight: "1rem" }}
              />
              <h3>{preInfo.title}</h3>
            </Box>
            {preInfo.text}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => {
                  cancelAddConnection();
                }}
                sx={{
                  fontSize: "1rem",
                  textTransform: "unset",
                }}
              >
                <ArrowBackIos fontSize="small" /> Back
              </Button>
              <CButton
                onClick={() => {
                  addConnectionNextStep(preInfo.network);
                }}
                variant="contained"
                size="large"
                sx={{ marginLeft: ".83rem" }}
              >
                Proceed
              </CButton>
            </Box>
          </div>
        ) : (
          <div className="connection-dialog__network-container">
            {Object.entries(CampaignNetworks).map(([key, network], i) => (
              <Button
                key={i}
                classes={{
                  root: "connection-dialog__network-btn",
                }}
                disabled={!testMode && network.comingSoon}
                onClick={() => addConnection(key)}
                title={network.title}
              >
                <NetworkIcon
                  network={key}
                  customLabel={
                    network.comingSoon && (
                      <>
                        <div>{network.title}</div>
                        <div className="connection-dialog__coming-soon">
                          Coming soon
                        </div>
                      </>
                    )
                  }
                  withLabel
                  className={
                    !testMode && network.comingSoon
                      ? "connection-dialog__network-disabled"
                      : null
                  }
                />
              </Button>
            ))}
          </div>
        )}
      </div>
    </EmptyDialog>
  );
};

NetworkConnection.propTypes = {
  btn: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({
    orgId: PropTypes.number,
  }).isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default NetworkConnection;
