import React, { useEffect, useState } from "react";
import MainTemplate from "../../templates/MainTemplate";
import { Alert, AlertTitle, Box, Collapse } from "@mui/material";
import { useUser } from "../../context/UserProvider";
import OrganizationInformation from "../../components/OrganizationPage/OrganizationInformation";
import ConnectionManagement from "../../components/OrganizationPage/ConnectionManagement";
import { Warning as WarningIcon } from "@mui/icons-material/";
import CButton from "../../components/common/CButton";
import { AccessEntity, AccessLevelsEnum, isAccess } from "../../enum/ACL";
import { Link } from "react-router-dom";
// import UserManagement from "../../components/OrganizationPage/UserManagement";

const OrganizationPage = () => {
  const user = useUser();
  const [org, setOrg] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [accessLevel, setAccessLevel] = useState(null);

  useEffect(() => {
    if (user.selectedOrg) {
      setOrg(user.selectedOrg);
      setShowAlert(user.selectedOrg.subscriptions?.length === 0);
    }
  }, [user.selectedOrg]);

  useEffect(() => {
    if (user.ACL) {
      setAccessLevel(user.ACL?.[AccessEntity.Org]);
    }
  }, [user.ACL]);

  return (
    <MainTemplate
      title={
        <>
          Organization
          <Box
            component="span"
            sx={{
              color: "primary.main",
              marginLeft: "0.3em",
            }}
          >
            {org?.orgName}
          </Box>
        </>
      }
    >
      <div className="content_block">
        <Collapse in={showAlert}>
          <Alert
            variant="outlined"
            severity="error"
            icon={false}
            sx={{
              p: "1.66rem 2rem",
              mb: "1.66rem",
              bgcolor: "white.main",
              borderWidth: "2px",
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <AlertTitle
                className="f-w-700 d-flex align-items-center"
                color="hover.main"
                fontSize="1.66rem"
                sx={{ gridGap: ".44rem" }}
              >
                <WarningIcon color="error" />
                Subscription is required
              </AlertTitle>
              <Box className="f-w-500 m-b-1" color="hover.main">
                Choose your subscription plan to continue using Adwizy.
              </Box>

              {isAccess(AccessLevelsEnum.manage, accessLevel) && (
                <CButton
                  component={Link}
                  to={`/org/${org?.orgId}/plan-selection`}
                  variant="contained"
                  color="primary"
                >
                  Select
                </CButton>
              )}
            </Box>
          </Alert>
        </Collapse>

        <OrganizationInformation
          orgId={org?.orgId}
          subscriptions={org?.subscriptions ?? []}
        />

        {user.isSubscriptionActiveForSelectedOrg() && (
          <ConnectionManagement orgId={org?.orgId} />
        )}

        {/*<UserManagement orgId={org?.orgId} />*/}
      </div>
    </MainTemplate>
  );
};

export default OrganizationPage;
