import React, { useEffect, useState } from "react";
import { types as tableTypes } from "../../../components/common/CTable";
import { Box, Grid } from "@mui/material";
import { ShowChart as ShowChartIcon } from "@mui/icons-material/";
import {
  arrayEquals,
  chartColors,
  countryMappingFilter,
  dateSendFormat,
  getPeriods,
  isDev,
  networkMappingFilter,
  sleep,
} from "../../../utils";
import {
  metrics,
  getMetricList,
  reverseGrowthMetrics,
} from "../../../enum/Metrics";
import DropdownBigText from "../../../components/common/DropdownBigText";
import BarChart, { yaxisContent } from "../../../components/charts/BarChart";
import WoW from "../../../components/common/WoW";
import { useUser } from "../../../context/UserProvider";
import adwizyApi from "../../../api/adwizyApi";
import { useParams } from "react-router-dom";
import GranularityLineChart, {
  granularity,
} from "../../../components/charts/GranularityLineChart";
import DropdownApp from "../../../components/common/DropdownApp";
import { useInformer } from "../../../context/InformerProvider";
import MainTemplate from "../../../templates/MainTemplate";
import CTableWithInclusionColumns from "../../../components/common/CTableWithInclusionColumns";
import AppId from "../../../helpers/appId";
import { getMetricsScoresAndRecsHandlerForCreatives } from "../../../helpers/eventHandlers";
import { useEvent, events } from "../../../context/EventProvider";
import { getAppstoreMappingValues } from "../../../enum/AppstoreTypes";
import { ReportTypes } from "../../../enum/ReportTypes";
import PropTypes from "prop-types";

const CreativeAnalysisPageContent = ({
  orgId,
  selectedApp,
  handleSelectApp,
}) => {
  const user = useUser();
  const event = useEvent();
  const informer = useInformer();

  const creativeAnalysisMetrics = getMetricList(
    metrics.cost,
    metrics.cpa,
    metrics.cpi,
    metrics.ipm,
    metrics.roas,
    metrics.ctr,
    metrics.targetConversions,
    metrics.installs,
    metrics.clicks
  );

  const initialStateChart = {
    labels: [],
    series: [],
    currency: null,
  };

  const [loadingFetchFilters, setLoadingFetchFilters] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingBarChart, setLoadingBarChart] = useState(true);
  const [loadingTimelineChart, setLoadingTimelineChart] = useState(true);
  const [concepts, setConcepts] = useState([]);
  const [barChartData, setBarChartData] = useState({ ...initialStateChart });
  const [timelineChartData, setTimelineChartData] = useState({
    ...initialStateChart,
  });
  const [conceptFilterValues, setConceptFilterValues] = useState({});
  const [assetTypes, setAssetTypes] = useState();
  const [networks, setNetworks] = useState();
  const [appstores, setAppstores] = useState();
  const [countries, setCountries] = useState();
  const [selectedConceptIds, setSelectedConceptIds] = useState();
  const [selectedAssetTypes, setSelectedAssetTypes] = useState();
  const [selectedNetworks, setSelectedNetworks] = useState();
  const [selectedAppstores, setSelectedAppstores] = useState();
  const [selectedCountries, setSelectedCountries] = useState();
  const [selectedBarChartFirstMetric, setSelectedBarChartFirstMetric] =
    useState(Object.keys(creativeAnalysisMetrics)[0]);
  const [selectedBarChartSecondMetric, setSelectedBarChartSecondMetric] =
    useState(Object.keys(creativeAnalysisMetrics)[1]);
  const [selectedTimelineMetric, setSelectedTimelineMetric] = useState(
    Object.keys(creativeAnalysisMetrics)[0]
  );
  const [selectedGranularity, setSelectedGranularity] = useState(
    Object.keys(granularity)[0]
  );
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [, setAppId] = useState();
  const [filterId, setFilterId] = useState(null);
  const [filterKey, setFilterKey] = useState(0);
  const [successfulFetchFilters, setSuccessfulFetchFilters] = useState(null);
  const [defaultColumnsDisplayed, setDefaultColumnsDisplayed] = useState([
    "cost",
    "cpa",
    "cpi",
    "ipm",
    "roas",
    "ctr",
  ]);

  const fetchFilters = async () => {
    setLoadingFetchFilters(true);
    setLoadingTimelineChart(true);
    setLoadingBarChart(true);
    setLoadingTable(true);
    if (isDev) {
      let response;
      if (Object.keys(concepts).length === 0) {
        response =
          await require("../../../mocks/filters__concept-analysis.json").data;
      } else {
        response =
          await require("../../../mocks/filters__concept-analysis_other.json")
            .data;
      }
      setConceptFilterValues(response.concepts);
      setAssetTypes(response.assetTypes);
      setNetworks(response.networks);
      setAppstores(response.appstores);
      setCountries(response.countries);
    } else {
      setSuccessfulFetchFilters(false);
      await adwizyApi
        .get("/report/creative-analysis/filter", {
          orgId: orgId,
          appId: selectedApp.appId,
          appstoreAppId: selectedApp.appstoreAppId,
          conceptId: selectedConceptIds,
          assetType: selectedAssetTypes,
          network: selectedNetworks,
          countryCode3: selectedCountries,
          appstore: selectedAppstores,
          dateFrom: dateFrom,
          dateTo: dateTo,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            const dataFilter = data.data;
            setConceptFilterValues(
              Object.keys(dataFilter.concepts).length ? dataFilter.concepts : {}
            );
            setAppstores(getAppstoreMappingValues(dataFilter.appstores));
            setAssetTypes(
              Object.keys(dataFilter.assetTypes).length
                ? dataFilter.assetTypes
                : {}
            );
            setNetworks(networkMappingFilter(dataFilter.networks));
            setCountries(countryMappingFilter(dataFilter.countryCodes3));
            setFilterId((prevState) => {
              if (prevState === dataFilter.filterId) {
                setLoadingTimelineChart(false);
                setLoadingBarChart(false);
                setLoadingTable(false);
              }
              return dataFilter.filterId;
            });
            setSuccessfulFetchFilters(true);
          }
        })
        .catch(informer.showErrorNotice);
    }
    setLoadingFetchFilters(false);
  };

  const handleErrorFilter = (error) => {
    if (error?.response?.status === 417 && successfulFetchFilters) {
      setFilterId(null);
      fetchFilters();
    } else {
      informer.showErrorNotice(error);
    }
  };

  const fetchBarChartData = async () => {
    setLoadingBarChart(true);
    if (isDev) {
      await sleep(500);
      const response =
        require("../../../mocks/concepts__analysis__data.json").data;
      setBarChartData(response);
    } else {
      await adwizyApi
        .get("/report/creative-analysis/chart", {
          orgId: orgId,
          filterId: filterId,
          firstMetric: selectedBarChartFirstMetric,
          secondMetric: selectedBarChartSecondMetric,
          forceConversion: user.forceConversion,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            const res = data.data;
            res.series.map((it) => {
              it.name = creativeAnalysisMetrics[it.name];
              return it;
            });
            setBarChartData(data.data);
          } else {
            setBarChartData({ ...initialStateChart });
          }
        })
        .catch((error) => handleErrorFilter(error));
    }
    setLoadingBarChart(false);
  };

  const fetchTimelineChartData = async () => {
    setLoadingTimelineChart(true);
    if (isDev) {
      await sleep(500);
      const response =
        require("../../../mocks/concepts__analysis__timeline.json").data;
      setTimelineChartData(response);
    } else {
      await adwizyApi
        .get("/report/creative-analysis/timeline", {
          orgId: orgId,
          filterId: filterId,
          metric: selectedTimelineMetric,
          granularity: selectedGranularity,
          forceConversion: user.forceConversion,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            setTimelineChartData(data.data);
          } else {
            setTimelineChartData({ ...initialStateChart });
          }
        })
        .catch((error) => handleErrorFilter(error));
    }
    setLoadingTimelineChart(false);
  };

  const fetchTableData = async () => {
    setLoadingTable(true);
    if (isDev) {
      await sleep(500);
      setConcepts(require("../../../mocks/concepts__analysis.json").data);
    } else {
      await adwizyApi
        .get("/report/creative-analysis/data", {
          orgId: orgId,
          filterId: filterId,
          forceConversion: user.forceConversion,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            setConcepts(data.data);
          } else {
            setConcepts([]);
          }
        })
        .catch((error) => handleErrorFilter(error));
    }
    setLoadingTable(false);
  };

  useEffect(() => {
    if (filterId) {
      fetchBarChartData();
    }
  }, [selectedBarChartFirstMetric, selectedBarChartSecondMetric]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterId) {
      fetchTimelineChartData();
    }
  }, [selectedTimelineMetric, selectedGranularity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      user.isAllowReport(ReportTypes.creativeAnalysis) &&
      (selectedApp.appstoreAppId || selectedApp.appId) &&
      dateFrom &&
      dateTo &&
      selectedConceptIds &&
      selectedAssetTypes &&
      selectedNetworks &&
      selectedAppstores &&
      selectedCountries
    ) {
      fetchFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterKey,
    selectedApp,
    selectedConceptIds,
    selectedAssetTypes,
    selectedNetworks,
    selectedAppstores,
    selectedCountries,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    if (filterId) {
      fetchBarChartData();
      fetchTimelineChartData();
      fetchTableData();
    }
  }, [filterId, user.forceConversion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAppId(AppId(selectedApp).toString());
  }, [selectedApp]);

  useEffect(() => {
    const handler = getMetricsScoresAndRecsHandlerForCreatives(
      setFilterKey,
      setAppId
    );
    event
      .subscribe(events.orgAppMetricsUpdated, handler)
      .catch(informer.showErrorNotice);
    return () => event.unsubscribe(events.orgAppMetricsUpdated, handler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const deltaFiled = (field) => ({
    title: () => <ShowChartIcon sx={{ color: "0, 0, 0, 0.6" }} />,
    format: tableTypes.custom,
    component: (concept) => (
      <WoW
        current={concept[`${field}Delta`].current.value}
        previous={concept[`${field}Delta`].previous.value}
        currency={concept[`${field}Delta`].current.currency}
        reverse={reverseGrowthMetrics.includes(field)}
        periods={getPeriods({ dateFrom, dateTo })}
      />
    ),
    sx: {
      paddingLeft: `0 !important`,
    },
  });

  const metricField = (title, type) => {
    return {
      title: () => title,
      format: type,
      includeNumberConditions: true,
      includeSort: true,
      sx: {
        textAlign: "right",
        whiteSpace: "nowrap",
      },
      filterCellProps: {
        sx: {
          justifyContent: "flex-end",
        },
      },
    };
  };

  const getConceptEnums = () => {
    return Object.fromEntries(
      Object.values(concepts).map((concept) => [
        concept.conceptId,
        concept.conceptName,
      ])
    );
  };

  const columns = {
    cost: metricField("Cost", tableTypes.money),
    costDelta: deltaFiled(metrics.cost),
    cpa: metricField("CPA", tableTypes.money),
    cpaDelta: deltaFiled(metrics.cpa),
    cpi: metricField("CPI", tableTypes.money),
    cpiDelta: deltaFiled(metrics.cpi),
    ipm: metricField("IPM", tableTypes.float),
    ipmDelta: deltaFiled(metrics.ipm),
    roas: metricField("ROAS", tableTypes.money),
    roasDelta: deltaFiled(metrics.roas),
    ctr: metricField("CTR", tableTypes.float),
    ctrDelta: deltaFiled(metrics.ctr),
    targetConversions: metricField("Conversions", tableTypes.number),
    targetConversionsDelta: deltaFiled(metrics.targetConversions),
    installs: metricField("Installs", tableTypes.number),
    installsDelta: deltaFiled(metrics.installs),
    clicks: metricField("Clicks", tableTypes.number),
    clicksDelta: deltaFiled(metrics.clicks),
  };

  const columnList = () => {
    return Object.fromEntries(
      Object.keys(columns)
        .filter((column) => !column.includes("Delta"))
        .map((column) => [column, columns[column].title()])
    );
  };

  const getConcepts = () => {
    return concepts.map((concept) => ({
      conceptId: concept.conceptId,
      conceptName: concept.conceptName,
      cost: concept.cost.current,
      costDelta: concept.cost,
      cpa: concept.cpa.current,
      cpaDelta: concept.cpa,
      cpi: concept.cpi.current,
      cpiDelta: concept.cpi,
      ipm: concept.ipm.current,
      ipmDelta: concept.ipm,
      roas: concept.roas.current,
      roasDelta: concept.roas,
      ctr: concept.ctr.current,
      ctrDelta: concept.ctr,
      targetConversions: concept.targetConversions.current,
      targetConversionsDelta: concept.targetConversions,
      installs: concept.installs.current,
      installsDelta: concept.installs,
      clicks: concept.clicks.current,
      clicksDelta: concept.clicks,
    }));
  };

  const handleSetFilter = (prevState, val) => {
    if (!prevState) {
      return val;
    }
    return !arrayEquals(prevState, val) ? val : prevState;
  };

  const getBarChartOptions = () => {
    return barChartData.series.length !== 0
      ? {
          plotOptions: {
            bar: {
              distributed: false,
              dataLabels: {
                position: "top",
              },
            },
          },
          stroke: {
            colors: ["transparent"],
            width: 10,
          },
          legend: {
            show: true,
          },
          yaxis: [
            {
              title: {
                text: barChartData?.series[0].name,
              },
              ...yaxisContent(
                barChartData?.series[0].currency,
                Math.max(...(barChartData?.series[0].data || {})),
                selectedBarChartFirstMetric
              ),
            },
            {
              title: {
                text: barChartData?.series[1].name,
              },
              opposite: true,
              ...yaxisContent(
                barChartData?.series[1].currency,
                Math.max(...(barChartData?.series[1].data || {})),
                selectedBarChartSecondMetric
              ),
            },
          ],
          currencies: [
            barChartData?.series[0].currency,
            barChartData?.series[1].currency,
          ],
        }
      : {};
  };

  return (
    <MainTemplate
      isAllowed={user.isAllowReport(ReportTypes.creativeAnalysis)}
      title="Creative analysis"
      subtitle={
        <DropdownApp
          title="App name"
          handleSelected={handleSelectApp}
          disabled={
            loadingFetchFilters ||
            loadingTable ||
            loadingBarChart ||
            loadingTimelineChart
          }
        />
      }
      filters={[
        {
          title: "Concept",
          values: conceptFilterValues,
          handleSelected: (val) =>
            setSelectedConceptIds((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedConceptIds,
          multiple: true,
          toFilterValues: true,
          maxSelected: 5,
          getParam: "concepts",
          disabled:
            loadingFetchFilters ||
            loadingTable ||
            loadingBarChart ||
            loadingTimelineChart,
          loading: loadingFetchFilters,
        },
        {
          title: "Creative type",
          values: assetTypes,
          handleSelected: (val) =>
            setSelectedAssetTypes((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedAssetTypes,
          multiple: true,
          toFilterValues: true,
          getParam: "assetTypes",
          disabled:
            loadingFetchFilters ||
            loadingTable ||
            loadingBarChart ||
            loadingTimelineChart,
          loading: loadingFetchFilters,
        },
        {
          title: "Network",
          values: networks,
          handleSelected: (val) =>
            setSelectedNetworks((prevState) => handleSetFilter(prevState, val)),
          selectedValue: selectedNetworks,
          multiple: true,
          toFilterValues: true,
          getParam: "networks",
          disabled:
            loadingFetchFilters ||
            loadingTable ||
            loadingBarChart ||
            loadingTimelineChart,
          loading: loadingFetchFilters,
        },
        {
          title: "Store",
          values: appstores,
          handleSelected: (val) =>
            setSelectedAppstores((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedAppstores,
          multiple: true,
          toFilterValues: true,
          getParam: "appstores",
          disabled:
            loadingFetchFilters ||
            loadingTable ||
            loadingBarChart ||
            loadingTimelineChart,
          loading: loadingFetchFilters,
        },
        {
          title: "Country",
          values: countries,
          handleSelected: (val) =>
            setSelectedCountries((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedCountries,
          multiple: true,
          toFilterValues: true,
          getParam: "countries",
          disabled:
            loadingFetchFilters ||
            loadingTable ||
            loadingBarChart ||
            loadingTimelineChart,
          loading: loadingFetchFilters,
        },
      ]}
      datepicker={{
        onChange: ({ start, end }) => {
          setDateFrom(start.format(dateSendFormat));
          setDateTo(end.format(dateSendFormat));
        },
        disabled:
          loadingFetchFilters ||
          loadingTable ||
          loadingBarChart ||
          loadingTimelineChart,
        withContext: true,
        withQuery: true,
      }}
      creativePagesStyles
    >
      <Box className="block-group">
        <Box className="block-content">
          <Grid container direction="row" sx={{ marginBottom: "3.61rem" }}>
            <Grid item>
              <DropdownBigText
                leftSubtitle="Top concepts by"
                title="first metric"
                values={creativeAnalysisMetrics}
                selectedValue={selectedBarChartFirstMetric}
                handleSelected={(evt) =>
                  setSelectedBarChartFirstMetric(evt.target.value)
                }
                color={chartColors[0]}
              />
            </Grid>
            <Grid item>
              <DropdownBigText
                leftSubtitle="and"
                title="second metric"
                values={creativeAnalysisMetrics}
                selectedValue={selectedBarChartSecondMetric}
                handleSelected={(evt) =>
                  setSelectedBarChartSecondMetric(evt.target.value)
                }
                color={chartColors[1]}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={12}>
              <BarChart
                series={barChartData.series}
                categories={barChartData.labels}
                options={getBarChartOptions()}
                height={480}
                loading={loadingBarChart}
                selectedMetric={[
                  selectedBarChartFirstMetric,
                  selectedBarChartSecondMetric,
                ]}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className="block-content">
          <Grid item xs={12}>
            <GranularityLineChart
              title={
                <Grid item>
                  <DropdownBigText
                    leftSubtitle="concepts by"
                    rightSubtitle="timeline"
                    title="dimension"
                    values={creativeAnalysisMetrics}
                    selectedValue={selectedTimelineMetric}
                    handleSelected={(evt) =>
                      setSelectedTimelineMetric(evt.target.value)
                    }
                  />
                </Grid>
              }
              series={timelineChartData.series}
              options={{
                colors: [...chartColors].slice(2),
                yaxisTitle: {
                  text: creativeAnalysisMetrics[selectedTimelineMetric],
                },
              }}
              changeGranularity={(granularity) =>
                setSelectedGranularity(granularity)
              }
              selectedGranularity={selectedGranularity}
              dates={timelineChartData.labels}
              loading={loadingTimelineChart}
              currency={timelineChartData?.currency}
            />
          </Grid>
        </Box>
      </Box>

      <CTableWithInclusionColumns
        loading={loadingTable}
        rows={getConcepts()}
        settings={{
          columns: {
            conceptName: {
              title: () => "Concept",
              customConditions: getConceptEnums(),
              customConditionsCallback: (concept, val) =>
                val.indexOf(concept.conceptId.toString()) !== -1,
              includeArrayConditions: true,
              trim: true,
            },
          },
          default: {
            order: "desc",
            orderBy: "cost",
          },
        }}
        columnList={columnList()}
        defaultColumnsDisplayed={defaultColumnsDisplayed}
        handleChangeDisplayedColumns={(val) => {
          setDefaultColumnsDisplayed((prevState) => {
            prevState.filter((item) => val.includes(item));
            return prevState;
          });
        }}
        columns={columns}
      />
    </MainTemplate>
  );
};

CreativeAnalysisPageContent.propTypes = {
  orgId: PropTypes.number,
  selectedApp: PropTypes.object,
  handleSelectApp: PropTypes.func,
};

const CreativeAnalysisPage = () => {
  const params = useParams();
  const orgId = Number(params.orgId);
  const [key, setKey] = useState(new Date().getTime());
  const [selectedApp, setSelectedApp] = useState({
    appstoreAppId: null,
    appId: null,
  });

  const handleSelectApp = (val) => {
    setSelectedApp((prevState) => {
      if (
        (prevState.appstoreAppId !== null || prevState.appId !== null) &&
        JSON.stringify(prevState) !== JSON.stringify(val)
      ) {
        setKey(new Date().getTime());
      }
      return JSON.stringify(prevState) !== JSON.stringify(val)
        ? val
        : prevState;
    });
  };

  return (
    <CreativeAnalysisPageContent
      key={key}
      orgId={orgId}
      selectedApp={selectedApp}
      handleSelectApp={handleSelectApp}
    />
  );
};

export default CreativeAnalysisPage;
