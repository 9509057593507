import React, { useState } from "react";
import {
  TextField,
  Link,
  Box,
  IconButton,
  outlinedInputClasses,
} from "@mui/material";
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material/";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Preloader from "../Preloader";
import { useInformer } from "../../context/InformerProvider";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "baseline",
    position: "relative",
    "&:hover": {
      "& > svg": {
        color: theme.palette.primary.main,
      },
    },
  },
  editIcon: {
    fontSize: "1.3rem",
    position: "absolute",
    right: 0,
    transform: "translateX(calc(100% + 20px))",
    cursor: "pointer",
    color: theme.palette.inactive.main,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    right: 0,
    top: 10,
    transform: "translateX(calc(100% + 20px))",
  },
}));

const StyledTextField = styled(TextField)(() => ({
  [`.${outlinedInputClasses.input}`]: {
    fontSize: "0.9rem",
    fontWeight: 500,
    textAlign: "center",
    padding: "0.64rem",
  },
}));

const EditableTextField = ({ defaultValue, onConfirm, sx }) => {
  const classes = useStyles();
  const informer = useInformer();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [newValue, setNewValue] = useState(value);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (event) => {
    setError("");
    setNewValue(event.target.value);
  };

  const turnOnEditMode = () => setEditMode(true);
  const turnOffEditMode = () => {
    setEditMode(false);
    setError("");
  };

  const confirmChanges = async () => {
    setLoading(true);
    await onConfirm(newValue)
      .then((response) => {
        if (response.status === "error") {
          setError(response.msg);
        } else {
          setValue(newValue);
          turnOffEditMode();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.errors.length > 0) {
          let errorText = "";
          for (const error in err.response.data.errors) {
            errorText += err.response.data.errors[error] + "\n\r";
          }
          setError(errorText);
        } else if (err?.response?.data?.message) {
          setError(err?.response?.data?.message);
        } else {
          informer.showErrorNotice(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        maxWidth: "100%",
        ...sx,
      }}
    >
      {editMode ? (
        <Box className={classes.wrapper}>
          <StyledTextField
            error={error.length > 0}
            defaultValue={value}
            margin="none"
            onChange={handleChange}
            disabled={loading}
            helperText={error}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                confirmChanges();
              }
              if (e.keyCode === 27) {
                turnOffEditMode();
              }
            }}
          />
          <Box className={classes.actions}>
            {loading ? (
              <Preloader size={20} />
            ) : (
              <>
                <IconButton
                  color="success"
                  onClick={confirmChanges}
                  sx={{ padding: 0, marginRight: "0.33rem" }}
                >
                  <CheckIcon sx={{ fontSize: "1.3rem" }} />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={turnOffEditMode}
                  sx={{ padding: 0 }}
                >
                  <CloseIcon sx={{ fontSize: "1.3rem" }} />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box className={classes.wrapper} onClick={turnOnEditMode}>
          <Link href="#" sx={{ wordBreak: "break-all" }}>
            {value}
          </Link>
          <EditIcon className={classes.editIcon} />
        </Box>
      )}
    </Box>
  );
};

EditableTextField.propTypes = {
  defaultValue: PropTypes.string,
  onConfirm: PropTypes.func,
  sx: PropTypes.object,
};

export default EditableTextField;
