import React, { createContext, useContext, useState } from "react";
import { useInformer } from "./InformerProvider";
import adwizyApi from "../api/adwizyApi";
import { isDev, debug, getStateFromSetter } from "../utils";

export const EventContext = createContext();
export const useEvent = () => useContext(EventContext);

export const events = {
  userAppsListChanged: "user.appsList.changed",
  userOrgsListChanged: "user.orgsList.changed",
  userDeleteRequested: "user.delete.requested",
  orgSettingsChanged: "org.settings.changed",
  orgConnectionAdded: "org.connection.added",
  orgConnectionUpdated: "org.connection.updated",
  orgConnectionRemoved: "org.connection.removed",
  orgAccountEnabled: "org.account.enabled",
  orgAccountDisabled: "org.account.disabled",
  orgAppMetricsUpdated: "org.appMetrics.updated",
  orgAppRecsUpdated: "org.appRecs.updated",
  orgAppScoresUpdated: "org.appScores.updated",
  orgConceptAdded: "org.concept.added",
  orgConceptUpdated: "org.concept.updated",
  orgConceptRemoved: "org.concept.removed",
  orgConceptLinked: "org.concept.linked",
  orgConceptUnlinked: "org.concept.unlinked",
  orgAppAdded: "org.app.added",
  orgAppRemoved: "org.app.removed",
  orgBillingUpdated: "org.billing.updated",
  MMPConnectionAdded: "mmp.connection.added",
  MMPConnectionUpdated: "mmp.connection.updated",
  MMPConnectionRemoved: "mmp.connection.removed",
  MMPTargetUpdated: "mmp.target.updated",
  MMPTargetConversionsUpdated: "mmp.targetConversions.updated",
  NotificationsUpdated: "notifications.updated",
};

const EventProvider = (props) => {
  const informer = useInformer();
  const [subscribers] = useState({});
  const [timeouts] = useState({
    poll: null,
    reset: null,
  });
  const intervals = {
    poll: 10 * 1000,
    reset: 15 * 60 * 1000,
  };

  /**
   * @param {?Number} timeout
   */
  const stopTimeout = (timeout) => {
    clearTimeout(timeout);
    clearInterval(timeout);
  };

  /**
   * @param {Array} data
   */
  const notifySubscribers = (data) => {
    data = data.reduce((result, current) => {
      if (result[current.eventName]) {
        result[current.eventName].push(current);
      } else {
        result[current.eventName] = [current];
      }

      return result;
    }, {});

    Object.entries(data).forEach(([event, data]) => {
      if (Object.prototype.hasOwnProperty.call(subscribers, event)) {
        subscribers[event].forEach((subscriber) => {
          try {
            subscriber(data);
          } catch (err) {
            debug && console.log(event, err);
          }
        });
      }
    });
  };

  /**
   * @param {Function} setSelectedOrg
   * @param {Number} timestamp
   * @returns {Object}
   */
  const listen = (setSelectedOrg, timestamp) => {
    const resetListenInit = () => {
      stopTimeout(timeouts.reset);
      timeouts.reset = setTimeout(() => {
        stopTimeout(timeouts.poll);
        informer.showWarningNotice("Reload now to view updates", {
          reloadButton: true,
        });
      }, intervals.reset);
    };

    resetListenInit();
    if (!isDev) {
      timeouts.poll = setInterval(
        () =>
          adwizyApi
            .get(`/changes-history`, {
              orgId: getStateFromSetter(setSelectedOrg)?.orgId,
              timestamp,
            })
            .then((res) => {
              if (res.data.success) {
                resetListenInit();
                timestamp = res.data.data.lastCheckChanges;
                notifySubscribers(res.data.data.data);
              }
            }),
        intervals.poll
      );
    }

    return {
      stopListen() {
        stopTimeout(timeouts.reset);
        stopTimeout(timeouts.poll);
      },
    };
  };

  /**
   * @param {Function} func
   * @returns {Function}
   */
  const getSubscriptionClientHandler = (func) => (event, subscriber) => {
    if (!Object.values(events).includes(event)) {
      return new Error(`The event ${event} has no registered`);
    }

    if (typeof subscriber !== "function") {
      return new Error(`Subscriber of event ${event} must be a function`);
    }

    func(event, subscriber);

    return null;
  };

  /**
   * @param {String|Object} event
   * @param {Function} subscriber
   * @param {Function} clientHandler
   * @returns {Promise}
   */
  const subscriptionHandler = (event, subscriber, clientHandler) => {
    if (typeof event === "object") {
      const clients = Object.entries(event).map(([event, subscriber]) => ({
        event,
        subscriber,
      }));
      const errors = {};

      clients.forEach(({ event, subscriber }) => {
        let error = clientHandler(event, subscriber);
        if (error) {
          errors[event] = error;
        }
      });

      if (Object.keys(errors).length) {
        return Promise.reject(errors);
      }

      return Promise.resolve();
    }

    let error = clientHandler(event, subscriber);
    if (error) {
      return Promise.reject(error);
    }

    return Promise.resolve();
  };

  /**
   * @param {String|Object} event
   * @param {Function} subscriber
   * @returns {Promise}
   */
  const subscribe = (event, subscriber) => {
    const clientHandler = getSubscriptionClientHandler((event, subscriber) => {
      if (Object.prototype.hasOwnProperty.call(subscribers, event)) {
        subscribers[event].push(subscriber);
      } else {
        subscribers[event] = [subscriber];
      }
    });

    return subscriptionHandler(event, subscriber, clientHandler);
  };

  /**
   * @param {String|Object} event
   * @param {Function} subscriber
   * @returns {Promise}
   */
  const unsubscribe = (event, subscriber) => {
    const clientHandler = getSubscriptionClientHandler((event, subscriber) => {
      if (Object.prototype.hasOwnProperty.call(subscribers, event)) {
        let idx = subscribers[event].indexOf(subscriber);
        if (idx !== -1) {
          subscribers[event].splice(idx, 1);

          if (!subscribers[event].length) {
            delete subscribers[event];
          }
        }
      }
    });

    return subscriptionHandler(event, subscriber, clientHandler);
  };

  return (
    <EventContext.Provider
      value={{
        listen,
        subscribe,
        unsubscribe,
      }}
      {...props}
    />
  );
};

export default EventProvider;
