import React from "react";
import InfoPanel from "./InfoPanel";
import PropTypes from "prop-types";
import classnames from "classnames";

const InfoBar = ({ className, panels, loading, loadingCount }) => {
  const infoBarClass = classnames({
    info_bar: true,
    [className]: className,
  });

  return (
    <div className={infoBarClass}>
      {loading
        ? Array(loadingCount)
            .fill(1)
            .map((it, i) => <InfoPanel key={i} loading={loading} />)
        : panels && panels.map((it, i) => <InfoPanel key={i} {...it} />)}
    </div>
  );
};

InfoBar.propTypes = {
  className: PropTypes.string,
  panels: PropTypes.arrayOf(PropTypes.shape(InfoPanel.propTypes)),
  loading: PropTypes.bool,
  loadingCount: PropTypes.number,
};

InfoBar.defaultProps = {
  loading: false,
  loadingCount: 0,
};

export default InfoBar;
