export const AccessLevelsEnum = {
  manage: "manage",
  edit: "edit",
  view: "view",
};

export const AccessLevelPriorities = {
  [AccessLevelsEnum.view]: 1,
  [AccessLevelsEnum.edit]: 2,
  [AccessLevelsEnum.manage]: 3,
};

export const AccessEntity = {
  App: "App",
  Org: "Org",
  TokenAccount: "TokenAccount",
  MMP: "MMP",
};

export const isAccess = (requireAccessLevel, accessLevel) =>
  (AccessLevelPriorities[accessLevel] !== undefined
    ? AccessLevelPriorities[accessLevel]
    : 0) >= AccessLevelPriorities[requireAccessLevel];
