import React, { useState } from "react";
import AuthTemplate, {
  deepValidateEmail,
  generatePassword,
  validatePassword,
  validatePasswordRepeat,
} from "../../templates/AuthTemplate";
import PasswordInput from "../../components/AuthTemplate/PasswordInput";
import { Box, Button, FormControlLabel } from "@mui/material";
import CCheckbox from "../../components/common/CCheckbox";
import { useInformer } from "../../context/InformerProvider";
import { Link, useNavigate } from "react-router-dom";
import CInput from "../../components/AuthTemplate/CInput";
import adwizyApi from "../../api/adwizyApi";
import { isDev } from "../../utils";
import classnames from "classnames";
import ErrorFormHelper from "../../components/AuthTemplate/ErrorFormHelper";
import Preloader from "../../components/Preloader";

const SignUpPage = () => {
  const informer = useInformer();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [blockedEmails, setBlockedEmails] = useState([]);
  const [blockEmailError, setBlockEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordHasError, setPasswordHasError] = useState(false);
  const [passwordHelper, setPasswordHelper] = useState(null);
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordRepeatError, setPasswordRepeatError] = useState("");
  const [acceptEula, setAcceptEula] = useState(false);
  const [acceptEulaError, setAcceptEulaError] = useState("");

  const validateEmail = (email) =>
    deepValidateEmail(email, setEmailError, blockedEmails, blockEmailError);

  const validateAcceptEula = (acceptEula) => {
    acceptEula
      ? setAcceptEulaError("")
      : setAcceptEulaError("This field is required");
    return acceptEula;
  };

  const submit = async () => {
    let isOk = validatePassword(password, setPasswordHelper);
    setPasswordHasError(!isOk);
    isOk = validateEmail(email, setEmailError) && isOk;
    isOk =
      validatePasswordRepeat(
        password,
        passwordRepeat,
        setPasswordRepeatError
      ) && isOk;
    isOk = validateAcceptEula(acceptEula) && isOk;

    if (isOk) {
      if (isDev) {
        navigate("/auth/activate");
      } else {
        setLoading(true);

        await adwizyApi
          .post("/auth/sign-up", {
            email,
            password: generatePassword(password),
            isAcceptedEula: acceptEula,
          })
          .then((res) => {
            if (res.data.success) {
              navigate("/auth/activate");
            }
          })
          .catch((err) => {
            let emailError = err.response?.data?.errors?.email;
            if (emailError && !Array.isArray(emailError)) {
              setBlockEmailError(emailError);
              setBlockedEmails((prev) => [...prev, email]);
              deepValidateEmail(email, setEmailError, [email], emailError);
            } else {
              informer.showErrorNotice(err);
            }
          });

        setLoading(false);
      }
    }
  };

  return (
    <AuthTemplate>
      <div className="window">
        <div className="title">Sign up to Adwizy</div>
        <div className="input_group">
          <CInput
            id="email"
            label="Email"
            value={email}
            hasError={emailError !== ""}
            helperText={emailError}
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail(e.target.value, setEmailError);
            }}
            disabled={loading}
            submit={submit}
          />
          <PasswordInput
            id="password"
            value={password}
            hasError={passwordHasError}
            helperText={passwordHelper}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordHasError(
                !validatePassword(e.target.value, setPasswordHelper)
              );
              validatePasswordRepeat(
                e.target.value,
                passwordRepeat,
                setPasswordRepeatError
              );
            }}
            disabled={loading}
            submit={submit}
          />
          <PasswordInput
            id="password_repeat"
            label="Repeat password"
            value={passwordRepeat}
            hasError={passwordRepeatError !== ""}
            helperText={passwordRepeatError}
            onChange={(e) => {
              setPasswordRepeat(e.target.value);
              validatePasswordRepeat(
                password,
                e.target.value,
                setPasswordRepeatError
              );
            }}
            disabled={loading}
            submit={submit}
          />
        </div>
        <div
          className={classnames({
            choose_block: true,
            with_helper: acceptEulaError,
          })}
        >
          <FormControlLabel
            className="choose_block__checkbox"
            label={
              <div>
                Accept{" "}
                <Link to="/eula" target="_blank" rel="noreferrer noopener">
                  EULA
                </Link>
              </div>
            }
            control={
              <CCheckbox
                id="accepteula"
                checked={acceptEula}
                onChange={(e) => setAcceptEula(e.target.checked)}
                disabled={loading}
              />
            }
          />
          {acceptEulaError && (
            <ErrorFormHelper style={{ marginTop: 0 }}>
              {acceptEulaError}
            </ErrorFormHelper>
          )}
        </div>
        <div className="actions">
          <Button
            className="button"
            variant="contained"
            onClick={submit}
            color="primary"
            disabled={
              loading ||
              !email ||
              !!emailError ||
              !password ||
              passwordHasError ||
              !passwordRepeat ||
              !!passwordRepeatError ||
              !acceptEula
            }
          >
            Sign up
            {loading && (
              <Box display="flex" alignItems="center" marginLeft={1}>
                <Preloader size={30} />
              </Box>
            )}
          </Button>
        </div>
        <div className="alternative_offer">
          Have an account? <Link to="/auth/sign-in">Sign in</Link>
        </div>
      </div>
    </AuthTemplate>
  );
};

export default SignUpPage;
