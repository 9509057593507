import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useUser } from "../../context/UserProvider";
import DropdownFilter from "../common/DropdownFilter";
import { setInStorage } from "../../localStorageUtils";
import { useNavigate } from "react-router-dom";

const OrgSelect = () => {
  const user = useUser();
  const navigate = useNavigate();
  const [orgs, setOrgs] = useState(null);
  const [selectedOrgId, setSelectedOrgId] = useState(null);

  useEffect(() => {
    setOrgs(
      Object.fromEntries(user.orgs.map((org) => [org.orgId, org.orgName]))
    );
  }, [user.orgs]);

  useEffect(() => {
    if (user.selectedOrg) {
      setSelectedOrgId(user.selectedOrg.orgId);
    }
  }, [user.selectedOrg]);

  const handleSelectOrg = (orgId) => {
    const org = user.orgs.find((it) => Number(it.orgId) === Number(orgId));

    if (org) {
      user.setSelectedOrg(org);
      setInStorage("selectedOrg", orgId);
      navigate(`/org/${orgId}/organisation`);
    }
  };

  return (
    <>
      {user.orgs.length > 1 && selectedOrgId && (
        <Box width="200px">
          <DropdownFilter
            title="Organization"
            values={orgs}
            selectedValue={selectedOrgId}
            handleSelected={handleSelectOrg}
          />
        </Box>
      )}
    </>
  );
};

export default OrgSelect;
