import React, { createRef, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { PropTypes } from "prop-types";

const CScrollbars = ({
  content,
  vertical,
  horizontal,
  children,
  onChangeStatus,
  ...other
}) => {
  const it = createRef();
  const resizable = createRef();
  const [scrollbar, setScrollbar] = useState(false);

  // force rerender if scrollbar content height are changed
  useEffect(() => {
    const resizeContentListener = new ResizeObserver((entries) => {
      const windowHeight = it.current?.getClientHeight();
      const contentHeight = entries[0]?.contentRect?.height;
      let newStatus;

      if (windowHeight < contentHeight && !scrollbar) {
        newStatus = true;
      }

      if (windowHeight > contentHeight && scrollbar) {
        newStatus = false;
      }

      if (newStatus != null) {
        setScrollbar(newStatus);
        typeof onChangeStatus === "function" && onChangeStatus(newStatus);
      }
    });

    resizeContentListener.observe(resizable.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const prop = (data) => {
    return data
      ? ({ style, ...props }) => (
          <div {...props} {...data} style={{ ...style, ...data.style }} />
        )
      : undefined;
  };

  const localProps = {
    renderView: prop(content),
    renderTrackVertical: prop(vertical ? vertical.track : null),
    renderThumbVertical: prop(vertical ? vertical.thumb : null),
    renderTrackHorizontal: prop(horizontal ? horizontal.track : null),
    renderThumbHorizontal: prop(horizontal ? horizontal.thumb : null),
    ...other,
  };

  return (
    <Scrollbars ref={it} {...localProps}>
      <div ref={resizable} className="h-100">
        {children}
      </div>
    </Scrollbars>
  );
};

CScrollbars.propTypes = {
  content: PropTypes.object,
  vertical: PropTypes.object,
  horizontal: PropTypes.object,
  children: PropTypes.node.isRequired,
  onChangeStatus: PropTypes.func,
};

export default CScrollbars;
