import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import DropdownBigText from "./DropdownBigText";
import { useUser } from "../../context/UserProvider";
import Query from "../../helpers/query";
import { useNavigate } from "react-router-dom";
import { htmlDecode } from "../../utils";

const DropdownApp = ({
  title,
  leftSubtitle,
  rightSubtitle,
  handleSelected,
  color,
  fontSize,
  disabledNavigate,
  disabled,
}) => {
  const user = useUser();
  const query = new Query();
  const navigate = useNavigate();

  const [apps, setApps] = useState({});
  const [selectedApp, setSelectedApp] = useState(null);

  const handleSelectApp = (val) => {
    const res = {
      appstoreAppId: null,
      appId: null,
    };
    if (val.includes("appId:")) {
      res.appId = parseInt(val.replace("appId:", ""));
    }
    if (val.includes("appstoreAppId:")) {
      res.appstoreAppId = parseInt(val.replace("appstoreAppId:", ""));
    }
    setSelectedApp(val);
    handleSelected(res);
    user.setAppIdOfSelectedApp(val);

    if (disabledNavigate) {
      return;
    }

    if (
      !query.has("app") ||
      !query.has("start_date") ||
      !query.has("end_date")
    ) {
      query.update({ app: val });

      navigate({ search: query.asString() }, { replace: true });
    } else if (val !== query.get("app")) {
      navigate({ search: new URLSearchParams({ app: val }).toString() });
    }
  };

  useEffect(() => {
    if (user.apps) {
      const apps = Object.fromEntries(
        user.apps.map((app) => [app.appId, htmlDecode(app.appName)])
      );
      setApps(apps);

      if (
        query.has("app") &&
        Object.prototype.hasOwnProperty.call(apps, query.get("app"))
      ) {
        handleSelectApp(query.get("app"));
      } else if (
        user.appIdOfSelectedApp &&
        Object.prototype.hasOwnProperty.call(apps, user.appIdOfSelectedApp)
      ) {
        handleSelectApp(user.appIdOfSelectedApp);
      } else {
        handleSelectApp(Object.keys(apps)[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.apps,
    query.get("app"), // eslint-disable-line react-hooks/exhaustive-deps
    query.has("start_date"), // eslint-disable-line react-hooks/exhaustive-deps
    query.has("end_date"), // eslint-disable-line react-hooks/exhaustive-deps
  ]);

  return (
    <Box display="flex" alignItems="center">
      <DropdownBigText
        title={title}
        leftSubtitle={leftSubtitle}
        rightSubtitle={rightSubtitle}
        fontSize={fontSize}
        color={color}
        values={apps}
        selectedValue={selectedApp}
        handleSelected={(evt) => handleSelectApp(evt.target.value)}
        disabled={disabled}
      />
    </Box>
  );
};

DropdownApp.propTypes = {
  title: PropTypes.string.isRequired,
  rightSubtitle: PropTypes.string,
  leftSubtitle: PropTypes.string,
  handleSelected: PropTypes.func.isRequired,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  disabledNavigate: PropTypes.bool,
  disabled: PropTypes.bool,
};

DropdownApp.defaultProps = {
  fontSize: "1.66rem",
};

export default DropdownApp;
