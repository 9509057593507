import React from "react";
import { Box } from "@mui/material";
import Preloader from "../Preloader";
import ArrowMetric from "./ArrowMetric";
import { colors } from "../../utils";
import PropTypes from "prop-types";

const CampaignAdGroupMetrics = ({ title, data, loading }) => {
  return (
    <Box display="flex" justifyContent="space-evenly" marginBottom="60px">
      {loading ? (
        <Preloader />
      ) : (
        <>
          {data?.budgetUtilization != null && (
            <ArrowMetric
              value={data.budgetUtilization.value}
              title="Budget Utilization"
              end={1.5}
              settings={[
                {
                  to: 0.2,
                  color: colors.red,
                  comment: "The campaign has very low budget utilization rate",
                },
                {
                  to: 0.49,
                  color: colors.yellow,
                  comment: "The campaign has low budget utilization rate",
                },
                {
                  to: 0.93,
                  color: colors.green,
                  comment: "The campaign has optimal utilization rate",
                },
                {
                  to: 0.99,
                  color: colors.yellow,
                  comment: "The campaign is almost limited by budget",
                },
                {
                  to: 1,
                  color: colors.red,
                  comment: "The campaign is strictly limited by budget",
                },
              ]}
              hasLegend
              beforeLegend={
                <Box textAlign="center" marginBottom={2}>
                  Budget Utilization = {data.budgetUtilization.formula}
                </Box>
              }
            />
          )}
          {data?.campaignAimAccuracy != null && (
            <ArrowMetric
              value={data.campaignAimAccuracy.value}
              title={title}
              start={0.5}
              end={1.5}
              settings={[
                {
                  to: 1.04,
                  color: colors.green,
                  comment: "The campaign has optimal performance",
                },
                {
                  to: 1.09,
                  color: colors.yellow,
                  comment: "The campaign performance is above the target bid",
                },
                {
                  to: 1.1,
                  color: colors.red,
                  comment:
                    "The campaign performance is strictly above the target bid",
                },
              ]}
              hasLegend
              beforeLegend={
                <Box textAlign="center" marginBottom={2}>
                  {title} = {data.campaignAimAccuracy.formula}
                </Box>
              }
            />
          )}
        </>
      )}
    </Box>
  );
};

CampaignAdGroupMetrics.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({
    budgetUtilization: PropTypes.shape({
      formula: PropTypes.string.isRequired,
      value: PropTypes.number,
    }),
    campaignAimAccuracy: PropTypes.shape({
      formula: PropTypes.string.isRequired,
      value: PropTypes.number,
    }),
  }),
  loading: PropTypes.bool,
};

export default CampaignAdGroupMetrics;
