import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  dateRenderFormat,
  formatValue,
  moneyFormatter,
  percentFormatter,
} from "../../utils";
import CTooltip, { placements } from "./CTooltip";
import theme from "../../theme";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    boxShadow: "0px 26px 45px rgba(7, 34, 52, 0.05)",
  },
  tooltipContent: {
    minWidth: "16.67rem",
    fontSize: "0.9rem",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: 500,
  },
  dates: {
    color: theme.palette.inactive.main,
    marginRight: "0.5rem",
  },
  versusBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.borders.main,
    position: "relative",

    "& > span": {
      flexShrink: 0,
      padding: "0 0.44rem",
    },

    "&::after, &::before": {
      content: `''`,
      display: "block",
      width: "100%",
      height: 1,
      background: theme.palette.borders.main,
    },
  },
}));

const WoW = ({
  current,
  previous,
  currency,
  reverse,
  periods,
  tooltipClassName,
  label,
  placement,
}) => {
  const classes = useStyles();
  const [percentDifferent, setPercentDifferent] = useState(null);
  const [direction, setDirection] = useState(true);
  const [daysDiff, setDaysDiff] = useState(null);

  useEffect(() => {
    let different =
      previous > 0 ? previous && ((current - previous) / previous) * 100 : 0;

    setPercentDifferent(isNaN(different) ? 0 : different);
    setDirection(reverse ? different < 0 : different > 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let diff = periods.current.to.diff(periods.current.from, "days");
    setDaysDiff(diff + 1);
  }, [periods]);

  const preparedValue = (value) => {
    if (value === null || value === undefined) {
      return "—";
    }

    if (currency) {
      return moneyFormatter({ num: value, currency: currency, clipped: false });
    }

    return formatValue(value);
  };

  const getPercentDifferent = () => {
    return (
      <Box
        sx={{
          ...(percentDifferent !== 0 && {
            color: direction
              ? theme.palette.success.main
              : theme.palette.error.main,
          }),
          textDecorationLine: "underline",
        }}
      >
        {percentDifferent > 0 ? "+" : ""}
        {percentFormatter(percentDifferent)}
      </Box>
    );
  };

  return (
    <CTooltip
      tooltipClassName={tooltipClassName || classes.tooltip}
      content={
        <Box className={classes.tooltipContent}>
          <Box className={classes.row} sx={{ marginBottom: "1.17rem" }}>
            <Box className={classes.title}>Changes</Box>
            {getPercentDifferent()}
          </Box>
          <Box className={classes.row} sx={{ marginBottom: "5px" }}>
            <Box className={classes.dates}>
              {periods.current.from === periods.current.to ? (
                periods.current.from.format(dateRenderFormat)
              ) : (
                <>
                  {periods.current.from.format(dateRenderFormat)} -{" "}
                  {periods.current.to.format(dateRenderFormat)}
                </>
              )}
            </Box>
            <b>{preparedValue(current)}</b>
          </Box>
          <Box className={classes.versusBlock}>
            <Box component="span">
              vs previous {daysDiff} {daysDiff > 1 ? "days" : "day"}
            </Box>
          </Box>
          <Box className={classes.row} sx={{ marginTop: "5px" }}>
            <Box className={classes.dates}>
              {periods.previous.from === periods.previous.to ? (
                periods.previous.from.format(dateRenderFormat)
              ) : (
                <>
                  {periods.previous.from.format(dateRenderFormat)} -{" "}
                  {periods.previous.to.format(dateRenderFormat)}
                </>
              )}
            </Box>
            <b>{preparedValue(previous)}</b>
          </Box>
        </Box>
      }
      label={getPercentDifferent()}
      customLabel={label}
      placement={placement || placements.leftStart}
    />
  );
};

WoW.propTypes = {
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  previous: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  reverse: PropTypes.bool,
  periods: PropTypes.shape({
    previous: PropTypes.shape({
      from: PropTypes.instanceOf(moment),
      to: PropTypes.instanceOf(moment),
    }),
    current: PropTypes.shape({
      from: PropTypes.instanceOf(moment),
      to: PropTypes.instanceOf(moment),
    }),
  }),
  tooltipClassName: PropTypes.string,
  label: PropTypes.node,
  placement: CTooltip.propTypes.placement,
};

export default WoW;
