import React, { useState, createContext, useContext, useEffect } from "react";
import { isDev, sleep } from "../utils";
import adwizyApi from "../api/adwizyApi";
import {
  getFromStorage,
  removeFromStorage,
  setInStorage,
} from "../localStorageUtils";

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

const AuthProvider = (props) => {
  const [isAuthorized, setIsAuthorized] = useState(
    !!getFromStorage("authToken")
  );

  const signInLocal = (token) => {
    setIsAuthorized(true);
    setInStorage("authToken", token);
  };

  const signIn = (payload) => {
    if (isDev) {
      return sleep(400).then(() => signInLocal("simpleTokenValue"));
    } else {
      return adwizyApi.post("/auth/sign-in", payload).then((res) => {
        if (res.data.success) {
          signInLocal(res.data.data?.token);
        }
      });
    }
  };

  const signOutLocal = () => {
    setIsAuthorized(false);
    removeFromStorage("authToken");
  };

  const signOut = () => {
    if (isDev) {
      return sleep(200).then(signOutLocal);
    } else {
      return adwizyApi.post("/auth/sign-out").then((res) => {
        if (res.data.success) {
          signOutLocal();
        }
      });
    }
  };

  useEffect(() => {
    if (!isAuthorized) {
      const checkAuth = () =>
        setTimeout(() => {
          if (getFromStorage("authToken")) {
            setIsAuthorized(true);
          } else {
            checkAuth();
          }
        }, 10 * 1000);

      checkAuth();
    }
  }, [isAuthorized]);

  return (
    <AuthContext.Provider
      value={{
        isAuthorized,
        signInLocal,
        signIn,
        signOutLocal,
        signOut,
      }}
      {...props}
    />
  );
};

export default AuthProvider;
