import React, { useState } from "react";
import { Box } from "@mui/material";
import DropdownActions from "./DropdownActions";
import CButton from "./CButton";
import { Edit as EditIcon } from "@mui/icons-material/";
import CheckboxList from "./CheckboxList";
import CTable from "./CTable";
import PropTypes from "prop-types";

const CTableWithInclusionColumns = ({
  columns,
  columnList,
  defaultColumnsDisplayed,
  settings,
  handleChangeDisplayedColumns,
  ...other
}) => {
  const { columns: columnsSettings, ...otherSettings } = settings;
  const [displayedColumns, setDisplayedColumns] = useState(
    defaultColumnsDisplayed
  );
  const [selectedColumns, setSelectedColumns] = useState([...displayedColumns]);

  const handleChangeSelectedColumns = (val) => {
    if (!selectedColumns.includes(val)) {
      setSelectedColumns((prevState) => [...prevState, val]);
    } else {
      const index = selectedColumns.indexOf(val);
      if (index !== -1) {
        selectedColumns.splice(index, 1);
      }
      setSelectedColumns([...selectedColumns]);
    }
  };

  const getColumns = () => {
    return Object.fromEntries(
      Object.keys(columns)
        .filter((column) =>
          displayedColumns.includes(column.replace("Delta", ""))
        )
        .map((column) => [column, columns[column]])
    );
  };

  return (
    <CTable
      {...other}
      settings={{
        columns: {
          ...columnsSettings,
          ...getColumns(),
        },
        ...otherSettings,
      }}
      topArea2={
        <DropdownActions
          title="Select metrics"
          btn={
            <CButton
              variant="contained"
              sx={{ margin: "0 0 1.61rem auto", display: "flex" }}
            >
              <EditIcon sx={{ marginRight: "0.44rem", fontSize: "1.3rem" }} />
              Add metrics
            </CButton>
          }
          handleOk={() => {
            setDisplayedColumns([...selectedColumns]);
            handleChangeDisplayedColumns([...selectedColumns]);
          }}
          handleCancel={() => setSelectedColumns([...displayedColumns])}
        >
          <Box sx={{ padding: "0 1.61rem" }}>
            <CheckboxList
              list={columnList}
              checkedCallback={(val) => selectedColumns.includes(val)}
              handleChange={handleChangeSelectedColumns}
            />
          </Box>
        </DropdownActions>
      }
    />
  );
};

CTableWithInclusionColumns.propTypes = {
  ...CTable.propTypes,
  columns: PropTypes.object,
  columnList: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  defaultColumnsDisplayed: PropTypes.array,
  settings: PropTypes.object,
  handleChangeDisplayedColumns: PropTypes.func,
};

export default CTableWithInclusionColumns;
