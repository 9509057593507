import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import DonutChart from "./DonutChart";
import BarChart from "./BarChart";
import DropdownBigText from "../common/DropdownBigText";
import GranularityLineChart, { granularity } from "./GranularityLineChart";
import { isDev } from "../../utils";
import adwizyApi from "../../api/adwizyApi";
import { AppstoreTypesMapping } from "../../enum/AppstoreTypes";
import { DimensionsEnum } from "../../enum/Dimensions";
import { getTitleOfNetwork } from "../../enum/CampaignNetworks";

const ReportChartsBlock = ({
  dimensions,
  metrics,
  urlRequest,
  orgId,
  filterId,
  forceConversion,
  handleError,
  metricsThatExcludeDonutChart,
  loading,
  handleChangeLoading,
  style,
}) => {
  const [loadingTopCharts, setLoadingTopCharts] = useState(true);
  const [loadingTimeline, setLoadingTimeline] = useState(true);
  const [selectedDimension, setSelectedDimension] = useState(
    Object.keys(dimensions)[0]
  );
  const [selectedMetric, setSelectedMetric] = useState(Object.keys(metrics)[0]);
  const [selectedGranularity, setSelectedGranularity] = useState(
    Object.keys(granularity)[0]
  );
  const [data, setData] = useState({
    labels: [],
    series: [],
  });
  const [dataSummarizedSeries, setDataSummarizedSeries] = useState([]);
  const [dataSummarizedCategories, setDataSummarizedCategories] = useState([]);

  const fetchData = async () => {
    if (!isDev) {
      await adwizyApi
        .get(urlRequest, {
          orgId: orgId,
          filterId: filterId,
          dimension: selectedDimension,
          metric: selectedMetric,
          granularity: selectedGranularity,
          forceConversion: forceConversion,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            const chartData = data.data;
            const sumData = data.data.sumData;
            const sortable = Object.entries(sumData)
              .sort(([, a], [, b]) => b - a)
              .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
            const summarizedCategories = Object.keys(sortable);
            const summarizedSeries = Object.values(sortable);

            const series = [];
            const categories = [];

            for (const dimension of summarizedCategories) {
              const index = chartData.series.findIndex(
                (el) => el.name === dimension
              );

              if (index === -1) {
                continue;
              }

              let seriesName = dimension;
              if (selectedDimension === DimensionsEnum.network) {
                seriesName = getTitleOfNetwork(seriesName);
              }
              if (selectedDimension === DimensionsEnum.appstore) {
                seriesName = AppstoreTypesMapping[seriesName];
              }
              categories.push(seriesName);

              chartData.series[index].name = seriesName;
              series.push(chartData.series[index]);
            }

            setDataSummarizedCategories(categories);
            setDataSummarizedSeries(summarizedSeries);
            setData({
              labels: chartData.labels,
              series: series,
              currency: chartData?.currency,
            });
          } else {
            setData({
              labels: [],
              series: [],
              currency: null,
            });
            setDataSummarizedCategories([]);
            setDataSummarizedSeries([]);
          }
        })
        .catch(handleError);
    }
    setLoadingTopCharts(false);
    setLoadingTimeline(false);
    handleChangeLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (filterId) {
      setLoadingTopCharts(true);
      setLoadingTimeline(true);
      fetchData();
    }
  }, [filterId, forceConversion, selectedDimension, selectedMetric]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (filterId) {
      setLoadingTimeline(true);
      fetchData();
    }
  }, [selectedGranularity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loading === true || loading === false) {
      setLoadingTopCharts(loading);
      setLoadingTimeline(loading);
    }
  }, [loading]);

  return (
    <Box className="block-group" style={style}>
      <Box className="block-content">
        <Grid container direction="row" sx={{ marginBottom: "3.61rem" }}>
          <Grid item>
            <DropdownBigText
              disabled={loadingTopCharts}
              leftSubtitle="Top"
              title="dimension"
              values={dimensions}
              selectedValue={selectedDimension}
              handleSelected={(evt) => setSelectedDimension(evt.target.value)}
            />
          </Grid>
          <Grid item>
            <DropdownBigText
              disabled={loadingTopCharts}
              leftSubtitle="by"
              title="metric"
              values={metrics}
              selectedValue={selectedMetric}
              handleSelected={(evt) => setSelectedMetric(evt.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={10}>
          {metricsThatExcludeDonutChart.includes(selectedMetric) ? (
            <Grid item xs={12}>
              <BarChart
                series={[{ data: dataSummarizedSeries }]}
                categories={dataSummarizedCategories}
                options={{
                  yaxisTitle: {
                    text: metrics[selectedMetric],
                  },
                }}
                currency={data?.currency}
                loading={loadingTopCharts}
                selectedMetric={selectedMetric}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={5.5}>
                <BarChart
                  series={[{ data: dataSummarizedSeries }]}
                  categories={dataSummarizedCategories}
                  options={{
                    yaxisTitle: {
                      text: metrics[selectedMetric],
                    },
                  }}
                  currency={data?.currency}
                  loading={loadingTopCharts}
                  selectedMetric={selectedMetric}
                />
              </Grid>
              <Grid item xs={6.5}>
                <DonutChart
                  series={dataSummarizedSeries}
                  categories={dataSummarizedCategories}
                  currency={data?.currency}
                  loading={loadingTopCharts}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box className="block-content">
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <GranularityLineChart
              series={data.series}
              dates={data.labels}
              currency={data?.currency}
              changeGranularity={(granularity) =>
                setSelectedGranularity(granularity)
              }
              selectedGranularity={selectedGranularity}
              dropdownRightSubtitle={`${metrics[selectedMetric]} by ${dimensions[selectedDimension]} timeline`}
              options={{
                yaxisTitle: {
                  text: metrics[selectedMetric],
                },
              }}
              includeTypeSelector
              loading={loadingTimeline}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

ReportChartsBlock.propTypes = {
  dimensions: PropTypes.object.isRequired,
  metrics: PropTypes.object.isRequired,
  urlRequest: PropTypes.string.isRequired,
  orgId: PropTypes.number,
  filterId: PropTypes.string,
  forceConversion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleError: PropTypes.func,
  metricsThatExcludeDonutChart: PropTypes.array,
  loading: PropTypes.bool,
  handleChangeLoading: PropTypes.func,
  style: PropTypes.object,
};

ReportChartsBlock.defaultProps = {
  metricsThatExcludeDonutChart: ["cpi", "cpa", "ipm", "roas", "ctr"],
};

export default ReportChartsBlock;
