import React, { useEffect, useState } from "react";
import AuthTemplate from "../templates/AuthTemplate";
import { Box, Button } from "@mui/material";
import { useInformer } from "../context/InformerProvider";
import GoBack from "../components/common/GoBack";
import { PaymentMethods } from "../enum/PaymentMethodsEnum";
import adwizyApi from "../api/adwizyApi";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../context/UserProvider";
import CButton from "../components/common/CButton";
import Query from "../helpers/query";

const PaymentMethodsPage = () => {
  const user = useUser();
  const navigate = useNavigate();
  const query = new Query();
  const params = useParams();
  const orgId = Number(params.orgId);
  const tariffCode = params.tariffCode;
  const informer = useInformer();
  const [loading, setLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [billingSubscriptionId, setBillingSubscriptionId] = useState(null);

  useEffect(() => {
    if (query.has("billingSubscriptionId")) {
      setBillingSubscriptionId(parseInt(query.get("billingSubscriptionId")));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (paymentMethod) => {
    setSelectedPaymentMethod(
      paymentMethod !== selectedPaymentMethod ? paymentMethod : null
    );
  };

  const submit = async () => {
    setLoading(true);
    await adwizyApi
      .post(`/billing/${orgId}/plan`, {
        paymentMethod: selectedPaymentMethod,
        tariffCode: tariffCode,
        billingSubscriptionId: billingSubscriptionId,
      })
      .then(async (res) => {
        if (res.data.success) {
          await user.fetchOrgs();
          await user.fetchACL();
          user.setSubscriptionStatusData({
            tariffCode: tariffCode,
            billingSubscriptionId: billingSubscriptionId,
            paymentMethod: selectedPaymentMethod,
          });
          navigate(`/org/${orgId}/subscription-status`);
        } else {
          informer.showErrorNotice(res.data.message);
        }
      });
  };

  return (
    <AuthTemplate>
      <div className="window">
        <GoBack />
        <div className="connection-dialog__content">
          <div className="title">Select a payment method</div>
          <Box
            className="connection-dialog__network-container"
            sx={{
              mb: "1.56rem",
            }}
          >
            {Object.keys(PaymentMethods).map((paymentMethod, i) => (
              <Button
                key={i}
                classes={{
                  root:
                    "connection-dialog__network-btn " +
                    (selectedPaymentMethod === paymentMethod ? "active" : ""),
                }}
                onClick={() => handleChange(paymentMethod)}
                title={PaymentMethods[paymentMethod].title}
              >
                <div className="d-flex align-items-center">
                  {React.createElement(PaymentMethods[paymentMethod].icon, {})}
                  <Box marginLeft={2} width="max-content">
                    {PaymentMethods[paymentMethod].title}
                  </Box>
                </div>
              </Button>
            ))}
          </Box>
        </div>
        <div className="text-center">
          <CButton
            className="button f-w-700"
            variant="contained"
            color="primary"
            disabled={selectedPaymentMethod === null || loading}
            onClick={submit}
          >
            Confirm
          </CButton>
        </div>
      </div>
    </AuthTemplate>
  );
};

export default PaymentMethodsPage;
