import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import CTooltip, { placements } from "../common/CTooltip";
import { informTypes } from "../../utils";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: "none !important",
  },
  list: {
    padding: "0 0 0 0.5em",
    margin: 0,
  },
  listItem: {
    marginBottom: "0.5em",

    "&:last-of-type": {
      marginBottom: 0,
    },
  },
}));

const CampaignList = ({ values, appId, orgId }) => {
  const classes = useStyles();
  const campaigns = Object.keys(values);

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <CTooltip
        tooltipClassName={classes.tooltip}
        content={
          <ol className={classes.list}>
            {campaigns.map((campaignId, i) => (
              <li key={i} className={classes.listItem}>
                <Link
                  to={`/org/${orgId}/reporting/app/${appId}/campaign/${campaignId}`}
                  className="link"
                >
                  {values[campaignId]}
                </Link>
              </li>
            ))}
          </ol>
        }
        label={
          <Box className="link">
            {campaigns.length} {campaigns.length > 1 ? "campaigns" : "campaign"}
          </Box>
        }
        placement={placements.top}
        type={informTypes.info}
      />
    </Box>
  );
};

CampaignList.propTypes = {
  values: PropTypes.object,
  appId: PropTypes.string,
  orgId: PropTypes.number,
};

export default CampaignList;
