import React from "react";
import PropTypes from "prop-types";
import FacebookIcon from "../icons/FacebookIcon";
import { CampaignNetworksEnum } from "../../enum/CampaignNetworks";

const NetworkMiniIcon = ({ network, color, sx }) => {
  if (network === CampaignNetworksEnum.facebookAds) {
    return (
      <FacebookIcon
        color={color}
        sx={{
          ...{
            width: "1.1rem",
            height: "1.1rem",
          },
          ...sx,
        }}
      />
    );
  }

  if (network === CampaignNetworksEnum.tiktokAds) {
    //todo
    return null;
  }
};

NetworkMiniIcon.propTypes = {
  network: PropTypes.oneOf(Object.values(CampaignNetworksEnum)),
  color: PropTypes.string,
  sx: PropTypes.object,
};

export default NetworkMiniIcon;
