import React, { useState } from "react";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material/";
import CTooltip from "../common/CTooltip";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../theme";
import Preloader from "../Preloader";

const useStyles = makeStyles((theme) => ({
  visibilityIcon: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  tooltip: {
    maxWidth: "23.33rem !important",
    background: "#FFFFFF",
    border: `1px solid ${theme.palette.borders.main}`,
    borderRadius: 5,
    padding: "1.11rem",
    filter: "drop-shadow(0px 26px 45px rgba(7, 34, 52, 0.05))",
  },
  content: {
    width: "21.11rem !important",
  },
  img: {
    maxHeight: "13rem",
    width: "100%",
    objectFit: "cover",
  },
  imgWrapper: {
    position: "relative",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&::before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      top: 0,
      left: 0,
    },
  },
  playIcon: {
    color: "#fff",
    position: "absolute",
    fontSize: "3.7rem",
  },
}));

const assetTypeEnum = {
  text: "text",
  video: "video",
  image: "image",
  html5: "html5",
};

const PreviewContent = ({ assetType, assetUrl, previewUrl, loading }) => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      {assetType === assetTypeEnum.image && (
        <a
          href={assetUrl}
          className={classes.imgWrapper}
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={assetUrl} className={classes.img} alt="Preview" />
        </a>
      )}
      {assetType === assetTypeEnum.video && (
        <a
          href={assetUrl}
          className={classes.imgWrapper}
          target="_blank"
          rel="noreferrer noopener"
        >
          <PlayArrowIcon className={classes.playIcon} />
          <img src={previewUrl} className={classes.img} alt="Video preview" />
        </a>
      )}
      {loading && <Preloader size={32} />}
    </Box>
  );
};

PreviewContent.propTypes = {
  assetType: PropTypes.oneOf(Object.values(assetTypeEnum)),
  assetUrl: PropTypes.string,
  previewUrl: PropTypes.string,
  loading: PropTypes.bool,
};

const PreviewContentAsset = ({
  assetId,
  assetType,
  assetUrl,
  previewUrl,
  isTemporalPreview,
  handleTemporalAssetUrl,
  isWaitingForResponse,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleOpen = async (assetId) => {
    setLoading(true);
    if (isWaitingForResponse) {
      return;
    }
    await handleTemporalAssetUrl(assetId);
    setLoading(false);
  };

  return (
    <>
      {assetType !== assetTypeEnum.text && (
        <div className={"d-flex align-items-center"}>
          {((assetUrl || previewUrl) && (
            <CTooltip
              content={
                <PreviewContent
                  assetType={assetType}
                  assetUrl={assetUrl || previewUrl}
                  previewUrl={previewUrl || assetUrl}
                />
              }
              label={<VisibilityIcon className={classes.visibilityIcon} />}
              tooltipClassName={classes.tooltip}
              labelClassName="d-flex align-items-center"
            />
          )) ||
            (isTemporalPreview && (
              <CTooltip
                onOpen={() => handleOpen(assetId)}
                content={<PreviewContent loading={loading} />}
                label={<VisibilityIcon className={classes.visibilityIcon} />}
                tooltipClassName={classes.tooltip}
                labelClassName="d-flex align-items-center"
              />
            )) || (
              <CTooltip
                content="Preview is not available for this asset."
                label={
                  <VisibilityOffIcon
                    sx={{
                      color: theme.palette.borders.main,
                      cursor: "pointer",
                    }}
                  />
                }
                tooltipClassName={classes.tooltip}
                labelClassName="d-flex align-items-center"
              />
            )}
        </div>
      )}
    </>
  );
};

PreviewContentAsset.propTypes = {
  assetId: PropTypes.number.isRequired,
  assetUrl: PropTypes.string,
  previewUrl: PropTypes.string,
  assetType: PropTypes.oneOf(Object.values(assetTypeEnum)).isRequired,
  isTemporalPreview: PropTypes.bool.isRequired,
  handleTemporalAssetUrl: PropTypes.func,
  isWaitingForResponse: PropTypes.bool,
};

export default PreviewContentAsset;
