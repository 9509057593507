import React from "react";
import PropTypes from "prop-types";
import { N10nPrioritiesEnum } from "../../enum/N10nPriorities";
import { InfoOutlined, ErrorOutline } from "@mui/icons-material";

const NotificationType = ({ /*category,*/ priority }) => (
  <div className="priority-icon">
    {priority === N10nPrioritiesEnum.warning && (
      <InfoOutlined color="warning" />
    )}
    {priority === N10nPrioritiesEnum.critical && <ErrorOutline color="error" />}
  </div>
);

NotificationType.propTypes = {
  category: PropTypes.string.isRequired,
  priority: PropTypes.oneOf(Object.values(N10nPrioritiesEnum)),
};

export default NotificationType;
