import React from "react";
import { Route, Routes } from "react-router-dom";
import ConceptMappingPage from "../pages/main/creatives/ConceptMappingPage";
import CreativeAnalysisPage from "../pages/main/creatives/CreativeAnalysisPage";
import AssetInsightsPage from "../pages/main/creatives/AssetInsightsPage";
import ConceptSettingsPage from "../pages/main/creatives/ConceptSettingsPage";

const CreativeRoutes = () => (
  <Routes>
    <Route path="/concept-mapping" element={<ConceptMappingPage />} />
    <Route path="/creative-analysis" element={<CreativeAnalysisPage />} />
    <Route path="/asset-insights" element={<AssetInsightsPage />} />
    <Route path="/concept-settings" element={<ConceptSettingsPage />} />
  </Routes>
);

export default CreativeRoutes;
