import React from "react";
import MainTemplate from "../../templates/MainTemplate";
import Notifications from "../../components/Notification/Notifications";

const NotificationsPage = () => {
  return (
    <MainTemplate title="Notifications">
      <div className="content_block">
        <Notifications />
      </div>
    </MainTemplate>
  );
};

export default NotificationsPage;
