import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  FormControl,
  TextField,
  InputAdornment,
  FormHelperText,
  Box,
  outlinedInputClasses,
} from "@mui/material";
import PropTypes from "prop-types";
import CSkeleton from "../common/CSkeleton";
import SearchIcon from "../icons/SearchIcon";
import { makeStyles } from "@mui/styles";
import CSelect from "../common/CSelect";
import CMenuItem from "../common/CMenuItem";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material/";

const useStyles = makeStyles(() => ({
  tableCell: {
    padding: ".94rem .55rem !important",
    lineHeight: "1 !important",
  },
}));

export const defaultConvWindowsValue = 14;

const MMPTargetConversionList = ({
  complexAppId,
  rows,
  modifyRowsHandler,
  setErrorHandler,
  considerHasConversionsValue,
  errorMsg,
  loading,
}) => {
  const classes = useStyles();
  const [selectedAll, setSelectedAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [rowKeys, setRowKeys] = useState([]);

  const convWindowValues = {
    1: "1 day",
    3: "3 days",
    5: "5 days",
    7: "7 days",
    14: "14 days",
    28: "28 days",
    30: "30 days",
    60: "60 days",
    90: "90 days",
  };

  const handleSearch = (evt) => {
    setSearchQuery(evt.target.value);
  };

  const handleSelectConversion = (key) => {
    modifyRowsHandler((prev) => {
      prev[key].isChecked = !prev[key].isChecked;
      return { ...prev };
    });
  };

  const handleSelectConvWindow = (key, newValue) => {
    modifyRowsHandler((prev) => {
      prev[key].conversionWindow = parseInt(newValue);
      return { ...prev };
    });
  };

  const handleSelectAll = () => {
    modifyRowsHandler((prev) => {
      Object.keys(prev).forEach((key) => {
        if (rowKeys.includes(key)) {
          prev[key].isChecked = !selectedAll;
        }
      });
      return { ...prev };
    });
    setSelectedAll(!selectedAll);
  };

  useEffect(() => {
    let error = false;
    const res = Object.keys(rows).filter((key) => {
      const row = rows[key];
      const mmpEventName = row.mmpEventName;
      const hasConversionsValue = row.hasConversionsValue;
      const isChecked = row.isChecked;

      if (searchQuery) {
        return mmpEventName.toLowerCase().includes(searchQuery.toLowerCase());
      } else if (considerHasConversionsValue) {
        if (!error) {
          error = !hasConversionsValue && isChecked;
        }
        return hasConversionsValue || isChecked;
      }

      return true;
    });
    setRowKeys(res);
    setErrorHandler(error);
  }, [rows, considerHasConversionsValue, searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedAll(false);
  }, [searchQuery]);

  return (
    <>
      {loading ? (
        <>
          <CSkeleton style={{ marginBottom: "1rem" }} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ marginBottom: "0.5em" }}>
                  <TableCell colSpan={3}>
                    <CSkeleton />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ marginBottom: "0.5em" }}>
                  <TableCell>
                    <CSkeleton />
                  </TableCell>
                  <TableCell>
                    <CSkeleton />
                  </TableCell>
                  <TableCell>
                    <CSkeleton />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ marginBottom: "0.5em" }}>
                  <TableCell>
                    <CSkeleton />
                  </TableCell>
                  <TableCell>
                    <CSkeleton />
                  </TableCell>
                  <TableCell>
                    <CSkeleton />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ marginBottom: "0.5em" }}>
                  <TableCell>
                    <CSkeleton />
                  </TableCell>
                  <TableCell>
                    <CSkeleton />
                  </TableCell>
                  <TableCell>
                    <CSkeleton />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <FormControl sx={{ mb: "1rem" }} fullWidth>
            <div className="text-field__label">Target action</div>

            <TextField
              fullWidth
              variant="outlined"
              margin="none"
              inputProps={{
                sx: { padding: "0.51rem 0.78rem" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ width: "1.07rem", height: "1.07rem" }} />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </FormControl>

          <TableContainer sx={{ maxHeight: "19.27rem" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" sx={{ width: "1rem" }}>
                    <Checkbox
                      className="p-0 checkbox"
                      checked={selectedAll}
                      onClick={handleSelectAll}
                      id={`select_all_${complexAppId}`}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <label
                      htmlFor={`select_all_${complexAppId}`}
                      className="m-0 f-w-700 cursor-pointer"
                    >
                      Conversion name
                    </label>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    sx={{ width: "8rem" }}
                  >
                    <p className="m-0 f-w-700">Conversion window</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowKeys.length === 0 && (
                  <TableRow>
                    <TableCell
                      className={classes.tableCell}
                      colSpan={3}
                      align="center"
                    >
                      {searchQuery
                        ? "No conversions matched specified search"
                        : "No conversions with value received pings since connection."}
                    </TableCell>
                  </TableRow>
                )}
                {rowKeys.map((key, index) => {
                  const row = rows[key];

                  return (
                    <TableRow key={index}>
                      <TableCell padding="checkbox" sx={{ width: "1rem" }}>
                        <Checkbox
                          className="p-0 checkbox"
                          checked={row.isChecked}
                          onClick={() => handleSelectConversion(key)}
                          id={`conversion_${key}_${complexAppId}`}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div className="d-flex flex-direction-column">
                          <label
                            htmlFor={`conversion_${key}_${complexAppId}`}
                            className="m-0 cursor-pointer"
                          >
                            {row.mmpEventName}
                          </label>

                          {considerHasConversionsValue &&
                            !row.hasConversionsValue && (
                              <FormHelperText error={true}>
                                Incompatible with selected optimization
                              </FormHelperText>
                            )}
                        </div>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: "8rem" }}
                      >
                        <CSelect
                          fullWidth
                          value={
                            row.conversionWindow ?? defaultConvWindowsValue
                          }
                          IconComponent={(props) => (
                            <KeyboardArrowDownIcon {...props} />
                          )}
                          sx={{
                            [`& .${outlinedInputClasses.input}`]: {
                              padding: ".17rem 1.22rem !important",
                            },
                          }}
                          MenuProps={{
                            classes: {
                              paper: "dropdown-paper dropdown-paper-mini",
                            },
                          }}
                          onChange={(e) =>
                            handleSelectConvWindow(key, e.target.value)
                          }
                        >
                          {Object.keys(convWindowValues).map((value, index) => (
                            <CMenuItem key={index} value={value}>
                              <Box sx={{ color: "hover.main" }}>
                                {convWindowValues[value]}
                              </Box>
                            </CMenuItem>
                          ))}
                        </CSelect>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <FormHelperText error={true}>{errorMsg}</FormHelperText>
        </>
      )}
    </>
  );
};

MMPTargetConversionList.propTypes = {
  complexAppId: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  modifyRowsHandler: PropTypes.func.isRequired,
  setErrorHandler: PropTypes.func.isRequired,
  considerHasConversionsValue: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
  loading: PropTypes.bool,
};

export default MMPTargetConversionList;
