import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  MMPConnectionStatusesEnum,
  MMPConnectionStatusesMapping,
} from "../../enum/MMPConnectionStatuses";
import { MMPServices } from "../../enum/MMPServices";
import { useUser } from "../../context/UserProvider";
import { Box, Button } from "@mui/material";
import {
  Error as ErrorIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material/";
import DropdownMenu from "../common/DropdownMenu";
import { htmlDecode } from "../../utils";
import CDialog from "../common/CDialog";
import adwizyApi from "../../api/adwizyApi";
import { useInformer } from "../../context/InformerProvider";
import MMPInstructionDialog from "../MMP/MMPInstructionDialog";

const AppMMPStatusDropdownMenu = ({
  appstoreAppId,
  orgId,
  appName,
  MMPConnectionStatus,
  MMPServiceName,
  afterDeleteConnectionCb,
  btn,
  autoWidthPaper,
}) => {
  const user = useUser();
  const informer = useInformer();
  const [loading, setLoading] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [openDisableDialog, setOpenDisableDialog] = useState(false);
  const [openInstructionDialog, setOpenInstructionDialog] = useState(false);

  const deleteConnection = async () => {
    setLoading(true);
    await adwizyApi
      .delete("/mmp/connection", {
        orgId: orgId,
        appstoreAppId: appstoreAppId,
      })
      .then(async (res) => {
        if (res.data.success) {
          informer.showSuccessNotice(res.data.message);
        } else {
          informer.showErrorNotice(res.data.message);
        }
      })
      .catch(informer.showErrorNotice);

    await user.userAppsListChanged().finally(() => setLoading(false));

    if (typeof afterDeleteConnectionCb === "function") {
      afterDeleteConnectionCb();
    }
  };

  useEffect(() => {
    const res = [];

    if (MMPConnectionStatus === MMPConnectionStatusesEnum.Pending) {
      res.push(
        <Box className="w-100" onClick={() => setOpenInstructionDialog(true)}>
          Instruction
        </Box>
      );
    }
    if (appstoreAppId && MMPConnectionStatus !== null) {
      res.push(
        <Box
          className="w-100"
          color="error.main"
          onClick={() => setOpenDisableDialog(true)}
        >
          Disable
        </Box>
      );
    }

    setMenuItems(res);
  }, [MMPConnectionStatus, appstoreAppId]);

  return (
    menuItems.length > 0 && (
      <>
        <DropdownMenu
          btn={
            btn ? (
              btn
            ) : (
              <Button
                variant="contained"
                disableElevation
                startIcon={
                  MMPConnectionStatus ===
                  MMPConnectionStatusesEnum.Disconnected ? (
                    <ErrorIcon />
                  ) : null
                }
                endIcon={<KeyboardArrowDownIcon sx={{ color: "#fff" }} />}
                className="text-transform-initial round-btn"
                color={(() => {
                  if (
                    MMPConnectionStatus === MMPConnectionStatusesEnum.Connected
                  ) {
                    return "success";
                  }
                  if (
                    MMPConnectionStatus ===
                    MMPConnectionStatusesEnum.Disconnected
                  ) {
                    return "error";
                  }
                  return "warning";
                })()}
              >
                {MMPConnectionStatusesMapping[MMPConnectionStatus]}
              </Button>
            )
          }
          items={menuItems}
          autoWidthPaper={autoWidthPaper}
        />

        <CDialog
          open={openDisableDialog}
          title="Are you sure?"
          content={`Are you sure you want to disable MMP for ${htmlDecode(
            appName
          )}?`}
          cancelBtnText="No"
          applyBtnText="Yes"
          applyBtnCb={deleteConnection}
          applyBtnStyle={{
            color: "error",
            variant: "outlined",
          }}
          loading={loading}
          onClose={() => setOpenDisableDialog(false)}
        />

        <MMPInstructionDialog
          open={openInstructionDialog}
          service={MMPServiceName}
          onClose={() => setOpenInstructionDialog(false)}
        />
      </>
    )
  );
};

AppMMPStatusDropdownMenu.propTypes = {
  appstoreAppId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  appName: PropTypes.string.isRequired,
  MMPConnectionStatus: PropTypes.oneOf(
    Object.values(MMPConnectionStatusesEnum)
  ),
  MMPServiceName: PropTypes.oneOf(Object.keys(MMPServices)),
  afterDeleteConnectionCb: PropTypes.func,
  btn: PropTypes.node,
  autoWidthPaper: PropTypes.bool,
};

export default AppMMPStatusDropdownMenu;
