export const GoalsEnum = {
  tCPI: "tCPI",
  maxInstalls: "maxInstalls",
  tCPA: "tCPA",
  maxConv: "maxConv",
  tCPC: "tCPC",
  maxClicks: "maxClicks",
  tCPM: "tCPM",
  maxReach: "maxReach",
  maxValue: "maxValue",
  post: "post",
  tROAS: "tROAS",
};

export const GoalsMapping = {
  [GoalsEnum.tCPI]: "Target CPI",
  [GoalsEnum.maxInstalls]: "Maximize installs",
  [GoalsEnum.tCPA]: "Target CPA",
  [GoalsEnum.maxConv]: "Maximize conversions",
  [GoalsEnum.tCPC]: "Target CPC",
  [GoalsEnum.maxClicks]: "Maximize clicks",
  [GoalsEnum.tCPM]: "Target CPM",
  [GoalsEnum.maxReach]: "Maximize reach",
  [GoalsEnum.maxValue]: "Maximize value",
  [GoalsEnum.post]: "Post",
  [GoalsEnum.tROAS]: "Target ROAS",
};

export const getGoalMappingValues = (campaignGoals) => {
  const res = {};
  for (const campaignGoal in campaignGoals) {
    res[campaignGoal] = GoalsMapping[campaignGoal];
  }

  return res;
};
