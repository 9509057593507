import React from "react";
import { Route, Routes } from "react-router-dom";
import ReportingPage from "../pages/main/reporting/ReportingPage";
import ReportingAppPage from "../pages/main/reporting/ReportingAppPage";
import ReportingCampaignPage from "../pages/main/reporting/ReportingCampaignPage";
import ReportingAdGroupPage from "../pages/main/reporting/ReportingAdGroupPage";

const ReportRoutes = () => (
  <Routes>
    <Route path="/" element={<ReportingPage />} />
    <Route path="/app/:appId" element={<ReportingAppPage />} />
    <Route
      path="/app/:appId/campaign/:campaignId"
      element={<ReportingCampaignPage />}
    />
    <Route
      path="/app/:appId/campaign/:campaignId/ad-group/:adGroupId"
      element={<ReportingAdGroupPage />}
    />
  </Routes>
);

export default ReportRoutes;
