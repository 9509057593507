import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

export const types = {
  text: "text",
  circle: "circular",
  rectangle: "rectangular",
};

const CSkeleton = ({ className, style, type, anim, height, width, sx }) => {
  return (
    <Skeleton
      className={className}
      style={{
        transform: "unset",
        ...style,
      }}
      variant={type}
      animation={anim}
      height={height}
      width={width}
      sx={sx}
    />
  );
};

CSkeleton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(Object.values(types)),
  anim: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sx: PropTypes.object,
};

CSkeleton.defaultProps = {
  type: types.text,
  width: "100%",
};

export default CSkeleton;
