import React, { useEffect, useState } from "react";
import AuthTemplate from "../templates/AuthTemplate";
import adwizyApi from "../api/adwizyApi";
import { useInformer } from "../context/InformerProvider";
import { useNavigate, useParams } from "react-router-dom";
import CButton from "../components/common/CButton";
import Preloader from "../components/Preloader";
import { useUser } from "../context/UserProvider";
import { PaymentMethodsEnum } from "../enum/PaymentMethodsEnum";

const SubscriptionStatusPage = () => {
  const navigate = useNavigate();
  const user = useUser();
  const params = useParams();
  const orgId = Number(params.orgId);
  const informer = useInformer();
  const [loading, setLoading] = useState(true);
  const [tariffCode, setTariffCode] = useState(null);
  const [billingSubscriptionId, setBillingSubscriptionId] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [tariffInfo, setTariffInfo] = useState(null);

  const cabinetURL = `/org/${orgId}/organisation`;

  const fetchTariffInfo = async () => {
    await adwizyApi
      .get("/billing/tariff", {
        tariffCode: tariffCode,
      })
      .then((res) => {
        if (res.data.success) {
          setTariffInfo(res.data.data);
        } else {
          informer.showErrorNotice(res.data.message);
        }
      })
      .catch(() => {
        navigate(cabinetURL);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = () => {
    user.setSubscriptionStatusData(null);
    navigate(cabinetURL);
  };

  useEffect(() => {
    if (user.subscriptionStatusData === null) {
      navigate(cabinetURL);
    }
    if (user.subscriptionStatusData) {
      setTariffCode(user.subscriptionStatusData?.tariffCode);
      setBillingSubscriptionId(
        user.subscriptionStatusData?.billingSubscriptionId
      );
      setPaymentMethod(user.subscriptionStatusData?.paymentMethod);
    }
  }, [user.subscriptionStatusData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tariffCode) {
      fetchTariffInfo();
    }
  }, [tariffCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthTemplate>
      <div className="window">
        {loading ? (
          <Preloader />
        ) : (
          <>
            <div className="title">
              {tariffInfo && (
                <>
                  «{tariffInfo.tariffName}»
                  {billingSubscriptionId
                    ? " tariff has been prolonged."
                    : " tariff has been selected."}
                </>
              )}
            </div>
            <div className="text-center">
              <p>
                {paymentMethod === PaymentMethodsEnum.Skip ? (
                  `The trial period will be available for ${tariffInfo.trialPeriodDaysCount} days.`
                ) : (
                  <>
                    {billingSubscriptionId
                      ? "Your tariff will take effect upon payment. We will contact you by mail for payment soon."
                      : "NEED TEXT"}
                  </>
                )}
              </p>
              <CButton
                variant="contained"
                color="primary"
                onClick={handleClick}
                disabled={loading}
              >
                OK
              </CButton>
            </div>
          </>
        )}
      </div>
    </AuthTemplate>
  );
};

export default SubscriptionStatusPage;
