import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const CTabPanel = ({ className, value, selectedTab, children, style }) => {
  const tabPanelClass = classnames({
    tab_panel: true,
    [className]: className,
  });

  return (
    <div className={tabPanelClass} hidden={selectedTab !== value} style={style}>
      {selectedTab === value && children}
    </div>
  );
};

CTabPanel.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default CTabPanel;
