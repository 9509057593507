import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
import UserProvider from "./context/UserProvider";
import InformerProvider, { positions } from "./context/InformerProvider";

import PreloaderPage from "./pages/PreloaderPage";
import EulaPage from "./pages/EulaPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import AuthRoutes from "./routes/AuthRoutes";
import MainRoutes from "./routes/MainRoutes";

const App = () => (
  <Suspense fallback={<PreloaderPage />}>
    <Router>
      <Routes>
        <Route path="/eula" element={<EulaPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          path="/auth/*"
          element={
            <AuthProvider>
              <InformerProvider
                settings={{
                  maxNotice: 3,
                  position: positions.topCenter,
                  // hideDuration: null
                }}
              >
                <AuthRoutes />
              </InformerProvider>
            </AuthProvider>
          }
        />
        <Route
          path="*"
          element={
            <AuthProvider>
              <InformerProvider>
                <UserProvider>
                  <MainRoutes />
                </UserProvider>
              </InformerProvider>
            </AuthProvider>
          }
        />
      </Routes>
    </Router>
  </Suspense>
);

export default App;
