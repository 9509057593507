import React from "react";
import { styled } from "@mui/styles";
import { FormHelperText, formHelperTextClasses } from "@mui/material";
import { PropTypes } from "prop-types";

const ErrorFormHelperText = styled(FormHelperText)(({ theme }) => ({
  [`&.${formHelperTextClasses.root}`]: {
    margin: "0.56rem 0",
    fontSize: "0.78rem",
    fontWeight: 500,
    color: theme.palette.error.main,
  },
}));

const ErrorFormHelper = ({ children, style }) => (
  <ErrorFormHelperText style={style}>{children}</ErrorFormHelperText>
);

ErrorFormHelper.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default ErrorFormHelper;
