import { events } from "../context/EventProvider";
import { getStateFromSetter } from "../utils";

/**
 * @param {Function} action
 * @param {Function} condition
 * @returns {Function}
 */
export const getMetricsScoresAndRecsHandler =
  (action, condition) => (events) => {
    if (typeof condition === "function") {
      for (const event of events) {
        if (condition(event)) {
          action(event?.payload);
          break;
        }
      }
    } else {
      action();
    }
  };

/**
 * @param {Function} setKey
 * @param {Function} setAppId
 * @returns {Function}
 */
export const getMetricsScoresAndRecsHandlerForCreatives = (setKey, setAppId) =>
  getMetricsScoresAndRecsHandler(
    () => setKey((key) => key + 1),
    ({ payload }) => payload?.appIds?.includes(getStateFromSetter(setAppId))
  );

/**
 * @param {Function} setTokens
 * @returns {Object}
 */
export const getConnectionsEventHandlers = (setTokens) => {
  const orgConnectionAdded = (events) =>
    setTokens((tokens) => {
      let changes = 0;
      events.forEach(({ payload }) => {
        let idx = tokens.findIndex((it) => it.tokenId === payload.tokenId);
        if (idx === -1) {
          changes++;
          tokens.push(payload);
        }
      });

      return changes ? tokens.slice() : tokens;
    });

  const orgConnectionUpdated = (events) =>
    setTokens((tokens) => {
      let changes = 0;
      events.forEach(({ payload }) => {
        let idx = tokens.findIndex((it) => it.tokenId === payload.tokenId);
        if (idx !== -1) {
          changes++;
          tokens.splice(idx, 1, payload);
        }
      });

      return changes ? tokens.slice() : tokens;
    });

  const orgConnectionRemoved = (events) =>
    setTokens((tokens) => {
      let changes = 0;
      events.forEach(({ ids }) => {
        let idx = tokens.findIndex((it) => it.tokenId === ids.tokenId);
        if (idx !== -1) {
          changes++;
          tokens.splice(idx, 1);
        }
      });

      return changes ? tokens.slice() : tokens;
    });

  return {
    [events.orgConnectionAdded]: orgConnectionAdded,
    [events.orgConnectionUpdated]: orgConnectionUpdated,
    [events.orgConnectionRemoved]: orgConnectionRemoved,
  };
};

/**
 * @param {Function} setTokens
 * @returns {Object}
 */
export const getConnectionsAccountsEventHandlers = (setTokens) => {
  const changeEnabled = (enabled) => (events) => {
    let changes = 0;
    const tokens = getStateFromSetter(setTokens);

    events.forEach(({ ids }) => {
      const token = tokens.find((it) => it.tokenId === ids.tokenId);
      if (token) {
        token.accounts.enabled += enabled ? 1 : -1;
        changes++;
      }
    });

    changes && setTokens(tokens.slice());
  };

  return {
    [events.orgAccountEnabled]: changeEnabled(true),
    [events.orgAccountDisabled]: changeEnabled(false),
  };
};
