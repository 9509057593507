import React, { useEffect, useState } from "react";
import EmptyDialog from "../EmptyDialog";
import { Box, FormControlLabel, Switch, switchClasses } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NetworkIcon from "./NetworkIcon";
import CButton from "../common/CButton";
import CTooltip from "../common/CTooltip";
import { InfoOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";
import CTable, { types as tableColumnTypes } from "../common/CTable";
import { useInformer } from "../../context/InformerProvider";
import { styled } from "@mui/material/styles";
import Preloader from "../Preloader";
import adwizyApi from "../../api/adwizyApi";
import {
  dateFromUnix,
  getStateFromSetter,
  informTypes,
  isDev,
  sleep,
} from "../../utils";
import { events, useEvent } from "../../context/EventProvider";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.66rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  subtitle: {
    color: theme.palette.inactive.main,
    fontSize: "1rem",
    fontWeight: 500,
    textAlign: "center",
  },
  switchLabel: {
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.inactive.main,
  },
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  [`.${switchClasses.track}`]: {
    opacity: "unset",
    backgroundColor: theme.palette.borders.main,
  },
}));

const NetworkAccountList = ({
  btn,
  open,
  onClose,
  data,
  removeNetwork,
  onChangeAutoConnect,
}) => {
  const event = useEvent();
  const informer = useInformer();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState(null);
  const classes = useStyles();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    open != null && setIsOpen(open);
    if (open) {
      setAccounts(null);
      if (isDev) {
        await sleep(700);
        setAccounts(require("../../mocks/user__network_accounts.json").data);
      } else {
        adwizyApi
          .get(`/org/${data.orgId}/connection/${data.tokenId}/accounts`)
          .then((res) => {
            if (res.data.success) {
              setAccounts(res.data.data);
            }
          })
          .catch((err) => {
            informer.showErrorNotice(err);
            localOnClose();
          });
      }
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const localOnClose = () => {
    setIsOpen(false);
    typeof onClose === "function" && onClose();
  };

  const getNotifyMessage = (enabled, name) =>
    enabled
      ? `Account ${name} is enabled. All data will be synced soon.`
      : `Account ${name} is disabled. All account data will be removed from Adwizy. This may take up to 24 hours.`;

  useEffect(() => {
    if (data) {
      const changeEnabled = (enabled) => (events) => {
        let changes = 0;
        const accounts = getStateFromSetter(setAccounts);

        events.forEach(({ ids }) => {
          if (data.tokenId === ids.tokenId) {
            const account = accounts.find(
              (it) => it.accountTokenId === ids.accountTokenId
            );
            if (account) {
              account.isEnabled = enabled;
              changes++;
              informer.showSuccessNotice(
                getNotifyMessage(enabled, account.externalName)
              );
            }
          }
        });

        changes && setAccounts(accounts.slice());
      };

      const eventHandlers = {
        [events.orgAccountEnabled]: changeEnabled(true),
        [events.orgAccountDisabled]: changeEnabled(false),
      };

      event.subscribe(eventHandlers).catch(informer.showErrorNotice);
      setLoading(false);

      return () => event.unsubscribe(eventHandlers);
    } else {
      setLoading(true);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeAutoConnect = async () => {
    setLoading(true);
    let isAutoAddNewAccounts = !data.isAutoAddNewAccounts;

    await adwizyApi
      .put(`/org/${data.orgId}/connection/${data.tokenId}/edit`, {
        isAutoAddNewAccounts,
      })
      .then((res) => {
        if (res.data.success) {
          onChangeAutoConnect(isAutoAddNewAccounts);
        }
      })
      .catch(informer.showErrorNotice);

    setLoading(false);
  };

  const editRequest = async (accountTokenData, successCb, errorCb) => {
    if (isDev) {
      await sleep(500);
      typeof successCb === "function" && successCb();
    } else {
      adwizyApi
        .post(`/org/${data.orgId}/connection/${data.tokenId}/edit-accounts`, {
          accountTokenData,
        })
        .then((res) => {
          if (res.data.success) {
            Object.keys(accountTokenData).forEach((key) => {
              const account = accounts.find(
                (it) => it.accountTokenId === Number(key)
              );
              informer.showSuccessNotice(
                getNotifyMessage(accountTokenData[key], account.externalName)
              );
            });
            typeof successCb === "function" && successCb(res);
          }
        })
        .catch((err) => {
          informer.showErrorNotice(err);
          typeof errorCb === "function" && errorCb(err);
        });
    }
  };

  const changeEnabled = async ({ accountTokenId, isEnabled }) => {
    const filterForEdit = (account) =>
      account.accountTokenId === accountTokenId;
    const account = accounts.find(filterForEdit);

    if (account) {
      setAccounts(
        accounts
          .map((it) =>
            filterForEdit(it)
              ? { ...it, isEnabled: !isEnabled, loading: true }
              : it
          )
          .slice()
      );

      editRequest(
        { [account.accountTokenId]: !isEnabled },
        () =>
          setAccounts((accounts) =>
            accounts
              .map((it) =>
                filterForEdit(it)
                  ? { ...it, isEnabled: !isEnabled, loading: false }
                  : it
              )
              .slice()
          ),
        () =>
          setAccounts((accounts) =>
            accounts
              .map((it) =>
                filterForEdit(it) ? { ...it, isEnabled, loading: false } : it
              )
              .slice()
          )
      );
    }
  };

  const connectAll = () => {
    const accountsForEdit = accounts.filter(
      (it) => !it.isEnabled && !it.loading
    );
    const accountsIdsForEdit = accountsForEdit.map((it) => it.accountTokenId);

    if (accountsIdsForEdit.length) {
      setAccounts(
        accounts
          .map((it) =>
            accountsIdsForEdit.includes(it.accountTokenId)
              ? { ...it, isEnabled: true, loading: true }
              : it
          )
          .slice()
      );

      editRequest(
        accountsForEdit.reduce(
          (result, current) => ({
            ...result,
            [current.accountTokenId]: true,
          }),
          {}
        ),
        () =>
          setAccounts((accounts) =>
            accounts
              .map((it) =>
                accountsIdsForEdit.includes(it.accountTokenId)
                  ? { ...it, isEnabled: true, loading: false }
                  : it
              )
              .slice()
          ),
        () =>
          setAccounts((accounts) =>
            accounts
              .map((it) =>
                accountsIdsForEdit.includes(it.accountTokenId)
                  ? { ...it, isEnabled: false, loading: false }
                  : it
              )
              .slice()
          )
      );
    }
  };

  return (
    <EmptyDialog
      btn={btn}
      onClickBtn={() => setIsOpen(true)}
      open={isOpen}
      onClose={localOnClose}
      maxWidth="md"
      sx={{
        paper: {
          padding: "3.11rem",
        },
      }}
    >
      <div className={classes.title}>Select accounts</div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={1}
      >
        <Box className={classes.subtitle} marginRight={2}>
          Select accounts to connect to Adwizy from
        </Box>
        {data ? (
          <NetworkIcon network={data.network} customLabel={data.name} />
        ) : (
          <Preloader size={30} />
        )}
      </Box>
      <Box display="flex" alignItems="center" marginTop={3} marginBottom={2}>
        <Box display="flex" alignItems="center">
          <CButton
            onClick={connectAll}
            variant="contained"
            disabled={!accounts}
          >
            Connect all
          </CButton>
        </Box>
        <FormControlLabel
          control={
            <StyledSwitch
              checked={data?.isAutoAddNewAccounts}
              onChange={changeAutoConnect}
              disabled={loading}
            />
          }
          label={
            <Box display="flex" alignItems="center">
              <Box className={classes.switchLabel} marginRight={1}>
                Automatically connect all new accounts
              </Box>
              <CTooltip
                customLabel={<InfoOutlined color="primary" />}
                content={
                  <>
                    Automatically connect and enable synchronization to all new
                    accounts associated with <strong>{data?.name}</strong>
                  </>
                }
                type={informTypes.info}
              />
            </Box>
          }
          sx={{
            marginRight: 0,
            marginLeft: 5,
          }}
        />
      </Box>
      <CTable
        loading={!accounts}
        rows={accounts || []}
        settings={{
          columns: {
            customerId: {
              title: () => "Account ID",
            },
            externalName: {
              title: () => "Account Name",
              sx: { maxWidth: "unset !important" },
            },
            isEnabled: {
              title: () => "Connect",
              filterCellProps: {
                sx: { justifyContent: "center" },
              },
              sx: { textAlign: "center" },
              format: tableColumnTypes.custom,
              component: (account) => (
                <FormControlLabel
                  control={
                    <StyledSwitch
                      checked={account.isEnabled}
                      onChange={() => changeEnabled(account)}
                      disabled={account.loading}
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center">
                      <CTooltip
                        customLabel={<InfoOutlined color="primary" />}
                        content={
                          <>
                            <strong>Last update: </strong>
                            {account.tsDateUpdated
                              ? dateFromUnix(account.tsDateUpdated)
                              : "never"}
                          </>
                        }
                        type={informTypes.info}
                      />
                    </Box>
                  }
                  sx={{ marginRight: 0 }}
                />
              ),
            },
          },
        }}
      />
      <Box
        sx={{
          marginTop: "3.11rem",
          display: "grid",
          justifyContent: "center",
          gridGap: "1.72rem",
          gridTemplateColumns: "12.22rem 12.22rem",
        }}
      >
        <CButton
          onClick={() => removeNetwork(data.tokenId)}
          variant="outlined"
          color="error"
          size="large"
          disabled={loading}
          className="white-space-nowrap"
        >
          Remove connection
        </CButton>
        <CButton onClick={localOnClose} variant="contained" size="large">
          Finish setup
        </CButton>
      </Box>
    </EmptyDialog>
  );
};

NetworkAccountList.propTypes = {
  btn: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({
    orgId: PropTypes.number,
    tokenId: PropTypes.number,
    network: PropTypes.string,
    name: PropTypes.string,
    isAutoAddNewAccounts: PropTypes.bool,
  }),
  removeNetwork: PropTypes.func.isRequired,
  onChangeAutoConnect: PropTypes.func.isRequired,
};

export default NetworkAccountList;
