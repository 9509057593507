import React, { useEffect, useRef, useState } from "react";
import { makeStyles, styled } from "@mui/styles";
import {
  TextField,
  ClickAwayListener,
  Box,
  Grid,
  ButtonGroup,
  Button,
  CardActions,
  buttonClasses,
  cardActionsClasses,
  buttonBaseClasses,
} from "@mui/material";
import {
  CalendarPicker,
  LocalizationProvider,
  PickersDay,
  calendarPickerClasses,
  pickersDayClasses,
} from "@mui/lab";
import CButton from "./common/CButton";
import moment from "moment";
import AdapterMoment from "@mui/lab/AdapterMoment";
import PropTypes from "prop-types";
import Query from "../helpers/query";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { useUser } from "../context/UserProvider";
import { dateRenderFormat, dateSendFormat } from "../utils";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    flexBasis: "27.1rem",
    flexShrink: 0,
  },
  popup: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    border: `1px solid ${theme.palette.borders.main}`,
    boxShadow: "0px 3px 45px rgba(7, 34, 52, 0.05)",
    padding: "1.44rem 1.44rem 1.22rem 1.44rem",
    zIndex: 120,
    width: "56.89rem",
    transform: "translate(0, 1.22rem)",
  },
  right: {
    right: 0,
  },
  left: {
    left: 0,
  },
  input: {
    "& input": {
      color: theme.palette.inactive.main,
      padding: ".88rem 1.22rem",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 2,
      height: "auto",
      width: "100%",
      cursor: "pointer",
    },
  },
  textFieldActive: {
    "& input": {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  title: {
    textAlign: "center",
    backgroundColor: theme.palette.defaultLight.main,
    fontSize: "1rem",
    lineHeight: 1.5,
    padding: ".66rem .66rem .72rem .66rem",
    marginBottom: ".66rem",
  },
  item: {
    display: "flex",
    flexDirection: "column !important",
  },
  block: {
    borderRadius: 5,
    border: `1px solid ${theme.palette.borders.main}`,
    height: "100%",
  },
  buttonGroup: {
    padding: "1.22rem 0",
    height: "100%",
    justifyContent: "space-around",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${buttonClasses.root}`]: {
    textTransform: "initial",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#000",
    padding: ".94rem",
    border: "none",
    "&:hover": {
      backgroundColor: theme.palette.defaultLight.main,
      border: "none",
    },
    [`&.active`]: {
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
  },
}));

const StyledCardActions = styled(CardActions)(() => ({
  [`&.${cardActionsClasses.root}`]: {
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1.05rem",
  },
}));

const StyledPickersDay = styled(PickersDay)(() => ({
  [`&.${pickersDayClasses.root}`]: {
    fontSize: ".85rem",
    fontWeight: 500,
    width: "2rem",
    height: "2rem",
  },
}));

const CustomPickersDay = styled(StyledPickersDay)(({ theme }) => ({
  [`&.${pickersDayClasses.root}`]: {
    color: theme.palette.primary.main,
    margin: "0 .11rem",
  },
}));

const StyledCalendarPicker = styled(CalendarPicker)(({ theme }) => ({
  [`&.${calendarPickerClasses.root}`]: {
    maxWidth: "100%",

    "& > div:first-of-type": {
      margin: "1.13rem 1.22rem .22rem",
      padding: 0,
      position: "relative",

      "& > div:first-of-type": {
        width: "100%",
        display: "flex",
        justifyContent: "center",

        [`& > .${buttonBaseClasses.root}`]: {
          display: "none",
        },

        "& > div:last-of-type > div": {
          marginRight: 0,
        },
      },
      "& > div:last-of-type": {
        position: "absolute",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",

        "& > button": {
          color: theme.palette.inactive.main,
        },
      },
    },
    "& > div:last-of-type": {
      "& > div": {
        "& > div:first-of-type": {
          color: theme.palette.inactive.main,
        },
        "& > div:last-of-type": {
          minHeight: "11rem",
        },
      },
    },
  },
}));

const CDateRangePicker = ({
  defaultDatePeriod,
  onChange,
  withContext,
  withQuery,
  disabled,
}) => {
  const positionPopupTypes = {
    right: "right",
    left: "left",
  };

  const user = useUser();
  const classes = useStyles();
  const query = new Query();
  const navigate = useNavigate();
  const input = useRef();
  const [positionPopup, setPositionPopup] = useState(positionPopupTypes.right);

  /**
   * @param {moment} start
   * @param {moment} end
   * @returns {String}
   */
  const formatValue = (start, end) => {
    const formattedStart = start.format(dateRenderFormat);
    const formattedEnd = end.format(dateRenderFormat);

    if (formattedStart === formattedEnd) {
      return formattedStart;
    } else {
      return `${start.format(dateRenderFormat)} - ${end.format(
        dateRenderFormat
      )}`;
    }
  };

  const getDefaultStart = (skipUserContext = false) => {
    if (withQuery && query.has("start_date")) {
      return moment(query.get("start_date"), dateSendFormat);
    }

    if (!skipUserContext && withContext && user.datePickerValue) {
      return user.datePickerValue.start;
    }

    return defaultDatePeriod
      ? defaultDatePeriod.start
      : moment().subtract(27, "days");
  };

  const getDefaultEnd = (skipUserContext = false) => {
    if (withQuery && query.has("end_date")) {
      return moment(query.get("end_date"), dateSendFormat);
    }

    if (!skipUserContext && withContext && user.datePickerValue) {
      return user.datePickerValue.end;
    }

    return defaultDatePeriod ? defaultDatePeriod.end : moment();
  };

  const activePeriodIs = (start, end) => {
    return (
      start.format(dateRenderFormat) === startDate.format(dateRenderFormat) &&
      end.format(dateRenderFormat) === endDate.format(dateRenderFormat)
    );
  };

  const isActivePeriod = () => {
    const [start, end] = getPeriodDates(27, "days");

    return (
      currentStartDate.format(dateRenderFormat) !==
        start.format(dateRenderFormat) ||
      currentEndDate.format(dateRenderFormat) !== end.format(dateRenderFormat)
    );
  };

  const getPeriodDates = (...args) => [moment().subtract(...args), moment()];

  const [startDate, setStartDate] = useState(getDefaultStart());
  const [endDate, setEndDate] = useState(getDefaultEnd());

  const [currentStartDate, setCurrentStartDate] = useState(getDefaultStart());
  const [currentEndDate, setCurrentEndDate] = useState(getDefaultEnd());

  const [value, setValue] = useState(() => {
    // eslint-disable-next-line no-mixed-operators
    let res = formatValue(currentStartDate, currentEndDate);
    if (activePeriodIs(...getPeriodDates(27, "days"))) {
      res += " (Last 28 days)";
    }

    return res;
  });
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const setPeriod = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const renderSelectedPickerDay = (date, selectedDates, pickersDayProps) => {
    if (moment(date).isBetween(startDate, endDate)) {
      return <CustomPickersDay {...pickersDayProps} />;
    } else {
      return <StyledPickersDay {...pickersDayProps} />;
    }
  };

  const handleCancel = () => {
    setStartDate(currentStartDate);
    setEndDate(currentEndDate);
    toggleOpen();
  };

  const setQuery = () => {
    if (withQuery) {
      const condition =
        (startDate.format(dateSendFormat) === query.get("start_date") &&
          endDate.format(dateSendFormat) === query.get("end_date")) ||
        (user?.datePickerValue !== null &&
          !query.has("start_date") &&
          !query.has("end_date")) ||
        (!query.has("start_date") && !query.has("end_date"));

      if (condition) {
        query.update({
          start_date: startDate.format(dateSendFormat),
          end_date: endDate.format(dateSendFormat),
        });

        navigate({ search: query.asString() }, { replace: true });
      } else {
        query.update({
          start_date: startDate.format(dateSendFormat),
          end_date: endDate.format(dateSendFormat),
        });

        navigate({ search: query.asString() });
      }
    }
  };

  const handleOk = () => {
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
    setValue(formatValue(startDate, endDate));
    if (
      !currentStartDate.isSame(startDate) ||
      !currentEndDate.isSame(endDate)
    ) {
      const date = {
        start: startDate,
        end: endDate,
      };

      if (withContext) {
        user.setDatePickerValue(date);
      }

      setQuery();
      onChange(date);
    }

    toggleOpen();
  };

  useEffect(() => {
    if (
      input.current.getBoundingClientRect().right < 800 ||
      input.current.getBoundingClientRect().left <= 800
    ) {
      setPositionPopup(positionPopupTypes.left);
    }

    if (withContext) {
      setQuery();
    }

    onChange({
      start: startDate,
      end: endDate,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const start = getDefaultStart(true);
    const end = getDefaultEnd(true);
    setStartDate(start);
    setEndDate(end);
    setCurrentStartDate(start);
    setCurrentEndDate(end);
    setValue(formatValue(start, end));

    onChange({
      start: start,
      end: end,
    });
  }, [query.get("start_date"), query.get("end_date"), disabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box className={classes.container}>
        <TextField
          ref={input}
          {...(isActivePeriod()
            ? {
                label: "Active period",
              }
            : {})}
          placeholder="Select the date"
          value={value}
          onClick={toggleOpen}
          className={classnames({
            "w-100": true,
            [classes.textFieldActive]: isActivePeriod(),
          })}
          InputProps={{
            className: classnames({
              [classes.input]: true,
              "date-range-picker-input": true,
            }),
            disabled: disabled,
          }}
          disabled={disabled}
          onKeyDown={(e) => {
            if (e.keyCode === 27) {
              handleCancel();
            }
          }}
        />
        {open && (
          <ClickAwayListener onClickAway={handleCancel}>
            <Box
              className={classnames({
                [classes.popup]: true,
                [classes.right]: positionPopup === positionPopupTypes.right,
                [classes.left]: positionPopup === positionPopupTypes.left,
              })}
            >
              <Grid container spacing={2.625}>
                <Grid item xs={4} className={classes.item}>
                  <Box className={classes.title}>Select from the list</Box>
                  <Box className={classes.block}>
                    <ButtonGroup
                      className={classes.buttonGroup}
                      orientation="vertical"
                      fullWidth
                    >
                      <StyledButton
                        onClick={() => setPeriod(...getPeriodDates(6, "days"))}
                        className={classnames({
                          active: activePeriodIs(...getPeriodDates(6, "days")),
                        })}
                      >
                        Last 7 days
                      </StyledButton>
                      <StyledButton
                        onClick={() => setPeriod(...getPeriodDates(13, "days"))}
                        className={classnames({
                          active: activePeriodIs(...getPeriodDates(13, "days")),
                        })}
                      >
                        Last 14 days
                      </StyledButton>
                      <StyledButton
                        onClick={() => setPeriod(...getPeriodDates(27, "days"))}
                        className={classnames({
                          active: activePeriodIs(...getPeriodDates(27, "days")),
                        })}
                      >
                        Last 28 days
                      </StyledButton>
                      <StyledButton
                        onClick={() =>
                          setPeriod(
                            moment()
                              .quarter(moment().quarter())
                              .startOf("quarter"),
                            moment()
                          )
                        }
                        className={classnames({
                          active: activePeriodIs(
                            moment()
                              .quarter(moment().quarter())
                              .startOf("quarter"),
                            moment()
                          ),
                        })}
                      >
                        Quarter to Date
                      </StyledButton>
                      <StyledButton
                        onClick={() =>
                          setPeriod(moment().startOf("year"), moment())
                        }
                        className={classnames({
                          active: activePeriodIs(
                            moment().startOf("year"),
                            moment()
                          ),
                        })}
                      >
                        Year to Date
                      </StyledButton>
                    </ButtonGroup>
                  </Box>
                </Grid>

                <Grid item xs={4} className={classes.item}>
                  <Box className={classes.title}>Start date</Box>

                  <Box className={classes.block}>
                    <StyledCalendarPicker
                      date={startDate}
                      onChange={setStartDate}
                      renderDay={renderSelectedPickerDay}
                      maxDate={moment()}
                    />
                  </Box>
                </Grid>

                <Grid item xs={4} className={classes.item}>
                  <Box className={classes.title}>End date</Box>

                  <Box className={classes.block}>
                    <StyledCalendarPicker
                      date={endDate}
                      onChange={setEndDate}
                      renderDay={renderSelectedPickerDay}
                      maxDate={moment()}
                      minDate={startDate}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2.625}>
                <Grid item xs={4} />
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <StyledCardActions>
                    <CButton
                      size="large"
                      variant="outlined"
                      color="inactive"
                      onClick={handleCancel}
                    >
                      Cancel
                    </CButton>
                    <CButton
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={handleOk}
                    >
                      OK
                    </CButton>
                  </StyledCardActions>
                </Grid>
              </Grid>
            </Box>
          </ClickAwayListener>
        )}
      </Box>
    </LocalizationProvider>
  );
};

CDateRangePicker.propTypes = {
  defaultDatePeriod: PropTypes.shape({
    start: PropTypes.instanceOf(moment),
    end: PropTypes.instanceOf(moment),
  }),
  onChange: PropTypes.func.isRequired,
  withContext: PropTypes.bool,
  withQuery: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CDateRangePicker;
