import React from "react";
import { SvgIcon } from "@mui/material";

const SearchIcon = (props) => (
  <SvgIcon viewBox="0 0 14 15" {...props}>
    <path
      d="M9.47488 3.22278C11.0807 5.07003 10.8849 7.86927 9.03768 9.47505C7.19043 11.0808 4.3912 10.8851 2.78541 9.03785C1.17962 7.19061 1.37537 4.39137 3.22261 2.78558C5.06986 1.1798 7.8691 1.37554 9.47488 3.22278ZM10.3632 10.0893C12.3708 7.94793 12.4716 4.59157 10.504 2.32816C8.40416 -0.0874716 4.74362 -0.343442 2.32799 1.75643C-0.0876433 3.85631 -0.343613 7.51685 1.75626 9.93248C3.68571 12.1521 6.93279 12.5483 9.32106 10.969L11.8812 13.9142C12.1283 14.1983 12.5589 14.2285 12.8431 13.9814C13.1273 13.7344 13.1574 13.3037 12.9104 13.0195L10.3632 10.0893Z"
      fill="#263E4D"
    />
  </SvgIcon>
);
SearchIcon.displayName = "SearchIcon";
SearchIcon.muiName = "SvgIcon";

export default SearchIcon;
