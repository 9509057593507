import React from "react";
import MainTemplate from "../../../templates/MainTemplate";

const ForbiddenPage = () => {
  return (
    <MainTemplate title="Forbidden">
      <div className="block-content">
        You don&#39;t have permission for this page
      </div>
    </MainTemplate>
  );
};

export default ForbiddenPage;
