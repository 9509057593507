import React from "react";
import PropTypes from "prop-types";
import { htmlDecode } from "../../utils";

const TableColumnTitle = ({ title, subtitle }) => (
  <div className="table-column-title d-flex flex-direction-column">
    <div className="table-column-title__title" title={htmlDecode(title)}>
      {htmlDecode(title)}
    </div>
    {subtitle && (
      <div
        className="table-column-title__subtitle"
        title={htmlDecode(subtitle)}
      >
        {htmlDecode(subtitle)}
      </div>
    )}
  </div>
);

TableColumnTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default TableColumnTitle;
