import React from "react";
import Percent, { variants } from "./Percent";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import classnames from "classnames";

const CTableCell = ({ item, style }) => {
  return (
    <TableCell
      style={{
        height: 0,
        width: 0,
        padding: 0,
        textAlign: "center",
        borderBottom: "none",
        ...style,
      }}
    >
      {item}
    </TableCell>
  );
};

CTableCell.propTypes = {
  item: PropTypes.node,
  style: PropTypes.object,
};

const PercentBlock = ({ className, variant, settings, items }) => {
  const percentBlockClass = classnames({
    percent_block: true,
    [className]: className,
  });

  let percents = {};
  let dividers = {};
  let descriptions = {};

  items &&
    items.forEach(({ description, divider, ...other }, i) => {
      percents[i] = (
        <Percent settings={settings} {...other} variant={variant} />
      );
      dividers[i] =
        i === items.length - 1 ? (
          ""
        ) : (
          <div className="percent_block__divider">{divider}</div>
        );
      descriptions[i] = (
        <div className="percent_block__item_description">{description}</div>
      );
    });

  return (
    <Table
      className={percentBlockClass}
      style={{ width: "fit-content", margin: "auto" }}
    >
      <TableBody className="percent_block__item">
        <TableRow key={"content"}>
          {Object.keys(percents).map((i) => (
            <React.Fragment key={i}>
              <CTableCell item={percents[i]} />
              <CTableCell item={dividers[i]} />
            </React.Fragment>
          ))}
        </TableRow>
        <TableRow key={"descriptions"}>
          {Object.keys(percents).map((i) => (
            <React.Fragment key={i}>
              <CTableCell
                item={descriptions[i]}
                style={{ verticalAlign: "top" }}
              />
              <CTableCell />
            </React.Fragment>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};

PercentBlock.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf([variants.circle, variants.circleClock]),
  settings: Percent.propTypes.settings,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      settings: Percent.propTypes.settings,
      value: Percent.propTypes.value,
      description: Percent.propTypes.description,
      divider: PropTypes.node,
    })
  ).isRequired,
};

PercentBlock.defaultProps = {
  variant: variants.circle,
};

export default PercentBlock;
