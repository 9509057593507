import React from "react";
import PropTypes from "prop-types";
import {
  PlatformTypesEnum,
  PlatformTypesMapping,
} from "../../enum/PlatformTypes";
import {
  Android as AndroidIcon,
  Apple as AppleIcon,
} from "@mui/icons-material/";

const PlatformIcon = ({ platform, sx }) => {
  if (platform === PlatformTypesEnum.ios) {
    return (
      <div title={PlatformTypesMapping[PlatformTypesEnum.ios]}>
        <AppleIcon sx={sx} />
      </div>
    );
  }

  if (platform === PlatformTypesEnum.android) {
    return (
      <div title={PlatformTypesMapping[PlatformTypesEnum.android]}>
        <AndroidIcon sx={sx} />
      </div>
    );
  }
};

PlatformIcon.propTypes = {
  platform: PropTypes.oneOf(Object.keys(PlatformTypesEnum)),
  sx: PropTypes.object,
};

export default PlatformIcon;
