import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Box, Breadcrumbs } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import DropdownFilter from "../common/DropdownFilter";
import CSkeleton from "../common/CSkeleton";
import CDateRangePicker from "../CDateRangePicker";
import classnames from "classnames";
import DropdownApp from "../common/DropdownApp";

const CBreadcrumbs = ({ breadcrumbs, loading }) =>
  loading ? (
    <CSkeleton />
  ) : breadcrumbs?.length ? (
    <Breadcrumbs
      aria-label="breadcrumb"
      className="breadcrumbs"
      separator={<NavigateNext fontSize="small" />}
    >
      {breadcrumbs.map((it, i) =>
        it.path ? (
          <Link
            key={i}
            to={it.path}
            className="breadcrumb_link text-decoration-none"
          >
            {it.content}
          </Link>
        ) : (
          it.content
        )
      )}
    </Breadcrumbs>
  ) : null;

CBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
  loading: PropTypes.bool,
};

const Filters = ({
  filters,
  dataActionColumns,
  creativePagesStyles,
  skeletonHeight,
}) => {
  return filters && filters.length ? (
    creativePagesStyles ? (
      <div
        className={classnames({
          sticky_header__filters: true,
          compact: dataActionColumns,
          creative_pages: creativePagesStyles,
        })}
      >
        {filters.map((it, i) => (
          <div key={i} className="sticky_header__filter">
            <DropdownFilter {...it} skeletonHeight={skeletonHeight} />
          </div>
        ))}
      </div>
    ) : (
      filters.map((it, i) => (
        <div key={i} className="sticky_header__filter">
          <DropdownFilter {...it} skeletonHeight={skeletonHeight} />
        </div>
      ))
    )
  ) : null;
};

Filters.propTypes = {
  filters: PropTypes.array,
  dataActionColumns: PropTypes.bool,
  creativePagesStyles: PropTypes.bool,
  skeletonHeight: PropTypes.string,
};

const StickyHeader = ({
  breadcrumbs,
  title,
  subtitle,
  filters,
  datepicker,
  dataActionColumns,
  creativePagesStyles,
  loading,
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
  });
  const stickyHeaderRef = useRef();
  const [height, setHeight] = useState(0);
  const [copyTitle, setCopyTitle] = useState();

  useEffect(() => {
    if (!stickyHeaderRef?.current) {
      return;
    }
    if (
      inView === false &&
      stickyHeaderRef?.current.classList.contains("fade-out")
    ) {
      stickyHeaderRef?.current.classList.remove("fade-out");
      stickyHeaderRef?.current.classList.add("fade-in");
    }

    if (inView === true) {
      stickyHeaderRef?.current.classList.add("fade-out");
      stickyHeaderRef?.current.classList.remove("fade-in");
    }
  }, [inView]);

  useEffect(() => {
    setHeight(stickyHeaderRef?.current?.clientHeight);
  }, [stickyHeaderRef?.current?.clientHeight]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!subtitle) {
      return;
    }

    if (subtitle?.type === DropdownApp) {
      setCopyTitle(
        React.cloneElement(subtitle, {
          disabledNavigate: true,
          disabled: false,
        })
      );
    } else {
      setCopyTitle(React.cloneElement(subtitle));
    }
  }, [subtitle]);

  return (
    <>
      <Box
        className="sticky_header block_outer__right"
        marginBottom={height ? `-${height}px` : 0}
      >
        {breadcrumbs && (
          <CBreadcrumbs breadcrumbs={breadcrumbs} loading={loading} />
        )}
        <div className="sticky_header__title_row">
          <Box width="100%">
            {title && (
              <h1 className="title">{loading ? <CSkeleton /> : title}</h1>
            )}
            {loading && subtitle !== false && (
              <h2 className="subtitle">
                <CSkeleton />
              </h2>
            )}
            {!loading && subtitle && <h2 className="subtitle">{subtitle}</h2>}
          </Box>
          {creativePagesStyles &&
            (dataActionColumns ? (
              <Box flexBasis="34.55rem" flexShrink="0">
                {datepicker && <CDateRangePicker {...datepicker} />}
                <Filters
                  filters={filters}
                  dataActionColumns
                  creativePagesStyles
                />
              </Box>
            ) : (
              datepicker && <CDateRangePicker {...datepicker} />
            ))}
        </div>
        {!dataActionColumns && (filters || datepicker) && (
          <>
            {creativePagesStyles ? (
              <Filters filters={filters} creativePagesStyles />
            ) : (
              <div className="sticky_header__filters">
                <Filters filters={filters} />
                {datepicker && <CDateRangePicker {...datepicker} />}
              </div>
            )}
          </>
        )}
      </Box>
      <Box ref={ref} />

      <div
        ref={stickyHeaderRef}
        className="sticky_header sticky_header__stickyed block_outer__right"
      >
        <div className="sticky_header__title_row">
          <Box display="flex" flexDirection="column" width="100%">
            {breadcrumbs && (
              <CBreadcrumbs breadcrumbs={breadcrumbs} loading={loading} />
            )}

            {subtitle !== false && loading && (
              <h2 className="subtitle">
                <CSkeleton height="2.05rem" />
              </h2>
            )}
            {copyTitle && !loading && <h2 className="subtitle">{copyTitle}</h2>}
          </Box>

          {creativePagesStyles && datepicker && (
            <CDateRangePicker {...datepicker} />
          )}
        </div>

        <Box
          className={classnames({
            sticky_header__filters: creativePagesStyles !== true,
            "data-action-columns": dataActionColumns,
          })}
          marginTop={
            datepicker !== undefined && subtitle !== undefined
              ? "1rem !important"
              : 0
          }
        >
          <Filters
            filters={filters}
            creativePagesStyles={creativePagesStyles}
            dataActionColumns={dataActionColumns}
            skeletonHeight="3rem"
          />

          {!creativePagesStyles && datepicker && (
            <CDateRangePicker {...datepicker} />
          )}
        </Box>
      </div>
    </>
  );
};

StickyHeader.propTypes = {
  breadcrumbs: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.node,
  filters: PropTypes.arrayOf(PropTypes.shape(DropdownFilter.propTypes)),
  datepicker: PropTypes.shape(CDateRangePicker.propTypes),
  dataActionColumns: PropTypes.bool,
  creativePagesStyles: PropTypes.bool,
  loading: PropTypes.bool,
};

StickyHeader.defaultProps = {
  dataActionColumns: false,
  creativePagesStyles: false,
  loading: false,
};

export default StickyHeader;
