import React from "react";
import { styled } from "@mui/material/styles";
import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionSummary,
  accordionSummaryClasses,
  Typography,
  typographyClasses,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import PropTypes from "prop-types";
import classnames from "classnames";

const StyledAccordion = styled(Accordion)(() => ({
  [`&.${accordionClasses.root}`]: {
    boxShadow: "none",
    border: "none",
    "&:before": {
      display: "none",
    },
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  [`&.${accordionSummaryClasses.root}`]: {
    justifyContent: "unset",
    minHeight: "unset",
    width: "fit-content",
    padding: 0,
  },
  [`.${accordionSummaryClasses.content}`]: {
    margin: 0,
    flexGrow: "unset",
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  [`&.${accordionDetailsClasses.root}`]: {
    padding: 0,
  },
}));

const StyledTypography = styled(Typography)(() => ({
  [`&.${typographyClasses.root}`]: {
    fontSize: "inherit",
    fontWeight: "inherit",
  },
}));

const TextAccordion = ({ className, title, titleColor, children }) => {
  const textAccordionClass = classnames({
    text_accordion: true,
    [className]: className,
  });

  return (
    <StyledAccordion className={textAccordionClass} disableGutters>
      <StyledAccordionSummary expandIcon={<ExpandMore color={titleColor} />}>
        <StyledTypography color={titleColor}>{title}</StyledTypography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

TextAccordion.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

TextAccordion.defaultProps = {
  titleColor: "#000000",
};

export default TextAccordion;
