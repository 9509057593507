import React /*, { useEffect }*/ from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  // useParams,
} from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import UserProvider /*, { useUser }*/ from "../context/UserProvider";

import SignInPage from "../pages/auth/SignInPage";
import SignOutPage from "../pages/auth/SignOutPage";
import SignUpPage from "../pages/auth/SignUpPage";
import ActivatePage from "../pages/auth/ActivatePage";
import RestorePasswordPage from "../pages/auth/RestorePasswordPage";
import SetNewPasswordPage from "../pages/auth/SetNewPasswordPage";
import CreateOrgPage from "../pages/auth/CreateOrgPage";
// import ConnectAccountPage from "../pages/auth/ConnectAccountPage";
import NotFoundPage from "../pages/auth/NotFoundPage";
import PropTypes from "prop-types";
// import PreloaderPage from "../pages/PreloaderPage";

const Public = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  return auth.isAuthorized ? (
    <Navigate to={location.state?.from || "/"} />
  ) : (
    children
  );
};

Public.propTypes = {
  children: PropTypes.node,
};

const Private = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  const from = location.pathname.indexOf("sign-out") !== -1 ? null : location;

  return auth.isAuthorized ? (
    children
  ) : (
    <Navigate to="/auth/sign-in" state={{ from }} />
  );
};

Private.propTypes = {
  children: PropTypes.node,
};

// const CheckAccess = ({ children }) => {
//   const user = useUser();
//   let { orgId } = useParams();
//   const org = user.orgs?.find((it) => Number(it.orgId) === Number(orgId));
//
//   useEffect(() => {
//     org && org !== user.selectedOrg && user.setSelectedOrg(org);
//   }, [org]);
//
//   return user.orgs ? org ? children : <Navigate to="/" /> : <PreloaderPage />;
// };
//
// CheckAccess.propTypes = {
//   children: PropTypes.node,
// };

const AuthRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/auth/sign-in" />} />
    <Route
      path="/sign-in"
      element={
        <Public>
          <SignInPage />
        </Public>
      }
    />
    <Route
      path="/sign-out"
      element={
        <Private>
          <SignOutPage />
        </Private>
      }
    />
    <Route
      path="/sign-up"
      element={
        <Public>
          <SignUpPage />
        </Public>
      }
    />
    <Route
      path="/activate"
      element={
        <Public>
          <ActivatePage />
        </Public>
      }
    />
    <Route
      path="/restore-password"
      element={
        <Public>
          <RestorePasswordPage />
        </Public>
      }
    />
    <Route
      path="/set-new-password"
      element={
        <Public>
          <SetNewPasswordPage />
        </Public>
      }
    />
    <Route
      path="/create-org"
      element={
        <Private>
          <UserProvider>
            <CreateOrgPage />
          </UserProvider>
        </Private>
      }
    />
    {/*<Route path="/org/:orgId/connect-account" element={*/}
    {/*    <Private>*/}
    {/*        <UserProvider>*/}
    {/*            <CheckAccess>*/}
    {/*                <ConnectAccountPage/>*/}
    {/*            </CheckAccess>*/}
    {/*        </UserProvider>*/}
    {/*    </Private>*/}
    {/*}/>*/}
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default AuthRoutes;
