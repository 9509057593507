import React, { useState } from "react";
import AuthTemplate, { deepValidateEmail } from "../../templates/AuthTemplate";
import CInput from "../../components/AuthTemplate/CInput";
import { Box, Button } from "@mui/material";
import GoBack from "../../components/common/GoBack";
import adwizyApi from "../../api/adwizyApi";
import { isDev } from "../../utils";
import { useInformer } from "../../context/InformerProvider";
import { useNavigate } from "react-router-dom";
import Preloader from "../../components/Preloader";

const RestorePasswordPage = () => {
  const informer = useInformer();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [blockedEmails, setBlockedEmails] = useState([]);
  const [blockEmailError, setBlockEmailError] = useState("");

  const validateEmail = (email) =>
    deepValidateEmail(email, setEmailError, blockedEmails, blockEmailError);

  const submit = async () => {
    if (validateEmail(email) && !isDev) {
      setLoading(true);

      await adwizyApi
        .post("/auth/restore-password", { email })
        .then((res) => {
          if (res.data.success) {
            navigate("/auth/set-new-password");
          }
        })
        .catch((err) => {
          let emailError = err.response?.data?.errors?.email;
          if (emailError && !Array.isArray(emailError)) {
            setBlockEmailError(emailError);
            setBlockedEmails((prev) => [...prev, email]);
            deepValidateEmail(email, setEmailError, [email], emailError);
          } else {
            informer.showErrorNotice(err);
          }
        });

      setLoading(false);
    }
  };

  return (
    <AuthTemplate>
      <div className="window">
        <GoBack />
        <div className="title">Forgot your password?</div>
        <div className="input_group">
          <CInput
            id="email"
            label="Email"
            value={email}
            hasError={emailError !== ""}
            helperText={emailError}
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail(e.target.value);
            }}
            disabled={loading}
            submit={submit}
          />
        </div>
        <div className="actions">
          <Button
            className="button"
            variant="contained"
            onClick={submit}
            color="primary"
            disabled={loading || !email || !!emailError}
          >
            Restore password
            {loading && (
              <Box display="flex" alignItems="center" marginLeft={1}>
                <Preloader size={30} />
              </Box>
            )}
          </Button>
        </div>
      </div>
    </AuthTemplate>
  );
};

export default RestorePasswordPage;
