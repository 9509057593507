import React, { cloneElement } from "react";
import {
  Box,
  Button,
  CardActions,
  Popover,
  Divider,
  popoverClasses,
  buttonClasses,
  cardActionsClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import Preloader from "../Preloader";
import theme from "../../theme";
import PropTypes from "prop-types";

const styles = () => ({
  title: {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: 2,
    padding: "1.5rem 1.72rem 0",
  },
});

const StyledPopover = styled(Popover)(() => ({
  [`.${popoverClasses.paper}`]: {
    width: "18.11rem",
    marginTop: ".72rem",
    background: "transparent",
    boxShadow: "none",
  },
}));

const StyledCardActions = styled(CardActions)(() => ({
  [`&.${cardActionsClasses.root}`]: {
    padding: "1.38rem 0 0 0",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${buttonClasses.root}`]: {
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.16rem",
    borderRadius: 2,
    padding: ".5rem",
  },
  [`&.${buttonClasses.containedPrimary}`]: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    padding: ".5rem 1.55rem",
  },
  [`&.${buttonClasses.text}`]: {
    textTransform: "initial",
    color: theme.palette.inactive.main,
  },
}));

class DropdownActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = async () => {
    const handleCancel = this.props.handleCancel;
    if (handleCancel) {
      await handleCancel();
    }

    this.setState({ anchorEl: null });
  };

  handleClickOkBtn = async () => {
    const handleOk = this.props.handleOk;
    if (handleOk) {
      await handleOk();
    }

    this.setState({ anchorEl: null });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.handleClickOkBtn();
    }
  };

  render() {
    const {
      classes,
      btn,
      title,
      children,
      anchorOrigin,
      transformOrigin,
      loading,
    } = this.props;

    const open = Boolean(this.state.anchorEl);

    return (
      <>
        {btn && cloneElement(btn, { onClick: this.handleClick, open: open })}
        <StyledPopover
          id={open ? "simple-popover" : undefined}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={
            anchorOrigin ?? {
              vertical: "bottom",
              horizontal: "left",
            }
          }
          transformOrigin={
            transformOrigin ?? {
              vertical: "top",
              horizontal: "left",
            }
          }
          onKeyDown={this.handleKeyDown}
        >
          <Box
            sx={{
              width: "100%",
              paddingTop: ".44rem",
              borderRadius: "0.27rem",
              border: `1px solid ${theme.palette.borders.main}`,
              filter: "drop-shadow(0px 26px 45px rgba(7, 34, 52, 0.05))",
              backgroundColor: "#fff !important",
            }}
          >
            {title && <Box className={classes.title}>{title}</Box>}
            {children}
            <Box sx={{ padding: "0 1.61rem 1.33rem" }}>
              <Divider className={"divider"} />
              <StyledCardActions sx={{ justifyContent: "end" }}>
                {loading ? (
                  <Preloader size={32} />
                ) : (
                  <>
                    <StyledButton variant="text" onClick={this.handleClose}>
                      Cancel
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={this.handleClickOkBtn}
                    >
                      OK
                    </StyledButton>
                  </>
                )}
              </StyledCardActions>
            </Box>
          </Box>
        </StyledPopover>
      </>
    );
  }
}

DropdownActions.propTypes = {
  classes: PropTypes.object,
  btn: PropTypes.node,
  title: PropTypes.node,
  children: PropTypes.node,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(DropdownActions);
