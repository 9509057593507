import React from "react";
import { FormControl, MenuItem, menuItemClasses, Select } from "@mui/material";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Check as CheckIcon,
} from "@mui/icons-material/";
import PropTypes from "prop-types";
import theme from "../../theme";
import CSkeleton from "./CSkeleton";
import { styled } from "@mui/material/styles";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`&.${menuItemClasses.root}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: ".8rem",
    fontWeight: 500,
    lineHeight: "1.44rem",
    color: theme.palette.hover.main,
    padding: "0 1rem",
    backgroundColor: "transparent",
    marginBottom: ".66rem",
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: "transparent !important",
    "& > .icon": {
      display: "block",
    },
  },
}));

const ValuePicker = ({
  values,
  selectedValue,
  handleSelected,
  label,
  loading,
  disabled,
  isValuesAsNode,
}) => {
  const handleChange = async (evt) => {
    if (handleSelected) {
      handleSelected(evt.target.value);
    }
  };

  return (
    <>
      {loading ? (
        <CSkeleton
          height="2.16rem"
          width="7.77rem"
          style={{ borderRadius: "50px" }}
        />
      ) : (
        <FormControl
          disabled={disabled}
          className="value-picker"
          variant="standard"
        >
          <Select
            value={selectedValue}
            onChange={handleChange}
            iconopencolor={theme.palette.primary.main}
            IconComponent={(props) =>
              disabled ? null : <KeyboardArrowDownIcon {...props} />
            }
            classes={{
              select: "value-picker__content",
              icon: "value-picker__icon",
            }}
            MenuProps={{
              classes: { paper: "dropdown-paper value-picker__paper" },
              variant: "menu",
            }}
            disabled={disabled}
            disableUnderline
          >
            {label && (
              <StyledMenuItem value={"none"} className="d-none">
                {label}
              </StyledMenuItem>
            )}
            {isValuesAsNode
              ? Object.keys(values).map((key) => (
                  <StyledMenuItem value={key} key={key}>
                    {values[key]}
                  </StyledMenuItem>
                ))
              : Object.keys(values).map((key) => (
                  <StyledMenuItem value={key} key={key}>
                    {values[key]}
                    <CheckIcon
                      className="icon"
                      color="inactive"
                      sx={{
                        fontSize: "1.3rem",
                        display: "none",
                      }}
                    />
                  </StyledMenuItem>
                ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

ValuePicker.propTypes = {
  values: PropTypes.object.isRequired,
  selectedValue: PropTypes.any,
  handleSelected: PropTypes.func,
  label: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  fontSize: PropTypes.string,
  isValuesAsNode: PropTypes.bool,
};

ValuePicker.defaultProps = {
  bgColor: theme.palette.success.main,
  textColor: "#fff",
  fontSize: ".83rem",
};

export default ValuePicker;
