import React from "react";
import { Box } from "@mui/material";
import clsx from "clsx";
import { styled } from "@mui/system";
import { useSwitch } from "@mui/base/SwitchUnstyled";
import { setInStorage } from "../../localStorageUtils";
import { useUser } from "../../context/UserProvider";
import CTooltip from "../common/CTooltip";
import { informTypes } from "../../utils";
import { InfoOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useInformer } from "../../context/InformerProvider";
import CheckIcon from "@mui/icons-material/Check";
import theme from "../../theme";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: "33.11rem !important",
  },
}));

const Title = styled("p")(({ theme }) => ({
  "&": {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: ".83rem",
    color: theme.palette.inactive.main,
    margin: "0 1.47rem 0 0",
  },
}));

const SwitchRoot = styled("span")(() => ({
  "&": {
    display: "inline-block",
    position: "relative",
    width: "2.61rem",
    height: "1.1rem",
  },
}));

const SwitchInput = styled("input")(() => ({
  "&": {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    opacity: 0,
    zIndex: 1,
    margin: 0,
    cursor: "pointer",
  },
}));

const SwitchThumb = styled("span")(({ theme }) => ({
  "&": {
    position: "absolute",
    display: "block",
    backgroundColor: theme.palette.borders.main,
    width: "1.94rem",
    height: "1.94rem",
    borderRadius: "4.1px",
    top: "-0.44rem",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
    transform: "translateX(-.66rem)",
  },
  "&::before": {
    display: "block",
    content: "''",
    width: "100%",
    height: "100%",
    background: `url('data:image/svg+xml;utf8,<svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 22c5.7 0 10.39-4.34 10.95-9.9.06-.59-.41-1.1-1-1.1-.51 0-.94.38-.99.88C19.52 16.44 15.67 20 11 20c-3.12 0-5.87-1.59-7.48-4H5c.55 0 1-.45 1-1s-.45-1-1-1H1c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1s1-.45 1-1v-1.67C3.99 20.15 7.28 22 11 22Zm0-22C5.3 0 .61 4.34.05 9.9c-.05.59.41 1.1 1 1.1.51 0 .94-.38.99-.88C2.48 5.56 6.33 2 11 2c3.12 0 5.87 1.59 7.48 4H17c-.55 0-1 .45-1 1s.45 1 1 1h4c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1.67A11.001 11.001 0 0 0 11 0Zm-.88 4.88c0-.49.4-.88.88-.88s.88.39.88.88v.37c1.07.19 1.75.76 2.16 1.3.34.44.16 1.08-.36 1.3-.36.15-.78.03-1.02-.28-.28-.38-.78-.77-1.6-.77-.7 0-1.81.37-1.81 1.39 0 .95.86 1.31 2.64 1.9 2.4.83 3.01 2.05 3.01 3.45 0 2.62-2.5 3.13-3.02 3.22v.37c0 .48-.39.88-.88.88s-.88-.39-.88-.88v-.42c-.63-.15-1.93-.61-2.69-2.1-.23-.44.03-1.02.49-1.2.41-.16.9-.01 1.11.38.32.61.95 1.37 2.12 1.37.93 0 1.98-.48 1.98-1.61 0-.96-.7-1.46-2.28-2.03-1.1-.39-3.35-1.03-3.35-3.31 0-.1.01-2.4 2.62-2.96v-.37Z" fill="${encodeURIComponent(
      "#fff"
    )}"/></svg>') center center no-repeat`,
  },
  "&.focusVisible": {
    backgroundColor: "#79b",
  },
  "&.checked": {
    transform: "translateX(1.33rem)",
    backgroundColor: theme.palette.primary.main,
  },
}));

const SwitchTrack = styled("span")(({ theme }) => ({
  "&": {
    backgroundColor: theme.palette.defaultLight.main,
    borderRadius: 5,
    width: "100%",
    height: "100%",
    display: "block",
  },
  ".checked &": {
    backgroundColor: theme.palette.primary.light,
  },
}));

const InformerMessage = ({ value, orgId }) => {
  return (
    <Box display="flex" alignItems="start">
      <CheckIcon
        sx={{
          color: theme.palette.success.main,
        }}
      />
      <Box display="flex" flexDirection="column" marginLeft=".78rem">
        <Box fontSize=".95rem" fontWeight="700">
          Force currency conversion is {value ? "on" : "off"}
        </Box>
        <Box fontSize=".8rem" fontWeight="400">
          {value ? (
            <>
              All money metrics were converted into default organisation
              currency. You can change default currency in{" "}
              <Link
                to={`/org/${orgId}/organisation`}
                style={{ color: theme.palette.primary.main }}
              >
                organisation settings
              </Link>
            </>
          ) : (
            <>
              All money metrics will be shown &#34;as is&#34; in account where
              possible.
              <br />
              <strong>NOTE</strong>: if the certain screen contains data with
              several currencies - currency will be converted into default
              organisation currency (can be changed in{" "}
              <Link
                to={`/org/${orgId}/organisation`}
                style={{ color: theme.palette.primary.main }}
              >
                settings
              </Link>
              ) regardless
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

InformerMessage.propTypes = {
  value: PropTypes.bool,
  orgId: PropTypes.number,
};

const ForceConversionSwitcher = (props) => {
  const user = useUser();
  const { getInputProps } = useSwitch(props);
  const classes = useStyles();
  const informer = useInformer();

  const stateClasses = {
    checked: JSON.parse(user.forceConversion) === 1,
  };

  const changeHandle = () => {
    const value = !JSON.parse(user.forceConversion) ? 1 : 0;

    user.setForceConversion(value);
    setInStorage(`forceConversion_${user?.selectedOrg?.orgId}`, value);
    informer.showNotice(
      <InformerMessage value={value} orgId={user?.selectedOrg?.orgId} />,
      { replaceKey: "forceConversion" }
    );
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Title>
        Force currency conversion
        <CTooltip
          tooltipClassName={classes.tooltip}
          customLabel={
            <InfoOutlined
              sx={{
                marginLeft: ".3rem",
                width: "1.05rem",
                height: "1.05rem",
              }}
              color="primary"
            />
          }
          content={
            <>
              Force currency conversion. If turned on - all money metrics will
              be converted into default organisation currency (can be changed in{" "}
              <Link
                to={`/org/${user?.selectedOrg?.orgId}/organisation`}
                style={{ color: theme.palette.primary.main }}
              >
                organisation settings
              </Link>
              ). If turned off - all money metrics will be shown &#34;as is&#34;
              in account where possible.
              <br />
              <br />
              <strong>NOTE</strong>: if the certain screen contains data with
              several currencies - currency will be converted into default
              organisation currency regardless
            </>
          }
          type={informTypes.info}
        />
      </Title>

      <SwitchRoot className={clsx(stateClasses)}>
        <SwitchTrack>
          <SwitchThumb className={clsx(stateClasses)} />
        </SwitchTrack>
        <SwitchInput
          {...getInputProps()}
          onChange={changeHandle}
          aria-label="Force currency conversion"
        />
      </SwitchRoot>
    </Box>
  );
};

export default ForceConversionSwitcher;
