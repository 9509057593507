import React from "react";
import PropTypes from "prop-types";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import CTooltip, { placements } from "./CTooltip";
import { Box } from "@mui/material";
import {
  formatValue,
  getCurrencySymbol,
  moneyFormatter,
  nFormatter,
  nMoneyFormatter,
  percentFormatter,
  scoreFormatter,
} from "../../utils";
import CSkeleton from "./CSkeleton";
import theme from "../../theme";
import WoW from "./WoW";

export const types = {
  number: "number",
  float: "float",
  money: "money",
  score: "score",
};

const InfoPanel = ({
  title,
  tooltip,
  currentValue,
  previousValue,
  type,
  currency,
  periods,
  reverse,
  loading,
}) => {
  currentValue = parseFloat(currentValue);
  previousValue = parseFloat(previousValue);
  let delta =
    previousValue && ((currentValue - previousValue) / previousValue) * 100;
  let clippedValue;
  let fullValue;
  let unit;

  if (!loading && !isNaN(parseFloat(currentValue)) && isFinite(currentValue)) {
    if (currency) {
      type = types.money;
    }

    switch (type) {
      case types.money:
        clippedValue = nMoneyFormatter(currentValue);
        unit = getCurrencySymbol(currency);
        fullValue = moneyFormatter({
          num: currentValue,
          currency,
          clipped: false,
        });
        break;
      case types.score:
        clippedValue = scoreFormatter(currentValue);
        fullValue = percentFormatter(currentValue);
        break;
      case types.number:
        clippedValue = nFormatter(currentValue, false);
        fullValue = formatValue(Math.round(currentValue * 100) / 100);
        break;
      default:
        clippedValue = nFormatter(currentValue);
        fullValue = nFormatter(currentValue);
    }
  }

  const deltaRender = () => {
    let upColor = "success";
    let downColor = "error";

    if (reverse) {
      downColor = "success";
      upColor = "error";
    }

    return delta ? (
      <div className="info_panel__delta">
        <div>{(delta > 0 ? "+" : "") + percentFormatter(delta)}</div>
        {delta > 0 ? (
          <ArrowUpward
            color={upColor}
            sx={{
              fontSize: "1.35714rem",
              marginRight: "-1rem",
            }}
          />
        ) : (
          <ArrowDownward
            color={downColor}
            sx={{
              fontSize: "1.35714rem",
              marginRight: "-1rem",
            }}
          />
        )}
      </div>
    ) : (
      ""
    );
  };

  return (
    <div className="info_panel block">
      <Box
        className="second_subtitle info_panel__title"
        display="flex"
        alignItems="center"
      >
        {loading ? <CSkeleton /> : title + (unit ? `, ${unit}` : "")}
        {tooltip && (
          <CTooltip {...tooltip} labelClassName="info_panel__title_tooltip" />
        )}
      </Box>
      <div className="info_panel__content">
        {loading ? (
          <>
            <div className="info_panel__value">
              <CSkeleton />
            </div>
            <div className="info_panel__delta">
              <CSkeleton />
            </div>
          </>
        ) : (
          <>
            {!isNaN(parseFloat(currentValue)) && isFinite(currentValue) ? (
              <>
                <CTooltip
                  placement={placements.topStart}
                  customLabel={
                    <div className="info_panel__value">{clippedValue}</div>
                  }
                  content={fullValue}
                />
                {delta && periods ? (
                  <WoW
                    tooltipClassName="info_panel__delta"
                    label={deltaRender()}
                    placement={placements.rightStart}
                    current={currentValue}
                    previous={previousValue}
                    currency={currency}
                    periods={periods}
                    reverse={reverse}
                  />
                ) : (
                  deltaRender()
                )}
              </>
            ) : (
              <Box
                sx={{
                  color: theme.palette.inactive.main,
                }}
              >
                No data
              </Box>
            )}
          </>
        )}
      </div>
    </div>
  );
};

InfoPanel.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.shape(CTooltip.propTypes),
  currentValue: WoW.propTypes.current,
  previousValue: WoW.propTypes.previous,
  type: PropTypes.oneOf(Object.values(types)),
  currency: PropTypes.string,
  periods: WoW.propTypes.periods,
  reverse: PropTypes.bool,
  loading: PropTypes.bool,
};

InfoPanel.defaultProps = {
  type: types.float,
  loading: false,
};

export default InfoPanel;
