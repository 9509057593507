import React, { useEffect, useState } from "react";
import CButton from "../common/CButton";
import CDialog from "../common/CDialog";
import { Link, useParams } from "react-router-dom";
import { AccessEntity, AccessLevelsEnum, isAccess } from "../../enum/ACL";
import { useUser } from "../../context/UserProvider";
import PropTypes from "prop-types";

const NotAllowedDialog = ({ open }) => {
  const user = useUser();
  const params = useParams();
  const orgId = Number(params.orgId);
  const [accessLevel, setAccessLevel] = useState(null);

  useEffect(() => {
    if (user.ACL) {
      setAccessLevel(user.ACL?.[AccessEntity.Org]);
    }
  }, [user.ACL]);

  return (
    <CDialog
      open={open}
      title="This functionality is not included in your subscription"
      content={
        <>
          <div className="m-b-1">
            Please upgrade your subscription to use this page and many more
            features
          </div>
          {isAccess(AccessLevelsEnum.manage, accessLevel) && (
            <div className="text-right">
              <CButton
                className="button f-w-700"
                variant="contained"
                color="primary"
                component={Link}
                to={`/org/${orgId}/plan-selection`}
              >
                Choose plan
              </CButton>
            </div>
          )}
        </>
      }
      hideActions
      closeable={false}
      container={() => document.getElementById("content-wrapper")}
    />
  );
};

NotAllowedDialog.propTypes = {
  open: PropTypes.bool,
};

export default NotAllowedDialog;
