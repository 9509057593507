import React, { useCallback, useEffect, useState } from "react";
import MainTemplate from "../../../templates/MainTemplate";
import { useNavigate, useParams, Link } from "react-router-dom";
import InfoBar from "../../../components/common/InfoBar";
import TabBlock from "../../../components/TabBlock";
import Recommendations from "../../../components/Recommendation/Recommendations";
import CampaignAdGroupMetrics from "../../../components/CampaignAdGroupReport/CampaignAdGroupMetrics";
import CampaignAdGroupCharts from "../../../components/CampaignAdGroupReport/CampaignAdGroupCharts";
import CampaignAdGroupInfoTable, {
  contentTypes as infoContentTypes,
} from "../../../components/CampaignAdGroupReport/CampaignAdGroupInfoTable";
import {
  dateSendFormat,
  dateFromUnix,
  getCountry,
  getLanguage,
  getPeriods,
  isDev,
  sleep,
  htmlDecode,
  colors,
  nFormatter,
} from "../../../utils";
import {
  metrics as enumMetrics,
  reverseGrowthMetrics,
} from "../../../enum/Metrics";
import adwizyApi from "../../../api/adwizyApi";
import { useUser } from "../../../context/UserProvider";
import { Box } from "@mui/material";
import AppId from "../../../helpers/appId";
import { useInformer } from "../../../context/InformerProvider";
import NetworkIcon from "../../../components/network/NetworkIcon";
import { types as infoPanelTypes } from "../../../components/common/InfoPanel";
import { useEvent, events } from "../../../context/EventProvider";
import { getMetricsScoresAndRecsHandler } from "../../../helpers/eventHandlers";
import deepEqual from "deep-equal";
import AppIcon from "../../../components/common/AppIcon";
import ValuePicker from "../../../components/common/ValuePicker";
import { StatusesEnum, StatusesMapping } from "../../../enum/Statuses";
import { CampaignTypesMapping } from "../../../enum/CampaignTypes";
import { GoalsMapping } from "../../../enum/Goals";
import { StrategiesEnum } from "../../../enum/Strategies";
import { CampaignLevelNetworks } from "../../../enum/CampaignNetworks";
import CSkeleton from "../../../components/common/CSkeleton";
import CTable, {
  types,
  types as tableTypes,
} from "../../../components/common/CTable";
import Percent, { variants } from "../../../components/common/Percent";
import StatusIcon from "../../../components/table/StatusIcon";
import {
  chartsIsEmpty,
  getInfoMultipleValuesContent,
  getInfoOptionalContent,
  CampaignAdGroupInfoTooltip,
  campaignAdGroupInfoTitles,
  prepareInfo,
} from "../../../helpers/campaignAdGroup";
import { ReportTypes } from "../../../enum/ReportTypes";
import { MMPConnectionStatusesEnum } from "../../../enum/MMPConnectionStatuses";
import CTooltip from "../../../components/common/CTooltip";

const ReportingCampaignPage = () => {
  const user = useUser();
  const event = useEvent();
  const informer = useInformer();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [campaignStrategy, setCampaignStrategy] = useState(null);
  const [campaignStrategyLoading, setCampaignStrategyLoading] = useState(false);

  const [app, setApp] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [score, setScore] = useState(null);

  const [recs, setRecs] = useState(null);
  const [showHiddenRecs, setShowHiddenRecs] = useState(false);
  const [adGroups, setAdGroups] = useState(null);
  const [adGroupGoalsByResultAdGroups, setAdGroupGoalsByResultAdGroups] =
    useState(null);
  const [allowedAdGroupStatuses, setAllowedAdGroupStatuses] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [charts, setCharts] = useState(null);
  const [info, setInfo] = useState(null);

  const [metricsKey, setMetricsKey] = useState(0);
  const [scoresKey, setScoresKey] = useState(0);
  const [recsKey, setRecsKey] = useState(0);

  const params = useParams();
  let orgId = Number(params.orgId);
  let appId = params.appId;
  let campaignId = Number(params.campaignId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const id = AppId(appId);
    let fApp = user.apps.find((it) => id.isEqual(it.appId));
    if (fApp) {
      fApp = { ...fApp, ...id };
      !deepEqual(fApp, app) && setApp(fApp);
    } else {
      setApp(null);
      navigate(`/org/${orgId}/reporting`);
    }
  }, [appId, user.apps]); // eslint-disable-line react-hooks/exhaustive-deps

  const campaignStrategyChangeHandler = async (val) => {
    setCampaignStrategyLoading(true);

    await adwizyApi
      .put(`/campaign/${campaignId}`, {
        orgId,
        campaignAdGroupStrategy: val,
      })
      .then((res) => {
        if (res.data.success) {
          setCampaignStrategy(val);
        }
      })
      .catch(informer.showErrorNotice);

    setCampaignStrategyLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setLoading(true);
    setCampaign(null);
    setCampaignStrategy(null);
    setInfo(null);

    if (app) {
      const setCampaignData = (campaign) => {
        setCampaign(campaign);
        setCampaignStrategy(campaign.campaignStrategy);
        setInfo(
          prepareInfo([
            {
              title: campaignAdGroupInfoTitles.settings,
              content: [
                {
                  label: "Campaign status",
                  value: campaign.campaignStatus
                    ? StatusesMapping[campaign.campaignStatus]
                    : null,
                },
                getInfoOptionalContent(
                  {
                    label: "Start date",
                    value: dateFromUnix(campaign.tsStartDate),
                  },
                  !!campaign.tsStartDate
                ),
                getInfoOptionalContent(
                  {
                    label: "End date",
                    value: dateFromUnix(campaign.tsEndDate),
                  },
                  !!campaign.tsEndDate
                ),
                getInfoOptionalContent(
                  {
                    label: "Target App",
                    value: campaign.targetApp,
                  },
                  !!campaign.targetApp
                ),
                getInfoMultipleValuesContent(
                  "Targeting location",
                  campaign.targetLocations?.included?.map(
                    (code) => getCountry(code)?.name
                  )
                  // "Any info about location"
                ),
                getInfoMultipleValuesContent(
                  "Targeting location (excluded)",
                  campaign.targetLocations?.excluded?.map(
                    (code) => getCountry(code)?.name
                  )
                  // "Excluded locations"
                ),
                getInfoMultipleValuesContent(
                  "Language",
                  campaign.languages?.map((code) => getLanguage(code)?.name)
                ),
              ],
            },
            {
              title: campaignAdGroupInfoTitles.bidding,
              content: [
                {
                  label: "Campaign type",
                  value: campaign.campaignType
                    ? CampaignTypesMapping[campaign.campaignType]
                    : null,
                },
                getInfoOptionalContent(
                  {
                    label: "Campaign goal",
                    value: campaign.campaignGoal
                      ? GoalsMapping[campaign.campaignGoal]
                      : null,
                  },
                  !!campaign.campaignGoal
                ),
                getInfoMultipleValuesContent(
                  "Target conversion",
                  campaign.targetConversions,
                  "",
                  { tootipTemplate: "ol" }
                ),
                getInfoOptionalContent(
                  {
                    label: "Attribution window",
                    value: campaign.attributionWindow,
                  },
                  campaign.attributionWindow
                ),
                getInfoOptionalContent(
                  {
                    label: campaign.budget?.label,
                    value: campaign.budget?.value,
                    type: infoContentTypes.money,
                    currency: campaign.currency,
                  },
                  !!campaign.budget
                ),
                getInfoOptionalContent(
                  {
                    label: campaign.bid?.label,
                    value: campaign.bid?.value,
                    type: campaign.bid?.type,
                    currency: campaign.currency,
                  },
                  !!campaign.bid
                ),
              ],
            },
            {
              title: campaignAdGroupInfoTitles.creatives,
              content: [
                {
                  label: "Text",
                  value: campaign.creatives.text,
                },
                {
                  label: "Image",
                  value: campaign.creatives.image,
                },
                {
                  label: "Video",
                  value: campaign.creatives.video,
                },
                {
                  label: "HTML5",
                  value: campaign.creatives.html5,
                },
              ],
            },
          ])
        );

        setLoading(false);
      };

      if (isDev) {
        await sleep(300);
        setCampaignData(
          require("../../../mocks/report__campaignInfo.json").data
        );
      } else {
        adwizyApi
          .get("/report/campaign/info", { orgId, campaignId })
          .then((res) => {
            if (res.data.success) {
              setCampaignData(res.data.data);
            }
          })
          .catch(informer.showErrorNotice);
      }
    }
  }, [orgId, app, campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchRecs = useCallback(async () => {
    setRecs(null);
    if (app && CampaignLevelNetworks[campaign?.network] && campaignStrategy) {
      if (isDev) {
        await sleep(400);
        setRecs({
          ...require("../../../mocks/report__campaignAdGroupRecs.json").data,
        });
      } else {
        adwizyApi
          .get("/recommendations/list", {
            orgId,
            campaignId,
            campaignAdGroupStrategy: campaignStrategy,
            showHidden: showHiddenRecs,
          })
          .then((res) => {
            if (res.data.success) {
              setRecs(res.data.data);
            }
          })
          .catch(informer.showErrorNotice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, app, campaign, campaignStrategy, showHiddenRecs]);

  useEffect(fetchRecs, [scoresKey, recsKey, fetchRecs]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setAdGroups(null);
    if (app && campaign && !CampaignLevelNetworks[campaign.network]) {
      if (isDev) {
        await sleep(400);
        setAdGroups(
          require("../../../mocks/report__campaignAdGroups.json").data
        );
      } else {
        adwizyApi
          .get("/report/campaign/ad-groups", {
            orgId,
            appId: app.appId,
            appstoreAppId: app.appstoreAppId,
            campaignId,
            dateFrom,
            dateTo,
            forceConversion: user.forceConversion,
          })
          .then((res) => {
            if (res.data.success) {
              const adGroupStatuses = {};
              const adGroupGoals = {};
              for (const adGroup of res.data.data) {
                const adGroupStatus = adGroup.adGroupStatus;
                const adGroupGoal = adGroup.adGroupGoal;
                if (adGroupStatus) {
                  adGroupStatuses[adGroupStatus] =
                    StatusesMapping[adGroupStatus];
                }
                if (adGroupGoal) {
                  adGroupGoals[adGroupGoal] = GoalsMapping[adGroupGoal];
                }
              }

              setAdGroups(res.data.data);
              setAllowedAdGroupStatuses(adGroupStatuses);
              setAdGroupGoalsByResultAdGroups(adGroupGoals);
            }
          })
          .catch(informer.showErrorNotice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, app, campaign, dateFrom, dateTo, user.forceConversion]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setMetrics(null);

    if (app && campaign) {
      if (isDev) {
        await sleep(300);
        setMetrics(require("../../../mocks/report__campaignMetrics.json").data);
      } else {
        adwizyApi
          .get("/report/campaign/metrics", {
            orgId,
            appId: app.appId,
            appstoreAppId: app.appstoreAppId,
            campaignId,
            dateFrom,
            dateTo,
            forceConversion: user.forceConversion,
          })
          .then((res) => {
            if (res.data.success) {
              setMetrics(res.data.data);
            }
          })
          .catch(informer.showErrorNotice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    metricsKey,
    orgId,
    app,
    campaign,
    dateFrom,
    dateTo,
    user.forceConversion,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setScore(null);
    if (app && campaign && campaignStrategy) {
      const periods = getPeriods({ dateFrom, dateTo });
      const setScoreData = (score) =>
        setScore([
          {
            title: "Total cost",
            currentValue: score.cost?.currentValue,
            previousValue: score.cost?.previousValue,
            currency: score.cost?.currency,
            periods,
            reverse: reverseGrowthMetrics.includes(enumMetrics.cost),
          },
          {
            title: "CPA",
            currentValue: score.cpa?.currentValue,
            previousValue: score.cpa?.previousValue,
            currency: score.cpa?.currency,
            periods,
            reverse: reverseGrowthMetrics.includes(enumMetrics.cpa),
          },
          {
            title: "Conversions",
            currentValue: score.conversions?.currentValue,
            previousValue: score.conversions?.previousValue,
            type: infoPanelTypes.number,
            periods,
            reverse: reverseGrowthMetrics.includes(enumMetrics.conversions),
          },
          {
            title: "Score",
            currentValue: score.score?.currentValue,
            type: infoPanelTypes.score,
            periods,
            reverse: reverseGrowthMetrics.includes(enumMetrics.score),
          },
        ]);

      if (isDev) {
        await sleep(300);
        setScoreData(
          require("../../../mocks/report__campaignScoreCard.json").data
        );
      } else {
        const payload = {
          orgId,
          appId: app.appId,
          appstoreAppId: app.appstoreAppId,
          campaignId,
          campaignStrategy,
          dateFrom,
          dateTo,
          forceConversion: user.forceConversion,
        };

        adwizyApi
          .get("/report/campaign/score-card", payload)
          .then((res) => {
            if (res.data.success) {
              setScoreData(res.data.data);
            }
          })
          .catch(informer.showErrorNotice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    metricsKey,
    scoresKey,
    orgId,
    app,
    campaign,
    campaignStrategy,
    dateFrom,
    dateTo,
    user.forceConversion,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setCharts(null);
    if (app && campaign && campaignStrategy) {
      if (isDev) {
        await sleep(500);
        setCharts(require("../../../mocks/report__campaignCharts.json").data);
      } else {
        const payload = {
          orgId,
          appId: app.appId,
          appstoreAppId: app.appstoreAppId,
          campaignId,
          campaignStrategy,
          dateFrom,
          dateTo,
          forceConversion: user.forceConversion,
        };

        adwizyApi
          .get("/report/campaign/charts", payload)
          .then((res) => {
            if (res.data.success) {
              setCharts(res.data.data);
            }
          })
          .catch(informer.showErrorNotice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    metricsKey,
    recsKey,
    orgId,
    app,
    campaign,
    campaignStrategy,
    dateFrom,
    dateTo,
    user.forceConversion,
  ]);

  useEffect(() => {
    const condition = ({ payload }) =>
      payload?.campaignIds?.includes(campaignId);
    const eventHandlers = {
      [events.orgAppMetricsUpdated]: getMetricsScoresAndRecsHandler(
        () => setMetricsKey((key) => key + 1),
        condition
      ),
      [events.orgAppScoresUpdated]: getMetricsScoresAndRecsHandler(
        () => setScoresKey((key) => key + 1),
        condition
      ),
      [events.orgAppRecsUpdated]: getMetricsScoresAndRecsHandler(
        () => setRecsKey((key) => key + 1),
        condition
      ),
    };

    event.subscribe(eventHandlers).catch(informer.showErrorNotice);
    return () => event.unsubscribe(eventHandlers);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainTemplate
      isAllowed={user.isAllowReport(ReportTypes.base)}
      breadcrumbs={
        app && [
          {
            path: `/org/${orgId}/reporting/app/${appId}`,
            content: (
              <Box className="d-flex align-items-center">
                <AppIcon
                  complexAppId={appId}
                  appstore={app?.appstore}
                  isAppMap={app?.isAppMap}
                  MMPConnectionStatus={app?.mmpConnectionStatus}
                  MMPServiceName={app?.mmpName}
                />
                <Box marginLeft={".5rem"}>{htmlDecode(app?.appName)}</Box>
              </Box>
            ),
          },
        ]
      }
      subtitle={
        campaign && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            fullWidth
          >
            <NetworkIcon
              className="subtitle_icon"
              network={campaign.network}
              customLabel={
                <>
                  <span>{campaign.campaignName}</span>
                  <span className="subtitle_info">
                    id {campaign.externalId}
                  </span>
                </>
              }
            />
            {CampaignLevelNetworks[campaign.network] && (
              <>
                {app.mmpConnectionStatus ===
                MMPConnectionStatusesEnum.Connected ? (
                  <CTooltip
                    customLabel={
                      <div className="value-picker">
                        <div className="value-picker__content without-icon">
                          {StrategiesEnum[campaignStrategy]}
                        </div>
                      </div>
                    }
                    content={
                      "Campaign strategy is selected automatically based on the app target and current campaign performance"
                    }
                  />
                ) : (
                  <ValuePicker
                    values={StrategiesEnum}
                    handleSelected={campaignStrategyChangeHandler}
                    selectedValue={campaignStrategy}
                    loading={loading || campaignStrategyLoading}
                    disabled={campaignStrategyLoading}
                  />
                )}
              </>
            )}
          </Box>
        )
      }
      datepicker={{
        onChange: ({ start, end }) => {
          setDateFrom(start.format(dateSendFormat));
          setDateTo(end.format(dateSendFormat));
        },
        disabled: loading,
        withContext: true,
        withQuery: true,
      }}
      loading={loading}
    >
      <InfoBar
        className="content_block"
        panels={score}
        loading={loading || !score}
        loadingCount={4}
      />
      <TabBlock
        className="content_block"
        queryParam="tab"
        tabs={[
          campaign
            ? CampaignLevelNetworks[campaign.network]
              ? { label: "Recommendations", value: "recommendations" }
              : { label: "Ad groups", value: "adGroups" }
            : { label: <CSkeleton />, value: "firstTab", disabled: true },
          { label: "Charts", value: "charts" },
          {
            label: (
              <Box display="flex" alignItems="center">
                Campaign Information
                <CampaignAdGroupInfoTooltip data={info} />
              </Box>
            ),
            value: "campaignInformation",
          },
        ]}
        panels={[
          campaign
            ? CampaignLevelNetworks[campaign.network]
              ? {
                  value: "recommendations",
                  content:
                    campaign?.campaignStatus === StatusesEnum.Enabled ? (
                      <Recommendations
                        orgId={orgId}
                        campaignId={campaignId}
                        campaignAdGroupStrategy={campaignStrategy}
                        showHiddenRecs={showHiddenRecs}
                        onToggleShowHiddenRecs={setShowHiddenRecs}
                        fetchFunc={fetchRecs}
                        data={{ ...recs }}
                      />
                    ) : (
                      <Box
                        paddingY="5rem"
                        textAlign="center"
                        color={colors.default}
                      >
                        The campaign is not active. Adwizy only shows
                        recommendations for active campaigns.
                      </Box>
                    ),
                  loading: loading || !recs,
                }
              : {
                  value: "adGroups",
                  content: (
                    <CTable
                      loading={loading || !adGroups}
                      rows={adGroups || {}}
                      settings={{
                        columns: {
                          adGroupName: {
                            title: () => "Ad Group name",
                            format: tableTypes.custom,
                            component: ({ adGroupId, adGroupName }) => (
                              <Link
                                to={`/org/${orgId}/reporting/app/${appId}/campaign/${campaignId}/ad-group/${adGroupId}`}
                                className={"link"}
                                title={adGroupName}
                              >
                                {adGroupName}
                              </Link>
                            ),
                            includeTextConditions: true,
                            includeSort: true,
                            sx: {
                              width: "18rem",
                              minWidth: "18rem",
                            },
                            trim: true,
                          },
                          status: {
                            title: () => "Status",
                            format: tableTypes.custom,
                            component: (adGroup) => (
                              <Box
                                display="flex"
                                justifyContent="center"
                                paddingRight="2rem"
                              >
                                <StatusIcon status={adGroup.adGroupStatus} />
                              </Box>
                            ),
                            includeSort: true,
                            customConditions: allowedAdGroupStatuses,
                            sx: {
                              textAlign: "center",
                            },
                          },
                          adGroupGoal: {
                            title: () => "Ad group goal",
                            format: tableTypes.custom,
                            component: (adGroup) =>
                              GoalsMapping[adGroup.adGroupGoal],
                            customConditions: adGroupGoalsByResultAdGroups,
                            includeTextConditions: true,
                            includeSort: true,
                          },
                          cost: {
                            title: () => "Cost",
                            includeNumberConditions: true,
                            includeSort: true,
                            format: types.money,
                          },
                          budgetUtilization: {
                            title: () => "Budget utilization",
                            format: types.custom,
                            component: (adGroup) =>
                              nFormatter(adGroup.budgetUtilization.value),
                            includeNumberConditions: true,
                            includeSort: true,
                          },
                          adGroupAccuracy: {
                            title: () => "Ad group accuracy",
                            format: types.custom,
                            component: (adGroup) =>
                              nFormatter(adGroup.adGroupAccuracy.value),
                            includeNumberConditions: true,
                            includeSort: true,
                          },
                          score: {
                            title: () => "Score",
                            format: types.custom,
                            component: (adGroup) => (
                              <Percent
                                variant={variants.text}
                                value={adGroup.optimizationScore.value}
                              />
                            ),
                            includeNumberConditions: true,
                            includeSort: true,
                          },
                        },
                      }}
                      style={{ border: "none" }}
                    />
                  ),
                  style: { padding: 0 },
                }
            : { value: "firstTab", loading: true },
          {
            value: "charts",
            content: chartsIsEmpty(metrics, charts) ? (
              <Box paddingY="5rem" textAlign="center" color={colors.default}>
                There is no data for this period.
              </Box>
            ) : (
              <Box display="flex" flexDirection="column">
                <CampaignAdGroupMetrics
                  title="Campaign accuracy"
                  data={metrics}
                />
                <Box zIndex={1}>
                  <CampaignAdGroupCharts data={charts} />
                </Box>
              </Box>
            ),
            loading: loading || !metrics || !charts,
          },
          {
            value: "campaignInformation",
            content: <CampaignAdGroupInfoTable data={info} />,
            style: { padding: loading || !info ? undefined : 0 },
            loading: loading || !info,
          },
        ]}
      />
    </MainTemplate>
  );
};

export default ReportingCampaignPage;
