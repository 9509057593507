import React, { useEffect, useState } from "react";
import EmptyDialog from "../EmptyDialog";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  dialogTitleClasses,
  dialogContentClasses,
} from "@mui/material";
import CButton from "./CButton";
import { styled } from "@mui/material/styles";
import Preloader from "../Preloader";
import PropTypes from "prop-types";

const StyledTitle = styled(DialogTitle)(() => ({
  [`&.${dialogTitleClasses.root}`]: {
    color: "#000",
    marginBottom: "1.78rem",
    padding: 0,
    fontSize: "1.3rem",
    fontWeight: 700,
    lineHeight: "1.67rem",
  },
}));

const StyledContent = styled(DialogContent)(({ theme }) => ({
  [`&.${dialogContentClasses.root}`]: {
    marginBottom: "1.56rem",
    padding: 0,
    fontWeight: 500,
    fontSize: "0.9rem",
    lineHeight: "1.44rem",
    color: theme.palette.inactive.main,
  },
}));

const CDialog = ({
  btn,
  open,
  onClose,
  maxWidth,
  fullWidth,
  title,
  content,
  hideActions,
  cancelBtn,
  cancelBtnText,
  syncCancelBtnCb,
  cancelBtnCb,
  cancelBtnStyle,
  applyBtnText,
  syncApplyBtnCb,
  applyBtn,
  applyBtnCb,
  applyBtnStyle,
  loading,
  applyBtnDisabled,
  closeable,
  container,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    open != null && setIsOpen(open);
  }, [open]);

  const localOnClose = () => {
    setIsOpen(false);
    typeof onClose === "function" && onClose();
  };

  const handleCancel = async () => {
    if (cancelBtnCb) {
      await cancelBtnCb().then((res) => {
        if (res !== false) {
          localOnClose();
        }
      });
    } else {
      syncCancelBtnCb && syncCancelBtnCb();
      localOnClose();
    }
  };

  const handleApply = async () => {
    if (applyBtnCb) {
      await applyBtnCb().then((res) => {
        if (res !== false) {
          localOnClose();
        }
      });
    } else {
      syncApplyBtnCb && syncApplyBtnCb();
      localOnClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleApply();
    }
  };

  return (
    <EmptyDialog
      btn={btn}
      onClickBtn={() => setIsOpen(true)}
      onKeyDown={handleKeyDown}
      open={isOpen}
      onClose={localOnClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      loading={loading}
      closeable={closeable}
      container={container}
      sx={{ paper: { padding: "2.22rem" } }}
    >
      {title && <StyledTitle>{title}</StyledTitle>}
      {content && <StyledContent>{content}</StyledContent>}
      {!hideActions && (
        <DialogActions sx={{ padding: 0 }}>
          {loading ? (
            <Preloader size="2.22rem" />
          ) : (
            <>
              {cancelBtn && (
                <CButton
                  color={cancelBtnStyle?.color || "inactive"}
                  variant={cancelBtnStyle?.variant || "text"}
                  onClick={handleCancel}
                  size="small"
                  sx={{ fontSize: "1rem !important" }}
                >
                  {cancelBtnText ?? "Cancel"}
                </CButton>
              )}
              {applyBtn && (
                <CButton
                  color={applyBtnStyle?.color || "primary"}
                  variant={applyBtnStyle?.variant || "contained"}
                  onClick={handleApply}
                  size="small"
                  sx={{ fontSize: "1rem !important" }}
                  disabled={applyBtnDisabled}
                >
                  {applyBtnText ?? "Apply"}
                </CButton>
              )}
            </>
          )}
        </DialogActions>
      )}
    </EmptyDialog>
  );
};

CDialog.propTypes = {
  btn: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  title: PropTypes.node,
  content: PropTypes.node,
  hideActions: PropTypes.bool,
  cancelBtn: PropTypes.bool,
  cancelBtnText: PropTypes.string,
  syncCancelBtnCb: PropTypes.func,
  cancelBtnCb: PropTypes.func,
  cancelBtnStyle: PropTypes.object,
  applyBtnText: PropTypes.string,
  syncApplyBtnCb: PropTypes.func,
  applyBtn: PropTypes.bool,
  applyBtnCb: PropTypes.func,
  applyBtnStyle: PropTypes.object,
  loading: PropTypes.bool,
  applyBtnDisabled: PropTypes.bool,
  closeable: PropTypes.bool,
  container: PropTypes.func,
};

CDialog.defaultProps = {
  cancelBtn: true,
  applyBtn: true,
};

export default CDialog;
