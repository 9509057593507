import React, { useState } from "react";
import AuthTemplate, { defaultValidate } from "../../templates/AuthTemplate";
import GoBack from "../../components/common/GoBack";
import CInput from "../../components/AuthTemplate/CInput";
import { Box, Button } from "@mui/material";
import { isDev } from "../../utils";
import adwizyApi from "../../api/adwizyApi";
import { useAuth } from "../../context/AuthProvider";
import { useInformer } from "../../context/InformerProvider";
import { useLocation } from "react-router-dom";
import Preloader from "../../components/Preloader";

const ActivatePage = () => {
  const auth = useAuth();
  const informer = useInformer();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(location.hash.split("#code=")[1] || "");
  const [codeError, setCodeError] = useState("");

  const validateCode = (code) => defaultValidate("Code", code, setCodeError);

  const submit = async () => {
    if (validateCode(code)) {
      if (isDev) {
        auth.signInLocal("simpleTokenValue");
      } else {
        setLoading(true);

        await adwizyApi
          .post("/auth/activate", { code })
          .then((res) => {
            if (res.data.success) {
              auth.signInLocal(res.data.data?.token);
            }
          })
          .catch(informer.showErrorNotice);

        setLoading(false);
      }
    }
  };

  return (
    <AuthTemplate>
      <div className="window">
        <GoBack />
        <div className="title">Activation code</div>
        <div className="subtitle">
          We have sent an activation code to your mail. Please check the code
          and insert it in the next field
        </div>
        <div className="input_group">
          <CInput
            id="code"
            label="Code"
            value={code}
            hasError={codeError !== ""}
            helperText={codeError}
            onChange={(e) => setCode(e.target.value)}
            disabled={loading}
            submit={submit}
          />
        </div>
        <div className="actions">
          <Button
            className="button"
            variant="contained"
            onClick={submit}
            color="primary"
            disabled={loading || !code || !!codeError}
          >
            Activate
            {loading && (
              <Box display="flex" alignItems="center" marginLeft={1}>
                <Preloader size={30} />
              </Box>
            )}
          </Button>
        </div>
      </div>
    </AuthTemplate>
  );
};

export default ActivatePage;
