import React from "react";
import moment from "moment";
import {
  Error,
  ErrorOutlined,
  Info,
  InfoOutlined,
  Warning,
  WarningAmber,
  AutoAwesome,
  AutoAwesomeOutlined,
} from "@mui/icons-material";
import {
  countries as countriesData,
  languages as languagesData,
} from "country-data-list";
import { getTitleOfNetwork } from "./enum/CampaignNetworks";
import { metricTypes, metricTypesMap } from "./enum/Metrics";

export const isDev = process.env.REACT_APP_IS_DEV === "true";
export const debug = process.env.REACT_APP_DEBUG === "true";
export const baseURL = (process.env.REACT_APP_ADWIZY_API_HOST || "") + "/papi";

export const colors = {
  default: "#9CA7AE",
  lightDefault: "#F6F6F6",
  primary: "#399BDC",
  primaryLight: "#E3EFF7",
  green: "#3D9F1A",
  lightGreen: "#E5FDDC",
  yellow: "#E6AE20",
  lightYellow: "#FFF7E4",
  red: "#E71E1E",
  lightRed: "#FFE5E5",
};

export const chartColors = [
  "#31BAF6",
  "#B642DF",
  "#465ED7",
  "#FFBC00",
  "#E91B1B",
  "#B5CE39",
  "#00AC6B",
  "#A4A4A4",
];

export const informTypes = {
  default: "default",
  info: "info",
  success: "success",
  warning: "warning",
  error: "error",
  feature: "feature",
};

/**
 * @param {String} color
 * @returns {String}
 */
export const getLightColor = (color) => {
  switch (color) {
    case colors.green:
      return colors.lightGreen;
    case colors.yellow:
      return colors.lightYellow;
    case colors.red:
      return colors.lightRed;
    case colors.default:
      return colors.lightDefault;
    default:
      return colors.default;
  }
};

const locale = "en-EN";

export const UCFirst = (text) => text.charAt(0).toUpperCase() + text.slice(1);

export const LCWithoutFirst = (text) =>
  text.charAt(0) + text.slice(1).toLowerCase();

/**
 * @param {object} obj
 * @param {function(key: string, value: any): {key: string, value: any}} preparator
 */
export const cloneObject = (obj, preparator = null) => {
  if (typeof preparator === "function") {
    const clone = {};
    Object.entries(obj).forEach(([key, value]) => {
      let { key: newKey, value: newValue } = preparator(key, value);
      clone[newKey] = newValue;
    });

    return clone;
  }

  return { ...obj };
};

/**
 * @param {?Number} value
 * @param {Object} options
 * @returns {String}
 */
export const formatValue = (value, options = {}) => {
  if (value === null || value === undefined) {
    return "-";
  }

  return value.toLocaleString(locale, options);
};

export const dateSendFormat = "YYYY-MM-DD";
export const dateRenderFormat = "ll";
export const dateTimeRenderFormat = "ll [at] HH:mm";

/**
 * @param {Number} timestamp
 * @param {String} format
 * @returns {String}
 */
export const dateFromUnix = (timestamp, format = dateRenderFormat) =>
  timestamp ? moment.unix(timestamp).format(format) : null;

/**
 * @param {[Object]} array
 * @param {String} param
 * @param {1|-1} to
 * @returns {[Object]}
 */
export const sortObjectsByParam = (array, param, to = 1) =>
  array
    .slice()
    .sort((a, b) =>
      a[param] > b[param] ? to : a[param] < b[param] ? -1 * to : 0
    );

/**
 * @param {Number} val
 * @param {Number} accuracy
 * @returns {Number}
 */
export const formatVal = (val, accuracy = 100) =>
  val != null ? Math.round(val * accuracy) / accuracy : null;

const lookup = [
  { value: 1e3, symbol: "k" },
  { value: 1e6, symbol: "M" },
  { value: 1e9, symbol: "B" },
  { value: 1e12, symbol: "T" },
  { value: 1e15, symbol: "P" },
  { value: 1e18, symbol: "E" },
];

/**
 * @param {Number} num
 * @param {Boolean} isFloat
 * @returns {String|Number}
 */
export const nFormatter = (num, isFloat = true) => {
  if (num === null || num === undefined) {
    return "–";
  }
  if (num >= 100000) {
    num = Math.round(num / 100) * 100;
  }
  if (num >= 1000 && num < 10000) {
    return parseFloat(num.toFixed(0)).toLocaleString(locale);
  }

  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);

  const fixedNum = parseFloat(item ? num / item.value : num);
  let res;

  const absNum = Math.abs(num);
  const absFixedNum = Math.abs(fixedNum);
  if (absNum < 10000) {
    if (absNum < 100) {
      res = fixedNum.toFixed(2);
      if (isFloat) {
        res = parseFloat(res).toFixed(2);
      } else {
        res = Math.round(res);
      }
    } else {
      res = fixedNum.toFixed(1);
      res = isFloat ? parseFloat(res).toFixed(1) : parseInt(res);
    }
  } else {
    let fractionDigits = 1;
    if (absFixedNum <= 10) {
      fractionDigits = 3;
    } else if (absFixedNum <= 100) {
      fractionDigits = 2;
    }
    res = parseFloat(fixedNum.toFixed(fractionDigits));
  }

  if (parseFloat(res) === 0) {
    return 0;
  }

  return (item ? res + item.symbol : res).toLocaleString(locale);
};

/**
 * @param {Number} num
 * @returns {String|Number}
 */
export const nMoneyFormatter = (num) => {
  if (num === null || num === undefined) {
    return "–";
  }
  if (num >= 100000) {
    num = Math.round(num / 100) * 100;
  }
  if (num >= 1000 && num < 10000) {
    return parseFloat(num.toFixed(0)).toLocaleString(locale);
  }

  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);

  const fixedNum = parseFloat(item ? num / item.value : num);
  let res;

  const absNum = Math.abs(num);
  const absFixedNum = Math.abs(fixedNum);
  if (absNum < 1000) {
    res = fixedNum;
  } else {
    let fractionDigits = 1;
    if (absFixedNum < 10) {
      fractionDigits = 3;
    } else if (absFixedNum < 100) {
      fractionDigits = 2;
    }
    res = parseFloat(fixedNum.toFixed(fractionDigits));
  }

  return (item ? res + item.symbol : parseFloat(res).toFixed(2)).toLocaleString(
    locale
  );
};

/**
 * @param {Number} num
 * @param {String} currency
 * @param {Boolean} clipped
 * @returns {String}
 */
export const moneyFormatter = ({ num, currency, clipped = true }) => {
  if (num === null || num === undefined) {
    return "-";
  }

  return clipped
    ? (0)
        .toLocaleString(locale, {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          currencyDisplay: "narrowSymbol",
        })
        .replace(/\d/g, nMoneyFormatter(num))
    : num.toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currencyDisplay: "narrowSymbol",
      });
};

/**
 * @param {Number} ts
 * @returns {Promise}
 */
export const sleep = (ts = 5000) => {
  // imitation of server response
  return new Promise((resolve) => setTimeout(resolve, ts));
};

/**
 * @param {String} type
 * @param {Object} props
 * @returns {React.ReactNode}
 */
export const getFilledIcon = (type, props = {}) => {
  switch (type) {
    case informTypes.info:
      return <Info color="primary" {...props} />;
    case informTypes.success:
      return <Info color="success" {...props} />;
    case informTypes.warning:
      return <Error color="warning" {...props} />;
    case informTypes.error:
      return <Warning color="error" {...props} />;
    case informTypes.feature:
      return <AutoAwesomeOutlined color="primary" {...props} />;
    default:
      return "";
  }
};

/**
 * @param {String} type
 * @param {Object} props
 * @returns {React.ReactNode}
 */
export const getOutlinedIcon = (type, props = {}) => {
  switch (type) {
    case informTypes.info:
      return <InfoOutlined color="primary" {...props} />;
    case informTypes.success:
      return <InfoOutlined color="success" {...props} />;
    case informTypes.warning:
      return <ErrorOutlined color="warning" {...props} />;
    case informTypes.error:
      return <WarningAmber color="error" {...props} />;
    case informTypes.feature:
      return <AutoAwesome color="primary" {...props} />;
    default:
      return "";
  }
};

/**
 *
 * @param currency
 * @returns {string}
 */
export const getCurrencySymbol = (currency) =>
  (0)
    .toLocaleString(locale, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      currencyDisplay: "narrowSymbol",
    })
    .replace(/\d/g, "")
    .trim();

/**
 * @returns {Array}
 */
export const getCurrenciesList = () =>
  require("./assets/currency-data-list.json");

/**
 * @returns {Object}
 */
export const getCurrencies = () =>
  getCurrenciesList().reduce(
    (result, current) => ({
      ...result,
      [current.code]: `${current.name} (${getCurrencySymbol(current.code)})`,
    }),
    {}
  );

/**
 * @param {String} currencyCode
 * @returns {?Object}
 */
export const getCurrency = (currencyCode) =>
  getCurrenciesList().find((it) => it.code === currencyCode);

const unnecessaryCountryCodes = [
  "IRN",
  "RUS",
  "BLR",
  "VEN",
  "CHN",
  "PRK",
  "AFG",
  "AIA",
  "ATA",
  "BRB",
  "BLZ",
  "BEN",
  "BMU",
  "BTN",
  "BOL",
  "BES",
  "BVT",
  "IOT",
  "BFA",
  "CAF",
  "CXR",
  "CCK",
  "COK",
  "CUW",
  "FLK",
  "FRO",
  "ATF",
  "GAB",
  "GMB",
  "GLP",
  "GUM",
  "GGY",
  "GUY",
  "HMD",
  "LAO",
  "LSO",
  "LBR",
  "LBY",
  "MKD",
  "MHL",
  "MTQ",
  "MYT",
  "FSM",
  "MSR",
  "NRU",
  "NIU",
  "NFK",
  "MNP",
  "PLW",
  "PSE",
  "PCN",
  "BLM",
  "SHN",
  "KNA",
  "LCA",
  "MAF",
  "SPM",
  "VCT",
  "STP",
  "SEN",
  "SXM",
  "SLB",
  "SOM",
  "SGS",
  "SSD",
  "SUR",
  "SJM",
  "SYR",
  "TLS",
  "TGO",
  "TKL",
  "TON",
  "TTO",
  "TCA",
  "TUV",
  "UMI",
  "VUT",
  "VAT",
  "VGB",
  "VIR",
  "WLF",
  "ESH",
  "ALA",
  "COD",
  "COG",
];

/**
 * @returns {Array}
 */
export const getCountriesList = () =>
  sortObjectsByParam(
    countriesData.all.filter((it) => it.status === "assigned"),
    "name"
  );

/**
 * @returns {Array}
 */
export const getFilteredCountriesList = () =>
  sortObjectsByParam(
    countriesData.all.filter(
      (it) =>
        it.status === "assigned" && !unnecessaryCountryCodes.includes(it.alpha3)
    ),
    "name"
  );

/**
 * @returns {Object}
 */
export const getCountries = () =>
  getCountriesList().reduce(
    (result, current) => ({
      ...result,
      [current.alpha3]: current.name,
    }),
    {}
  );
/**
 * @returns {Object}
 */
export const getFilteredCountries = () =>
  getFilteredCountriesList().reduce(
    (result, current) => ({
      ...result,
      [current.alpha3]: current.name,
    }),
    {}
  );

/**
 * @param {String} countryCode
 * @returns {?Object}
 */
export const getCountry = (countryCode) =>
  getCountriesList().find((it) => it.alpha3 === countryCode?.toUpperCase());

/**
 * @returns {Array}
 */
export const getLanguagesList = () =>
  languagesData.all.filter((it) => it.alpha2);

/**
 * @param {String} languageCode
 * @returns {?Object}
 */
export const getLanguage = (languageCode) => {
  switch (languageCode) {
    case "bgc":
      return {
        alpha2: "",
        alpha3: "bgc",
        bibliographic: "",
        name: "Haryana",
      };
    case "raj":
      return {
        alpha2: "",
        alpha3: "raj",
        bibliographic: "",
        name: "Rajasthani",
      };
    case "zh_TW":
      return {
        alpha2: "",
        alpha3: "",
        bibliographic: "",
        name: "Chinese (Traditional)",
      };
    default:
      return getLanguagesList().find(
        (it) => it.alpha2 === languageCode?.toLowerCase()
      );
  }
};

/**
 *
 * @param countryCodes
 * @returns {{}}
 */
export const countryMappingFilter = (countryCodes) => {
  const res = {};
  for (const countryCode in countryCodes) {
    res[countryCode] = getCountry(countryCode)?.name;
  }
  return res;
};

/**
 *
 * @param networks
 * @returns {{}}
 */
export const networkMappingFilter = (networks) => {
  const res = {};
  for (const network in networks) {
    res[network] = getTitleOfNetwork(network);
  }
  return res;
};

/**
 * @param {Moment|String} dateFrom
 * @param {Moment|String} dateTo
 * @returns {Object}
 */
export const getPeriods = ({ dateFrom, dateTo }) => {
  const previous = {
    to: moment(dateFrom).subtract(1, "days"),
  };

  previous.from = moment(previous.to).subtract(
    Math.abs(moment(dateFrom).diff(dateTo, "days")),
    "days"
  );

  return {
    current: {
      from: dateFrom instanceof moment ? dateFrom : moment(dateFrom),
      to: dateTo instanceof moment ? dateTo : moment(dateTo),
    },
    previous,
  };
};

/**
 *
 * @param {Array} a
 * @param {Array} b
 * @returns {false|*}
 */
export const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

/**
 *
 * @param input
 * @returns {string}
 */
export const htmlDecode = (input) => {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

/**
 *
 * @param num
 * @returns {String|Number|number}
 */
export const conversionsFormatter = (num) => {
  if (num < 1000) {
    return parseFloat(parseFloat(nFormatter(num)).toFixed(1));
  } else {
    return nFormatter(num);
  }
};

/**
 *
 * @param num
 * @returns {string}
 */
export const percentFormatter = (num) => {
  if (num === null || num === undefined) {
    return "–";
  }
  if (num >= 100000) {
    num = Math.round(num);
  }

  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);

  const fixedNum = parseFloat(item ? num / item.value : num);
  let res;

  const absNum = Math.abs(num);
  const absFixedNum = Math.abs(fixedNum);
  if (absNum < 10000) {
    if (absNum < 10) {
      res = fixedNum.toFixed(2);
    } else if (absNum < 100) {
      res = fixedNum.toFixed(1);
    } else if (absNum < 1000) {
      res = Math.round(fixedNum);
    } else {
      res = parseFloat(parseFloat(fixedNum.toFixed(3)).toFixed(2));
    }
  } else {
    if (absFixedNum <= 10) {
      res = Math.round(fixedNum * 100) / 100;
    } else if (fixedNum <= 100) {
      res = Math.round(fixedNum * 10) / 10;
    } else if (fixedNum <= 1000) {
      res = Math.round(fixedNum);
    }
  }

  if (parseFloat(res) === 0) {
    res = 0;
  }

  return (item ? res + item.symbol : res) + "%";
};

export const scoreFormatter = (num) => {
  if (num === null || num === undefined) {
    return "–";
  }
  return num.toFixed(0);
};

export const metricFormatter = ({
  num,
  metric = null,
  currency = null,
  clipped = false,
}) => {
  switch (metricTypesMap[metric]) {
    case metricTypes.conversions:
      return conversionsFormatter(num);
    case metricTypes.number:
      return nFormatter(num, false);
    case metricTypes.money:
      if (currency) {
        return moneyFormatter({ num, currency, clipped });
      } else {
        return nMoneyFormatter(num);
      }
    // case "percent":
    //     return percentFormatter(nFormatter(num))
    case metricTypes.float:
    default:
      return nFormatter(num);
  }
};

export const getStateFromSetter = (setState) => {
  if (typeof setState === "function") {
    let value;
    setState((prev) => {
      value = prev;
      return prev;
    });

    return value;
  }

  return setState;
};

export const updateObjectStateBySetter = (setState, data) => {
  if (typeof setState === "function") {
    setState((state) => ({ ...state, ...data }));
  }
};
