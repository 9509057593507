import axios from "axios";
import { baseURL, debug } from "../utils";
import { getFromStorage, setInStorage } from "../localStorageUtils";
const getToken = () => getFromStorage("authToken");
const clientConnectionId = Math.floor(Math.random() * 4294967295);

const defaultOptions = {
  baseURL,
  reqHandleFunc: (config) => {
    const token = getToken();
    token && (config.headers.Authorization = `Bearer ${token}`);
    config.headers["X-Client-Connection-Id"] = clientConnectionId;

    return config;
  },
  reqErrorFunc: (error) => {
    debug && console.error(error);
    return Promise.reject(error);
  },
  resHandleFunc: (response) => response,
  resErrorFunc: (error) => {
    debug && console.error(error);

    if (
      error?.response?.status === 401 &&
      window.location.pathname.indexOf("sign-in") === -1
    ) {
      let { hash, pathname, search } = window.location;
      setInStorage(
        "locationStateFrom",
        JSON.stringify({ pathname, search, hash })
      );
      window.location.href = "/auth/sign-out?force=true";
    }

    return Promise.reject(error);
  },
};

const initOptions = {
  ...defaultOptions,
};

const service = axios.create(initOptions);

service.interceptors.request.use(
  (config) => initOptions.reqHandleFunc(config),
  (error) => initOptions.reqErrorFunc(error)
);

service.interceptors.response.use(
  (response) => initOptions.resHandleFunc(response),
  (error) => initOptions.resErrorFunc(error)
);

const action = (opt) => {
  return service(opt);
};

const adwizyApi = {
  /**
   * @param {string} url
   * @param {object} data
   * @param {object} options
   */
  get: (url, data, options = {}) =>
    action({
      ...options,
      ...{
        method: "get",
        url,
        params: data,
      },
    }),
  /**
   * @param {string} url
   * @param {object} data
   * @param {object} options
   */
  post: (url, data, options = {}) =>
    action({
      ...options,
      ...{
        method: "post",
        url,
        data,
      },
    }),
  /**
   * @param {string} url
   * @param {object} data
   * @param {object} options
   */
  put: (url, data, options = {}) =>
    action({
      ...options,
      ...{
        method: "put",
        url,
        data,
      },
    }),
  /**
   * @param {string} url
   * @param {object} data
   * @param {object} options
   */
  delete: (url, data, options = {}) =>
    action({
      ...options,
      ...{
        method: "delete",
        url,
        data,
      },
    }),
};

export default adwizyApi;
