import React, { useState } from "react";
import AuthTemplate, {
  validateEmail,
  generatePassword,
  defaultValidate,
} from "../../templates/AuthTemplate";
import { Button, FormControlLabel, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useInformer } from "../../context/InformerProvider";
import PasswordInput from "../../components/AuthTemplate/PasswordInput";
import CCheckbox from "../../components/common/CCheckbox";
import CInput from "../../components/AuthTemplate/CInput";
import Preloader from "../../components/Preloader";

const SignInPage = () => {
  const auth = useAuth();
  const informer = useInformer();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [remember, setRemember] = useState(false);

  const validatePassword = (password) =>
    defaultValidate("Password", password, setPasswordError);

  const submit = async () => {
    let isOk = validateEmail(email, setEmailError);
    isOk = validatePassword(password) && isOk;

    if (isOk) {
      setLoading(true);

      await auth
        .signIn({
          email,
          password: generatePassword(password),
          remember,
        })
        .catch((err) => {
          if (err?.response?.data?.errors?.account === "notActivated") {
            navigate("/auth/activate");
          }

          informer.showErrorNotice(err);
        });

      setLoading(false);
    }
  };

  return (
    <AuthTemplate>
      <div className="window">
        <div className="title">Sign in to Adwizy</div>
        <div className="input_group">
          <CInput
            id="email"
            label="Email"
            value={email}
            hasError={emailError !== ""}
            helperText={emailError}
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail(e.target.value, setEmailError);
            }}
            disabled={loading}
            submit={submit}
          />
          <PasswordInput
            id="password"
            value={password}
            hasError={passwordError !== ""}
            helperText={passwordError}
            onChange={(e) => {
              setPassword(e.target.value);
              validatePassword(e.target.value);
            }}
            disabled={loading}
            submit={submit}
          />
        </div>
        <Box
          className="choose_block"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControlLabel
            className="choose_block__checkbox"
            label="Remember me"
            control={
              <CCheckbox
                id="rememberme"
                value="1"
                checked={remember}
                onChange={() => setRemember((prev) => !prev)}
                disabled={loading}
              />
            }
          />
          <Link to="/auth/restore-password">Forgot your password?</Link>
        </Box>
        <div className="actions">
          <Button
            className="button"
            variant="contained"
            onClick={submit}
            color="primary"
            disabled={
              loading || !email || !!emailError || !password || !!passwordError
            }
          >
            Sign in
            {loading && (
              <Box display="flex" alignItems="center" marginLeft={1}>
                <Preloader size={30} />
              </Box>
            )}
          </Button>
        </div>
        <div className="alternative_offer">
          Don&#39;t have an account? <Link to="/auth/sign-up">Sign up</Link>
        </div>
      </div>
    </AuthTemplate>
  );
};

export default SignInPage;
