import React from "react";
import { Grid } from "@mui/material";
import CTooltip, { placements } from "../common/CTooltip";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { informTypes, moneyFormatter, percentFormatter } from "../../utils";
import KeyValueTable from "../KeyValueTable";

export const contentTypes = {
  number: "number",
  string: "string",
  money: "money",
  percent: "percent",
};

const tooltip = (tooltip, type) => {
  if (!tooltip || typeof tooltip !== "object") {
    return "";
  }

  const details = tooltip.details ? (
    <CTooltip
      placement={placements.top}
      label={<div className="tooltip_content__details">Details</div>}
      content={tooltip.details}
    />
  ) : (
    tooltip.link && (
      <Link to={tooltip.link} className="tooltip_content__details_link">
        Details
      </Link>
    )
  );

  return (
    <CTooltip
      type={type}
      label={tooltip.label}
      content={
        details ? (
          <div className="tooltip_content__wrap">
            <div>{tooltip.title}</div>
            {details}
          </div>
        ) : (
          tooltip.title
        )
      }
    />
  );
};

const CampaignAdGroupInfoTable = ({ data }) => {
  return (
    <Grid className="campaign_info_table__sections" container>
      {data &&
        data.map((it, i) => (
          <Grid
            key={i}
            item
            xs={12 / data.length}
            className="campaign_info_table__section"
          >
            <div className="campaign_info_table__section__title_row">
              <div className="second_subtitle campaign_info_table__section_title">
                {it.title}
              </div>
              {/*<IconButton sx={{padding: 0}}>*/}
              {/*    <Settings color="borders" fontSize="large"/>*/}
              {/*</IconButton>*/}
            </div>
            <KeyValueTable
              data={it.content.reduce(
                (result, { label, value, type, currency, info, error }) => {
                  result[label] =
                    value == null && info == null && error == null ? null : (
                      <>
                        <div>
                          {type === contentTypes.money
                            ? moneyFormatter({
                                num: value,
                                currency: currency,
                                clipped: false,
                              })
                            : type === contentTypes.percent
                            ? percentFormatter(value)
                            : value}
                        </div>
                        {tooltip(info, informTypes.info)}
                        {tooltip(error, informTypes.error)}
                      </>
                    );

                  return result;
                },
                {}
              )}
            />
          </Grid>
        ))}
    </Grid>
  );
};

CampaignAdGroupInfoTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          type: PropTypes.oneOf(Object.values(contentTypes)),
          currency: PropTypes.string,
          info: PropTypes.shape({
            label: PropTypes.node,
            title: PropTypes.node.isRequired,
            details: PropTypes.node,
            link: PropTypes.node,
          }),
          error: PropTypes.shape({
            label: PropTypes.node,
            title: PropTypes.node.isRequired,
            details: PropTypes.node,
            link: PropTypes.node,
          }),
        })
      ),
    })
  ),
};

export default CampaignAdGroupInfoTable;
