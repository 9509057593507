import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import CTooltip from "./CTooltip";
import { dateTimeRenderFormat } from "../../utils";

const DateAgo = ({ timestamp }) => {
  const [dateAgo, setDateAgo] = useState(null);
  const momentObj = moment(timestamp * 1000);
  const fromNow = () => momentObj.fromNow();

  const tick = () => {
    setDateAgo(fromNow());
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 30000);
    return () => {
      clearInterval(timerID);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CTooltip
      customLabel={<div>{fromNow() ?? dateAgo}</div>}
      content={momentObj.format(dateTimeRenderFormat)}
    />
  );
};

DateAgo.propTypes = {
  timestamp: PropTypes.number.isRequired,
};

export default DateAgo;
