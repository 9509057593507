import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import BarChart, { yaxisContent } from "./charts/BarChart";
import {
  chartColors,
  cloneObject,
  colors,
  isDev,
  moneyFormatter,
  percentFormatter,
  sleep,
  UCFirst,
} from "../utils";
import { metrics, getMetricList, absoluteMetrics } from "../enum/Metrics";
import CTable, { types as tableTypes } from "./common/CTable";
import adwizyApi from "../api/adwizyApi";
import PropTypes from "prop-types";
import DropdownBigText from "./common/DropdownBigText";
import DropdownFilter from "./common/DropdownFilter";

const assetInsightsVideoMetrics = getMetricList(
  metrics.cost,
  metrics.impressions,
  metrics.installs,
  metrics.conversions,
  metrics.cpa,
  metrics.cpi,
  metrics.cpm,
  metrics.ipm,
  metrics.apm,
  metrics.cvr
);

const assetInsightsVideoMetricsUCFirst = cloneObject(
  assetInsightsVideoMetrics,
  (key, value) => ({ key, value: UCFirst(value) })
);

const chartDimensionEnum = {
  lengthCategory: "lengthCategory",
  orientation: "orientation",
};

const chartDimensionLabels = {
  [chartDimensionEnum.lengthCategory]: "duration",
  [chartDimensionEnum.orientation]: "orientation",
};

const orientationEnum = {
  vertical: "v",
  horizontal: "h",
  square: "s",
};

const orientationLabels = {
  [orientationEnum.vertical]: "Vertical",
  [orientationEnum.horizontal]: "Horizontal",
  [orientationEnum.square]: "Square",
};

const tableValueTypes = {
  Absolute: "Absolute",
  Percentage: "Percentage",
};

const AssetInsightsVideo = ({
  orgId,
  filterId,
  forceConversion,
  handleError,
  loading,
  handleChangeLoading,
  style,
}) => {
  const [loadingVideoChart, setLoadingVideoChart] = useState(true);
  const [loadingVideoTable, setLoadingVideoTable] = useState(true);
  const [firstChartMetric, setFirstChartMetric] = useState(
    Object.keys(assetInsightsVideoMetrics)[0]
  );
  const [secondChartMetric, setSecondChartMetric] = useState(
    Object.keys(assetInsightsVideoMetrics)[1]
  );
  const [chartDimension, setChartDimension] = useState(
    Object.keys(chartDimensionEnum)[0]
  );
  const [tableMetric, setTableMetric] = useState(
    Object.keys(assetInsightsVideoMetrics)[0]
  );
  const [tableValueType, setTableValueType] = useState(
    Object.keys(tableValueTypes)[0]
  );
  const [chartData, setChartData] = useState({
    labels: [],
    series: [],
  });
  const [tableData, setTableData] = useState({});

  const getChartOptions = () =>
    chartData.series.length
      ? {
          plotOptions: {
            bar: {
              distributed: false,
              dataLabels: {
                position: "top",
              },
            },
          },
          stroke: {
            colors: ["transparent"],
            width: 10,
          },
          legend: {
            show: true,
          },
          yaxis: [
            {
              title: {
                text: chartData.series[0].name,
              },
              ...yaxisContent(
                chartData?.series[0].currency,
                Math.max(...chartData.series[0].data),
                firstChartMetric
              ),
            },
            {
              title: {
                text: chartData.series[1].name,
              },
              opposite: true,
              ...yaxisContent(
                chartData.series[1].currency,
                Math.max(...chartData.series[1].data),
                secondChartMetric
              ),
            },
          ],
          currencies: [
            chartData.series[0].currency,
            chartData.series[1].currency,
          ],
        }
      : {};

  const prepareTableData = (row, field) => {
    let num = row.data[field];
    switch (row.type) {
      case "money":
        return moneyFormatter({ num, currency: row.currency });
      case "percent":
        return percentFormatter(num && num * 100);
      default:
        return num || "-";
    }
  };

  const tableRowSx = (row, def = {}) =>
    row.lengthCategory === "Total"
      ? {
          color: `${colors.default} !important`,
          fontWeight: "bold",
        }
      : def;

  const tableMetricIsAbsolute = absoluteMetrics.includes(tableMetric);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (filterId) {
      setLoadingVideoChart(true);
      const setChart = (data) => {
        data.labels = data.labels.map((it) =>
          chartDimension === chartDimensionEnum.lengthCategory
            ? `${it} sec`
            : orientationLabels[it]
        );

        data.series.forEach((it) => {
          it.name = assetInsightsVideoMetrics[it.name];
        });

        setChartData(data);
      };

      if (isDev) {
        await sleep(500);
        setChart({ ...require("../mocks/asset__insightVideoChart.json").data });
      } else {
        await adwizyApi
          .get("/report/asset-insights/video/chart", {
            orgId,
            filterId,
            firstMetric: firstChartMetric,
            secondMetric: secondChartMetric,
            dimension: chartDimension,
            forceConversion,
          })
          .then((res) => {
            if (res.data.success) {
              setChart(res.data.data);
            }
          })
          .catch(handleError);
      }

      setLoadingVideoChart(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterId,
    firstChartMetric,
    secondChartMetric,
    chartDimension,
    forceConversion,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (filterId) {
      setLoadingVideoTable(true);
      const setTable = (data) => {
        if (data.length) {
          const totalRow = {
            lengthCategory: "Total",
            data: {},
          };

          data.forEach((item) => {
            item.data.total = 0;
            Object.values(item.data).forEach((value) => {
              item.data.total += value;
            });

            totalRow.type = item.type;
            totalRow.currency = item.currency;
            Object.entries(item.data).forEach(([key, value]) => {
              if (!Object.prototype.hasOwnProperty.call(totalRow.data, key)) {
                totalRow.data[key] = 0;
              }

              totalRow.data[key] += value;
            });
          });

          data.push(totalRow);
        }

        setTableData(data);
      };

      if (isDev) {
        await sleep(300);
        setTable(
          require("../mocks/asset__insightVideoTable.json").data.slice()
        );
      } else {
        await adwizyApi
          .get("/report/asset-insights/video/data", {
            orgId,
            filterId,
            metric: tableMetric,
            percentageConversion: tableMetricIsAbsolute
              ? Number(tableValueType === tableValueTypes.Percentage)
              : 0,
            forceConversion,
          })
          .then((res) => {
            if (res.data.success) {
              setTable(res.data.data);
            }
          })
          .catch(handleError);
      }

      setLoadingVideoTable(false);
    }
  }, [filterId, tableMetric, tableValueType, forceConversion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loadingVideoChart === loadingVideoTable) {
      handleChangeLoading(loadingVideoChart);
    }
  }, [loadingVideoChart, loadingVideoTable]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoadingVideoChart(loading);
    setLoadingVideoTable(loading);
  }, [loading]);

  return (
    <Box className="block-group" style={style}>
      <Box className="block-content">
        <Grid container direction="row" sx={{ marginBottom: "3.61rem" }}>
          <Grid item>
            <DropdownBigText
              title="first chart metric"
              values={assetInsightsVideoMetricsUCFirst}
              selectedValue={firstChartMetric}
              handleSelected={(evt) => setFirstChartMetric(evt.target.value)}
              disabled={loadingVideoChart}
              color={chartColors[0]}
            />
          </Grid>
          <Grid item>
            <DropdownBigText
              leftSubtitle="and"
              title="second chart metric"
              values={assetInsightsVideoMetrics}
              selectedValue={secondChartMetric}
              handleSelected={(evt) => setSecondChartMetric(evt.target.value)}
              disabled={loadingVideoChart}
              color={chartColors[1]}
            />
          </Grid>
          <Grid item>
            <DropdownBigText
              leftSubtitle="by"
              title="chart dimension"
              values={chartDimensionLabels}
              selectedValue={chartDimension}
              handleSelected={(evt) => setChartDimension(evt.target.value)}
              disabled={loadingVideoChart}
            />
          </Grid>
        </Grid>
        <BarChart
          series={chartData.series}
          categories={chartData.labels}
          options={getChartOptions()}
          loading={loadingVideoChart}
          selectedMetric={[firstChartMetric, secondChartMetric]}
        />
      </Box>
      <Box className="block-content">
        <Box display="flex" alignItems="center">
          <DropdownBigText
            title="table metric"
            rightSubtitle="by duration and orientation"
            values={assetInsightsVideoMetricsUCFirst}
            selectedValue={tableMetric}
            handleSelected={(evt) => setTableMetric(evt.target.value)}
            disabled={loadingVideoTable}
          />
          <DropdownFilter
            title="Values"
            selectedValue={
              tableMetricIsAbsolute ? tableValueType : tableValueTypes.Absolute
            }
            values={tableValueTypes}
            handleSelected={setTableValueType}
            disabled={loadingVideoTable || !tableMetricIsAbsolute}
            sx={{
              width: "10rem",
              marginLeft: "auto",
            }}
          />
        </Box>
        <CTable
          pagination={false}
          loading={loadingVideoTable}
          rows={tableData}
          emptyDataText="There is no data"
          settings={{
            columns: {
              lengthCategory: {
                title: () => "Duration",
                format: tableTypes.custom,
                component: ({ lengthCategory }) =>
                  lengthCategory === "Total"
                    ? lengthCategory
                    : `${lengthCategory} sec`,
                sx: tableRowSx,
              },
              vertical: {
                title: () => "Vertical",
                format: tableTypes.custom,
                component: (row) =>
                  prepareTableData(row, orientationEnum.vertical),
                sx: tableRowSx,
              },
              horizontal: {
                title: () => "Horizontal",
                format: tableTypes.custom,
                component: (row) =>
                  prepareTableData(row, orientationEnum.horizontal),
                sx: tableRowSx,
              },
              square: {
                title: () => "Square",
                format: tableTypes.custom,
                component: (row) =>
                  prepareTableData(row, orientationEnum.square),
                sx: tableRowSx,
              },
              total: {
                title: () => "Total",
                format: tableTypes.custom,
                component: (row) => prepareTableData(row, "total"),
                sx: {
                  color: `${colors.default} !important`,
                  fontWeight: "bold",
                },
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

AssetInsightsVideo.propTypes = {
  orgId: PropTypes.number,
  filterId: PropTypes.string,
  forceConversion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleError: PropTypes.func,
  loading: PropTypes.bool,
  handleChangeLoading: PropTypes.func,
  style: PropTypes.object,
};

export default AssetInsightsVideo;
