export const metrics = {
  conversions: "conversions",
  targetConversions: "targetConversions",
  cost: "cost",
  installs: "installs",
  impressions: "impressions",
  clicks: "clicks",
  cpi: "cpi",
  cpa: "cpa",
  cpm: "cpm",
  ipm: "ipm",
  apm: "apm",
  ctr: "ctr",
  cvr: "cvr",
  roas: "roas",
  score: "score",
};

export const metricTypes = {
  conversions: "conversions",
  money: "money",
  number: "number",
  float: "float",
  percent: "percent",
};

export const metricTypesMap = {
  conversions: metricTypes.conversions,
  targetConversions: metricTypes.conversions,
  cost: metricTypes.money,
  installs: metricTypes.number,
  impressions: metricTypes.float,
  clicks: metricTypes.number,
  cpi: metricTypes.money,
  cpa: metricTypes.money,
  ipm: metricTypes.float,
  roas: metricTypes.percent,
  ctr: metricTypes.percent,
};

export const absoluteMetrics = [
  metrics.cost,
  metrics.impressions,
  metrics.installs,
  metrics.conversions,
  metrics.clicks,
];

export const reverseGrowthMetrics = [metrics.cpa, metrics.cpi];

export const getMetricName = (metric) => {
  switch (metric) {
    case metrics.targetConversions:
      return "conversions";
    case metrics.cvr:
      return "CvR";
    case metrics.conversions:
    case metrics.cost:
    case metrics.installs:
    case metrics.impressions:
    case metrics.clicks:
    case metrics.score:
      return metric;
    case metrics.cpi:
    case metrics.cpa:
    case metrics.cpm:
    case metrics.ipm:
    case metrics.apm:
    case metrics.ctr:
    case metrics.roas:
      return metric.toUpperCase();
    default:
      return "";
  }
};

export const getMetricList = (...metricValues) => {
  const res = {};

  for (let metricValue of metricValues) {
    if (!Object.prototype.hasOwnProperty.call(metrics, metricValue)) {
      continue;
    }
    res[metricValue] = getMetricName(metricValue);
  }

  return res;
};
