import { moneyFormatter, nFormatter, percentFormatter } from "../utils";

export const MMPTargetsEnum = {
  budgetROAS: "budgetROAS",
  budgetCPA: "budgetCPA",
  cpa: "cpa",
  cpi: "cpi",
  roas: "roas",
};

export const MMPTargetsMapping = {
  [MMPTargetsEnum.budgetROAS]: "Budget (with ROAS optimization)",
  [MMPTargetsEnum.budgetCPA]: "Budget (with CPA optimization)",
  [MMPTargetsEnum.cpa]: "CPA",
  [MMPTargetsEnum.cpi]: "CPI",
  [MMPTargetsEnum.roas]: "ROAS",
};

export const getTargetString = (target, targetValue, targetCurrency) => {
  let res;
  if (target === MMPTargetsEnum.roas) {
    res = percentFormatter(targetValue);
  } else {
    if (targetCurrency) {
      res = moneyFormatter({
        num: targetValue,
        currency: targetCurrency,
        clipped: true,
      });
    } else {
      res = nFormatter(targetValue);
    }
  }

  return MMPTargetsMapping[target] + " " + res;
};
