export const DimensionsEnum = {
  appstore: "appstore",
  appstoreApp: "appstoreApp",
  account: "account",
  network: "network",
  campaignName: "campaignName",
  assetType: "assetType",
};

export const DimensionsMapping = {
  [DimensionsEnum.appstore]: "app store",
  [DimensionsEnum.appstoreApp]: "app name",
  [DimensionsEnum.account]: "account",
  [DimensionsEnum.network]: "network",
  [DimensionsEnum.campaignName]: "campaign name",
  [DimensionsEnum.assetType]: "ad format",
};

export const getDimensionsMappingValues = (dimensions) => {
  const res = {};
  for (const dimension of dimensions) {
    res[dimension] = DimensionsMapping[dimension];
  }

  return res;
};
