import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Box } from "@mui/material";
import { chartColors, formatValue, moneyFormatter } from "../../utils";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";

const styles = () => ({
  chart: {
    "& .apexcharts-legend": {
      maxWidth: "16.67rem",
    },
    "& .apexcharts-legend-series": {
      display: "flex",
      margin: "0 0 .25em 0 !important",
    },
    "& .apexcharts-legend-marker": {
      flexShrink: 0,
    },
    "& .apexcharts-legend-text": {
      fontSize: "0.8rem !important",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
});

const DonutChart = ({
  classes,
  categories,
  series,
  options,
  width,
  title,
  loading,
  currency,
}) => {
  const [localCategories, setLocalCategories] = useState([]);
  const [localSeries, setLocalSeries] = useState([]);

  const isNoData = () => {
    return Object.keys(localCategories).length === 0;
  };

  useEffect(() => {
    const resSeries = [];
    const resCategories = [];
    for (const index in series.slice(0, 10)) {
      if (series[index] !== null) {
        resSeries.push(series[index]);
        resCategories.push(categories[index]);
      }
    }
    setLocalSeries(resSeries);
    setLocalCategories(resCategories);
  }, [series]); // eslint-disable-line react-hooks/exhaustive-deps

  const generateOptions = () => {
    const {
      chart,
      stroke,
      legend,
      plotOptions,
      responsive,
      colors = chartColors,
      ...other
    } = options || {};

    return {
      chart: {
        type: "donut",
        animations: {
          enabled: false,
        },
        ...chart,
      },
      colors,
      stroke: {
        width: 0,
        ...stroke,
      },
      legend: {
        show: true,
        ...legend,
      },
      labels: localCategories,
      plotOptions: {
        pie: {
          donut: {
            size: "60%",
          },
          expandOnClick: false,
        },
        ...plotOptions,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
          },
        },
        {
          breakpoint: 9001,
          options: {
            dataLabels: {
              enabled: true,
              textAnchor: "start",
              style: {
                fontSize: "1rem",
                fontWeight: 400,
                colors: ["#fff"],
              },
              formatter: function (val) {
                return `${val.toFixed(1)}%`;
              },
              dropShadow: {
                enabled: false,
              },
            },
            chart: {
              height: 346,
            },
          },
          ...responsive,
        },
      ],
      tooltip: {
        y: {
          formatter: (value) =>
            currency
              ? moneyFormatter({
                  num: value,
                  currency: currency,
                  clipped: false,
                })
              : formatValue(value).toUpperCase(),
        },
      },
      ...other,
    };
  };

  return (
    <Box
      sx={{
        margin: "0 auto",
        ...(width && {
          width: `${width}px`,
        }),
      }}
      className={classes.chart}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {title && <p className={"charts-title"}>{title}</p>}

        {isNoData() && !loading && (
          <Box color="inactive.main" ml="auto">
            There is no data for diagram
          </Box>
        )}
      </Box>
      {isNoData() || loading ? (
        <div className={"no-data-placeholder"} />
      ) : (
        <ReactApexChart
          options={generateOptions()}
          series={localSeries}
          type="donut"
        />
      )}
    </Box>
  );
};

DonutChart.propTypes = {
  classes: PropTypes.object,
  options: PropTypes.shape({
    colors: PropTypes.array,
    chart: PropTypes.object,
    stroke: PropTypes.object,
    legend: PropTypes.object,
    plotOptions: PropTypes.object,
    responsive: PropTypes.object,
  }),
  categories: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
  title: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  currency: PropTypes.string,
};

export default withStyles(styles)(DonutChart);
