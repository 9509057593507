import React, { useEffect, useState } from "react";
import CTable, { types as tableTypes } from "../common/CTable";
import { useUser } from "../../context/UserProvider";
import NotificationType from "./NotificationType";
import DateAgo from "../common/DateAgo";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  IconButton,
  CircularProgress,
  Box,
  Button,
} from "@mui/material";
import {
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import adwizyApi from "../../api/adwizyApi";
import { useInformer } from "../../context/InformerProvider";
import NotificationContent from "./NotificationContent";
import CDialog from "../common/CDialog";
import PropTypes from "prop-types";

const ArchiveBtn = ({ notificationUid, callback }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await callback(notificationUid).finally(() => setLoading(false));
  };

  return loading ? (
    <CircularProgress size={"1.5rem"} />
  ) : (
    <div className="hover-show-content">
      <CDialog
        btn={
          <IconButton
            color="inactive"
            aria-label="archive"
            title="To archive"
            component="label"
            disabled={loading}
          >
            <Delete />
          </IconButton>
        }
        title="Are you sure?"
        content="Are you sure you want to archive this notification?"
        syncApplyBtnCb={handleClick}
      />
    </div>
  );
};

ArchiveBtn.propTypes = {
  notificationUid: PropTypes.string,
  callback: PropTypes.func,
};

const Notifications = () => {
  const user = useUser();
  const informer = useInformer();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [archive, setArchive] = useState(false);
  const [key, setKey] = useState(Date.now());
  const [openRows, setOpenRows] = useState([]);

  useEffect(() => {
    if (user.notifications && !archive) {
      setRows(user.notifications);
      setLoading(false);
      setArchive(false);
    }
  }, [user.notifications]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setKey(Date.now());
  }, [archive]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchNotifications = async (isArchived) => {
    setLoading(true);
    await adwizyApi
      .get("/user/notifications", {
        isArchived: isArchived,
      })
      .then((res) => {
        if (res.data.success) {
          setRows(res.data.data);
        } else {
          informer.showErrorNotice(res.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeArchive = (e) => {
    setArchive(e.target.checked);
    fetchNotifications(e.target.checked ? 1 : 0);
  };

  const updateNotification = (notificationUid, data) => {
    return adwizyApi
      .put(`/user/notifications/${notificationUid}`, {
        data: data,
      })
      .then((res) => {
        if (res.data.success) {
          user.fetchNotifications();
        } else {
          informer.showErrorNotice(res.data.message);
        }
      });
  };

  const archiveNotification = (notificationUid) => {
    return updateNotification(notificationUid, {
      archive: 1,
    });
  };

  const openNotification = async (row) => {
    const notificationUids = openRows;
    const index = notificationUids.indexOf(row.notification_uid);

    if (index !== -1) {
      notificationUids.splice(index, 1);
    } else {
      notificationUids.push(row.notification_uid);
    }

    setOpenRows([...notificationUids]);

    if (!row.is_read) {
      await updateNotification(row.notification_uid, {
        read: 1,
      });
    }
  };

  return (
    <CTable
      key={key}
      loading={loading}
      rows={rows ?? []}
      topArea1={
        <FormGroup className="m-b-1 align-items-start">
          <FormControlLabel
            control={
              <Switch
                checked={archive}
                onChange={handleChangeArchive}
                disabled={loading}
              />
            }
            label="Show only archived notifications"
          />
        </FormGroup>
      }
      settings={{
        columns: {
          category: {
            title: () => null,
            format: tableTypes.custom,
            component: (row) => (
              <Box mt="1.6rem">
                <NotificationType
                  category={row.category}
                  priority={row.priority}
                />
              </Box>
            ),
            sx: {
              width: "1rem",
              verticalAlign: "top",
            },
            cb: (row) => openNotification(row),
          },
          title: {
            title: () => "Subject",
            format: tableTypes.custom,
            component: (row) => (
              <NotificationContent
                title={row.title}
                teaser={row.teaser}
                content={row.content}
                open={openRows.includes(row.notification_uid)}
              />
            ),
            sx: {
              verticalAlign: "top",
            },
            cb: (row) => openNotification(row),
          },
          ts: {
            title: () => "Date",
            format: tableTypes.custom,
            component: (row) => (
              <Box mt="1.7rem">
                <DateAgo timestamp={row.ts} />
              </Box>
            ),
            includeSort: true,
            sx: {
              width: "10rem",
              minWidth: "10rem",
              verticalAlign: "top",
            },
            cb: (row) => openNotification(row),
          },
          collapse: {
            title: () => null,
            format: tableTypes.custom,
            component: (row) => (
              <Box mt="1.38rem">
                <Button
                  className="text-transform-initial f-w-700"
                  endIcon={
                    openRows.includes(row.notification_uid) ? (
                      <KeyboardArrowUp
                        sx={{ fontSize: "1.35rem !important" }}
                      />
                    ) : (
                      <KeyboardArrowDown
                        sx={{ fontSize: "1.35rem !important" }}
                      />
                    )
                  }
                  color="primary"
                >
                  {openRows.includes(row.notification_uid) ? "Hide" : "Show"}
                </Button>
              </Box>
            ),
            sx: {
              width: "1rem",
              verticalAlign: "top",
            },
            cb: (row) => openNotification(row),
          },
          actions: {
            title: () => null,
            format: tableTypes.custom,
            component: (row) =>
              !archive && (
                <Box mt="1.2rem">
                  <ArchiveBtn
                    notificationUid={row.notification_uid}
                    callback={archiveNotification}
                  />
                </Box>
              ),
            sx: {
              textAlign: "right",
              width: "1rem",
              verticalAlign: "top",
            },
          },
        },
        activeRules: {
          field: "is_read",
          cb: (val) => !val,
        },
        rowIndexField: "notification_uid",
        default: {
          order: "desc",
          orderBy: "ts",
        },
      }}
      emptyDataText="There is no notifications yet"
    />
  );
};

export default Notifications;
