import React, { useEffect, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import DropdownFilter from "../common/DropdownFilter";
import CReactApexLineChart, {
  types as chartTypes,
} from "./CReactApexLineChart";
import DropdownBigText from "../common/DropdownBigText";
import PropTypes from "prop-types";

export const granularity = {
  day: "Daily",
  week: "Weekly",
  month: "Monthly",
};

const GranularityLineChart = ({
  title,
  selectedGranularity,
  series,
  options,
  dates,
  changeGranularity,
  dropdownRightSubtitle,
  dropdownLeftSubtitle,
  includeTypeSelector,
  loading,
  currency,
}) => {
  const [selectedType, setSelectedType] = useState(Object.keys(chartTypes)[0]);
  const [heightWrapper, setHeightWrapper] = useState(0);
  const wrapper = useRef(null);
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    if (wrapper.current.clientHeight > heightWrapper) {
      setHeightWrapper(wrapper.current.clientHeight);
    }
    setKey(Math.random());
  }, [selectedType, series]); // eslint-disable-line react-hooks/exhaustive-deps

  const collectSeriesForLineGraph = () => {
    return series.map((metric) => {
      let normalizeSeries = {};
      for (let index in dates) {
        if (
          Object.prototype.hasOwnProperty.call(normalizeSeries, dates[index])
        ) {
          normalizeSeries[dates[index]] += metric.data[index];
        } else {
          normalizeSeries[dates[index]] = metric.data[index];
        }
      }

      return {
        name: metric.name,
        data: Object.keys(normalizeSeries).map((date) => {
          return { x: date, y: normalizeSeries[date] };
        }),
      };
    });
  };

  return (
    <Box ref={wrapper} sx={{ minHeight: `${heightWrapper}px` }}>
      <CReactApexLineChart
        key={key}
        title={
          <Grid container direction="row">
            <Grid item>
              <DropdownBigText
                title="granularity"
                rightSubtitle={dropdownRightSubtitle}
                leftSubtitle={dropdownLeftSubtitle}
                values={granularity}
                selectedValue={selectedGranularity}
                handleSelected={(evt) => changeGranularity(evt.target.value)}
                disabled={loading}
              />
            </Grid>
            {title}
          </Grid>
        }
        series={collectSeriesForLineGraph()}
        type={selectedType}
        options={{
          ...options,
          xaxisTitle: {
            text: selectedGranularity,
          },
        }}
        loading={loading}
        currency={currency}
        tools={[
          <>
            {includeTypeSelector && (
              <Box
                sx={{
                  width: "11.11rem",
                  marginLeft: "1rem",
                }}
              >
                <DropdownFilter
                  title="Type"
                  values={chartTypes}
                  selectedValue={selectedType}
                  handleSelected={setSelectedType}
                  disabled={loading}
                />
              </Box>
            )}
          </>,
        ]}
      />
    </Box>
  );
};

GranularityLineChart.propTypes = {
  title: PropTypes.node,
  selectedGranularity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  series: PropTypes.array.isRequired,
  options: PropTypes.shape({
    colors: PropTypes.array,
    chart: PropTypes.object,
    stroke: PropTypes.object,
    legend: PropTypes.object,
    xaxis: PropTypes.object,
    yaxis: PropTypes.object,
    tooltip: PropTypes.object,
    markers: PropTypes.object,
  }),
  dates: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  changeGranularity: PropTypes.func,
  dropdownRightSubtitle: PropTypes.string,
  dropdownLeftSubtitle: PropTypes.string,
  includeTypeSelector: PropTypes.bool,
  currency: PropTypes.string,
  loading: PropTypes.bool,
};

export default GranularityLineChart;
