import React, { useEffect, useState } from "react";
import AuthTemplate, { defaultValidate } from "../../templates/AuthTemplate";
import { Box, Button, FormHelperText } from "@mui/material";
import CInput from "../../components/AuthTemplate/CInput";
import {
  isDev,
  getCurrencies,
  getCountry,
  getFilteredCountries,
} from "../../utils";
import adwizyApi from "../../api/adwizyApi";
import { useInformer } from "../../context/InformerProvider";
import { useNavigate } from "react-router-dom";
import DropdownFilter from "../../components/common/DropdownFilter";
import ErrorFormHelperText from "../../components/AuthTemplate/ErrorFormHelper";
import classnames from "classnames";
import Preloader from "../../components/Preloader";
import { useUser } from "../../context/UserProvider";

const CreateOrgPage = () => {
  const user = useUser();
  const informer = useInformer();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orgs, setOrgs] = useState(user.orgs);
  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [countries, setCountries] = useState({});
  const [currencies, setCurrencies] = useState({});
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");
  const [currency, setCurrency] = useState("");
  const [currencyError, setCurrencyError] = useState("");

  const validateCompany = (company) =>
    defaultValidate("Company name", company, setCompanyError);
  const validateCountry = (country) =>
    defaultValidate("Country", country, setCountryError);
  const validateCurrency = (currency) =>
    defaultValidate("Currency", currency, setCurrencyError);

  const submit = async () => {
    let isOk = validateCompany(company);
    isOk = validateCountry(country) && isOk;
    isOk = validateCurrency(currency) && isOk;

    if (isOk) {
      if (isDev) {
        let orgId = require("../../mocks/user__orgs.json").data[0].orgId;
        navigate(`/org/${orgId}/plan-selection`);
      } else {
        setLoading(true);

        await adwizyApi
          .post("/org/create", {
            name: company,
            country,
            currency,
          })
          .then((res) => {
            if (res.data.success) {
              const org = res.data.data;
              user.addOrg(org);
              navigate(`/org/${org.orgId}/plan-selection`);
            }
          })
          .catch(informer.showErrorNotice);

        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setCountries(getFilteredCountries());
    setCurrencies(getCurrencies());
  }, []);

  useEffect(() => {
    if (country) {
      let currency = getCountry(country)?.currencies[0];
      setCurrency(currency && currencies[currency] ? currency : "USD");
    }
  }, [country]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orgs && !orgs.length && user.orgs && user.orgs.length) {
      navigate(`/org/${user.orgs[0].orgId}/plan-selection`);
    }

    if (orgs !== user.orgs) {
      setOrgs(user.orgs);
    }
  }, [user.orgs]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthTemplate>
      <div className="window">
        <div className="title">Create new organization</div>
        <div className="subtitle">
          We have sent an activation code to your mail. Please check the code
          and insert it in the next field
        </div>
        <div className="input_group">
          <CInput
            id="company"
            label="Company name"
            value={company}
            hasError={companyError !== ""}
            helperText={companyError}
            onChange={(e) => {
              setCompany(e.target.value);
              validateCompany(e.target.value);
            }}
            disabled={loading}
            submit={submit}
          />
          <div
            className={classnames({
              input: true,
              with_helper: countryError,
            })}
          >
            <DropdownFilter
              id="country"
              title="Country"
              values={countries}
              handleSelected={(value) => {
                setCountry(value);
                validateCountry(value);
              }}
              hasError={!!countryError}
              disabled={loading}
            />
            {countryError && (
              <ErrorFormHelperText>{countryError}</ErrorFormHelperText>
            )}
          </div>
          <div
            className={classnames({
              input: true,
              with_helper: currencyError,
            })}
          >
            <DropdownFilter
              id="currency"
              title="Preferred currency"
              values={currencies}
              selectedValue={currency}
              handleSelected={(value) => {
                setCurrency(value);
                validateCurrency(value);
              }}
              hasError={!!currencyError}
              disabled={loading}
            />
            <FormHelperText>You can change it later</FormHelperText>
            {currencyError && (
              <ErrorFormHelperText style={{ marginTop: 0 }}>
                {currencyError}
              </ErrorFormHelperText>
            )}
          </div>
        </div>
        <div className="actions">
          <Button
            className="button"
            variant="contained"
            onClick={submit}
            color="primary"
            disabled={
              loading ||
              !company ||
              !!companyError ||
              !country ||
              !!countryError ||
              !currency ||
              !!currencyError
            }
          >
            Create organization
            {loading && (
              <Box display="flex" alignItems="center" marginLeft={1}>
                <Preloader size={30} />
              </Box>
            )}
          </Button>
        </div>
      </div>
    </AuthTemplate>
  );
};

export default CreateOrgPage;
