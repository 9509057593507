import React from "react";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import CheckboxList from "../common/CheckboxList";
import DialogNewConcept from "./DialogNewConcept";
import DropdownActions from "../common/DropdownActions";
import CDialog from "../common/CDialog";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  link: {
    marginBottom: 19,
    color: theme.palette.inactive.main,
    fontSize: "1rem",
    lineHeight: "21px",
    fontWeight: 600,
    textDecoration: "none",
    display: "block",
    cursor: "pointer",
  },
}));

const ConceptActions = ({
  btn,
  title,
  loading,
  handleOk,
  handleCancel,
  concepts,
  conceptIdsChecked,
  changeConceptIdsChecked,
  onAddNewConcept,
  allowCreateNewConcept,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const orgId = Number(params.orgId);

  return (
    <DropdownActions
      title={title}
      btn={btn}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      handleOk={handleOk}
      handleCancel={handleCancel}
      loading={loading}
    >
      <Box sx={{ padding: "0 1.61rem" }}>
        <CheckboxList
          list={concepts}
          checkedCallback={(conceptId) => conceptIdsChecked.includes(conceptId)}
          handleChange={changeConceptIdsChecked}
          includeSearch
        />
        <Divider className={"divider"} />
        <Box sx={{ margin: "1.33rem 0 0.78rem" }}>
          {allowCreateNewConcept && (
            <DialogNewConcept
              btn={<Box className={classes.link}>Create new concept</Box>}
              onAddNewConcept={onAddNewConcept}
            />
          )}
          {conceptIdsChecked.length === 0 ? (
            <Link
              className={classes.link}
              to={`/org/${orgId}/creatives/concept-settings`}
            >
              Manage concepts
            </Link>
          ) : (
            <CDialog
              btn={
                <Link className={classes.link} to="#">
                  Manage concepts
                </Link>
              }
              title="Attention"
              content="Are you sure you want to leave this page? All selected settings will be reset"
              syncApplyBtnCb={() =>
                navigate(`/org/${orgId}/creatives/concept-settings`)
              }
              maxWidth="sm"
              fullWidth={true}
            />
          )}
        </Box>
      </Box>
    </DropdownActions>
  );
};

ConceptActions.propTypes = {
  btn: PropTypes.node,
  title: PropTypes.node,
  loading: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  concepts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  conceptIdsChecked: PropTypes.array,
  changeConceptIdsChecked: PropTypes.func,
  onAddNewConcept: PropTypes.func,
  allowCreateNewConcept: PropTypes.bool,
};

export default ConceptActions;
