import React from "react";
import { PropTypes } from "prop-types";
import { MMPServices } from "../../enum/MMPServices";

const MMPConnectionInstruction = ({ service }) =>
  Object.values(MMPServices[service]?.stepByStepInstructions).map(
    (stepMsg, i) => (
      <p key={i}>
        <b>Step {i + 1}.</b>{" "}
        <span dangerouslySetInnerHTML={{ __html: stepMsg }} />
      </p>
    )
  );

MMPConnectionInstruction.propTypes = {
  service: PropTypes.oneOf(Object.keys(MMPServices)).isRequired,
};

export default MMPConnectionInstruction;
