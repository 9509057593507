import React, { useEffect, useState } from "react";
import { MMPServices } from "../../enum/MMPServices";
import EmptyDialog from "../EmptyDialog";
import PropTypes from "prop-types";
import MMPConnectionInstruction from "./MMPConnectionInstruction";

const MMPInstructionDialog = ({ service, open, btn, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    open != null && setIsOpen(open);
  }, [open]);

  return (
    <EmptyDialog
      btn={btn}
      onClickBtn={() => setIsOpen(true)}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        if (typeof onClose === "function") {
          onClose();
        }
      }}
      maxWidth="md"
      sx={{
        paper: {
          padding: "1.61rem 4.77rem 2.33rem",
        },
      }}
    >
      <div className="connection-dialog__content">
        <div className="connection-dialog__title">Connection instruction</div>

        <MMPConnectionInstruction service={service} />
      </div>
    </EmptyDialog>
  );
};

MMPInstructionDialog.propTypes = {
  service: PropTypes.oneOf(Object.keys(MMPServices)).isRequired,
  btn: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MMPInstructionDialog;
