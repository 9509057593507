import AppsFlyerIcon from "../components/MMP/icons/appsflyer.svg";
import AdjustIcon from "../components/MMP/icons/adjust.svg";

export const MMPServices = {
  appsflyer: {
    title: "AppsFlyer",
    icon: AppsFlyerIcon,
    stepByStepInstructions: [
      "Sign in to your Appsflyer account and go to Configuration > Integrated Partners.",
      "Find <b>adwizy_in</b> and give access to your Appsflyer data to it",
      "Wait for the pings to be collected by our service - the status of the connection will be then set to “Awaiting target setup”",
      "Choose your target conversions and set the target",
    ],
  },
  adjust: {
    title: "Adjust",
    icon: AdjustIcon,
    comingSoon: true,
  },
};
