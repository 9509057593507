import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { MMPServices } from "../../enum/MMPServices";
import CButton from "../common/CButton";
import EmptyDialog from "../EmptyDialog";
import MMPIcon from "./MMPIcon";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { ChevronLeft } from "@mui/icons-material";
import theme from "../../theme";
import adwizyApi from "../../api/adwizyApi";
import { useInformer } from "../../context/InformerProvider";
import { useUser } from "../../context/UserProvider";
import CSkeleton from "../common/CSkeleton";
import MMPConnectionInstruction from "./MMPConnectionInstruction";

const MMPConnection = ({
  appstoreAppId,
  orgId,
  btn,
  cbAfterAddedConnection,
}) => {
  const user = useUser();
  const informer = useInformer();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [testMode, setTestMode] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    location.hash === "#testMMP" && setTestMode(true);
  }, [location.hash]);

  const selectService = (service) => {
    setSelectedService(service);
    setStep(2);
  };

  const localOnClose = () => {
    setIsOpen(false);
  };
  const openHandle = () => {
    setStep(1);
    setIsOpen(true);
  };

  const addConnection = async () => {
    setLoading(true);
    await adwizyApi
      .post("/mmp/connection", {
        orgId: orgId,
        appstoreAppId: appstoreAppId,
        mmpName: selectedService,
      })
      .then(async (res) => {
        if (!res.data.success) {
          informer.showErrorNotice(res.data.message);
        }
      })
      .catch(informer.showErrorNotice)
      .finally(() => {
        setIsOpen(false);
      });

    if (typeof cbAfterAddedConnection === "function") {
      await cbAfterAddedConnection();
    }

    await user.userAppsListChanged().finally(() => setLoading(false));
  };

  return (
    <EmptyDialog
      btn={loading ? <CSkeleton width="8rem" /> : btn}
      onClickBtn={openHandle}
      open={isOpen}
      onClose={localOnClose}
      maxWidth="md"
      sx={{
        paper: {
          padding: "1.61rem 4.77rem 2.33rem",
        },
      }}
    >
      <div className="connection-dialog__content">
        {step === 1 && (
          <Box marginTop="1rem">
            <div className="connection-dialog__title text-center">
              MMP connection
            </div>
            <div className="connection-dialog__subtitle text-center">
              Connect your MMP to Adwizy
            </div>
            <div className="connection-dialog__network-container">
              {Object.keys(MMPServices).map((service, i) => (
                <Button
                  key={i}
                  classes={{
                    root: "connection-dialog__network-btn",
                  }}
                  disabled={!testMode && MMPServices[service].comingSoon}
                  onClick={() => selectService(service)}
                  title={MMPServices[service].title}
                >
                  <MMPIcon
                    service={service}
                    customLabel={
                      MMPServices[service].comingSoon && (
                        <>
                          <div>{MMPServices[service].title}</div>
                          <div className="connection-dialog__coming-soon">
                            Coming soon
                          </div>
                        </>
                      )
                    }
                    withLabel
                    className={
                      !testMode && MMPServices[service].comingSoon
                        ? "connection-dialog__network-disabled"
                        : null
                    }
                  />
                </Button>
              ))}
            </div>
          </Box>
        )}
        {step === 2 && (
          <>
            <Button
              sx={{
                textTransform: "unset",
              }}
              onClick={() => setStep(1)}
              startIcon={<ChevronLeft fontSize="large" />}
            >
              Back
            </Button>
            <div className="connection-dialog__title">
              Connection instruction
            </div>

            <MMPConnectionInstruction service={selectedService} />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gridGap: ".88rem",
                marginTop: "1rem",
              }}
            >
              <CButton
                color="defaultLight"
                variant="contained"
                onClick={localOnClose}
                size="large"
                sx={{
                  border: `1px solid ${theme.palette.borders.main}`,
                }}
              >
                Cancel
              </CButton>
              <CButton
                color="primary"
                variant="contained"
                onClick={addConnection}
                size="large"
                disabled={loading}
              >
                Connect
              </CButton>
            </Box>
          </>
        )}
      </div>
    </EmptyDialog>
  );
};

MMPConnection.propTypes = {
  appstoreAppId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  btn: PropTypes.node.isRequired,
  cbAfterAddedConnection: PropTypes.func,
};

export default MMPConnection;
