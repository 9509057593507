import React, { useEffect, useState } from "react";
import { chartColors, metricFormatter } from "../../utils";
import ReactApexChart from "react-apexcharts";
import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import CSkeleton from "../common/CSkeleton";

export const yaxisContent = (
  currency = null,
  max = null,
  selectedMetric = null
) => {
  const res = {
    labels: {
      style: {
        fontSize: "0.9rem",
        fontWeight: 100,
        colors: ["#072234"],
      },
      formatter: (value) => {
        if (value === Infinity) {
          value = 0;
        }

        return metricFormatter({
          num: value,
          metric: selectedMetric,
          currency: currency,
          clipped: true,
        });
      },
    },
  };

  if (max && typeof max === "number" && !Number.isInteger(max)) {
    res.tickAmount = 5;
    res.max = Math.ceil(max * 1.05);
  }

  return res;
};

const styles = () => ({
  chart: {
    "& .apexcharts-legend": {
      paddingTop: "2.56rem",
    },
  },
});

const BarChart = ({
  classes,
  categories,
  series,
  options,
  title,
  width,
  height,
  loading,
  currency,
  selectedMetric,
}) => {
  const [localCategories, setLocalCategories] = useState([]);
  const [localSeries, setLocalSeries] = useState([]);

  const isNoData = () => {
    return Object.keys(localCategories).length === 0;
  };

  useEffect(() => {
    const resSeries = [];
    const resCategories = [];

    // todo need test
    if (series.length === 1) {
      const resData = [];
      const { data, ...rest } = series[0];
      for (const index in data.slice(0, 10)) {
        if (data[index] !== null) {
          resData.push(data[index]);
          resCategories.push(categories[index]);
        }
      }

      resSeries.push({
        data: resData,
        ...rest,
      });
    }

    setLocalSeries(resSeries.length ? resSeries : series.slice());
    setLocalCategories(
      resCategories.length ? resCategories : categories.slice()
    );
  }, [series]); // eslint-disable-line react-hooks/exhaustive-deps

  const generateOptions = () => {
    const {
      chart,
      plotOptions,
      dataLabels,
      legend,
      xaxis = {},
      yaxis = {},
      yaxisTitle,
      xaxisTitle,
      grid,
      colors = chartColors,
      tooltip,
      states,
      ...other
    } = options || {};

    return {
      chart: {
        type: "bar",
        redrawOnParentResize: true,
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        animations: {
          enabled: false,
        },
        ...chart,
      },
      plotOptions: {
        bar: {
          columnWidth: (localCategories.length > 3 ? 80 : 50) + "%",
          distributed: true,
          dataLabels: {
            position: "top",
          },
        },
        ...plotOptions,
      },
      dataLabels: {
        style: {
          fontSize: "0.95rem",
          fontWeight: 600,
          colors: ["#000"],
        },
        position: "top",
        offsetY: -24,
        formatter: (value, a) => {
          if (Array.isArray(selectedMetric)) {
            return metricFormatter({
              num: value,
              metric: selectedMetric[a?.seriesIndex],
            });
          } else {
            return metricFormatter({ num: value, metric: selectedMetric });
          }
        },
        ...dataLabels,
      },
      legend: {
        show: false,
        showForSingleSeries: true,
        ...legend,
      },
      xaxis: {
        categories: localCategories,
        labels: {
          trim: true,
          style: {
            fontSize: "1rem",
            color: "#072234",
          },
          offsetY: 6,
        },
        ...xaxis,
        title: {
          ...xaxisTitle,
        },
      },
      yaxis:
        Object.keys(yaxis).length > 0
          ? yaxis
          : {
              ...yaxisContent(
                currency,
                Math.max(...(localSeries[0]?.data || {})),
                selectedMetric
              ),
              title: {
                ...yaxisTitle,
              },
            },
      colors,
      fill: {
        opacity: 1,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        ...grid,
      },
      tooltip: {
        y: {
          formatter: (value, a) => {
            const currencyRes =
              Object.keys(yaxis).length > 0
                ? options?.currencies[a?.seriesIndex]
                : currency;

            if (Array.isArray(selectedMetric)) {
              return metricFormatter({
                num: value,
                metric: selectedMetric[a?.seriesIndex],
                currency: currencyRes,
              });
            } else {
              return metricFormatter({
                num: value,
                metric: selectedMetric,
                currency: currencyRes,
              });
            }
          },
          title: {
            formatter: () => null,
          },
        },
        ...tooltip,
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
        ...states,
      },
      ...other,
    };
  };

  return (
    <Box
      className={"chart-container"}
      sx={{
        margin: "0 auto",
        ...(width && {
          width: `${width}px`,
        }),
      }}
    >
      {loading ? (
        <Grid display="flex" alignItems="end" justifyContent="space-between">
          <CSkeleton width="30%" height="16.67rem" />
          <CSkeleton width="30%" height="13.89rem" />
          <CSkeleton width="30%" height="12.22rem" />
        </Grid>
      ) : (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {title && <p className={"charts-title"}>{title}</p>}

            {isNoData() && (
              <Box color="inactive.main" marginLeft="auto">
                There is no data for graphic
              </Box>
            )}
          </Box>
          <ReactApexChart
            options={generateOptions()}
            series={localSeries}
            type="bar"
            height={height}
            className={classes.chart}
          />
        </>
      )}
    </Box>
  );
};

BarChart.propTypes = {
  classes: PropTypes.object,
  options: PropTypes.shape({
    colors: PropTypes.array,
    chart: PropTypes.object,
    plotOptions: PropTypes.object,
    dataLabels: PropTypes.object,
    legend: PropTypes.object,
    xaxis: PropTypes.object,
    yaxis: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    xaxisTitle: PropTypes.object,
    yaxisTitle: PropTypes.object,
    grid: PropTypes.object,
    tooltip: PropTypes.object,
    states: PropTypes.object,
    currencies: PropTypes.array,
  }),
  categories: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
  title: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  currency: PropTypes.string,
  selectedMetric: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

BarChart.defaultProps = {
  height: 350,
};

export default withStyles(styles)(BarChart);
