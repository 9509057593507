import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { backdropClasses, Dialog, dialogClasses } from "@mui/material";
import PropTypes from "prop-types";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${dialogClasses.root}`]: {
    [`.${backdropClasses.root}`]: {
      backgroundColor: "rgba(25, 25, 25, 0.2)",
    },
  },
  [`.${dialogClasses.paperWidthXs}`]: {
    maxWidth: "29.17rem",
  },
  [`.${dialogClasses.paperWidthSm}`]: {
    maxWidth: "32.17rem",
  },
  [`.${dialogClasses.paper}`]: {
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.borders.main,
    boxShadow: "0px 26px 45px rgba(7, 34, 52, 0.05)",
  },
}));

const EmptyDialog = ({
  children,
  btn,
  open,
  onClickBtn,
  onClose,
  onKeyDown,
  maxWidth,
  fullWidth,
  scrollable,
  sx,
  loading,
  closeable,
  container,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    open != null && setIsOpen(open);
  }, [open]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    if (!loading && closeable) {
      setIsOpen(false);
      typeof onClose === "function" && onClose();
    }
  };

  return (
    <>
      {btn &&
        React.cloneElement(btn, {
          onClick: () => {
            handleOpen();
            typeof onClickBtn === "function" && onClickBtn();
          },
        })}
      <StyledDialog
        open={isOpen}
        onClose={handleClose}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        PaperProps={{ sx: sx?.paper }}
        scroll={scrollable ? "paper" : "body"}
        onKeyDown={onKeyDown}
        container={container}
        sx={{
          position: container ? "absolute" : "fixed",
        }}
        BackdropProps={{
          sx: {
            position: container ? "absolute" : "fixed",
          },
        }}
      >
        {children}
      </StyledDialog>
    </>
  );
};

EmptyDialog.propTypes = {
  children: PropTypes.node.isRequired,
  btn: PropTypes.node,
  open: PropTypes.bool,
  onClickBtn: PropTypes.func,
  onClose: PropTypes.func,
  onKeyDown: PropTypes.func,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  scrollable: PropTypes.bool,
  sx: PropTypes.object,
  loading: PropTypes.bool,
  closeable: PropTypes.bool,
  container: PropTypes.func,
};

EmptyDialog.defaultProps = {
  scrollable: false,
  loading: false,
  closeable: true,
};

export default EmptyDialog;
