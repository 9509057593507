import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { PropTypes } from "prop-types";
import { ExpandMore /*Settings*/ } from "@mui/icons-material";
import {
  Accordion,
  accordionClasses,
  AccordionSummary,
  accordionSummaryClasses,
  AccordionDetails,
} from "@mui/material";
import classNames from "classnames";
import Preloader from "./Preloader";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  [`&.${accordionClasses.root}`]: {
    boxShadow: "none",
    border: "1px solid " + theme.palette.borders.main,
    borderRadius: 5,
    "&:before": {
      display: "none",
    },
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  [`&.${accordionSummaryClasses.root}`]: {
    padding: "2.1rem",
  },
  [`.${accordionSummaryClasses.content}`]: {
    margin: 0,
  },
}));

const AccordionBlock = ({
  className,
  title,
  children,
  expanded,
  onToggle,
  loading,
}) => {
  const accordionBlockClass = classNames({
    accordion_block: true,
    [className]: className,
  });
  const [localExpanded, setLocalExpanded] = useState(expanded || false);

  useEffect(() => {
    setLocalExpanded(expanded);
  }, [expanded]);

  const localOnToggle = (evt, val) => {
    setLocalExpanded(val);
    onToggle && onToggle(evt, val);
  };

  return (
    <StyledAccordion
      disableGutters
      className={accordionBlockClass}
      expanded={localExpanded}
      onChange={localOnToggle}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMore color="primary" sx={{ fontSize: "2.5rem" }} />}
        className="block"
      >
        <h2 className="subtitle accordion_block__title">{title}</h2>
      </StyledAccordionSummary>
      <AccordionDetails sx={{ padding: "0.89rem 2.22rem 2.39rem" }}>
        {loading ? <Preloader /> : children}
      </AccordionDetails>
    </StyledAccordion>
  );
};

AccordionBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  onToggle: PropTypes.func,
  loading: PropTypes.bool,
};

AccordionBlock.defaultProps = {
  expanded: false,
  loading: false,
};

export default AccordionBlock;
