import CButton from "../common/CButton";
import PasswordInput from "../AuthTemplate/PasswordInput";
import {
  defaultValidate,
  generatePassword,
  validatePassword,
  validatePasswordRepeat,
} from "../../templates/AuthTemplate";
import { Box } from "@mui/material";
import Preloader from "../Preloader";
import EmptyDialog from "../EmptyDialog";
import React, { useState } from "react";
import { useInformer } from "../../context/InformerProvider";
import { isDev } from "../../utils";
import adwizyApi from "../../api/adwizyApi";
import PropTypes from "prop-types";

const ChangePassword = ({ btn }) => {
  const informer = useInformer();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordHasError, setNewPasswordHasError] = useState(false);
  const [newPasswordHelper, setNewPasswordHelper] = useState(null);
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [newPasswordRepeatError, setNewPasswordRepeatError] = useState("");

  const validateCurrentPassword = (newPassword) =>
    defaultValidate("Current password", newPassword, setCurrentPasswordError);

  const submit = async () => {
    let isOk = validateCurrentPassword(currentPassword);
    isOk = validatePassword(newPassword, setNewPasswordHelper) && isOk;
    setNewPasswordHasError(!isOk);
    isOk =
      validatePasswordRepeat(
        newPassword,
        newPasswordRepeat,
        setNewPasswordRepeatError
      ) && isOk;

    if (isOk) {
      const successfullyHandle = () => {
        informer.showSuccessNotice("Password successfully changed");
        reset();
        setIsOpen(false);
      };

      if (isDev) {
        successfullyHandle();
      } else {
        setLoading(true);
        await adwizyApi
          .post("/user/change-password", {
            currentPassword: generatePassword(currentPassword),
            newPassword: generatePassword(newPassword),
          })
          .then((res) => {
            if (res.data.success) {
              successfullyHandle();
            }
          })
          .catch(informer.showErrorNotice);

        setLoading(false);
      }
    }
  };

  const reset = () => {
    setCurrentPassword("");
    setCurrentPasswordError("");
    setNewPassword("");
    setNewPasswordHasError(false);
    setNewPasswordHelper(null);
    setNewPasswordRepeat("");
    setNewPasswordRepeatError("");
  };

  return (
    <EmptyDialog
      id="auth_template"
      btn={btn}
      open={isOpen}
      onClickBtn={() => setIsOpen(true)}
      onClose={reset}
      loading={loading}
      sx={{
        paper: {
          padding: "2.22rem",
        },
      }}
    >
      <Box className="subtitle" textAlign="center" marginBottom="1.11rem">
        Change password
      </Box>
      <PasswordInput
        id="current_password"
        label="Current password"
        value={currentPassword}
        hasError={currentPasswordError !== ""}
        helperText={currentPasswordError}
        onChange={(e) => {
          setCurrentPassword(e.target.value);
          validateCurrentPassword(e.target.value);
        }}
        disabled={loading}
        submit={submit}
        sx={{ marginBottom: currentPasswordError !== "" ? 0 : "1.11rem" }}
      />
      <PasswordInput
        id="new_password"
        label="New password"
        value={newPassword}
        hasError={newPasswordHasError}
        helperText={newPasswordHelper}
        onChange={(e) => {
          setNewPassword(e.target.value);
          setNewPasswordHasError(
            !validatePassword(e.target.value, setNewPasswordHelper)
          );
          validatePasswordRepeat(
            e.target.value,
            newPasswordRepeat,
            setNewPasswordRepeatError
          );
        }}
        disabled={loading}
        submit={submit}
        sx={{ marginBottom: newPasswordHelper ? 0 : "1.11rem" }}
      />
      <PasswordInput
        id="new_password_repeat"
        label="Repeat new password"
        value={newPasswordRepeat}
        hasError={newPasswordRepeatError !== ""}
        helperText={newPasswordRepeatError}
        onChange={(e) => {
          setNewPasswordRepeat(e.target.value);
          validatePasswordRepeat(
            newPassword,
            e.target.value,
            setNewPasswordRepeatError
          );
        }}
        disabled={loading}
        submit={submit}
        sx={{ marginBottom: newPasswordRepeatError !== "" ? 0 : "1.11rem" }}
      />
      <Box display="flex" justifyContent="center">
        <CButton
          color="primary"
          variant="contained"
          onClick={submit}
          size="large"
          disabled={
            loading ||
            !currentPassword ||
            !!currentPasswordError ||
            !newPassword ||
            newPasswordHasError ||
            !newPasswordRepeat ||
            !!newPasswordRepeatError
          }
          sx={{ marginRight: "1.11rem" }}
        >
          Change password
          {loading && (
            <Box display="flex" alignItems="center" marginLeft={1}>
              <Preloader size={30} />
            </Box>
          )}
        </CButton>
        <CButton
          color="primary"
          variant="outlined"
          onClick={() => {
            setIsOpen(false);
            reset();
          }}
          size="large"
          disabled={loading}
        >
          Cancel
        </CButton>
      </Box>
    </EmptyDialog>
  );
};

ChangePassword.propTypes = {
  btn: PropTypes.node.isRequired,
};

export default ChangePassword;
