import React from "react";
import MainTemplate from "../../../templates/MainTemplate";

const NotFoundPage = () => {
  return (
    <MainTemplate title="Page not found">
      <div className="block-content">Oops.. This page does not exists</div>
    </MainTemplate>
  );
};

export default NotFoundPage;
