import React from "react";
import { colors, getLightColor, sortObjectsByParam } from "../../utils";
import { PropTypes } from "prop-types";
import classnames from "classnames";
import { Box } from "@mui/material";

export const variants = {
  bar: "bar",
  barHorizontal: "percent_bar_horizontal",
  circle: "circle",
  circleClock: "circle_clock",
  text: "text",
};

const Percent = ({ variant, settings, value, description }) => {
  const sortedSettings = sortObjectsByParam(settings, "to");
  value = value == null ? null : Math.round(value);
  const selectedSetting =
    value == null
      ? null
      : sortedSettings.find((it) =>
          it.excluded ? value < it.to : value <= it.to
        ) || sortedSettings[sortedSettings.length - 1];
  const color = selectedSetting ? selectedSetting.color : colors.default;

  const percentBarClass = classnames({
    percent_bar: variant === variants.bar,
    percent_bar_horizontal: variant !== variants.bar,
  });

  const percentCircleClass = classnames({
    percent_circle: true,
    percent_circle__clock: variant === variants.circleClock,
  });

  const prc = (value > 100 ? 100 : value < 0 ? 0 : value) / 100;
  const preparedValue = (value || 0) + "%";

  description = description && (
    <div className="percent_description">{description}</div>
  );

  let percent = (
    <Box
      className={percentBarClass}
      width={value == null ? "fit-content" : prc * 100 + "%"}
    >
      {description}
      <Box
        className="percent_background"
        textAlign="center"
        backgroundColor={getLightColor(color)}
      >
        <div className="percent_value" style={{ color }}>
          {preparedValue}
        </div>
      </Box>
    </Box>
  );

  // eslint-disable-next-line default-case
  switch (variant) {
    case variants.circle:
      percent = (
        <div className={percentCircleClass}>
          <Box
            className="percent_borders"
            position="relative"
            display="flex"
            alignItems="end"
            height="100%"
            width="100%"
            overflow="hidden"
            style={{ borderColor: color }}
          >
            <Box
              className="percent_background"
              height={value == null ? "fit-content" : prc * 100 + "%"}
              width="100%"
              backgroundColor={getLightColor(color)}
            />
            <Box
              className="percent_value"
              position="absolute"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
              color={color}
            >
              {preparedValue}
            </Box>
          </Box>
          {description}
        </div>
      );
      break;
    case variants.circleClock:
      percent = (
        <Box className={percentCircleClass} position="relative" height="100%">
          <Box
            className="percent_borders"
            position="absolute"
            style={{
              transform: "rotate(" + (45 + prc * 360) + "deg)",
              borderLeftColor: color,
            }}
            sx={{
              "&:after": {
                backgroundColor: color,
              },
            }}
          />
          <Box
            className="percent_background hider"
            position="absolute"
            display={prc <= 0.25 ? "block" : "none"}
            alignItems="end"
            style={{
              background: `linear-gradient(90deg, ${getLightColor(
                color
              )} 0%, ${getLightColor(
                color
              )} 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%)`,
            }}
            sx={{
              "&:after": {
                backgroundColor: color,
              },
            }}
          />
          <Box
            className="percent_borders"
            position="absolute"
            style={{
              transform: "rotate(45deg)",
              borderTopColor: prc >= 0.25 && color,
              borderRightColor: prc >= 0.5 && color,
              borderBottomColor: prc >= 0.75 && color,
            }}
            sx={{
              "&:after": {
                backgroundColor: color,
              },
            }}
          />
          <Box
            className="percent_background"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: getLightColor(color) }}
          >
            <div className="percent_value" style={{ color }}>
              {preparedValue}
            </div>
          </Box>
          {description}
        </Box>
      );
      break;
    case variants.bar:
      percent = (
        <Box
          className={percentBarClass}
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100%"
          width="fit-content"
        >
          <Box display="flex" alignItems="end" height="100%" width="100%">
            <Box
              className="percent_background"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={value == null ? "unset" : prc * 100 + "%"}
              width="100%"
              backgroundColor={getLightColor(color)}
            >
              <div className="percent_value" style={{ color }}>
                {preparedValue}
              </div>
            </Box>
          </Box>
          {description}
        </Box>
      );
      break;
    case variants.text:
      percent = (
        <Box sx={{ color: color }}>
          <strong>{value ? preparedValue.replace("%", "") : "-"}</strong>
        </Box>
      );
  }

  return <div className="percent">{percent}</div>;
};

Percent.propTypes = {
  variant: PropTypes.oneOf(Object.values(variants)),
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.number,
  description: PropTypes.string,
};

Percent.defaultProps = {
  settings: [
    { to: 35, color: colors.red },
    { to: 85, color: colors.yellow },
    { to: 100, color: colors.green },
  ],
  variant: variants.barHorizontal,
};

export default Percent;
