import React, { useEffect, useState } from "react";
import {
  arrayEquals,
  countryMappingFilter,
  dateSendFormat,
  isDev,
  sleep,
} from "../../../utils";
import { makeStyles } from "@mui/styles";
import CTable, { types } from "../../../components/common/CTable";
import CDialog from "../../../components/common/CDialog";
import CButton from "../../../components/common/CButton";
import CampaignList from "../../../components/ConceptMapping/CampaignList";
import AssetConcepts from "../../../components/ConceptMapping/AssetConcepts";
import { Box, Checkbox } from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material/";
import ConceptActions from "../../../components/ConceptMapping/ConceptActions";
import { useUser } from "../../../context/UserProvider";
import adwizyApi from "../../../api/adwizyApi";
import { useParams } from "react-router-dom";
import PreviewContentAsset from "../../../components/ConceptMapping/PreviewContentAsset";
import DropdownApp from "../../../components/common/DropdownApp";
import { useInformer } from "../../../context/InformerProvider";
import MainTemplate from "../../../templates/MainTemplate";
import { useEvent, events } from "../../../context/EventProvider";
import AppId from "../../../helpers/appId";
import { getMetricsScoresAndRecsHandlerForCreatives } from "../../../helpers/eventHandlers";
import CTooltip from "../../../components/common/CTooltip";
import NetworkIcon from "../../../components/network/NetworkIcon";
import { getAppstoreMappingValues } from "../../../enum/AppstoreTypes";
import { AssetTypesEnum } from "../../../enum/AssetTypes";
import { getTitleOfNetwork } from "../../../enum/CampaignNetworks";
import { ReportTypes } from "../../../enum/ReportTypes";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  table: {
    paddingTop: 0,
  },
  topPanel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.61rem 0",
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
  },
  topPanelText: {
    color: theme.palette.hover.main,
    margin: "0 1.22rem 0 0",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.33rem",
  },
}));

const ConceptMappingPageContent = ({ orgId, selectedApp, handleSelectApp }) => {
  const user = useUser();
  const event = useEvent();
  const informer = useInformer();

  const classes = useStyles();
  const [loadingFetchFilters, setLoadingFetchFilters] = useState(true);
  const [loadingFetchAssets, setLoadingFetchAssets] = useState(true);
  const [loadingConceptChange, setLoadingConceptChange] = useState(false);
  const [
    loadingTemporalAssetUrlsResponse,
    setLoadingTemporalAssetUrlsResponse,
  ] = useState(false);
  const [assets, setAssets] = useState({});
  const [chunkOfAssetCampaignIds, setChunkOfAssetCampaignIds] = useState([]);
  const [concepts, setConcepts] = useState([]);
  const [conceptIdsChecked, setConceptIdsChecked] = useState([]);
  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [appstores, setAppstores] = useState();
  const [countries, setCountries] = useState();
  const [selectedAppstores, setSelectedAppstores] = useState();
  const [selectedCountries, setSelectedCountries] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [, setAppId] = useState();
  const [selectedAppString, setSelectedAppString] = useState("");
  const [filterKey, setFilterKey] = useState(0);
  const [filterId, setFilterId] = useState(null);
  const [successfulFetchFilters, setSuccessfulFetchFilters] = useState(null);
  const [isOpenAttentionDialog, setIsOpenAttentionDialog] = useState(false);

  const [assetTypesOfResultAssets, setAssetTypesOfResultAssets] =
    useState(null);
  const [networksOfResultAssets, setNetworksOfResultAssets] = useState(null);
  const [campaignIdsOfResultAssets, setCampaignIdsOfResultAssets] =
    useState(null);

  const fetchFilters = async () => {
    setLoadingFetchFilters(true);
    if (isDev) {
      let response;
      if (Object.keys(appstores).length === 0) {
        response =
          await require("../../../mocks/filters__creative-mapping.json").data;
      } else {
        response =
          await require("../../../mocks/filters__creative-mapping_other.json")
            .data;
      }
      setAppstores(response.appstores);
      setCountries(response.countries);
    } else {
      setSuccessfulFetchFilters(false);
      await adwizyApi
        .get("/report/concept-mapping/filter", {
          orgId: orgId,
          appId: selectedApp.appId,
          appstoreAppId: selectedApp.appstoreAppId,
          appstore: selectedAppstores,
          countryCode3: selectedCountries,
          dateFrom: dateFrom,
          dateTo: dateTo,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            const dataFilter = data.data;
            setAppstores(getAppstoreMappingValues(dataFilter.appstores));
            setCountries(countryMappingFilter(dataFilter.countryCodes3));
            setFilterId(dataFilter.filterId);
            setSuccessfulFetchFilters(true);
          }
        })
        .catch(informer.showErrorNotice);
    }
    setLoadingFetchFilters(false);
  };

  const fetchConcepts = async () => {
    if (isDev) {
      setConcepts(await require("../../../mocks/concepts.json").data);
    } else {
      await adwizyApi
        .get("/report/concept-mapping/concepts", {
          orgId: orgId,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            setConcepts(data.data);
          }
        })
        .catch(informer.showErrorNotice);
    }
  };

  const fetchAssets = async () => {
    if (isDev) {
      await sleep(2000);
      setAssets(await require("../../../mocks/assets.json").data);
    } else {
      await adwizyApi
        .get("/report/concept-mapping/assets", {
          orgId: orgId,
          filterId: filterId,
          forceConversion: user.forceConversion,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            const assets = data.data;
            const assetTypes = {};
            const networks = {};
            const campaignIds = {};
            const assetCampaignIds = [new Map()];
            let index = 0;
            for (const assetId in assets) {
              const asset = assets[assetId];
              const assetType = asset.assetType;
              const network = asset.network;
              assetTypes[assetType] = AssetTypesEnum[assetType];
              networks[network] = getTitleOfNetwork(network);
              for (const campaignId in asset.campaigns) {
                campaignIds[campaignId] = asset.campaigns[campaignId];
              }

              if (assetCampaignIds[index].size >= 100) {
                index++;
                assetCampaignIds.push(new Map());
              }

              if (asset.isTemporalPreview) {
                assetCampaignIds[index].set(
                  asset.assetId,
                  Object.keys(asset.campaigns).map((it) => parseInt(it))
                );
              }
            }

            if (selectedApp.appstoreAppId) {
              setSelectedAppString(
                "appstoreAppId:" + selectedApp.appstoreAppId
              );
            }
            if (selectedApp.appId) {
              setSelectedAppString("appId:" + selectedApp.appId);
            }
            setAssetTypesOfResultAssets(assetTypes);
            setNetworksOfResultAssets(networks);
            setCampaignIdsOfResultAssets(campaignIds);
            setAssets(assets);
            setChunkOfAssetCampaignIds(assetCampaignIds);
          } else {
            setAssetTypesOfResultAssets(null);
            setNetworksOfResultAssets(null);
            setCampaignIdsOfResultAssets(null);
            setAssets({});
          }
        })
        .catch((error) => {
          if (error?.response?.status === 417 && successfulFetchFilters) {
            setFilterId(null);
            fetchFilters();
          } else {
            informer.showErrorNotice(error);
            setAssets({});
          }
        });
    }
    await clearSelection();
    setLoadingFetchAssets(false);
  };

  const fetchConceptAssetIds = async (assetId) => {
    if (!isDev) {
      await adwizyApi
        .get("/report/concept-mapping/concept-assets", {
          orgId: orgId,
          assetId: assetId,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === false) {
            return;
          }
          const newAssets = { ...assets };
          newAssets[assetId].conceptIds = data.data.conceptIds;
          setAssets(newAssets);
        });
    }
  };

  useEffect(() => {
    if (
      user.isAllowReport(ReportTypes.creativeAnalysis) &&
      (selectedApp.appstoreAppId || selectedApp.appId) &&
      selectedAppstores &&
      selectedCountries &&
      dateFrom &&
      dateTo
    ) {
      setLoadingFetchAssets(true);
      fetchFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterKey,
    selectedApp,
    selectedAppstores,
    selectedCountries,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    if (filterId) {
      (async () => {
        setLoadingFetchAssets(true);
        await fetchConcepts();
        await fetchAssets();
      })();
    }
  }, [filterId, user.forceConversion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAppId(AppId(selectedApp).toString());
  }, [selectedApp]);

  useEffect(() => {
    const handler = getMetricsScoresAndRecsHandlerForCreatives(
      setFilterKey,
      setAppId
    );
    event
      .subscribe(events.orgAppMetricsUpdated, handler)
      .catch(informer.showErrorNotice);
    return () => event.unsubscribe(events.orgAppMetricsUpdated, handler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedAsset = (evt) => {
    const value = parseInt(evt.target.value);
    const assetIds = selectedAssetIds;
    const index = assetIds.indexOf(value);

    if (index !== -1) {
      assetIds.splice(index, 1);
    } else {
      assetIds.push(value);
    }

    if (assetIds.length === 0) {
      clearSelection();
    } else {
      setSelectedAssetIds([...assetIds]);
    }
  };

  const selectAllAssets = (selectedAssets) => {
    if (selectedAll) {
      clearSelection();
    } else {
      setSelectedAssetIds([...selectedAssets.map((asset) => asset.assetId)]);
      setSelectedAll(true);
    }
  };

  const clearSelection = () => {
    setSelectedAll(false);
    setSelectedAssetIds([]);
  };

  const deleteConcept = async (assetId, conceptId) => {
    if (isDev) {
      await sleep();
      setAssets((prevState) => [
        ...prevState.map((asset) => {
          if (asset.assetId === assetId) {
            return Object.assign(asset, {
              conceptIds: asset.conceptIds.filter(
                (assetConceptId) => assetConceptId !== conceptId
              ),
            });
          }
          return asset;
        }),
      ]);
    } else {
      await adwizyApi
        .delete("/report/concept-mapping/concept-assets", {
          orgId: orgId,
          assetIds: [assetId],
          conceptIds: [conceptId],
        })
        .then((res) => {
          if (res.data.success === true) {
            fetchConceptAssetIds(assetId);
          }
        });
    }
  };

  const addNewConcept = async (concept) => {
    if (isDev) {
      await sleep();
      const conceptMaxId = concepts.reduce((acc, val) => {
        if (acc < val.conceptId) {
          acc = val.concepId;
        }
        return acc;
      }, 1);

      setConcepts((prevState) => [
        ...prevState,
        { conceptId: conceptMaxId + 1, conceptName: concept },
      ]);
    } else {
      return adwizyApi
        .post("/concept", {
          orgId: orgId,
          conceptName: concept,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            fetchConcepts();
          }
          return res;
        });
    }
  };

  const changeAssetConcepts = async (assetId, conceptIds) => {
    if (isDev) {
      await sleep();
      setAssets((prevState) => [
        ...prevState.map((asset) => {
          if (asset.assetId === assetId) {
            return Object.assign(asset, { conceptIds: conceptIds });
          }
          return asset;
        }),
      ]);
    } else {
      await adwizyApi
        .post("/report/concept-mapping/concept-assets", {
          orgId: orgId,
          assetIds: [assetId],
          conceptIds: conceptIds.map((it) => parseInt(it)),
        })
        .then((res) => {
          if (res.data.success === true) {
            fetchConceptAssetIds(assetId);
          }
        });
    }
  };

  const getConcepts = () => {
    return Object.fromEntries(
      Object.values(concepts).map((concept) => [
        concept.conceptId,
        concept.conceptName,
      ])
    );
  };

  const getConceptsSelectedAssets = () => {
    const conceptIds = [];

    for (const assetId in assets) {
      if (!selectedAssetIds.includes(assets[assetId].assetId)) {
        continue;
      }

      for (const conceptId of assets[assetId].conceptIds) {
        if (!conceptIds.includes(conceptId)) {
          conceptIds.push(conceptId);
        }
      }
    }

    return Object.fromEntries(
      Object.values(concepts)
        .filter((concept) => conceptIds.includes(concept.conceptId))
        .map((concept) => [concept.conceptId, concept.conceptName])
    );
  };

  const changeConceptIdsChecked = (conceptId) => {
    const conceptIds = conceptIdsChecked;
    const index = conceptIds.indexOf(conceptId);

    if (index !== -1) {
      conceptIds.splice(index, 1);
    } else {
      conceptIds.push(conceptId);
    }

    setConceptIdsChecked([...conceptIds]);
  };

  const resetConceptIdsChecked = async () => setConceptIdsChecked([]);

  const handleAddConcepts = async () => {
    setLoadingConceptChange(true);
    if (isDev) {
      await sleep();
      setAssets((prevState) => [
        ...prevState.map((asset) => {
          if (selectedAssetIds.includes(asset.assetId)) {
            const assetConceptIds = asset.conceptIds;
            for (const conceptId of conceptIdsChecked) {
              if (!assetConceptIds.includes(conceptId)) {
                assetConceptIds.push(conceptId);
              }
            }
            return Object.assign(asset, { conceptIds: assetConceptIds });
          }
          return asset;
        }),
      ]);
    } else {
      await adwizyApi
        .post("/report/concept-mapping/concept-assets", {
          orgId: orgId,
          assetIds: selectedAssetIds,
          conceptIds: conceptIdsChecked.map((it) => parseInt(it)),
        })
        .then(async (res) => {
          if (res.data.success === true) {
            setLoadingFetchAssets(true);
            await fetchAssets();
            setLoadingFetchAssets(false);
          }
        });
    }
    await resetConceptIdsChecked();
    setLoadingConceptChange(false);
  };

  const handleDeleteConcepts = async () => {
    setLoadingConceptChange(true);
    if (isDev) {
      await sleep();
      setAssets((prevState) => [
        ...prevState.map((asset) => {
          if (selectedAssetIds.includes(asset.assetId)) {
            return Object.assign(asset, {
              conceptIds: asset.conceptIds.filter(
                (conceptID) => !conceptIdsChecked.includes(conceptID)
              ),
            });
          }
          return asset;
        }),
      ]);
    } else {
      await adwizyApi
        .delete("/report/concept-mapping/concept-assets", {
          orgId: orgId,
          assetIds: selectedAssetIds,
          conceptIds: conceptIdsChecked,
        })
        .then(async (res) => {
          if (res.data.success === true) {
            setLoadingFetchAssets(true);
            await fetchAssets();
            setLoadingFetchAssets(false);
          }
        });
    }
    await resetConceptIdsChecked();
    setLoadingConceptChange(false);
  };

  const handleSetFilter = (prevState, val) => {
    if (!prevState) {
      return val;
    }
    return !arrayEquals(prevState, val) ? val : prevState;
  };

  const handleTemporalAssetUrl = async (assetId) => {
    setLoadingTemporalAssetUrlsResponse(true);
    const assetCampaignIds = {};
    for (const chunk of chunkOfAssetCampaignIds) {
      if (Array.from(chunk.keys()).includes(assetId)) {
        for (let pair of chunk.entries()) {
          assetCampaignIds[pair[0]] = pair[1];
        }
      }
    }

    if (Object.keys(assetCampaignIds).length) {
      await adwizyApi
        .post("/report/concept-mapping/asset-urls", {
          orgId,
          assetCampaignIds,
        })
        .then((res) => {
          if (res.data.success === true) {
            const assetUrls = res.data.data;
            const newAssets = { ...assets };
            for (assetId in assetUrls) {
              newAssets[assetId].assetUrl = assetUrls[assetId].url;
              newAssets[assetId].previewUrl = assetUrls[assetId].preview_url;
              newAssets[assetId].isTemporalPreview = false;
            }
            setAssets(newAssets);
          }
        });
    }
    setLoadingTemporalAssetUrlsResponse(false);
  };

  return (
    <MainTemplate
      isAllowed={user.isAllowReport(ReportTypes.creativeAnalysis)}
      title="Concept mapping"
      subtitle={
        <DropdownApp
          title="App name"
          handleSelected={handleSelectApp}
          disabled={loadingFetchFilters || loadingFetchAssets}
        />
      }
      filters={[
        {
          title: "Store",
          values: appstores,
          handleSelected: (val) =>
            setSelectedAppstores((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedAppstores,
          multiple: true,
          toFilterValues: true,
          getParam: "appstores",
          disabled: loadingFetchFilters || loadingFetchAssets,
          loading: loadingFetchFilters,
        },
        {
          title: "Country",
          values: countries,
          handleSelected: (val) =>
            setSelectedCountries((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedCountries,
          multiple: true,
          toFilterValues: true,
          getParam: "countries",
          disabled: loadingFetchFilters || loadingFetchAssets,
          loading: loadingFetchFilters,
        },
      ]}
      datepicker={{
        onChange: ({ start, end }) => {
          setDateFrom(start.format(dateSendFormat));
          setDateTo(end.format(dateSendFormat));
        },
        disabled: loadingFetchFilters || loadingFetchAssets,
        withContext: true,
        withQuery: true,
      }}
      dataActionColumns
      creativePagesStyles
    >
      <CTable
        className={loadingFetchAssets ? "" : classes.table}
        loading={loadingFetchAssets}
        rows={assets}
        settings={{
          columns: {
            assetId: {
              title: (additional, rows) => (
                <Checkbox
                  className="p-0 checkbox"
                  checked={additional.selectedAll}
                  onChange={() => selectAllAssets(rows)}
                />
              ),
              format: types.custom,
              component: (asset, props) => (
                <Checkbox
                  className="p-0 checkbox"
                  value={asset.assetId}
                  checked={
                    //eslint-disable-next-line react/prop-types
                    props.selectedAssetIds?.includes(asset.assetId)
                  }
                  onChange={handleSelectedAsset}
                />
              ),
              sx: {
                width: "2.11rem",
              },
            },
            order: {
              title: () => "#",
              format: types.order,
              sx: {
                width: "2.33rem",
              },
            },
            name: {
              title: () => "Creative",
              format: types.custom,
              includeTextConditions: true,
              includeSort: true,
              component: (asset) => (
                <Box
                  sx={{ display: "grid", gridTemplateColumns: "1fr 1.5rem" }}
                >
                  <Box sx={{ wordWrap: "anywhere" }}>{asset.name}</Box>
                  <PreviewContentAsset
                    assetId={asset.assetId}
                    assetUrl={asset.assetUrl}
                    previewUrl={asset.previewUrl}
                    assetType={asset.assetType}
                    isTemporalPreview={asset.isTemporalPreview}
                    handleTemporalAssetUrl={handleTemporalAssetUrl}
                    isWaitingForResponse={loadingTemporalAssetUrlsResponse}
                  />
                </Box>
              ),
              sx: {
                minWidth: "11rem",
                maxWidth: "12rem",
              },
            },
            assetType: {
              title: () => "Type",
              format: types.custom,
              component: (asset) => AssetTypesEnum[asset.assetType],
              includeTextConditions: true,
              includeSort: true,
              customConditions: assetTypesOfResultAssets,
              sx: {
                width: "6.11rem",
              },
            },
            campaigns: {
              title: () => "Campaigns",
              format: types.custom,
              component: (asset) => (
                <CampaignList
                  values={asset.campaigns}
                  orgId={orgId}
                  appId={selectedAppString}
                />
              ),
              customConditions: campaignIdsOfResultAssets,
              customConditionsCallback: (asset, val) =>
                Object.keys(asset.campaigns).filter(
                  (campaignId) => val.indexOf(campaignId) !== -1
                ).length !== 0,
              includeArrayConditions: true,
              sx: {
                width: "11.11rem",
                maxWidth: "11.11rem",
              },
            },
            network: {
              title: () => "Network",
              format: types.custom,
              component: (asset) => (
                <Box
                  className="d-flex justify-content-center"
                  sx={{
                    paddingRight: "1.16rem",
                  }}
                >
                  <CTooltip
                    customLabel={
                      <div>
                        <NetworkIcon
                          network={asset.network}
                          className="table-icon"
                        />
                      </div>
                    }
                    content={getTitleOfNetwork(asset.network)}
                  />
                </Box>
              ),
              includeTextConditions: true,
              customConditions: networksOfResultAssets,
              includeSort: true,
              sx: {
                width: "5.55rem",
                maxWidth: "5.55rem",
              },
            },
            cost: {
              title: () => "Cost",
              includeNumberConditions: true,
              includeSort: true,
              format: types.money,
              sx: {
                width: "5.55rem",
              },
            },
            cpa: {
              title: () => "CPA",
              includeNumberConditions: true,
              includeSort: true,
              format: types.money,
              sx: {
                width: "4.44rem",
              },
            },
            conceptIds: {
              title: () => "Concepts",
              format: types.custom,
              component: (asset) => (
                <AssetConcepts
                  conceptIds={asset.conceptIds}
                  concepts={concepts}
                  onDeleteConcept={(conceptId) =>
                    deleteConcept(asset.assetId, conceptId)
                  }
                  onAddNewConcept={addNewConcept}
                  onChangeConcepts={(conceptIds) =>
                    changeAssetConcepts(asset.assetId, conceptIds)
                  }
                />
              ),
              customConditions: getConcepts(),
              customConditionsCallback: (asset, val) =>
                asset.conceptIds.filter(
                  (conceptId) => val.indexOf(conceptId.toString()) !== -1
                ).length !== 0,
              includeArrayConditions: true,
              sx: {
                width: "14.44rem",
                minWidth: "14.44rem",
                maxWidth: "14.44rem",
              },
            },
          },
          activeRules: {
            field: "assetId",
            //eslint-disable-next-line react/prop-types
            cb: (val, props) => props.selectedAssetIds?.includes(val),
          },
          default: {
            order: "desc",
            orderBy: "cost",
          },
        }}
        topArea2={
          <Box sx={{ minHeight: "3.56rem" }}>
            {selectedAssetIds.length > 0 && (
              <Box className={classes.topPanel}>
                <Box className="d-flex align-items-center">
                  <p className={classes.topPanelText}>
                    {selectedAssetIds.length} creatives selected
                  </p>
                  <CButton
                    sx={{ fontWeight: 500 }}
                    onClick={clearSelection}
                    disabled={loadingFetchAssets}
                  >
                    Clear selection
                  </CButton>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ConceptActions
                    btn={
                      <CButton
                        variant="outlined"
                        sx={{ padding: "0.39rem 1.22rem" }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Apply concepts
                          <KeyboardArrowDownIcon />
                        </Box>
                      </CButton>
                    }
                    title="Select concepts to add to creatives"
                    loading={loadingConceptChange}
                    handleOk={handleAddConcepts}
                    handleCancel={resetConceptIdsChecked}
                    concepts={getConcepts()}
                    conceptIdsChecked={conceptIdsChecked}
                    changeConceptIdsChecked={changeConceptIdsChecked}
                    onAddNewConcept={addNewConcept}
                    allowCreateNewConcept
                  />

                  <ConceptActions
                    btn={
                      <CButton
                        variant="contained"
                        sx={{ marginLeft: "0.92rem" }}
                      >
                        Clear concepts
                      </CButton>
                    }
                    title="Select concepts to remove from creatives"
                    loading={loadingConceptChange}
                    handleOk={() => setIsOpenAttentionDialog(true)}
                    handleCancel={resetConceptIdsChecked}
                    concepts={getConceptsSelectedAssets()}
                    conceptIdsChecked={conceptIdsChecked}
                    changeConceptIdsChecked={changeConceptIdsChecked}
                    onAddNewConcept={addNewConcept}
                  />

                  <CDialog
                    open={isOpenAttentionDialog}
                    title="Attention"
                    content="Are you sure you want to clear all concepts on selected creatives? This action cannot be undone."
                    applyBtnCb={() =>
                      handleDeleteConcepts().then(() =>
                        setIsOpenAttentionDialog(false)
                      )
                    }
                    cancelBtnCb={() => {
                      resetConceptIdsChecked().then(() =>
                        setIsOpenAttentionDialog(false)
                      );
                    }}
                    applyBtnText="Clear"
                    maxWidth="xs"
                    loading={loadingConceptChange}
                  />
                </Box>
              </Box>
            )}
          </Box>
        }
        additional={{
          selectedAssetIds: selectedAssetIds,
          selectedAll: selectedAll,
        }}
      />
    </MainTemplate>
  );
};

ConceptMappingPageContent.propTypes = {
  orgId: PropTypes.number,
  selectedApp: PropTypes.object,
  handleSelectApp: PropTypes.func,
};

const ConceptMappingPage = () => {
  const params = useParams();
  const orgId = Number(params.orgId);
  const [key, setKey] = useState(new Date().getTime());
  const [selectedApp, setSelectedApp] = useState({
    appstoreAppId: null,
    appId: null,
  });

  const handleSelectApp = (val) => {
    setSelectedApp((prevState) => {
      if (
        (prevState.appstoreAppId !== null || prevState.appId !== null) &&
        JSON.stringify(prevState) !== JSON.stringify(val)
      ) {
        setKey(new Date().getTime());
      }
      return JSON.stringify(prevState) !== JSON.stringify(val)
        ? val
        : prevState;
    });
  };

  return (
    <ConceptMappingPageContent
      key={key}
      orgId={orgId}
      selectedApp={selectedApp}
      handleSelectApp={handleSelectApp}
    />
  );
};

export default ConceptMappingPage;
