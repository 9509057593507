import React from "react";
import { CircularProgress, Box } from "@mui/material";
import PropTypes from "prop-types";

const Preloader = ({ size, progress, sx }) => (
  <Box display="flex" justifyContent="center" alignItems="center">
    <CircularProgress
      size={size || 64}
      variant={progress != null ? "determinate" : undefined}
      value={progress}
      sx={sx}
    />
  </Box>
);

Preloader.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  progress: PropTypes.number,
  sx: PropTypes.object,
};

export default Preloader;
