import React, { useState } from "react";
import AuthTemplate, {
  validatePassword,
  validatePasswordRepeat,
  generatePassword,
  defaultValidate,
} from "../../templates/AuthTemplate";
import PasswordInput from "../../components/AuthTemplate/PasswordInput";
import { Box, Button } from "@mui/material";
import CInput from "../../components/AuthTemplate/CInput";
import GoBack from "../../components/common/GoBack";
import { isDev } from "../../utils";
import adwizyApi from "../../api/adwizyApi";
import { useInformer } from "../../context/InformerProvider";
import { useLocation, useNavigate } from "react-router-dom";
import Preloader from "../../components/Preloader";

const SetNewPasswordPage = () => {
  const informer = useInformer();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(location.hash.split("#code=")[1] || "");
  const [codeError, setCodeError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordHasError, setPasswordHasError] = useState(false);
  const [passwordHelper, setPasswordHelper] = useState(null);
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordRepeatError, setPasswordRepeatError] = useState("");

  const validateCode = (code) => defaultValidate("Code", code, setCodeError);

  const submit = async () => {
    let isOk = validatePassword(password, setPasswordHelper);
    setPasswordHasError(!isOk);
    isOk = validateCode(code) && isOk;
    isOk =
      validatePasswordRepeat(
        password,
        passwordRepeat,
        setPasswordRepeatError
      ) && isOk;

    if (isOk && !isDev) {
      setLoading(true);

      await adwizyApi
        .post("/auth/set-new-password", {
          code,
          password: generatePassword(password),
        })
        .then((res) => {
          if (res.data.success) {
            navigate("/auth/sign-in");
          }
        })
        .catch(informer.showErrorNotice);

      setLoading(false);
    }
  };

  return (
    <AuthTemplate>
      <div className="window">
        <GoBack />
        <div className="title">Restore password</div>
        <div className="subtitle">
          We have sent an activation code to your mail. Please check the code
          and insert it in the next field
        </div>
        <div className="input_group">
          <CInput
            id="code"
            label="Code"
            value={code}
            hasError={codeError !== ""}
            helperText={codeError}
            onChange={(e) => {
              setCode(e.target.value);
              validateCode(e.target.value);
            }}
            disabled={loading}
            submit={submit}
          />
          <PasswordInput
            id="password"
            value={password}
            hasError={passwordHasError}
            helperText={passwordHelper}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordHasError(
                !validatePassword(e.target.value, setPasswordHelper)
              );
              validatePasswordRepeat(
                e.target.value,
                passwordRepeat,
                setPasswordRepeatError
              );
            }}
            disabled={loading}
            submit={submit}
          />
          <PasswordInput
            id="password_repeat"
            label="Repeat password"
            value={passwordRepeat}
            hasError={passwordRepeatError !== ""}
            helperText={passwordRepeatError}
            onChange={(e) => {
              setPasswordRepeat(e.target.value);
              validatePasswordRepeat(
                password,
                e.target.value,
                setPasswordRepeatError
              );
            }}
            disabled={loading}
            submit={submit}
          />
        </div>
        <div className="actions">
          <Button
            className="button"
            variant="contained"
            onClick={submit}
            color="primary"
            disabled={
              loading ||
              !code ||
              !!codeError ||
              !password ||
              passwordHasError ||
              !passwordRepeat ||
              !!passwordRepeatError
            }
          >
            Set new password
            {loading && (
              <Box display="flex" alignItems="center" marginLeft={1}>
                <Preloader size={30} />
              </Box>
            )}
          </Button>
        </div>
      </div>
    </AuthTemplate>
  );
};

export default SetNewPasswordPage;
