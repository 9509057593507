import React from "react";
import PropTypes from "prop-types";
import AppStoreIcon from "../icons/AppStoreIcon";
import GooglePlayIcon from "../icons/GooglePlayIcon";
import FolderIcon from "../icons/FolderIcon";
import { AppstoreTypesEnum } from "../../enum/AppstoreTypes";
import { AutoAwesomeMotion } from "@mui/icons-material";

const AppMiniIcon = ({ appstore, isAppMap, color, sx }) => {
  if (isAppMap) {
    return (
      <FolderIcon
        color={color}
        sx={{
          ...{
            width: "1rem",
            height: ".77rem",
          },
          ...sx,
        }}
      />
    );
  }

  if (appstore === AppstoreTypesEnum.Apple) {
    return (
      <AppStoreIcon
        color={color}
        sx={{
          ...{
            width: "1rem",
            height: "1rem",
          },
          ...sx,
        }}
      />
    );
  }

  if (appstore === AppstoreTypesEnum.Google) {
    return (
      <GooglePlayIcon
        color={color}
        sx={{
          ...{
            width: "1rem",
            height: "1rem",
          },
          ...sx,
        }}
      />
    );
  }

  if (appstore === AppstoreTypesEnum.Huawei) {
    //todo
  }

  if (appstore === AppstoreTypesEnum.Omni) {
    return (
      <AutoAwesomeMotion
        color={color}
        sx={{
          ...{
            width: "1rem",
            height: "1rem",
          },
          ...sx,
        }}
      />
    );
  }
};

AppMiniIcon.propTypes = {
  appstore: PropTypes.oneOf(Object.values(AppstoreTypesEnum)),
  isAppMap: PropTypes.bool,
  color: PropTypes.string,
  sx: PropTypes.object,
};

export default AppMiniIcon;
