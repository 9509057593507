import React, { useEffect, useState } from "react";
import "../styles/AuthTemplate.sass";
import { Link, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import Sha1 from "sha.js/sha1";
import CScrollbars from "../components/CScrollbars";
import { PropTypes } from "prop-types";
import { useAuth } from "../context/AuthProvider";
import PasswordHelper from "../components/AuthTemplate/PasswordHelper";

export const defaultValidate = (label, value, errorSetter) => {
  let result = /\S/.test(value);

  if (errorSetter) {
    result ? errorSetter("") : errorSetter(`${label} isn't set`);
  }

  return result;
};

export const validateEmail = (email, setEmailError) => {
  if (!defaultValidate("Email", email, setEmailError)) {
    return false;
  }
  const maxLength = 180;
  if (email.length > maxLength) {
    setEmailError(
      `This value is too long. It should have ${maxLength} characters or less.`
    );
    return false;
  }

  let re =
    /^([0-9A-Za-z]([-_.]*[0-9A-Za-z])*)@((?=[^.]{1,64})((xn--[a-zA-Z0-9]+)|([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*))\.){1,126}(?=[^.]{1,64}$)((xn--)?[a-zA-Z0-9]+)$/;
  let result = re.test(email);
  if (setEmailError) {
    result ? setEmailError("") : setEmailError("Incorrect email");
  }

  return result;
};

export const deepValidateEmail = (
  email,
  setEmailError,
  blockedEmails,
  blockEmailError
) => {
  if (!validateEmail(email, setEmailError)) {
    return false;
  }

  let result = !(Array.isArray(blockedEmails) && blockedEmails.includes(email));

  if (setEmailError) {
    result
      ? setEmailError("")
      : setEmailError(blockEmailError || "Incorrect email");
  }

  return result;
};

export const validatePassword = (password, setPasswordHelper) => {
  let length = password.length >= 8 && password.length <= 32;
  let register = /(.*[a-z].*[A-Z].*)|(.*[A-Z].*[a-z].*)/.test(password);
  let digits = /[0-9]/.test(password);
  let symbols = /[!@#$%^&*()_\-+=[\]{}"'/\\?.,<>;:]+/.test(password);
  let forbidden = password
    .match(/[^a-zA-Z0-9!@#$%^&*()_\-+=[\]{}"'/\\?.,<>;:]/g)
    ?.filter((it, i, arr) => arr.indexOf(it) === i)
    .map((it) => (it === " " ? 'symbol "space"' : it));

  setPasswordHelper &&
    setPasswordHelper(
      <PasswordHelper
        length={length}
        register={register}
        digits={digits}
        symbols={symbols}
        forbidden={forbidden}
      />
    );

  return length && register && digits && symbols && !forbidden;
};

export const validatePasswordRepeat = (
  password,
  passwordRepeat,
  setPasswordRepeatError
) => {
  let result = password === passwordRepeat;

  if (setPasswordRepeatError) {
    result
      ? setPasswordRepeatError("")
      : setPasswordRepeatError("Passwords don't match");
  }

  return result;
};

export const generatePassword = (password) => {
  const hash = new Sha1();
  return hash.update(hash.update(password).digest("hex")).digest("hex");
};

const AuthTemplate = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  const [optionalLink, setOptionalLink] = useState();

  useEffect(() => {
    setOptionalLink(
      auth.isAuthorized
        ? {
            link: "/auth/sign-out",
            label: "Sign out",
          }
        : location.pathname.indexOf("sign-in") === -1
        ? {
            link: "/auth/sign-in",
            label: "Sign in",
          }
        : {
            link: "/auth/sign-up",
            label: "Sign up",
          }
    );
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CScrollbars
      style={{ height: "100vh" }}
      content={{ id: "auth_template" }}
      vertical={{
        track: { className: "scrollbar" },
        thumb: { className: "scrollbar_thumb" },
      }}
    >
      <Box
        id="header"
        position="fixed"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Link to="/" className="logo">
          <img src={"/static/logo.svg"} alt="Logo" />
        </Link>
        <Box className="header__nav" display="flex">
          <a
            href="https://adwizy.io/#adwizyCompany"
            className="header__nav_item"
            target="_blank"
            rel="noreferrer"
          >
            Company
          </a>
          <a
            href="https://adwizy.io/#adwizyPricing"
            className="header__nav_item"
            target="_blank"
            rel="noreferrer"
          >
            Pricing
          </a>
          {optionalLink && (
            <Link to={optionalLink.link} className="header__nav_item">
              <strong>{optionalLink.label}</strong>
            </Link>
          )}
        </Box>
      </Box>
      <div className="ellipse top" />
      <div className="ellipse bot" />
      <Box
        id="content"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Box>
    </CScrollbars>
  );
};

AuthTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthTemplate;
