import React, { useEffect, useState } from "react";
import {
  arrayEquals,
  dateSendFormat,
  getPeriods,
  isDev,
  networkMappingFilter,
  sleep,
} from "../../../utils";
import {
  metrics,
  getMetricList,
  reverseGrowthMetrics,
} from "../../../enum/Metrics";
import InfoBar from "../../../components/common/InfoBar";
import { useUser } from "../../../context/UserProvider";
import adwizyApi from "../../../api/adwizyApi";
import { useParams } from "react-router-dom";
import DropdownApp from "../../../components/common/DropdownApp";
import { useInformer } from "../../../context/InformerProvider";
import ReportChartsBlock from "../../../components/charts/ReportChartsBlock";
import MainTemplate from "../../../templates/MainTemplate";
import { types as infoPanelTypes } from "../../../components/common/InfoPanel";
import { getMetricsScoresAndRecsHandlerForCreatives } from "../../../helpers/eventHandlers";
import { events, useEvent } from "../../../context/EventProvider";
import AppId from "../../../helpers/appId";
import { getAppstoreMappingValues } from "../../../enum/AppstoreTypes";
import {
  DimensionsEnum,
  getDimensionsMappingValues,
} from "../../../enum/Dimensions";
import { ReportTypes } from "../../../enum/ReportTypes";
import TabBlock from "../../../components/TabBlock";
import AssetInsightsVideo from "../../../components/AssetInsightsVideo";
import PropTypes from "prop-types";

const AssetInsightsPageContent = ({ orgId, selectedApp, handleSelectApp }) => {
  const user = useUser();
  const event = useEvent();
  const informer = useInformer();

  const [loading, setLoading] = useState(true);
  const [loadingFetchFilters, setLoadingFetchFilters] = useState(true);
  const [loadingScore, setLoadingScore] = useState(true);
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [loadingVideo, setLoadingVideo] = useState(true);
  const [score, setScore] = useState(null);
  const [appstores, setAppstores] = useState();
  const [assetTypes, setAssetTypes] = useState();
  const [networks, setNetworks] = useState();
  const [accounts, setAccounts] = useState();
  const [selectedAppstores, setSelectedAppstores] = useState();
  const [selectedAssetTypes, setSelectedAssetTypes] = useState();
  const [selectedNetworks, setSelectedNetworks] = useState();
  const [selectedAccounts, setSelectedAccounts] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [, setAppId] = useState();
  const [filterId, setFilterId] = useState(null);
  const [filterKey, setFilterKey] = useState(0);
  const [successfulFetchFilters, setSuccessfulFetchFilters] = useState(null);

  const fetchFilters = async () => {
    setLoadingFetchFilters(true);
    setLoadingScore(true);
    setLoadingTotal(true);
    setLoadingVideo(true);
    if (isDev) {
      let response;
      if (Object.keys(appstores).length === 0) {
        response = await require("../../../mocks/filters__asset-insights.json")
          .data;
      } else {
        response =
          await require("../../../mocks/filters__asset-insights_other.json")
            .data;
      }
      setAppstores(response.appstores);
      setAssetTypes(response.assetTypes);
      setNetworks(response.networks);
      setAccounts(response.accounts);
    } else {
      setSuccessfulFetchFilters(false);
      await adwizyApi
        .get("/report/asset-insights/filter", {
          orgId: orgId,
          appId: selectedApp.appId,
          appstoreAppId: selectedApp.appstoreAppId,
          appstore: selectedAppstores,
          assetType: selectedAssetTypes,
          network: selectedNetworks,
          accountId: selectedAccounts,
          dateFrom: dateFrom,
          dateTo: dateTo,
        })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            const dataFilter = data.data;
            setAppstores(getAppstoreMappingValues(dataFilter.appstores));
            setAssetTypes(
              Object.keys(dataFilter.assetTypes).length
                ? dataFilter.assetTypes
                : {}
            );
            setNetworks(networkMappingFilter(dataFilter.networks));
            setAccounts(
              Object.keys(dataFilter.accounts).length ? dataFilter.accounts : {}
            );
            setFilterId((prevState) => {
              if (prevState === dataFilter.filterId) {
                setLoadingScore(false);
                setLoadingTotal(false);
                setLoadingVideo(false);
              }
              return dataFilter.filterId;
            });
            setSuccessfulFetchFilters(true);
          }
        })
        .catch(informer.showErrorNotice);
    }
    setLoadingFetchFilters(false);
  };

  const handleErrorFilter = (error) => {
    if (error?.response?.status === 417 && successfulFetchFilters) {
      setFilterId(null);
      fetchFilters();
    } else {
      informer.showErrorNotice(error);
    }
  };

  const fetchScore = async () => {
    setLoadingScore(true);
    if (isDev) {
      await sleep(500);
      const response = await require("../../../mocks/report__asset.json").data;
      setScore(response);
    } else {
      await adwizyApi
        .get("/report/asset-insights/score-card", {
          orgId: orgId,
          filterId: filterId,
          forceConversion: user.forceConversion,
        })
        .then((res) => {
          if (res.data.success) {
            const score = res.data.data;
            const periods = getPeriods({ dateFrom, dateTo });

            setScore([
              {
                title: "Total cost",
                currentValue: score.cost?.currentValue,
                previousValue: score.cost?.previousValue,
                currency: score.cost?.currency,
                periods,
              },
              {
                title: "CPA",
                currentValue: score.cpa?.currentValue,
                previousValue: score.cpa?.previousValue,
                currency: score.cpa?.currency,
                periods,
                reverse: reverseGrowthMetrics.includes(metrics.cpa),
              },
              {
                title: "Installs",
                currentValue: score.installs?.currentValue,
                previousValue: score.installs?.previousValue,
                type: infoPanelTypes.number,
                periods,
              },
            ]);
          }
        })
        .catch((error) => handleErrorFilter(error));
    }
    setLoadingScore(false);
  };

  const handleSetFilter = (prevState, val) => {
    if (!prevState) {
      return val;
    }
    return !arrayEquals(prevState, val) ? val : prevState;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      user.isAllowReport(ReportTypes.creativeAnalysis) &&
      (selectedApp.appstoreAppId || selectedApp.appId) &&
      selectedAppstores &&
      selectedAssetTypes &&
      selectedNetworks &&
      selectedAccounts &&
      dateFrom &&
      dateTo
    ) {
      fetchFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterKey,
    selectedApp,
    selectedAppstores,
    selectedAssetTypes,
    selectedNetworks,
    selectedAccounts,
    dateFrom,
    dateTo,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (filterId) {
      fetchScore();
    }
  }, [filterId, user.forceConversion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAppId(AppId(selectedApp).toString());
  }, [selectedApp]);

  useEffect(() => {
    const handler = getMetricsScoresAndRecsHandlerForCreatives(
      setFilterKey,
      setAppId
    );
    event
      .subscribe(events.orgAppMetricsUpdated, handler)
      .catch(informer.showErrorNotice);
    return () => event.unsubscribe(events.orgAppMetricsUpdated, handler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !loadingFetchFilters &&
      !loadingScore &&
      (!loadingTotal || !loadingVideo)
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loadingFetchFilters, loadingScore, loadingTotal, loadingVideo]);

  return (
    <MainTemplate
      isAllowed={user.isAllowReport(ReportTypes.creativeAnalysis)}
      title="Asset insights"
      subtitle={
        <DropdownApp
          title="App name"
          handleSelected={handleSelectApp}
          disabled={loading}
        />
      }
      filters={[
        {
          title: "Store",
          values: appstores,
          handleSelected: (val) =>
            setSelectedAppstores((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedAppstores,
          multiple: true,
          toFilterValues: true,
          getParam: "appstores",
          disabled: loading,
          loading: loadingFetchFilters,
        },
        {
          title: "Asset type",
          values: assetTypes,
          handleSelected: (val) =>
            setSelectedAssetTypes((prevState) =>
              handleSetFilter(prevState, val)
            ),
          selectedValue: selectedAssetTypes,
          multiple: true,
          toFilterValues: true,
          getParam: "assetTypes",
          disabled: loading,
          loading: loadingFetchFilters,
        },
        {
          title: "Network",
          values: networks,
          handleSelected: (val) =>
            setSelectedNetworks((prevState) => handleSetFilter(prevState, val)),
          selectedValue: selectedNetworks,
          multiple: true,
          toFilterValues: true,
          getParam: "networks",
          disabled: loading,
          loading: loadingFetchFilters,
        },
        {
          title: "Account",
          values: accounts,
          handleSelected: (val) =>
            setSelectedAccounts((prevState) => handleSetFilter(prevState, val)),
          selectedValue: selectedAccounts,
          multiple: true,
          toFilterValues: true,
          getParam: "accounts",
          disabled: loading,
          loading: loadingFetchFilters,
        },
      ]}
      datepicker={{
        onChange: ({ start, end }) => {
          setDateFrom(start.format(dateSendFormat));
          setDateTo(end.format(dateSendFormat));
        },
        disabled: loading,
        withContext: true,
        withQuery: true,
      }}
      creativePagesStyles
    >
      <InfoBar
        className="content_block"
        panels={score}
        loading={loadingScore || !score}
        loadingCount={3}
      />

      <TabBlock
        className="content_block"
        queryParam="tab"
        tabs={[
          { label: "Total Insights", value: "total" },
          { label: "Video Insights", value: "video" },
        ]}
        panels={[
          {
            value: "total",
            content: (
              <ReportChartsBlock
                urlRequest="/report/asset-insights/timeline"
                orgId={orgId}
                filterId={filterId}
                forceConversion={user.forceConversion}
                dimensions={getDimensionsMappingValues([
                  DimensionsEnum.network,
                  DimensionsEnum.assetType,
                ])}
                metrics={getMetricList(
                  metrics.installs,
                  metrics.impressions,
                  metrics.impressions,
                  metrics.clicks,
                  metrics.cost
                )}
                handleError={handleErrorFilter}
                loading={loadingTotal}
                handleChangeLoading={setLoadingTotal}
                style={{
                  border: "none",
                  marginBottom: 0,
                }}
              />
            ),
            style: { padding: 0 },
          },
          {
            value: "video",
            content: (
              <AssetInsightsVideo
                orgId={orgId}
                filterId={filterId}
                forceConversion={user.forceConversion}
                handleError={handleErrorFilter}
                loading={loadingVideo}
                handleChangeLoading={setLoadingVideo}
                style={{
                  border: "none",
                  marginBottom: 0,
                }}
              />
            ),
            style: { padding: 0 },
          },
        ]}
        handleChangeTab={() => {
          setLoadingTotal(true);
          setLoadingVideo(true);
        }}
      />
    </MainTemplate>
  );
};

AssetInsightsPageContent.propTypes = {
  orgId: PropTypes.number,
  selectedApp: PropTypes.object,
  handleSelectApp: PropTypes.func,
};

const AssetInsightsPage = () => {
  const params = useParams();
  const orgId = Number(params.orgId);
  const [key, setKey] = useState(new Date().getTime());
  const [selectedApp, setSelectedApp] = useState({
    appstoreAppId: null,
    appId: null,
  });

  const handleSelectApp = (val) => {
    setSelectedApp((prevState) => {
      if (
        (prevState.appstoreAppId !== null || prevState.appId !== null) &&
        JSON.stringify(prevState) !== JSON.stringify(val)
      ) {
        setKey(new Date().getTime());
      }
      return JSON.stringify(prevState) !== JSON.stringify(val)
        ? val
        : prevState;
    });
  };

  return (
    <AssetInsightsPageContent
      key={key}
      orgId={orgId}
      selectedApp={selectedApp}
      handleSelectApp={handleSelectApp}
    />
  );
};

export default AssetInsightsPage;
